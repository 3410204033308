import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CircleImageComponent } from 'src/app/shared/circle-image/circle-image.component';
import { NgxNeoDirectivesModule } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-neo-directives.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxNeoPipesModule } from 'src/app/shared/lib/ngx-neo-pipes/ngx-neo-pipes.module';
import { CdnImageUrlPipe } from 'src/app/shared/pipes/cdn-image-url.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxNeoComponentsModule } from 'src/app/shared/lib/ngx-neo-components-mat/ngx-neo-components.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MobileSidebarComponent } from './mobile-sidebar/mobile-sidebar.component';
import { SelectVistaModalComponent } from './sidebar-menu/select-vista-modal/select-vista-modal.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MobileSidebarComponent,
    SidebarMenuComponent,
    SelectVistaModalComponent,
  ],
  imports: [
    CommonModule,
    NgxNeoComponentsModule,
    RouterModule,
    NgxNeoDirectivesModule,
    SharedModule,
    TranslateModule,
    NgxNeoPipesModule,
    CircleImageComponent,
    CdnImageUrlPipe,
    MatButtonModule,
    MatTabsModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatBadgeModule,
  ],
  exports: [HeaderComponent, FooterComponent, SidebarComponent, MobileSidebarComponent, SidebarMenuComponent],
})
export class CoreModule {}
