import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { ReclutamientoOfertaDTO } from 'src/app/ModelDTO/DTO/reclutamientoOferta.DTO';


export class ReclutamientoOfertaConocimientoDTO implements IEntityDTO {

   oferta: ReclutamientoOfertaDTO;
   conocimiento = '';
   puntaje = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.oferta = new ReclutamientoOfertaDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.oferta != null) { this.oferta.PrepareDTO(jsonObj.oferta); }
      if (jsonObj.conocimiento != null) { this.conocimiento = jsonObj.conocimiento; }
      if (jsonObj.puntaje != null) { this.puntaje = jsonObj.puntaje; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
