import { Injectable } from '@angular/core';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageEntity.DTO';
import { EmpresaStatusDTO } from 'src/app/ModelDTO/DTO/empresaStatus.DTO';
import { BehaviorSubject } from 'rxjs';
import { CURRENT_COMPANY } from 'src/app/shared/localStorageConstants';
import { ProfileImageThumbnailUrlPipe } from 'src/app/shared/pipes/profile-image-thumbnail-url.pipe';
import { Meta } from '@angular/platform-browser';
import { EmpresaDTO } from 'src/app/ModelDTO/DTO/empresa.DTO';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { SolicitudBajaEmpresaDTO } from 'src/app/ModelDTO/DTO/solicitudBajaEmpresa.DTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';
import { EmpresaServiceBackend } from 'src/app/ServiceBackend/empresa.ServiceBackend';
import { PaisServiceBackend } from 'src/app/ServiceBackend/pais.ServiceBackend';
import { OficinasServiceBackend } from 'src/app/ServiceBackend/oficinas.ServiceBackend';
import { AuthResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authResponse.DTO';

@Injectable({
  providedIn: 'root',
})
export class PerfilEmpresaService {
  public get company(): EmpresaDTO {
    return this.companySubject.value;
  }
  private readonly appImageDefault = 'assets/img/character-home.png';

  private companySubject = new BehaviorSubject<EmpresaDTO>(undefined);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public company$ = this.companySubject.asObservable();

  private appImageSubject = new BehaviorSubject<string>(this.appImageDefault);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public appImage$ = this.appImageSubject.asObservable();

  private companyLogoSubject = new BehaviorSubject<string>(this.appImageDefault);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public companyLogo$ = this.companyLogoSubject.asObservable();

  constructor(
    private empresaServiceBackend: EmpresaServiceBackend,
    private paisServiceBackend: PaisServiceBackend,
    private oficinaBackendService: OficinasServiceBackend,
    private profileImagePipe: ProfileImageThumbnailUrlPipe,
    private meta: Meta,
  ) {}

  public loadCompany(): void {
    this.empresaServiceBackend.getEmpresasId(0).then((company) => {
      this.companySubject.next(company);
      if (company) {
        localStorage.setItem(CURRENT_COMPANY, JSON.stringify(company));
      } else {
        localStorage.removeItem(CURRENT_COMPANY);
      }

      this.setImage(company?.uaalooImage);
      this.setLogo(company?.image);
      this.setMetaData(company);
    });
  }

  public loadCompanyFromStorage(): void {
    const company = JSON.parse(localStorage.getItem(CURRENT_COMPANY));
    this.setImage(company?.uaalooImage);
    this.setLogo(company?.image);
    this.setMetaData(company);

    if (company) {
      this.companySubject.next(company);
    } else {
      this.loadCompany();
    }
  }

  public async editarPerfilEmpresa(empresaDTO: EmpresaDTO): Promise<EmpresaDTO> {
    const company = await this.empresaServiceBackend.updateEmpresasId(empresaDTO.id, empresaDTO);
    this.companySubject.next(company);
    return this.company;
  }

  public async crearTokenParaAPI(): Promise<AuthResponseDTO> {
    return this.empresaServiceBackend.insertEmpresasCreateWSToken();
  }

  public async obtenerPaises(): Promise<Array<PaisDTO>> {
    const res = await this.paisServiceBackend.getPaises();
    return res;
  }

  public async guardarImagenEmpresa(imagen: ImageEntityDTO): Promise<ImageEntityDTO> {
    const res = await this.empresaServiceBackend.insertEmpresasIdImage(0, imagen);
    return res;
  }

  public async guardarImagenUaaloo(imagen: ImageEntityDTO): Promise<void> {
    await this.empresaServiceBackend.insertEmpresasIdUaalooImage(0, imagen);
    this.loadCompany();
  }

  public async eliminarCuentaEmpresa(solicitudBajaEmpresaDTO: SolicitudBajaEmpresaDTO): Promise<void> {
    solicitudBajaEmpresaDTO.fecha = new Date();
    solicitudBajaEmpresaDTO.empresa = this.company;
    await this.empresaServiceBackend.insertEmpresasId(this.company.id, solicitudBajaEmpresaDTO);
  }

  public cleanCache(): void {
    this.companySubject.next(undefined);
  }

  public async obtenerOficinas(): Promise<Array<OficinaDTO>> {
    return this.oficinaBackendService.getOficinas();
  }

  public async agregarOficina(oficina: OficinaDTO): Promise<OficinaDTO> {
    const res = await this.oficinaBackendService.insertOficinas(oficina);
    return res;
  }

  public async editarOficina(oficina: OficinaDTO): Promise<OficinaDTO> {
    const res = await this.oficinaBackendService.updateOficinasId(oficina.id, oficina);
    return res;
  }

  public async eliminarOficina(oficina: OficinaDTO): Promise<void> {
    await this.oficinaBackendService.deleteOficinasId(oficina.id);
  }

  public async obtenerOficinaPorId(id: number): Promise<OficinaDTO> {
    const res = await this.oficinaBackendService.getOficinasId(id);
    return res;
  }

  public async getStatus(): Promise<EmpresaStatusDTO> {
    const res = await this.empresaServiceBackend.getEmpresasIdStatus(0);
    return res;
  }

  private setMetaData(company: EmpresaDTO): void {
    // Usamos la metadata en Google Tag Manager para informarla como Atributo de Usuario en Google Analytics
    this.meta.removeTag('name="company"');
    this.meta.addTag({ name: 'company', content: company?.name });
  }

  private setImage(uaalooImage: string, base64 = false): void {
    this.appImageSubject.next(
      // eslint-disable-next-line no-nested-ternary
      uaalooImage ? (base64 ? uaalooImage : this.profileImagePipe.transform(uaalooImage, 200)) : this.appImageDefault,
    );
  }
  private setLogo(companyLogo: string): void {
    this.companyLogoSubject.next(
      // eslint-disable-next-line no-nested-ternary
      companyLogo ? this.profileImagePipe.transform(companyLogo, 200) : this.appImageDefault,
    );
  }
}
