import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { colors } from 'src/app/shared/colors';

declare var PushNotification: any;

@Injectable({
  providedIn: 'root',
})
export class NativePushNotificationService {
  private push: any;

  private pOnNotification = new BehaviorSubject<any>(null);
  public onNotification = this.pOnNotification.asObservable();

  private pEnRegistration = new BehaviorSubject<any>(null);
  public onRegistration = this.pEnRegistration.asObservable();

  private pOnError = new BehaviorSubject<Error>(null);
  public onError = this.pOnError.asObservable();

  public init() {
    this.push = PushNotification.init({
      android: {
        sound: true,
        icon: 'notification_icon',
        iconColor: colors.color_orange_500,
      },
      ios: {
        alert: 'true',
        badge: true,
        sound: 'false',
      },
    });

    this.push.on('notification', (data: any) => {
      this.pOnNotification.next(data);
    });

    this.push.on('registration', (data: any) => {
      this.pEnRegistration.next(data);
    });

    this.push.on('error', (error: Error) => {
      this.pOnError.next(error);
    });
  }

  public stop(): void {
    this.push.unregister(
      () => {
        // console.log('success native unregister');
      },
      () => {
        console.error('error native unregister');
      },
    );
  }
}
