import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageEntity.DTO';
import { EmpresaDTO } from 'src/app/ModelDTO/DTO/empresa.DTO';
import { EmpresaStatusDTO } from 'src/app/ModelDTO/DTO/empresaStatus.DTO';
import { SolicitudBajaEmpresaDTO } from 'src/app/ModelDTO/DTO/solicitudBajaEmpresa.DTO';
import { AuthResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authResponse.DTO';

@Injectable({
   providedIn: 'root'
})
export class EmpresaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getEmpresasIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/empresas/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getEmpresasId(id: number): Promise<EmpresaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/empresas/' + id));
         if (!res) { return null; }
         const resDTO = new EmpresaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateEmpresasId(id: number, empresaDTO: EmpresaDTO): Promise<EmpresaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/empresas/' + id, empresaDTO));
         const resDTO = new EmpresaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertEmpresasIdImage(id: number, imageEntityDTO: ImageEntityDTO): Promise<ImageEntityDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/empresas/' + id + '/Image', imageEntityDTO));
         const resDTO = new ImageEntityDTO();
         resDTO.PrepareDTO(res);
         return resDTO;
   }

   public async insertEmpresasIdUaalooImage(id: number, imageEntityDTO: ImageEntityDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/empresas/' + id + '/uaaloo/Image', imageEntityDTO));

   }

   public async insertEmpresasId(id: number, solicitudBajaEmpresaDTO: SolicitudBajaEmpresaDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/empresas/' + id, solicitudBajaEmpresaDTO));

   }

   public async deleteEmpresasCODEMPRESA(codEmpresa: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/empresas/code/' + codEmpresa));

   }

   public async getEmpresasIdStatus(id: number): Promise<EmpresaStatusDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/empresas/' + id + '/status'));
         if (!res) { return null; }
         const resDTO = new EmpresaStatusDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertEmpresasCreateWSToken(): Promise<AuthResponseDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/empresas/createWSToken', null));
         const resDTO = new AuthResponseDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
