import { Subscription } from 'rxjs';
import { CompetenceEvaluationDTO } from 'src/app/ModelDTO/DTO/competenceEvaluation.DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { LevelRangeModelDTO } from 'src/app/ModelDTO/levelRange.ModelDTO';
import { CompetenceResultDTO } from 'src/app/ModelDTO/DTO/competenceResult.DTO';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';


export class CompetenceEvaluationModelDTO extends EntityModelDTO<CompetenceEvaluationDTO> {

   private resultadoModel: LevelRangeModelDTO;
   private resultadoSubscribe: Subscription;

   public constructor(protected entityDTO: CompetenceEvaluationDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: CompetenceEvaluationDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.resultadoModel = new LevelRangeModelDTO(this.entityDTO?.resultado);
      this.resultadoSubscribe = this.resultadoModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.resultadoModel.dispose();
      this.resultadoSubscribe.unsubscribe();
   }

   get CreationDate(): Date { return this.entityDTO.creationDate; }
   set CreationDate(value: Date) { this.notifyChangeDTO('creationDate', value); }

   get ResultadoModel(): LevelRangeModelDTO { return this.resultadoModel; }
   get Resultado(): LevelRangeDTO { return this.resultadoModel.getEntityDTO(); }
   set Resultado(value: LevelRangeDTO) { this.notifyChange(() => { this.entityDTO.resultado = value; this.resultadoModel.setEntityDTO(value); }); }

   get Results(): Array<CompetenceResultDTO> { return this.entityDTO.results; }
   set Results(value: Array<CompetenceResultDTO>) { this.notifyChangeDTO('results', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }
}
