import { Injectable } from '@angular/core';
import { PersonalAreaDTO } from 'src/app/ModelDTO/DTO/personalArea.DTO';
import { PersonalAreaBasicaDTO } from 'src/app/ModelDTO/DTO/personalAreaBasica.DTO';
import { PersonalAreaServiceBackend } from 'src/app/ServiceBackend/personalArea.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class AreasService {
  private areaAEditar: PersonalAreaDTO;

  constructor(private personalAreaServiceBackend: PersonalAreaServiceBackend) {}

  public async obtenerAreas(): Promise<Array<PersonalAreaDTO>> {
    return this.personalAreaServiceBackend.getPersonalAreas();
  }

  public async obtenerAreasBasicos(): Promise<Array<PersonalAreaBasicaDTO>> {
    const res = await this.personalAreaServiceBackend.getPersonalAreas();
    const areaBasicos = new Array<PersonalAreaBasicaDTO>();
    for (const c of res) {
      const aBasico = new PersonalAreaBasicaDTO();
      aBasico.id = c.id;
      aBasico.nombre = c.nombre;
      aBasico.descripcion = c.descripcion;
      areaBasicos.push(aBasico);
    }
    return areaBasicos;
  }

  public async agregarArea(area: PersonalAreaDTO): Promise<PersonalAreaDTO> {
    const res = await this.personalAreaServiceBackend.insertPersonalAreas(area);
    return res;
  }

  public async editarArea(area: PersonalAreaDTO): Promise<PersonalAreaDTO> {
    const res = await this.personalAreaServiceBackend.updatePersonalAreasId(area.id, area);
    return res;
  }

  public async eliminarArea(area: PersonalAreaDTO): Promise<void> {
    await this.personalAreaServiceBackend.deletePersonalAreasId(area.id);
  }

  get AreaAEditar(): PersonalAreaDTO {
    return this.areaAEditar;
  }

  set AreaAEditar(t: PersonalAreaDTO) {
    this.areaAEditar = t;
  }
}
