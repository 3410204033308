import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EstadoEntrega } from '@api/enums/estado-entrega.enum';
import { NamedBlobDTO } from '@api/interfaces/named-blob.dto';
import { PersonalEntregaDTO } from '@api/interfaces/personal-entrega.interface';
import { PersonalEntregaBackendService } from '@api/services/personal-entrega-backend.service';
import { DateTime } from 'luxon';
import { EMPTY, from, map, Observable, Subscription, switchMap } from 'rxjs';
import {
  ActionsEntrega,
  DetalleEntregaModalComponent,
} from 'src/app/app-common/entregas/detalle-entrega-modal/detalle-entrega-modal.component';
import { DeliveryListFiltersModalComponent } from 'src/app/layout/administrador/administrador-entregas/delivery-list-filters-modal/delivery-list-filters-modal.component';
import {
  DeliveryListFilters,
  DeliveryListFiltersData,
} from 'src/app/layout/administrador/administrador-entregas/delivery-list-filters-modal/delivery-list-filters.interface';
import { DeliveryService } from 'src/app/layout/administrador/administrador-entregas/delivery.service';
import { ListadoDeEntregasService } from 'src/app/layout/administrador/administrador-entregas/listado-de-entregas.service';
import { PersonalEntregaDTO as PersonalEntregaDTOClass } from 'src/app/ModelDTO/DTO/personalEntrega.DTO';
import { AlertButton } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { FileDownloaderService } from 'src/app/shared/services/fileDownloader.service';
import { compare } from 'src/app/shared/shared-functions';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';

export const DUED_RETURN = 'DUE_RETURN';

enum ColumnNames {
  Personal = 'Personal',
  Tipo = 'Tipo',
  Entrega = 'Entrega',
  Vencimiento = 'Vencimiento',
  Estado = 'Estado',
}
@Component({
  selector: 'app-listado-de-entregas',
  templateUrl: './listado-de-entregas.component.html',
  styleUrls: ['./listado-de-entregas.component.scss'],
})
export class ListadoDeEntregasComponent implements OnInit, OnDestroy {
  public readonly ColumnNames = ColumnNames;
  public displayedColumns: string[] = [
    ColumnNames.Personal,
    ColumnNames.Tipo,
    ColumnNames.Entrega,
    ColumnNames.Estado,
    ColumnNames.Vencimiento,
  ];
  public estadoEnum = EstadoEntrega;
  public deliveries: PersonalEntregaDTO[] = [];
  private currentFilters: DeliveryListFilters;
  private subscription = new Subscription();

  constructor(
    public deliveryService: DeliveryService,
    public listadoDeEntregasService: ListadoDeEntregasService,
    public activeRoute: ActivatedRoute,
    public router: Router,
    private dialog: MatDialog,
    private neoModalService: NgxNeoModalMatService,
    private snackBar: SnackBarService,
    private personalEntregaBackendService: PersonalEntregaBackendService,
    private fileDownloaderService: FileDownloaderService,
  ) {}

  public ngOnInit(): void {
    this.currentFilters = {
      from: this.deliveryService.from?.toJSDate(),
      to: this.deliveryService.to?.toJSDate(),
      state: EstadoEntrega[EstadoEntrega.No_definido],
      type: this.deliveryService.selectedDeliveryType?.id > 0 ? this.deliveryService.selectedDeliveryType : undefined,
    };

    this.subscription.add(
      this.activeRoute.queryParams.subscribe((params) => {
        this.handleParams(params);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public sortData(sort: Sort): void {
    const data = this.deliveries.slice();
    if (!sort.active || !sort.direction?.length) {
      this.deliveries = data;
      return;
    }

    this.deliveries = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case ColumnNames.Entrega:
          return compare(a.fechaPactada?.toMillis(), b.fechaPactada?.toMillis(), isAsc);
        case ColumnNames.Estado:
          return compare(this.estadoEnum[a.estado], this.estadoEnum[b.estado], isAsc);
        case ColumnNames.Personal:
          return compare(a.personalLegajo.nombreCompleto, b.personalLegajo.nombreCompleto, isAsc);
        case ColumnNames.Tipo:
          return compare(a.entrega.nombre, b.entrega.nombre, isAsc);
        case ColumnNames.Vencimiento:
          return compare(a.vencimientoAproximado?.toMillis(), b.vencimientoAproximado?.toMillis(), isAsc);
        default:
          return 0;
      }
    });
  }

  public openModalFilter(): void {
    const data: DeliveryListFiltersData = {
      ...this.currentFilters,
      deliveryTypes: this.deliveryService.deliveryTypes,
    };
    this.dialog
      .open(DeliveryListFiltersModalComponent, { data, panelClass: 'full-size-sm-600-lg-modal' })
      .afterClosed()
      .subscribe((filters: DeliveryListFilters) => {
        if (filters) {
          this.deliveryService.from = DateTime.fromJSDate(filters.from);
          this.deliveryService.to = DateTime.fromJSDate(filters.to);
          this.currentFilters = filters;
          this.filter();
        }
      });
  }

  public exportDeliveries = (): Observable<void> => {
    if (this.deliveryService.from.isValid && this.deliveryService.to.isValid) {
      const blob = new NamedBlobDTO();
      return from(
        this.personalEntregaBackendService.getEntregaPersonalXLSX({
          fechaDesde: this.deliveryService.from.toJSDate(),
          fechaHasta: this.deliveryService.to.toJSDate(),
          estado: EstadoEntrega[this.currentFilters.state],
          idTipoEntrega: this.currentFilters.type?.id ?? 0,
          idPersonal: this.currentFilters.teammate?.id ?? 0,
          sinRechazos: false,
          namedBlob: blob,
        }),
      ).pipe(
        map(() => {
          this.fileDownloaderService.saveAs(blob);
        }),
      );
    }
    return EMPTY;
  };

  public seeDeliveryDetail(delivery: PersonalEntregaDTO): void {
    const deliveryClass = new PersonalEntregaDTOClass();
    deliveryClass.PrepareDTO(delivery);
    const dialogRef = this.dialog.open(DetalleEntregaModalComponent, {
      disableClose: false,
      panelClass: 'full-size-sm-600-lg-modal',
      data: deliveryClass,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.actionEntrega === ActionsEntrega.DarDeBaja) {
          this.deleteDelivery(result.entregaDTO);
        } else if (result.actionEntrega === ActionsEntrega.CambiarEstado) {
          this.changeDeliveryStatus(result.entregaDTO);
        }
      }
    });
  }

  private deleteDelivery(delivery: PersonalEntregaDTO): void {
    from(this.neoModalService.decision('DELIVERIES.DECISION_TO_DELETE_DELIVERY'))
      .pipe(
        switchMap((res) => {
          if (res.ButtonResponse === AlertButton.Accept) {
            return this.personalEntregaBackendService.deleteEntregaIdPersonalIDPersonal(delivery.id, delivery.personalLegajo.id);
          }
          return EMPTY;
        }),
      )
      .subscribe(() => {
        const index = this.deliveries.findIndex((x) => x.id === delivery.id);
        this.deliveries.splice(index, 1);
        this.deliveries = [...this.deliveries];
        this.snackBar.showInfo('GENERAL.SUCCESSFUL_DELETION');
      });
  }

  private changeDeliveryStatus(delivery: PersonalEntregaDTO): void {
    from(this.neoModalService.decision('DELIVERIES.DECISION_CHANGE_DELIVERY_STATUS'))
      .pipe(
        switchMap((res) => {
          if (res.ButtonResponse === AlertButton.Accept) {
            return this.personalEntregaBackendService.updateEntregaIdCambioEstado(delivery.id, delivery);
          }
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.filter();
        this.snackBar.showInfo('GENERAL.SUCCESSFUL_MODIFICATION');
      });
  }

  private async handleParams(params: Params): Promise<void> {
    this.currentFilters.state = params.status ?? EstadoEntrega[EstadoEntrega.No_definido];
    this.filter();
  }

  private filter(): void {
    if (this.deliveryService.from.isValid && this.deliveryService.to.isValid) {
      const state = this.currentFilters.state === DUED_RETURN ? EstadoEntrega.Entregado : EstadoEntrega[this.currentFilters.state];
      this.personalEntregaBackendService
        .getEntregaPersonal({
          fechaDesde: this.deliveryService.from.toJSDate(),
          fechaHasta: this.deliveryService.to.toJSDate(),
          estado: state,
          idTipoEntrega: this.currentFilters.type?.id ?? 0,
          idPersonal: this.currentFilters.teammate?.id ?? 0,
          sinRechazos: undefined,
          duedReturn: this.currentFilters.state === DUED_RETURN,
        })
        .subscribe((deliveries) => {
          this.deliveries = deliveries;
        });
    }
  }
}
