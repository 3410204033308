import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { PersonalSectorDTO } from 'src/app/ModelDTO/DTO/personalSector.DTO';
import { PersonalAreaBasicaDTO } from 'src/app/ModelDTO/DTO/personalAreaBasica.DTO';


export class PersonalAreaDTO implements IEntityDTO {

   sectores: Array<PersonalSectorDTO>;
   superior: PersonalAreaBasicaDTO;
   nombre = '';
   descripcion = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.sectores = new Array<PersonalSectorDTO>();
      this.superior = new PersonalAreaBasicaDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.sectores != null) { for (const item of jsonObj.sectores) { const itemDTO = new PersonalSectorDTO(); itemDTO.PrepareDTO(item); this.sectores.push(itemDTO); } }
      if (jsonObj.superior != null) { this.superior.PrepareDTO(jsonObj.superior); }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
