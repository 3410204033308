import { ConfettiAnimationDTO } from '@api/interfaces/confetti-animation.interface';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';

export class TipoFeedbackDTO implements IEntityDTO {

   nombre = '';
   descripcion = '';
   icon = '';
   backgroundColor = '';
   animation?: ConfettiAnimationDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.icon != null) { this.icon = jsonObj.icon; }
      if (jsonObj.backgroundColor != null) { this.backgroundColor = jsonObj.backgroundColor; }
      if (jsonObj.animation != null) { this.animation = jsonObj.animation; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
