import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CommentReactionDTO } from 'src/app/ModelDTO/DTO/commentReaction.DTO';
import { NewCommentReactionDTO } from 'src/app/ModelDTO/DTO/newCommentReaction.DTO';
import { FrontEndConfig, FrontEndConfigService } from '../../FrontendConfig';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NotificationDataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/notificationData.DTO';
import { NotificationStatsDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/notificationStats.DTO';
import { NotificationDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/notification.DTO';
import { NewNotificationDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/newNotification.DTO';
import { ListOfIdsDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/listOfIds.DTO';
import { NotificationAttachmentsDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/notificationAttachments.DTO';

@Injectable({
  providedIn: 'root',
})
export class NotificationServiceBackend {
  constructor(
    @Inject(FrontEndConfigService) protected Constants: FrontEndConfig,
    protected http: HttpClient,
  ) {}

  public async getUserNotificationsIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {
    const res = await this.http.get<DataDTO>(this.Constants.apiURL + '/user/notifications/' + id + '/Auditory').toPromise();
    const resJson = res.data;
    const resDTO = new Array<AuditLogEntryDTO>();
    for (const item of resJson) {
      const itemDTO = new AuditLogEntryDTO();
      itemDTO.PrepareDTO(item);
      resDTO.push(itemDTO);
    }
    return resDTO;
  }

  public async getUserNotifications(
    archived: boolean,
    pageNumber: number,
    pageSize: number,
    tipo: number = -1,
    includeRead: boolean = false,
    priorityDescOrder: boolean = false,
    onlyCommunications?: boolean,
    searchValue?: string,
  ): Promise<NotificationDataDTO> {
    const res = await this.http
      .get(
        this.Constants.apiURL +
          '/user/notifications/' +
          '?archived=' +
          archived +
          '&pageNumber=' +
          pageNumber +
          '&pageSize=' +
          pageSize +
          '&tipo=' +
          tipo +
          '&includeRead=' +
          includeRead +
          '&priorityDescOrder=' +
          priorityDescOrder +
          '&onlyCommunications=' +
          onlyCommunications +
          '&searchValue=' +
          searchValue,
      )
      .toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new NotificationDataDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getUserNotificationsTipo(
    archived: boolean,
    pageNumber: number,
    pageSize: number,
    tipo: number = -1,
  ): Promise<NotificationDataDTO> {
    const res = await this.http
      .get(
        this.Constants.apiURL +
          '/user/notifications/tipo' +
          '?archived=' +
          archived +
          '&pageNumber=' +
          pageNumber +
          '&pageSize=' +
          pageSize +
          '&tipo=' +
          tipo,
      )
      .toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new NotificationDataDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getUserNotificationsStaticsByType(
    type: number = -1,
    archived: boolean = true,
    collaboratorView: boolean = false,
  ): Promise<Array<NotificationStatsDTO>> {
    const res = await this.http
      .get<DataDTO>(
        this.Constants.apiURL +
          '/user/notifications/staticsByType' +
          '?type=' +
          type +
          '&archived=' +
          archived +
          '&collaboratorView=' +
          collaboratorView,
      )
      .toPromise();
    const resJson = res.data;
    const resDTO = new Array<NotificationStatsDTO>();
    for (const item of resJson) {
      const itemDTO = new NotificationStatsDTO();
      itemDTO.PrepareDTO(item);
      resDTO.push(itemDTO);
    }
    return resDTO;
  }

  public async getUserNotificationsLast(type: number = -1): Promise<NotificationDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/user/notifications/last' + '?type=' + type).toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new NotificationDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getUserNotificationsTotalWithoutRead(type: number = -1, pageSize: number = 0): Promise<NotificationDataDTO> {
    const res = await this.http
      .get(this.Constants.apiURL + '/user/notifications/totalWithoutRead' + '?type=' + type + '&pageSize=' + pageSize)
      .toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new NotificationDataDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async insertUserNotifications(newNotificationDTO: NewNotificationDTO): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/user/notifications/', newNotificationDTO).toPromise();
  }

  public async insertUserNotificationsUserTypeVALUE(vALUE: number, notificationDTO: NotificationDTO): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/user/notifications/userType/' + vALUE, notificationDTO).toPromise();
  }

  public async insertUserNotificationsUserTypeIdId(id: number, newNotificationDTO: NewNotificationDTO): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/user/notifications/userTypeId/' + id, newNotificationDTO).toPromise();
  }

  public async insertUserNotificationsClearNumberInBell(): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/user/notifications/clearNumberInBell', null).toPromise();
  }

  public async updateUserNotificationsId(
    id: number,
    notificationDTO: NotificationDTO,
    unArchive: boolean = false,
  ): Promise<NotificationDTO> {
    const res = await this.http
      .put(this.Constants.apiURL + '/user/notifications/' + id + '?unArchive=' + unArchive, notificationDTO)
      .toPromise();
    const resDTO = new NotificationDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async updateUserNotificationsStatsId(id: number): Promise<NotificationDTO> {
    const res = await this.http.put(this.Constants.apiURL + '/user/notifications/stats/' + id, null).toPromise();
    const resDTO = new NotificationDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async updateUserNotificationsIdOpened(
    id: number,
    notificationDTO: NotificationDTO,
    unArchive: boolean = false,
  ): Promise<NotificationDTO> {
    const res = await this.http
      .put(this.Constants.apiURL + '/user/notifications/' + id + '/opened' + '?unArchive=' + unArchive, notificationDTO)
      .toPromise();
    const resDTO = new NotificationDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async updateUserNotificationsIdUnderstood(
    id: number,
    notificationDTO: NotificationDTO,
    unArchive: boolean = false,
  ): Promise<NotificationDTO> {
    const res = await this.http
      .put(this.Constants.apiURL + '/user/notifications/' + id + '/understood' + '?unArchive=' + unArchive, notificationDTO)
      .toPromise();
    const resDTO = new NotificationDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async updateUserNotificationsIdNotUnderstood(
    id: number,
    notificationDTO: NotificationDTO,
    unArchive: boolean = false,
  ): Promise<NotificationDTO> {
    const res = await this.http
      .put(this.Constants.apiURL + '/user/notifications/' + id + '/notUnderstood' + '?unArchive=' + unArchive, notificationDTO)
      .toPromise();
    const resDTO = new NotificationDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async updateUserNotificationsArchive(listOfIdsDTO: ListOfIdsDTO): Promise<void> {
    await this.http.put(this.Constants.apiURL + '/user/notifications/archive', listOfIdsDTO).toPromise();
  }

  public async deleteUserNotificationsId(id: number): Promise<void> {
    await this.http.delete(this.Constants.apiURL + '/user/notifications/' + id).toPromise();
  }

  public async deleteUserNotificationsStatsId(id: number, permanently: boolean): Promise<void> {
    await this.http.delete(this.Constants.apiURL + '/user/notifications/stats/' + id + '?permanently=' + permanently).toPromise();
  }

  public async getUserNotificationsId(id: number): Promise<NotificationAttachmentsDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/user/notifications/' + id).toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new NotificationAttachmentsDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getUserNotificationsIdNotification(id: number): Promise<NotificationAttachmentsDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/user/notifications/' + id + '/notification').toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new NotificationAttachmentsDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getUserNotificationsNotificationId(id: number): Promise<NotificationAttachmentsDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/user/notifications/notification/' + id).toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new NotificationAttachmentsDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public getUserNotificationsNotificationIdReactions(id: number): Observable<CommentReactionDTO[]> {
    return this.http.get<DataDTO>(this.Constants.apiURL + '/user/notifications/' + id + '/reactions').pipe(
      map((res) =>
        res.data.map((element) => {
          const resDTO = new CommentReactionDTO();
          resDTO.PrepareDTO(element);
          return resDTO;
        }),
      ),
    );
  }

  public updateUserNotificationsNotificationIdReactions(
    id: number,
    newCommentReactionDTO: NewCommentReactionDTO,
  ): Observable<NotificationAttachmentsDTO> {
    return this.http.put(this.Constants.apiURL + '/user/notifications/' + id + '/reactions', newCommentReactionDTO).pipe(
      map((res) => {
        const resDTO = new NotificationAttachmentsDTO();
        resDTO.PrepareDTO(res);
        return resDTO;
      }),
    );
  }

  public deleteUserNotificationsNotificationIdReactionsEMOJIID(id: number, emojiId: string): Observable<NotificationAttachmentsDTO> {
    return this.http.delete(this.Constants.apiURL + '/user/notifications/' + id + '/reactions/' + emojiId).pipe(
      map((res) => {
        const resDTO = new NotificationAttachmentsDTO();
        resDTO.PrepareDTO(res);
        return resDTO;
      }),
    );
  }
}
