import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { TipoGenero } from 'src/app/ModelDTO/DTO/tipoGenero.ENUM';
import { EstadoCivil } from 'src/app/ModelDTO/DTO/estadoCivil.ENUM';


export class PersonalSancionFlatDTO implements IEntityDTO {

   observacion = '';
   fecha: Date;
   fechaCarga: Date;
   tipoId = 0;
   tipoNombre = '';
   tipoDescripcion = '';
   conSuspension: boolean;
   suspensionDesde: Date;
   suspensionHasta: Date;
   personalId = 0;
   personalNombre = '';
   personalApellido = '';
   personalLegajo = '';
   personalCuil = '';
   personalImage = '';
   personalDni = '';
   personalFechaNacimiento: Date;
   personalGenero: TipoGenero;
   personalEstadoCivil: EstadoCivil;
   personalOficina = '';
   responsableId = 0;
   responsableNombre = '';
   responsableApellido = '';
   responsableLegajo = '';
   responsableCuil = '';
   responsableImage = '';
   responsableDni: number;
   totalRecibidos = 0;
   totalAdjuntos = 0;
   personalNombreCompleto = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.observacion != null) { this.observacion = jsonObj.observacion; }
      if (jsonObj.fecha != null) { this.fecha = new Date(jsonObj.fecha); }
      if (jsonObj.fechaCarga != null) { this.fechaCarga = new Date(jsonObj.fechaCarga); }
      if (jsonObj.tipoId != null) { this.tipoId = jsonObj.tipoId; }
      if (jsonObj.tipoNombre != null) { this.tipoNombre = jsonObj.tipoNombre; }
      if (jsonObj.tipoDescripcion != null) { this.tipoDescripcion = jsonObj.tipoDescripcion; }
      if (jsonObj.conSuspension != null) { this.conSuspension = jsonObj.conSuspension; }
      if (jsonObj.suspensionDesde != null) { this.suspensionDesde = new Date(jsonObj.suspensionDesde); }
      if (jsonObj.suspensionHasta != null) { this.suspensionHasta = new Date(jsonObj.suspensionHasta); }
      if (jsonObj.personalId != null) { this.personalId = jsonObj.personalId; }
      if (jsonObj.personalNombre != null) { this.personalNombre = jsonObj.personalNombre; }
      if (jsonObj.personalApellido != null) { this.personalApellido = jsonObj.personalApellido; }
      if (jsonObj.personalLegajo != null) { this.personalLegajo = jsonObj.personalLegajo; }
      if (jsonObj.personalCuil != null) { this.personalCuil = jsonObj.personalCuil; }
      if (jsonObj.personalImage != null) { this.personalImage = jsonObj.personalImage; }
      if (jsonObj.personalDni != null) { this.personalDni = jsonObj.personalDni; }
      if (jsonObj.personalFechaNacimiento != null) { this.personalFechaNacimiento = new Date(jsonObj.personalFechaNacimiento); }
      if (jsonObj.personalGenero != null) { this.personalGenero = jsonObj.personalGenero as TipoGenero; }
      if (jsonObj.personalEstadoCivil != null) { this.personalEstadoCivil = jsonObj.personalEstadoCivil as EstadoCivil; }
      if (jsonObj.personalOficina != null) { this.personalOficina = jsonObj.personalOficina; }
      if (jsonObj.responsableId != null) { this.responsableId = jsonObj.responsableId; }
      if (jsonObj.responsableNombre != null) { this.responsableNombre = jsonObj.responsableNombre; }
      if (jsonObj.responsableApellido != null) { this.responsableApellido = jsonObj.responsableApellido; }
      if (jsonObj.responsableLegajo != null) { this.responsableLegajo = jsonObj.responsableLegajo; }
      if (jsonObj.responsableCuil != null) { this.responsableCuil = jsonObj.responsableCuil; }
      if (jsonObj.responsableImage != null) { this.responsableImage = jsonObj.responsableImage; }
      if (jsonObj.responsableDni != null) { this.responsableDni = jsonObj.responsableDni; }
      if (jsonObj.totalRecibidos != null) { this.totalRecibidos = jsonObj.totalRecibidos; }
      if (jsonObj.totalAdjuntos != null) { this.totalAdjuntos = jsonObj.totalAdjuntos; }
      if (jsonObj.personalNombreCompleto != null) { this.personalNombreCompleto = jsonObj.personalNombreCompleto; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
