import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserLanguage } from '../../models/DTO/userLanguage.ENUM';
import { AuthenticationService } from './authentication.service';
import { AppCacheService, ENDPOINT_KEYS, ENDPOINTS } from '../../services/app-cache/app-cache.service';
import { ConnectionStatusService } from 'src/app/shared/services/connectionStatus.service';
import { CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/cordova.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private cordovaService: CordovaService,
    private authenticationService: AuthenticationService,
    private appCacheService: AppCacheService,
    private connectionStatusService: ConnectionStatusService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('X-Skip-Interceptor')) {
      // We don't want to use this interceptor on some specific calls
      const modifiedRequest = req.clone({
        headers: req.headers.delete('x-skip-interceptor'),
      });

      return next.handle(modifiedRequest);
    }

    let authReq = req;

    if (this.cordovaService.isAndroidApp) {
      authReq = req.clone({
        headers: req.headers.set('x-neo-iscordova', 'true'),
      });
    }

    if (this.authenticationService.authResponseDTO && this.authenticationService.authResponseDTO.token) {
      // Authorization token
      if (!req.headers.has('Authorization') && !this.awsAuthorization(req)) {
        authReq = req.clone({
          headers: req.headers.set('Authorization', this.authenticationService.authResponseDTO.token),
        });
      }

      // Language
      if (this.authenticationService.authResponseDTO.language) {
        authReq = authReq.clone({
          headers: authReq.headers.set('Accept-Language', UserLanguage[this.authenticationService.authResponseDTO.language]),
        });
      }

      // Version
      authReq = authReq.clone({
        headers: authReq.headers.set('X-Client-Version', environment.appVersion),
      });

      // tz-offset tz-name
      authReq = authReq.clone({
        headers: authReq.headers.set('x-tz-offset', this.getTimeZoneOffset()).set('x-tz-name', this.getTimeZoneName()),
      });

      if (req.url.includes('BLOB=true')) {
        authReq = authReq.clone({ responseType: 'blob' });
      } else if (req.url.includes('BLOB=XLSX')) {
        authReq = authReq.clone({ responseType: 'blob', headers: authReq.headers.set('Accept', 'application/xlsx') });
      } else if (req.url.includes('BLOB=PDF')) {
        authReq = authReq.clone({ responseType: 'blob', headers: authReq.headers.set('Accept', 'application/pdf') });
      }
    }

    // Chat auth
    if (this.chatAuthorization(req)) {
      const chatAuth = this.authenticationService.chatAuth;
      if (chatAuth && chatAuth.token) {
        authReq = authReq.clone({
          headers: authReq.headers.set('Authorization', `Bearer ${chatAuth.token}`),
          params: authReq.params.set('userId', chatAuth.userId).set('tenantId', chatAuth.tenantId),
        });
      }
    }

    const isGET = authReq.method === 'GET';
    const CACHED_ENDPOINTS = ENDPOINTS;
    const CACHED_ENDPOINTS_KEYS = ENDPOINT_KEYS;
    const cacheKey = CACHED_ENDPOINTS_KEYS.find((key) => authReq.url.endsWith(key));

    if (isGET) {
      const cachedResponse: HttpResponse<any> = this.appCacheService.getCache(cacheKey);
      if (cachedResponse) {
        const diff = DateTime.now().diff(DateTime.fromISO(cachedResponse.headers.get('cache-date')));
        const maxTime = CACHED_ENDPOINTS?.[CACHED_ENDPOINTS_KEYS.find((k) => authReq.url.endsWith(k))];
        if (diff.as('seconds') < maxTime) {
          return of(cachedResponse.clone());
        }
      }
    }
    return next.handle(authReq).pipe(
      tap((httpEvent: HttpEvent<any>) => {
        if (httpEvent instanceof HttpResponse) {
          if (httpEvent.status === 200) {
            this.connectionStatusService.updateConnectionStatus(true);
          }

          if (isGET && cacheKey) {
            this.appCacheService.setCache(
              cacheKey,
              httpEvent.clone({ headers: httpEvent.headers.set('cache-date', new Date().toISOString()) }),
            );
          }
        }
      }),
    );
  }

  private awsAuthorization(req: HttpRequest<any>): boolean {
    return this.chatAuthorization(req) || this.s3Authorization(req);
  }
  private chatAuthorization(req: HttpRequest<any>): boolean {
    return req.url.includes('execute-api.us-east-1.amazonaws.com');
  }
  private s3Authorization(req: HttpRequest<any>): boolean {
    return req.urlWithParams.includes('AWSAccessKeyId');
  }

  private getTimeZoneOffset(): string {
    return new Date().getTimezoneOffset().toString();
  }

  private getTimeZoneName(): string {
    // NFD descompone las letras con acento en 2 caracteres, por ejemplo á -> a'
    // el siguiente replace elimina el caracter '
    // el siguiente replace elimina los caracteres que no sean alfanumericos.
    return new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      .substring(4)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9\s()―]/g, '');
  }
}
