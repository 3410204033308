import { HealthInsuranceCompanyDTO } from '@api/interfaces/health-insurance-company.interface';
import { BusinessNameDTO } from 'src/app/ModelDTO/DTO/businessName.DTO';
import { CurrencyDTO } from 'src/app/ModelDTO/DTO/currency.DTO';
import { EmpresaDTO } from 'src/app/ModelDTO/DTO/empresa.DTO';
import { ModalidadContractualDTO } from 'src/app/ModelDTO/DTO/modalidadContractual.DTO';
import { PaymentModalityType } from 'src/app/ModelDTO/DTO/paymentModalityType.ENUM';
import { PersonalAreaDTO } from 'src/app/ModelDTO/DTO/personalArea.DTO';
import { PersonalBeneficioDTO } from 'src/app/ModelDTO/DTO/personalBeneficio.DTO';
import { PersonalBeneficioAusenciaDTO } from 'src/app/ModelDTO/DTO/personalBeneficioAusencia.DTO';
import { PersonalCambioEstadoConAnteriorDTO } from 'src/app/ModelDTO/DTO/personalCambioEstadoConAnterior.DTO';
import { PersonalCategoriaBasicaDTO } from 'src/app/ModelDTO/DTO/personalCategoriaBasica.DTO';
import { PersonalLegajoPrivacyPolicyDTO } from 'src/app/ModelDTO/DTO/personalLegajoPrivacyPolicy.DTO';
import { PersonalSubcategoriaDTO } from 'src/app/ModelDTO/DTO/personalSubcategoria.DTO';
import { PuestoDTO } from 'src/app/ModelDTO/DTO/puesto.DTO';
import { TipoContratacion } from 'src/app/ModelDTO/DTO/tipoContratacion.ENUM';
import { WorkDayDTO } from 'src/app/ModelDTO/DTO/workDay.DTO';
import { WorkLocationType } from 'src/app/ModelDTO/DTO/workLocationType.ENUM';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';


export class LegajoDTO implements IEntityDTO {

   fechaSalida: Date;
   categoriaContratacion: PersonalCategoriaBasicaDTO;
   subcategoriaContratacion: PersonalSubcategoriaDTO;
   puestos: Array<PuestoDTO>;
   workDays: Array<WorkDayDTO>;
   modalidad: ModalidadContractualDTO;
   modoTrabajo: WorkLocationType;
   representanteGremial: boolean;
   inicioRepresentacionGremial: Date;
   finRepresentacionGremial: Date;
   emailEmpresa = '';
   antiguedad = 0;
   sueldoBruto = 0;
   currency: CurrencyDTO;
   tieneCertificado: boolean;
   esAdmin = 0;
   beneficios: Array<PersonalBeneficioDTO>;
   beneficiosAusencia: Array<PersonalBeneficioAusenciaDTO>;
   nextState?: PersonalCambioEstadoConAnteriorDTO;
   estadoActual: PersonalCambioEstadoConAnteriorDTO;
   privacyPolicies: Array<PersonalLegajoPrivacyPolicyDTO>;
   internNumber: number;
   legajo = '';
   empresa: EmpresaDTO;
   contratacion: TipoContratacion;
   fechaIngreso: Date;
   paymentModality: PaymentModalityType;
   hayGrade: '';
   hayGradePercentage: '';
   businessName: BusinessNameDTO | undefined;
   hrArea: PersonalAreaDTO | undefined;
   healthInsuranceCompany?: HealthInsuranceCompanyDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.categoriaContratacion = new PersonalCategoriaBasicaDTO();
      this.subcategoriaContratacion = new PersonalSubcategoriaDTO();
      this.puestos = new Array<PuestoDTO>();
      this.workDays = new Array<WorkDayDTO>();
      this.modalidad = new ModalidadContractualDTO();
      this.currency = new CurrencyDTO();
      this.beneficios = new Array<PersonalBeneficioDTO>();
      this.beneficiosAusencia = new Array<PersonalBeneficioAusenciaDTO>();
      this.estadoActual = new PersonalCambioEstadoConAnteriorDTO();
      this.nextState = new PersonalCambioEstadoConAnteriorDTO();
      this.privacyPolicies = new Array<PersonalLegajoPrivacyPolicyDTO>();
      this.empresa = new EmpresaDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fechaSalida != null) { this.fechaSalida = new Date(jsonObj.fechaSalida); }
      if (jsonObj.categoriaContratacion != null) { this.categoriaContratacion.PrepareDTO(jsonObj.categoriaContratacion); }
      if (jsonObj.subcategoriaContratacion != null) { this.subcategoriaContratacion.PrepareDTO(jsonObj.subcategoriaContratacion); }
      if (jsonObj.puestos != null) { for (const item of jsonObj.puestos) { const itemDTO = new PuestoDTO(); itemDTO.PrepareDTO(item); this.puestos.push(itemDTO); } }
      if (jsonObj.workDays != null) { for (const item of jsonObj.workDays) { const itemDTO = new WorkDayDTO(); itemDTO.PrepareDTO(item); this.workDays.push(itemDTO); } }
      if (jsonObj.modalidad != null) { this.modalidad.PrepareDTO(jsonObj.modalidad); }
      if (jsonObj.modoTrabajo != null) { this.modoTrabajo = jsonObj.modoTrabajo as WorkLocationType; }
      if (jsonObj.paymentModality != null) { this.paymentModality = jsonObj.paymentModality as PaymentModalityType; }
      if (jsonObj.representanteGremial != null) { this.representanteGremial = jsonObj.representanteGremial; }
      if (jsonObj.inicioRepresentacionGremial != null) { this.inicioRepresentacionGremial = new Date(jsonObj.inicioRepresentacionGremial); }
      if (jsonObj.finRepresentacionGremial != null) { this.finRepresentacionGremial = new Date(jsonObj.finRepresentacionGremial); }
      if (jsonObj.emailEmpresa != null) { this.emailEmpresa = jsonObj.emailEmpresa; }
      if (jsonObj.antiguedad != null) { this.antiguedad = jsonObj.antiguedad; }
      if (jsonObj.sueldoBruto != null) { this.sueldoBruto = jsonObj.sueldoBruto; }
      if (jsonObj.currency != null) { this.currency.PrepareDTO(jsonObj.currency); }
      if (jsonObj.tieneCertificado != null) { this.tieneCertificado = jsonObj.tieneCertificado; }
      if (jsonObj.esAdmin != null) { this.esAdmin = jsonObj.esAdmin; }
      if (jsonObj.beneficios != null) { for (const item of jsonObj.beneficios) { const itemDTO = new PersonalBeneficioDTO(); itemDTO.PrepareDTO(item); this.beneficios.push(itemDTO); } }
      if (jsonObj.beneficiosAusencia != null) { for (const item of jsonObj.beneficiosAusencia) { const itemDTO = new PersonalBeneficioAusenciaDTO(); itemDTO.PrepareDTO(item); this.beneficiosAusencia.push(itemDTO); } }
      if (jsonObj.estadoActual != null) { this.estadoActual.PrepareDTO(jsonObj.estadoActual); }
      if (jsonObj.nextState != null) { this.nextState.PrepareDTO(jsonObj.nextState); }
      if (jsonObj.privacyPolicies != null) { for (const item of jsonObj.privacyPolicies) { const itemDTO = new PersonalLegajoPrivacyPolicyDTO(); itemDTO.PrepareDTO(item); this.privacyPolicies.push(itemDTO); } }
      if (jsonObj.internNumber != null) { this.internNumber = jsonObj.internNumber; }
      if (jsonObj.legajo != null) { this.legajo = jsonObj.legajo; }
      if (jsonObj.empresa != null) { this.empresa.PrepareDTO(jsonObj.empresa); }
      if (jsonObj.contratacion != null) { this.contratacion = jsonObj.contratacion as TipoContratacion; }
      if (jsonObj.fechaIngreso != null) { this.fechaIngreso = new Date(jsonObj.fechaIngreso); }
      if (jsonObj.hayGrade != null) { this.hayGrade = jsonObj.hayGrade; }
      if (jsonObj.hayGradePercentage != null) { this.hayGradePercentage = jsonObj.hayGradePercentage; }
      if (jsonObj.businessName != null) { this.businessName = new BusinessNameDTO(); this.businessName.PrepareDTO(jsonObj.businessName); }
      if (jsonObj.hrArea != null) { this.hrArea = new PersonalAreaDTO(); this.hrArea.PrepareDTO(jsonObj.hrArea); }
      if (jsonObj.healthInsuranceCompany != null) { this.healthInsuranceCompany = jsonObj.healthInsuranceCompany }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
