import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { CompetenceResultDTO } from 'src/app/ModelDTO/DTO/competenceResult.DTO';


export class CompetenceEvaluationDTO implements IEntityDTO {

   creationDate: Date;
   resultado: LevelRangeDTO;
   results: Array<CompetenceResultDTO>;
   id = 0;

   constructor() {
      this.resultado = new LevelRangeDTO();
      this.results = new Array<CompetenceResultDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.creationDate != null) { this.creationDate = new Date(jsonObj.creationDate); }
      if (jsonObj.resultado != null) { this.resultado.PrepareDTO(jsonObj.resultado); }
      if (jsonObj.results != null) { for (const item of jsonObj.results) { const itemDTO = new CompetenceResultDTO(); itemDTO.PrepareDTO(item); this.results.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
