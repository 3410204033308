import { DocumentacionConAnalisisDocumentoDTO } from 'src/app/ModelDTO/DTO/documentacionConAnalisisDocumento.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NameDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/nameDetail.DTO';


export class DocumentacionConAnalisisDTO implements IEntityDTO {

   documents: Array<DocumentacionConAnalisisDocumentoDTO>;
   errores: Array<NameDetailDTO>;
   id = 0;

   constructor() {
      this.documents = new Array<DocumentacionConAnalisisDocumentoDTO>();
      this.errores = new Array<NameDetailDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.documents != null) { for (const item of jsonObj.documents) { const itemDTO = new DocumentacionConAnalisisDocumentoDTO(); itemDTO.PrepareDTO(item); this.documents.push(itemDTO); } }
      if (jsonObj.errores != null) { for (const item of jsonObj.errores) { const itemDTO = new NameDetailDTO(); itemDTO.PrepareDTO(item); this.errores.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
