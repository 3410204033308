import { Injectable } from '@angular/core';
import { EstadoCapacitacion } from '@api/enums/estado-capacitacion.enum';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { ListadoDeCapacitacionesService } from 'src/app/layout/administrador/administrador-capacitaciones/listado-de-capacitaciones.service';
import { PersonalCapacitacionDTO } from 'src/app/ModelDTO/DTO/personalCapacitacion.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { TipoCapacitacionDTO } from 'src/app/ModelDTO/DTO/tipoCapacitacion.DTO';
import { TipoCapacitacionFlatDTO } from 'src/app/ModelDTO/DTO/tipoCapacitacionFlat.DTO';

@Injectable({
  providedIn: 'root',
})
export class CapacitacionesViewModel {
  public desde: Date;
  public hasta: Date;
  public userSelected: PersonalLegajoBasicoDTO;
  public usuarioString: string;
  public capacitacionesFiltradas: PersonalCapacitacionDTO[];
  public estado: string;
  public pageNumber: number;
  public pageSize = 50;

  public tiposDeCapacitacion: Array<TipoCapacitacionDTO>;
  public tipoDeCapacitacion: TipoCapacitacionFlatDTO;

  constructor(
    private headerService: HeaderAppService,
    private listadoDeCapacitacionesService: ListadoDeCapacitacionesService,
  ) {
    this.initViewModel();

    this.headerService.loggedOut$.subscribe((data) => {
      if (data) {
        this.initViewModel();
      }
    });
  }

  public initViewModel(): void {
    this.pageNumber = 0;
    this.estado = EstadoCapacitacion[EstadoCapacitacion.No_definido];
    this.userSelected = new PersonalLegajoBasicoDTO();
    this.desde = new Date();
    this.hasta = new Date();
    this.desde.setMonth(this.desde.getMonth() - 3);
    this.hasta.setMonth(this.hasta.getMonth() + 3);
    this.tipoDeCapacitacion = new TipoCapacitacionFlatDTO();
    this.userSelected.id = this.headerService.personalLegajoId;
  }

  public async filtrar(): Promise<void> {
    if (this.desde && this.hasta) {
      this.capacitacionesFiltradas = await this.listadoDeCapacitacionesService.obtenerCapacitacionesFiltradas(
        this.desde,
        this.hasta,
        EstadoCapacitacion[this.estado],
        this.tipoDeCapacitacion,
        this.userSelected.id,
      );
    }
  }
}
