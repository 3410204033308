import { Subscription } from 'rxjs';
import { ContactoEmergenciaModelDTO } from 'src/app/ModelDTO/contactoEmergencia.ModelDTO';
import { DomicilioModelDTO } from 'src/app/ModelDTO/domicilio.ModelDTO';
import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';
import { BancoDTO } from 'src/app/ModelDTO/DTO/banco.DTO';
import { BankAccountTypeDTO } from 'src/app/ModelDTO/DTO/bankAccountType.DTO';
import { ContactoEmergenciaDTO } from 'src/app/ModelDTO/DTO/contactoEmergencia.DTO';
import { DomicilioDTO } from 'src/app/ModelDTO/DTO/domicilio.DTO';
import { EstadoCivil } from 'src/app/ModelDTO/DTO/estadoCivil.ENUM';
import { FamiliarDTO } from 'src/app/ModelDTO/DTO/familiar.DTO';
import { LegajoDTO } from 'src/app/ModelDTO/DTO/legajo.DTO';
import { ModalidadContractualDTO } from 'src/app/ModelDTO/DTO/modalidadContractual.DTO';
import { NivelEstudioDTO } from 'src/app/ModelDTO/DTO/nivelEstudio.DTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { RelationshipPositionEnum } from 'src/app/ModelDTO/DTO/relationshipPositionEnum.ENUM';
import { TipoDocumento } from 'src/app/ModelDTO/DTO/tipoDocumento.ENUM';
import { TipoGenero } from 'src/app/ModelDTO/DTO/tipoGenero.ENUM';
import { LegajoModelDTO } from 'src/app/ModelDTO/legajo.ModelDTO';
import { ModalidadContractualModelDTO } from 'src/app/ModelDTO/modalidadContractual.ModelDTO';
import { NivelEstudioModelDTO } from 'src/app/ModelDTO/nivelEstudio.ModelDTO';
import { OficinaModelDTO } from 'src/app/ModelDTO/oficina.ModelDTO';
import { PaisModelDTO } from 'src/app/ModelDTO/pais.ModelDTO';
import { BancoModelDTO } from './banco.ModelDTO';
import { BankAccountTypeModelDTO } from './bankAccountType.ModelDTO';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';


export class PersonalModelDTO extends EntityModelDTO<PersonalDTO> {

   private modalidadModel: ModalidadContractualModelDTO;
   private modalidadSubscribe: Subscription;
   private nivelEstudioModel: NivelEstudioModelDTO;
   private nivelEstudioSubscribe: Subscription;
   private nacionalidadModel: PaisModelDTO;
   private nacionalidadSubscribe: Subscription;
   private issuingCountryModel: PaisModelDTO;
   private issuingCountrySubscribe: Subscription;
   private domicilioModel: DomicilioModelDTO;
   private domicilioSubscribe: Subscription;
   private domicilioResidenciaModel: DomicilioModelDTO;
   private domicilioResidenciaSubscribe: Subscription;
   private oficinaModel: OficinaModelDTO;
   private oficinaSubscribe: Subscription;
   private contactoEmergenciaModel: ContactoEmergenciaModelDTO;
   private contactoEmergenciaSubscribe: Subscription;
   private bancoModel: BancoModelDTO;
   private bancoSubscribe: Subscription;
   private bankAccountTypeModel: BankAccountTypeModelDTO;
   private bankAccountTypeSubscribe: Subscription;
   private legajoModel: LegajoModelDTO;
   private legajoSubscribe: Subscription;

   public constructor(protected entityDTO: PersonalDTO) {
      super(entityDTO);
   }

   public static getTipoDocumento(): string[] {
      return EntityModelDTO.getEnumArray(TipoDocumento);
   }

   public static getTipoGenero(): string[] {
      return EntityModelDTO.getEnumArray(TipoGenero);
   }

   public static getEstadoCivil(): string[] {
      return EntityModelDTO.getEnumArray(EstadoCivil);
   }

   public static getRelationshipPositionEnum(): string[] {
      return EntityModelDTO.getEnumArray(RelationshipPositionEnum);
   }

   public setEntityDTO(entityDTO: PersonalDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.modalidadModel = new ModalidadContractualModelDTO(this.entityDTO.modalidad);
      this.modalidadSubscribe = this.modalidadModel.changed.subscribe((changed) => this.changed.next(changed));
      this.nivelEstudioModel = new NivelEstudioModelDTO(this.entityDTO.nivelEstudio);
      this.nivelEstudioSubscribe = this.nivelEstudioModel.changed.subscribe((changed) => this.changed.next(changed));
      this.nacionalidadModel = new PaisModelDTO(this.entityDTO.nacionalidad);
      this.nacionalidadSubscribe = this.nacionalidadModel.changed.subscribe((changed) => this.changed.next(changed));
      this.issuingCountryModel = new PaisModelDTO(this.entityDTO.issuingCountry);
      this.issuingCountrySubscribe = this.issuingCountryModel.changed.subscribe((changed) => this.changed.next(changed));
      this.domicilioModel = new DomicilioModelDTO(this.entityDTO.domicilio);
      this.domicilioSubscribe = this.domicilioModel.changed.subscribe((changed) => this.changed.next(changed));
      this.domicilioResidenciaModel = new DomicilioModelDTO(this.entityDTO.domicilioResidencia);
      this.domicilioResidenciaSubscribe = this.domicilioResidenciaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.oficinaModel = new OficinaModelDTO(this.entityDTO.oficina);
      this.oficinaSubscribe = this.oficinaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.contactoEmergenciaModel = new ContactoEmergenciaModelDTO(this.entityDTO.contactoEmergencia);
      this.contactoEmergenciaSubscribe = this.contactoEmergenciaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.bancoModel = new BancoModelDTO(this.entityDTO.banco);
      this.bancoSubscribe = this.bancoModel.changed.subscribe((changed) => this.changed.next(changed));
      this.bankAccountTypeModel = new BankAccountTypeModelDTO(this.entityDTO.bankAccountType);
      this.bankAccountTypeSubscribe = this.bankAccountTypeModel.changed.subscribe((changed) => this.changed.next(changed));
      this.legajoModel = new LegajoModelDTO(this.entityDTO.legajo);
      this.legajoSubscribe = this.legajoModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.modalidadModel.dispose();
      this.modalidadSubscribe.unsubscribe();
      this.nivelEstudioModel.dispose();
      this.nivelEstudioSubscribe.unsubscribe();
      this.nacionalidadModel.dispose();
      this.nacionalidadSubscribe.unsubscribe();
      this.issuingCountryModel.dispose();
      this.issuingCountrySubscribe.unsubscribe();
      this.domicilioModel.dispose();
      this.domicilioSubscribe.unsubscribe();
      this.domicilioResidenciaModel.dispose();
      this.domicilioResidenciaSubscribe.unsubscribe();
      this.oficinaModel.dispose();
      this.oficinaSubscribe.unsubscribe();
      this.contactoEmergenciaModel.dispose();
      this.contactoEmergenciaSubscribe.unsubscribe();
      this.bancoModel.dispose();
      this.bancoSubscribe.unsubscribe();
      this.bankAccountTypeModel.dispose();
      this.bankAccountTypeSubscribe.unsubscribe();
      this.legajoModel.dispose();
      this.legajoSubscribe.unsubscribe();
   }

   get ModalidadModel(): ModalidadContractualModelDTO { return this.modalidadModel; }
   get Modalidad(): ModalidadContractualDTO { return this.modalidadModel.getEntityDTO(); }
   set Modalidad(value: ModalidadContractualDTO) { this.notifyChange(() => { this.entityDTO.modalidad = value; this.modalidadModel.setEntityDTO(value); }); }

   get NivelEstudioModel(): NivelEstudioModelDTO { return this.nivelEstudioModel; }
   get NivelEstudio(): NivelEstudioDTO { return this.nivelEstudioModel.getEntityDTO(); }
   set NivelEstudio(value: NivelEstudioDTO) { this.notifyChange(() => { this.entityDTO.nivelEstudio = value; this.nivelEstudioModel.setEntityDTO(value); }); }

   get NacionalidadModel(): PaisModelDTO { return this.nacionalidadModel; }
   get Nacionalidad(): PaisDTO { return this.nacionalidadModel.getEntityDTO(); }
   set Nacionalidad(value: PaisDTO) { this.notifyChange(() => { this.entityDTO.nacionalidad = value; this.nacionalidadModel.setEntityDTO(value); }); }

   get IssuingCountryModel(): PaisModelDTO { return this.issuingCountryModel; }
   get IssuingCountry(): PaisDTO { return this.issuingCountryModel.getEntityDTO(); }
   set IssuingCountry(value: PaisDTO) { this.notifyChange(() => { this.entityDTO.issuingCountry = value; this.issuingCountryModel.setEntityDTO(value); }); }

   get DomicilioModel(): DomicilioModelDTO { return this.domicilioModel; }
   get Domicilio(): DomicilioDTO { return this.domicilioModel.getEntityDTO(); }
   set Domicilio(value: DomicilioDTO) { this.notifyChange(() => { this.entityDTO.domicilio = value; this.domicilioModel.setEntityDTO(value); }); }

   get DomicilioResidenciaModel(): DomicilioModelDTO { return this.domicilioResidenciaModel; }
   get DomicilioResidencia(): DomicilioDTO { return this.domicilioResidenciaModel.getEntityDTO(); }
   set DomicilioResidencia(value: DomicilioDTO) { this.notifyChange(() => { this.entityDTO.domicilioResidencia = value; this.domicilioResidenciaModel.setEntityDTO(value); }); }

   get OficinaModel(): OficinaModelDTO { return this.oficinaModel; }
   get Oficina(): OficinaDTO { return this.oficinaModel.getEntityDTO(); }
   set Oficina(value: OficinaDTO) { this.notifyChange(() => { this.entityDTO.oficina = value; this.oficinaModel.setEntityDTO(value); }); }

   get ContactoEmergenciaModel(): ContactoEmergenciaModelDTO { return this.contactoEmergenciaModel; }
   get ContactoEmergencia(): ContactoEmergenciaDTO { return this.contactoEmergenciaModel.getEntityDTO(); }
   set ContactoEmergencia(value: ContactoEmergenciaDTO) { this.notifyChange(() => { this.entityDTO.contactoEmergencia = value; this.contactoEmergenciaModel.setEntityDTO(value); }); }

   get BancoModel(): BancoModelDTO { return this.bancoModel; }
   get Banco(): BancoDTO { return this.bancoModel.getEntityDTO(); }
   set Banco(value: BancoDTO) { this.notifyChange(() => { this.entityDTO.banco = value; this.bancoModel.setEntityDTO(value); }); }

   get BankAccountTypeModel(): BankAccountTypeModelDTO { return this.bankAccountTypeModel; }
   get BankAccountType(): BankAccountTypeDTO { return this.bankAccountTypeModel.getEntityDTO(); }
   set BankAccountType(value: BankAccountTypeDTO) { this.notifyChange(() => { this.entityDTO.bankAccountType = value; this.bankAccountTypeModel.setEntityDTO(value); }); }

   get LegajoModel(): LegajoModelDTO { return this.legajoModel; }
   get Legajo(): LegajoDTO { return this.legajoModel.getEntityDTO(); }
   set Legajo(value: LegajoDTO) { this.notifyChange(() => { this.entityDTO.legajo = value; this.legajoModel.setEntityDTO(value); }); }

   get Familiares(): Array<FamiliarDTO> { return this.entityDTO.familiares; }
   set Familiares(value: Array<FamiliarDTO>) { this.notifyChangeDTO('familiares', value); }

   get PosiblesAusencias(): Array<AusenciaDTO> { return this.entityDTO.posiblesAusencias; }
   set PosiblesAusencias(value: Array<AusenciaDTO>) { this.notifyChangeDTO('posiblesAusencias', value); }

   get FirmaOlografaImage(): string { return this.entityDTO.firmaOlografaImage; }
   set FirmaOlografaImage(value: string) { this.notifyChangeDTO('firmaOlografaImage', value); }

   get UsaSuFirmaOlografa(): boolean { return this.entityDTO.usaSuFirmaOlografa; }
   set UsaSuFirmaOlografa(value: boolean) { this.notifyChangeDTO('usaSuFirmaOlografa', value); }

   get AgregarDatosAnagraficos(): boolean { return this.entityDTO.agregarDatosAnagraficos; }
   set AgregarDatosAnagraficos(value: boolean) { this.notifyChangeDTO('agregarDatosAnagraficos', value); }

   get Image(): string { return this.entityDTO.image; }
   set Image(value: string) { this.notifyChangeDTO('image', value); }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Apellido(): string { return this.entityDTO.apellido; }
   set Apellido(value: string) { this.notifyChangeDTO('apellido', value); }

   get NombreCompleto(): string { return this.entityDTO.nombreCompleto; }
   set NombreCompleto(value: string) { this.notifyChangeDTO('nombreCompleto', value); }

   get Dni(): string { return this.entityDTO.dni; }
   set Dni(value: string) { this.notifyChangeDTO('dni', value); }

   get Passport(): string { return this.entityDTO.passport; }
   set Passport(value: string) { this.notifyChangeDTO('passport', value); }

   get TipoDocumento(): string { return TipoDocumento[this.entityDTO.tipoDocumento]; }
   set TipoDocumento(value: string) { this.notifyChangeDTO('tipoDocumento', TipoDocumento[value]); }

   get FechaNacimiento(): Date { return this.entityDTO.fechaNacimiento; }
   set FechaNacimiento(value: Date) { this.notifyChangeDTO('fechaNacimiento', value); }

   get Genero(): string { return TipoGenero[this.entityDTO.genero]; }
   set Genero(value: string) { this.notifyChangeDTO('genero', TipoGenero[value]); }

   get Cuil(): string { return this.entityDTO.cuil; }
   set Cuil(value: string) { this.notifyChangeDTO('cuil', value); }

   get Telefonos(): string { return this.entityDTO.telefonos; }
   set Telefonos(value: string) { this.notifyChangeDTO('telefonos', value); }

   get Email(): string { return this.entityDTO.email; }
   set Email(value: string) { this.notifyChangeDTO('email', value); }

   get ConfirmarEmail(): boolean { return this.entityDTO.confirmarEmail; }
   set ConfirmarEmail(value: boolean) { this.notifyChangeDTO('confirmarEmail', value); }

   get Edad(): number { return this.entityDTO.edad; }
   set Edad(value: number) { this.notifyChangeDTO('edad', value); }

   get Peso(): number { return this.entityDTO.peso; }
   set Peso(value: number) { this.notifyChangeDTO('peso', value); }

   get Altura(): number { return this.entityDTO.altura; }
   set Altura(value: number) { this.notifyChangeDTO('altura', value); }

   get CircunferenciaCabeza(): number { return this.entityDTO.circunferenciaCabeza; }
   set CircunferenciaCabeza(value: number) { this.notifyChangeDTO('circunferenciaCabeza', value); }

   get TallePantalon(): string { return this.entityDTO.tallePantalon; }
   set TallePantalon(value: string) { this.notifyChangeDTO('tallePantalon', value); }

   get TalleCalzado(): string { return this.entityDTO.talleCalzado; }
   set TalleCalzado(value: string) { this.notifyChangeDTO('talleCalzado', value); }

   get TalleCamisa(): string { return this.entityDTO.talleCamisa; }
   set TalleCamisa(value: string) { this.notifyChangeDTO('talleCamisa', value); }

   get MovilidadPropia(): boolean { return this.entityDTO.movilidadPropia; }
   set MovilidadPropia(value: boolean) { this.notifyChangeDTO('movilidadPropia', value); }

   get DisponibilidadTraslado(): boolean { return this.entityDTO.disponibilidadTraslado; }
   set DisponibilidadTraslado(value: boolean) { this.notifyChangeDTO('disponibilidadTraslado', value); }

   get OficinaNombre(): string { return this.entityDTO.oficinaNombre; }
   set OficinaNombre(value: string) { this.notifyChangeDTO('oficinaNombre', value); }

   get EstadoCivil(): string { return EstadoCivil[this.entityDTO.estadoCivil]; }
   set EstadoCivil(value: string) { this.notifyChangeDTO('estadoCivil', EstadoCivil[value]); }

   get FamiliaresACargo(): number { return this.entityDTO.familiaresACargo; }
   set FamiliaresACargo(value: number) { this.notifyChangeDTO('familiaresACargo', value); }

   get UltimoSueldo(): number { return this.entityDTO.ultimoSueldo; }
   set UltimoSueldo(value: number) { this.notifyChangeDTO('ultimoSueldo', value); }

   get GrupoSanguineo(): string { return this.entityDTO.grupoSanguineo; }
   set GrupoSanguineo(value: string) { this.notifyChangeDTO('grupoSanguineo', value); }

   get NroCuenta(): string { return this.entityDTO.nroCuenta; }
   set NroCuenta(value: string) { this.notifyChangeDTO('nroCuenta', value); }

   get Alias(): string { return this.entityDTO.alias; }
   set Alias(value: string) { this.notifyChangeDTO('alias', value); }

   get RelationshipPosition(): string { return RelationshipPositionEnum[this.entityDTO.relationshipPosition]; }
   set RelationshipPosition(value: string) { this.notifyChangeDTO('relationshipPosition', RelationshipPositionEnum[value]); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
