import { EstadoEncuesta } from 'src/app/ModelDTO/DTO/estadoEncuesta.ENUM';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';


export class EncuestaResumenDTO implements IEntityDTO {

   nombre = '';
   estado: EstadoEncuesta;
   creadorId = 0;
   creadorNombre = '';
   creadorApellido = '';
   creadorImage = '';
   cuestionarioId = 0;
   evaluationId = 0;
   dueDays: number;
   evaluationType = '';
   planificacionTipo = '';
   planificacionFechaUnica: Date;
   planificacionFechaInicio: Date;
   planificacionFechaFinRecurrencia: Date;
   isAnonymous: boolean;
   onlyCreatorCanEdit: boolean;
   canEdit: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.estado != null) { this.estado = jsonObj.estado as EstadoEncuesta; }
      if (jsonObj.creadorId != null) { this.creadorId = jsonObj.creadorId; }
      if (jsonObj.creadorNombre != null) { this.creadorNombre = jsonObj.creadorNombre; }
      if (jsonObj.creadorApellido != null) { this.creadorApellido = jsonObj.creadorApellido; }
      if (jsonObj.creadorImage != null) { this.creadorImage = jsonObj.creadorImage; }
      if (jsonObj.cuestionarioId != null) { this.cuestionarioId = jsonObj.cuestionarioId; }
      if (jsonObj.evaluationId != null) { this.evaluationId = jsonObj.evaluationId; }
      if (jsonObj.evaluationType != null) { this.evaluationType = jsonObj.evaluationType; }
      if (jsonObj.planificacionTipo != null) { this.planificacionTipo = jsonObj.planificacionTipo; }
      if (jsonObj.planificacionFechaUnica != null) { this.planificacionFechaUnica = new Date(jsonObj.planificacionFechaUnica); }
      if (jsonObj.planificacionFechaInicio != null) { this.planificacionFechaInicio = new Date(jsonObj.planificacionFechaInicio); }
      if (jsonObj.planificacionFechaFinRecurrencia != null) { this.planificacionFechaFinRecurrencia = new Date(jsonObj.planificacionFechaFinRecurrencia); }
      if (jsonObj.isAnonymous != null) { this.isAnonymous = jsonObj.isAnonymous; }
      if (jsonObj.onlyCreatorCanEdit != null) { this.onlyCreatorCanEdit = jsonObj.onlyCreatorCanEdit; }
      if (jsonObj.canEdit != null) { this.canEdit = jsonObj.canEdit; }
      if (jsonObj.dueDays != null) { this.dueDays = jsonObj.dueDays; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
