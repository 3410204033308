
import { EvaluatorRole } from 'src/app/ModelDTO/DTO/evaluatorRole.ENUM';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';


export class EncuestaEvaluatorDTO implements IEntityDTO {

   role: EvaluatorRole;
   percentage = 0;
   id = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.role != null) { this.role = jsonObj.role as EvaluatorRole; }
      if (jsonObj.percentage != null) { this.percentage = jsonObj.percentage; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
