import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { SolicitudEntregaDTO } from 'src/app/ModelDTO/DTO/solicitudEntrega.DTO';

@Injectable({
   providedIn: 'root'
})
export class SolicitudEntregaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getSolicitudEntregaIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/solicitudEntrega/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertSolicitudEntrega(solicitudEntregaDTO: FormData): Promise<SolicitudEntregaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/solicitudEntrega/', solicitudEntregaDTO));
         const resDTO = new SolicitudEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateSolicitudEntregaId(id: number, solicitudEntregaDTO: FormData): Promise<SolicitudEntregaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/solicitudEntrega/' + id, solicitudEntregaDTO));
         const resDTO = new SolicitudEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getSolicitudEntregaPorSolicitudId(id: number): Promise<SolicitudEntregaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/solicitudEntrega/porSolicitud/' + id));
         if (!res) { return null; }
         const resDTO = new SolicitudEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateSolicitudEntregaIdEstado(id: number, solicitudEntregaDTO: SolicitudEntregaDTO): Promise<SolicitudEntregaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/solicitudEntrega/' + id + '/estado', solicitudEntregaDTO));
         const resDTO = new SolicitudEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getSolicitudEntregaFiltradas(fechaDesde: Date, fechaHasta: Date, pageNumber: number, pageSize: number, estado: number = 0, personal: number = 0): Promise<Array<SolicitudEntregaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/solicitudEntrega/filtradas' + '?fechaDesde=' + fechaDesde.toISOString() + '&fechaHasta=' + fechaHasta.toISOString() + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&estado=' + estado + '&personal=' + personal));
         const resJson = res.data;
         const resDTO = new Array<SolicitudEntregaDTO>();
         for (const item of resJson) {
            const itemDTO = new SolicitudEntregaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getSolicitudEntregaIdPersonalEntrega(idPersonalEntrega: number): Promise<SolicitudEntregaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/solicitudEntrega/teammate-deliver/' + idPersonalEntrega));
         if (!res) { return null; }
         const resDTO = new SolicitudEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteSolicitudEntregaIdSolicitudCancelar(idSolicitud: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/solicitudEntrega/' + idSolicitud + '/cancelar'));

   }

}
