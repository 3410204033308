import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { TipoAusencia } from 'src/app/ModelDTO/DTO/tipoAusencia.ENUM';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';
import { TipoUnidadAusencia } from 'src/app/ModelDTO/DTO/tipoUnidadAusencia.ENUM';
import { TipoGeneracionAusencia } from 'src/app/ModelDTO/DTO/tipoGeneracionAusencia.ENUM';
import { TipoDiasConteo } from 'src/app/ModelDTO/DTO/tipoDiasConteo.ENUM';
import { TipoLimiteAusencia } from 'src/app/ModelDTO/DTO/tipoLimiteAusencia.ENUM';
import { TipoVencimientoAusencia } from 'src/app/ModelDTO/DTO/tipoVencimientoAusencia.ENUM';
import { TipoConteoAntiguedad } from 'src/app/ModelDTO/DTO/tipoConteoAntiguedad.ENUM';
import { TipoCargaFamiliar } from 'src/app/ModelDTO/DTO/tipoCargaFamiliar.ENUM';


export class AusenciaDTO implements IEntityDTO {

   tipo: TipoAusencia;
   nombre = '';
   observacion = '';
   letra = '';
   convenio: ConvenioColectivoDTO;
   gozaHaberes: boolean;
   unidad: TipoUnidadAusencia;
   cantUnidades = 0;
   tipoGeneracion: TipoGeneracionAusencia;
   tipoConteo: TipoDiasConteo;
   cycleStartDay?: number;
   cycleStartMonth?: number;
   limite: TipoLimiteAusencia;
   maximoAcumulable = 0;
   tipoVencimiento: TipoVencimientoAusencia;
   limiteVtoAusencia = 0;
   conteoAntiguedad: TipoConteoAntiguedad;
   antiguedadDesde = 0;
   antiguedadHasta = 0;
   cargaFamiliar: TipoCargaFamiliar;
   generatesProportionalDays: boolean;
   proportionalDays?: number;
   workedDaysForProportional?: number;
   maximumWorkedDaysForProportional?: number;
   correspondeAColaborador: boolean;
   totalAsignados = 0;
   totalConsumidos = 0;
   totalDisponible = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.convenio = new ConvenioColectivoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.tipo != null) { this.tipo = jsonObj.tipo as TipoAusencia; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.observacion != null) { this.observacion = jsonObj.observacion; }
      if (jsonObj.letra != null) { this.letra = jsonObj.letra; }
      if (jsonObj.convenio != null) { this.convenio.PrepareDTO(jsonObj.convenio); }
      if (jsonObj.gozaHaberes != null) { this.gozaHaberes = jsonObj.gozaHaberes; }
      if (jsonObj.unidad != null) { this.unidad = jsonObj.unidad as TipoUnidadAusencia; }
      if (jsonObj.cantUnidades != null) { this.cantUnidades = jsonObj.cantUnidades; }
      if (jsonObj.tipoGeneracion != null) { this.tipoGeneracion = jsonObj.tipoGeneracion as TipoGeneracionAusencia; }
      if (jsonObj.tipoConteo != null) { this.tipoConteo = jsonObj.tipoConteo as TipoDiasConteo; }
      if (jsonObj.cycleStartDay != null) { this.cycleStartDay = jsonObj.cycleStartDay; }
      if (jsonObj.cycleStartMonth != null) { this.cycleStartMonth = jsonObj.cycleStartMonth; }
      if (jsonObj.limite != null) { this.limite = jsonObj.limite as TipoLimiteAusencia; }
      if (jsonObj.maximoAcumulable != null) { this.maximoAcumulable = jsonObj.maximoAcumulable; }
      if (jsonObj.tipoVencimiento != null) { this.tipoVencimiento = jsonObj.tipoVencimiento as TipoVencimientoAusencia; }
      if (jsonObj.limiteVtoAusencia != null) { this.limiteVtoAusencia = jsonObj.limiteVtoAusencia; }
      if (jsonObj.conteoAntiguedad != null) { this.conteoAntiguedad = jsonObj.conteoAntiguedad as TipoConteoAntiguedad; }
      if (jsonObj.antiguedadDesde != null) { this.antiguedadDesde = jsonObj.antiguedadDesde; }
      if (jsonObj.antiguedadHasta != null) { this.antiguedadHasta = jsonObj.antiguedadHasta; }
      if (jsonObj.cargaFamiliar != null) { this.cargaFamiliar = jsonObj.cargaFamiliar as TipoCargaFamiliar; }
      if (jsonObj.generatesProportionalDays != null) { this.generatesProportionalDays = jsonObj.generatesProportionalDays; }
      if (jsonObj.proportionalDays != null) { this.proportionalDays = jsonObj.proportionalDays; }
      if (jsonObj.workedDaysForProportional != null) { this.workedDaysForProportional = jsonObj.workedDaysForProportional; }
      if (jsonObj.maximumWorkedDaysForProportional != null) { this.maximumWorkedDaysForProportional = jsonObj.maximumWorkedDaysForProportional; }
      if (jsonObj.correspondeAColaborador != null) { this.correspondeAColaborador = jsonObj.correspondeAColaborador; }
      if (jsonObj.totalAsignados != null) { this.totalAsignados = jsonObj.totalAsignados; }
      if (jsonObj.totalConsumidos != null) { this.totalConsumidos = jsonObj.totalConsumidos; }
      if (jsonObj.totalDisponible != null) { this.totalDisponible = jsonObj.totalDisponible; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
