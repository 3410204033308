import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { db } from 'src/app/local-db/db';
import { ConfiguracionDTO } from 'src/app/ModelDTO/DTO/configuracion.DTO';
import { ConfiguracionServiceBackend } from 'src/app/ServiceBackend/configuracion.ServiceBackend';
import { classToJson } from 'src/app/shared/shared-functions';
import { GLOBAL_SETTINGS, GLOBAL_SETTINGS_TIMESTAMP } from 'src/app/shared/localStorageConstants';

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingsService {
  constructor(private configuracionServiceBackend: ConfiguracionServiceBackend) {}

  public async initSettings(): Promise<void> {
    const dbSettings = await db.globalSettings.where('id').equals(1).first();
    const storedSettings = dbSettings ?? localStorage.getItem(GLOBAL_SETTINGS);

    if (!storedSettings || (await this.isOutdated())) {
      await this.getSettings();
    }
  }

  public async getSettings(useCache: boolean = false): Promise<ConfiguracionDTO> {
    // Check for cached settings in localStorage
    if (useCache) {
      const dbSettings = await db.globalSettings.where('id').equals(1).first();
      if (dbSettings) {
        const dto = new ConfiguracionDTO();
        dto.PrepareDTO(dbSettings);
        return dto;
      }

      const storedSettings = localStorage.getItem(GLOBAL_SETTINGS);
      if (storedSettings) {
        return JSON.parse(storedSettings);
      }
    }

    try {
      // Try fetching from backend
      const res: ConfiguracionDTO[] = await this.configuracionServiceBackend.getConfiguracion();
      const settings = res && res.length ? res[0] : null;

      // Store the settings in localStorage for future use
      this.saveLocalSettings(settings);
      return settings;
    } catch (error) {
      const dbSettings = await db.globalSettings.where('id').equals(1).first();
      if (dbSettings) {
        const dto = new ConfiguracionDTO();
        dto.PrepareDTO(dbSettings);
        return dto;
      }

      const storedSettings = localStorage.getItem(GLOBAL_SETTINGS);

      if (!useCache && storedSettings) {
        return JSON.parse(storedSettings);
      }

      // If backend fails and no cached settings were used, reject the promise
      throw error;
    }
  }

  public async updateSettings(settingsId: number, settingsDTO: ConfiguracionDTO): Promise<ConfiguracionDTO> {
    const res = await this.configuracionServiceBackend.patchConfiguracionId(settingsId, settingsDTO);
    await this.saveLocalSettings(res);
    return res;
  }

  public async updateSpecialPermissions(settingsDTO: ConfiguracionDTO): Promise<ConfiguracionDTO> {
    const res = await this.configuracionServiceBackend.updateConfiguracionIdPermisos(settingsDTO.id, settingsDTO);
    await this.saveLocalSettings(res);
    return res;
  }

  public async removeSettingsFromLocalStorage(): Promise<void> {
    localStorage.removeItem(GLOBAL_SETTINGS);
    await db.globalSettings.clear();
    await db.globalSettingsTimestamp.clear();
  }

  private async saveLocalSettings(settings: ConfiguracionDTO): Promise<void> {
    await db.globalSettings.put({ ...classToJson(settings), id: 1 });
    await db.globalSettingsTimestamp.put({ id: 1, date: DateTime.now().toISO() });
  }

  private async isOutdated(): Promise<boolean> {
    const globalSettingsTimestamp = await db.globalSettingsTimestamp.where('id').equals(1).first();

    const lastUpdated = globalSettingsTimestamp?.date ?? localStorage.getItem(GLOBAL_SETTINGS_TIMESTAMP);
    if (!lastUpdated) return true;

    const oneWeekAgo = DateTime.now().minus({ weeks: 1 });
    return DateTime.fromISO(lastUpdated) < oneWeekAgo;
  }
}
