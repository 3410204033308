import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { SurveyResultDTO } from 'src/app/ModelDTO/DTO/surveyResult.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';


export class ResultsDTO implements IEntityDTO {

   questionnaireName = '';
   questionnaireCreationDate: Date;
   totalParticipants = 0;
   respondingParticipants = 0;
   surveyResults: Array<SurveyResultDTO>;
   generalResult: LevelRangeDTO;
   evaluated: PersonalLegajoBasicoDTO;
   id = 0;

   constructor() {
      this.surveyResults = new Array<SurveyResultDTO>();
      this.generalResult = new LevelRangeDTO();
      this.evaluated = new PersonalLegajoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.questionnaireName != null) { this.questionnaireName = jsonObj.questionnaireName; }
      if (jsonObj.questionnaireCreationDate != null) { this.questionnaireCreationDate = new Date(jsonObj.questionnaireCreationDate); }
      if (jsonObj.totalParticipants != null) { this.totalParticipants = jsonObj.totalParticipants; }
      if (jsonObj.respondingParticipants != null) { this.respondingParticipants = jsonObj.respondingParticipants; }
      if (jsonObj.surveyResults != null) { for (const item of jsonObj.surveyResults) { const itemDTO = new SurveyResultDTO(); itemDTO.PrepareDTO(item); this.surveyResults.push(itemDTO); } }
      if (jsonObj.generalResult != null) { this.generalResult.PrepareDTO(jsonObj.generalResult); }
      if (jsonObj.evaluated != null) { this.evaluated.PrepareDTO(jsonObj.evaluated); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
