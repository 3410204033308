import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';


export class DocumentacionConAnalisisHojaDTO implements IEntityDTO {

   numero = 0;
   personalLegajos: Array<PersonalLegajoBasicoDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.personalLegajos = new Array<PersonalLegajoBasicoDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.numero != null) { this.numero = jsonObj.numero; }
      if (jsonObj.personalLegajos != null) { for (const item of jsonObj.personalLegajos) { const itemDTO = new PersonalLegajoBasicoDTO(); itemDTO.PrepareDTO(item); this.personalLegajos.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
