import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { UserDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/user.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';


export class AttachmentConNuevosFirmantesDTO implements IEntityDTO {

   attachment: AttachmentUaalooDTO;
   solicitante: UserDTO;
   nuevosFirmantes: Array<PersonalLegajoBasicoDTO>;
   notificarPorEmail: boolean;
   incluirSolicitante: boolean;
   posicionFirma: ConfiguracionFirmaDigitalPosicionDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.attachment = new AttachmentUaalooDTO();
      this.solicitante = new UserDTO();
      this.nuevosFirmantes = new Array<PersonalLegajoBasicoDTO>();
      this.posicionFirma = new ConfiguracionFirmaDigitalPosicionDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.attachment != null) { this.attachment.PrepareDTO(jsonObj.attachment); }
      if (jsonObj.solicitante != null) { this.solicitante.PrepareDTO(jsonObj.solicitante); }
      if (jsonObj.nuevosFirmantes != null) { for (const item of jsonObj.nuevosFirmantes) { const itemDTO = new PersonalLegajoBasicoDTO(); itemDTO.PrepareDTO(item); this.nuevosFirmantes.push(itemDTO); } }
      if (jsonObj.notificarPorEmail != null) { this.notificarPorEmail = jsonObj.notificarPorEmail; }
      if (jsonObj.incluirSolicitante != null) { this.incluirSolicitante = jsonObj.incluirSolicitante; }
      if (jsonObj.posicionFirma != null) { this.posicionFirma.PrepareDTO(jsonObj.posicionFirma); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
