import { Component, Input } from '@angular/core';
import { TipoEstadoPersonalLegajoCuestinario } from '@api/enums/tipo-estado-personal-legajo-cuestinario.enum';
import { CuestionarioPreguntaDTO } from 'src/app/ModelDTO/DTO/cuestionarioPregunta.DTO';
import { CuestionarioRespuestaDTO } from 'src/app/ModelDTO/DTO/cuestionarioRespuesta.DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { QuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/question.component';

@Component({
  selector: 'app-accent-mark-question',
  templateUrl: './accent-mark-question.component.html',
  styleUrls: ['./accent-mark-question.component.scss'],
})
export class AccentMarkQuestionComponent implements QuestionComponent {
  @Input() public data: CuestionarioPreguntaDTO;
  @Input() public modoAdministrador: boolean;
  @Input() public editionMode: boolean;
  @Input() public estadoCuestionario: TipoEstadoPersonalLegajoCuestinario;
  @Input() public cuestionarioTerminado: TipoEstadoPersonalLegajoCuestinario;
  @Input() public position: number;
  @Input() public previousResult: LevelRangeDTO;

  public respuestaChange(respuesta: CuestionarioRespuestaDTO, value: boolean): void {
    respuesta.modificada = true;
    respuesta.fecha = new Date();
    respuesta.valorNumerico = value ? 1 : 0;
    respuesta.id = this.data.id;
  }
}
