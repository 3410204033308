import { ConfiguracionDTO } from '@api/interfaces/configuracion.interface';
import Dexie, { Table } from 'dexie';

export class AppDB extends Dexie {
  public globalSettings!: Table<ConfiguracionDTO, number>;
  public globalSettingsTimestamp!: Table<{ id: number; date: string }, number>;

  constructor() {
    super('ngdexieliveQuery');
    this.version(1).stores({
      globalSettings: 'id',
      globalSettingsTimestamp: 'id',
    });
  }
}

export const db = new AppDB();
