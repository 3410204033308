/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, Signal, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Router, Scroll } from '@angular/router';
import { UserState } from '@api/enums/user-state.enum';
import { TeammatesBackendService } from '@api/services/teammates-backend.service';
import { UsersBackendService } from '@api/services/users-backend.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  forkJoin,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
  toArray,
} from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { companyFeatureChat, featureGetUsers } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { AuthenticationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/auth/authentication.service';
import { CdnImageUrlPipe } from 'src/app/shared/pipes/cdn-image-url.pipe';
import { ChatBackendService } from './chat-backend.service';
import { ChatServiceState } from './chat-service-state.enum';
import { ChatConversation } from './interfaces/chat-conversation.interface';
import { ChatMessage, SystemChatMessage, UserChatMessage } from './interfaces/chat-message.interface';
import { ChatUser } from './interfaces/chat-user.interface';
import { SystemMessageMap } from './system-message-code.type';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private openWidgetTrigger = new Subject<boolean>();
  public openWidgetTrigger$ = this.openWidgetTrigger.asObservable();

  public userId: string;
  // number of conversations with at least one unread message
  private readonly $_unreadConversations = signal<number>(0);
  public readonly $unreadConversations = this.$_unreadConversations.asReadonly();

  private readonly $_groupViewOpen = signal<boolean>(false);
  public readonly $groupViewOpen = this.$_groupViewOpen.asReadonly();

  private readonly $_serviceState = signal<ChatServiceState>('Inactive');
  private readonly serviceState$ = toObservable(this.$_serviceState);
  public readonly $serviceState = this.$_serviceState.asReadonly();

  public readonly $chatEnabled = signal<boolean>(false);

  // Events
  private messageSentEvent: Subject<void> = new Subject<void>();
  public messageSent$: Observable<void> = this.messageSentEvent.asObservable();
  private currentConversation: BehaviorSubject<ChatConversation | null> = new BehaviorSubject<ChatConversation | null>(null);
  public currentConversationChanged$: Observable<ChatConversation> = this.currentConversation.asObservable();
  public conversationListChanged$: Observable<ChatConversation[]>;
  private userData: BehaviorSubject<Map<string, ChatUser>> = new BehaviorSubject<Map<string, ChatUser>>(new Map());
  public userData$: Observable<Map<string, ChatUser>> = this.userData.asObservable();
  public $userData: Signal<Map<string, ChatUser>> = toSignal(this.userData);
  private chatOpenEvent = new Subject<boolean>();
  public readonly $chatOpen: Signal<boolean> = toSignal(this.chatOpenEvent);

  private msgSubscription: Subscription;
  private unfilteredConversationList: ChatConversation[] = [];
  private mockConversationsMap: Map<string, ChatConversation>;

  #filterText$ = new BehaviorSubject('');
  public $filterText = this.#filterText$.asObservable();

  private readonly mockPrefix = 'mock#';

  private conversationsLoadTrigger$ = new Subject<void>();
  private conversationListUpdateTrigger$ = new Subject<void>();

  constructor(
    private chatBackendService: ChatBackendService,
    private teammatesBackendService: TeammatesBackendService,
    private usersServiceBackend: UsersBackendService,
    private headerService: HeaderAppService,
    private featureFlagService: FeatureFlagService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private cdnImagePipe: CdnImageUrlPipe,
  ) {
    this.conversationListChanged$ = combineLatest([this.#filterText$, this.conversationListUpdateTrigger$]).pipe(
      map(([filterText]) => {
        if (!filterText) {
          return this.unfilteredConversationList ?? [];
        }
        const filterRegExp = new RegExp(filterText, 'i');
        return this.unfilteredConversationList?.filter((conversation) => filterRegExp.test(conversation.title)) ?? [];
      }),
      shareReplay({
        bufferSize: 1,
        refCount: false,
      }),
      startWith([]),
    );

    combineLatest([this.authenticationService.loggedEvent$, this.featureFlagService.flags$])
      .pipe(
        map(([logData, flagsData]) => {
          const chatEnabled =
            logData &&
            FeatureFlagHelper.featureOn(companyFeatureChat, flagsData.flags) &&
            FeatureFlagHelper.featureOn(featureGetUsers, flagsData.flags);

          this.$chatEnabled.set(chatEnabled);
          return chatEnabled;
        }),
        filter((chatEnabled) => chatEnabled),
        switchMap(() => this.chatBackendService.chatConnected$),
        filter((connected) => connected),
      )
      .subscribe(() => {
        this.init();
      });

    // Conversations data load after logging: on first message or conversations load trigger
    this.authenticationService.loggedEvent$
      .pipe(
        switchMap(() =>
          combineLatest([
            this.serviceState$.pipe(filter((state) => state === 'Initialized')),
            merge(this.conversationsLoadTrigger$, this.chatBackendService.onMessageReceived$),
          ]).pipe(
            switchMap(() => this.initializeConversationsData()),
            take(1),
          ),
        ),
        takeUntilDestroyed(),
      )
      .subscribe((channels) => this.updateConversationList(channels));

    this.headerService.loggedOut$.pipe(takeUntilDestroyed()).subscribe(() => this.disconnect());

    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof Scroll),
        map((event: Scroll) => event.routerEvent.url.startsWith('/user/chat')),
        distinctUntilChanged(),
      )
      .subscribe((open: boolean) => {
        this.chatOpenEvent.next(open);
      });

    this.chatOpenEvent
      .pipe(
        filter((value) => !value),
        switchMap(() => this.setCurrentConversation(null)),
      )
      .subscribe();
  }

  public init(): void {
    if (this.$_serviceState() !== 'Initializing' && this.$_serviceState() !== 'Initialized') {
      this.$_serviceState.set('Initializing');

      this.userId = this.headerService.userLogged.userName;
      this.chatBackendService.chatConnected$
        .pipe(
          tap((connected) => {
            this.msgSubscription?.unsubscribe();
            if (connected) {
              this.msgSubscription = combineLatest([
                this.chatBackendService.onMessageReceived$,
                this.conversationListChanged$.pipe(
                  filter((conversations) => conversations?.length > 0),
                  take(1),
                ),
              ]).subscribe(([msg]) => {
                this.onMessageReceived(msg);
              });
            }
          }),
          switchMap((connected) => of<ChatServiceState>(connected ? 'Initialized' : 'Error')),
          takeUntil(this.headerService.loggedOut$),
        )
        .subscribe((status) => {
          this.$_serviceState.set(status);
        });

      this.serviceState$
        .pipe(
          filter((state) => state === 'Initialized'),
          take(1),
          switchMap(() => this.chatBackendService.getUnreadConversations()),
        )
        .subscribe((unreadConversations) => {
          this.$_unreadConversations.set(unreadConversations);
        });
    }
  }

  public disconnect(): void {
    if (this.$_serviceState() !== 'Inactive') {
      this.msgSubscription?.unsubscribe();
      this.$_serviceState.set('Inactive');
      this.updateConversationList([]);
      this.$_unreadConversations.set(0);
      this.#filterText$.next('');
    }
  }

  public loadConversations(): void {
    this.conversationsLoadTrigger$.next();
  }

  private initializeConversationsData(): Observable<ChatConversation[]> {
    return forkJoin({
      mockArray: this.getMockConversations(),
      backEndArray: this.chatBackendService.getConversations(),
    }).pipe(
      switchMap(({ mockArray, backEndArray }) => {
        this.unfilteredConversationList = backEndArray;
        this.mockConversationsMap = new Map(mockArray.map((channel) => [channel.createdBy, channel]));

        // Setup the conversations to use mock data
        return this.setUpChannelsFromMockData();
      }),
      switchMap((channels) => {
        channels.sort((a, b) => b.lastMessage.date.toMillis() - a.lastMessage.date.toMillis());
        channels.push(...this.mockConversationsMap.values());
        return of(channels);
      }),
    );
  }

  public openChat(): void {
    this.router.navigateByUrl('/user/chat');
  }

  public openNewGroupView(): void {
    this.setCurrentConversation(null);
    this.$_groupViewOpen.set(true);
  }

  public openGroupView(): void {
    this.$_groupViewOpen.set(true);
  }

  public closeGroupView(): void {
    this.$_groupViewOpen.set(false);
  }

  public createGroup(name: string, members: ChatUser[]): Observable<void> {
    const groupMembers = [...members, this.userData.value.get(this.userId)];

    const newConversation: ChatConversation = {
      id: 'newGroup',
      title: name,
      subtitle: groupMembers.map((member) => member.fullName).join(', '),
      creationDate: DateTime.now(),
      lastMessage: null,
      createdBy: this.userId,
      members: groupMembers.map((member) => member.id),
      messages: [],
      loadedMessages: true,
      isGroup: true,
      admin: this.userId,
    };

    // when id is returned, if conversation already exists, set that as current
    // else, set newConversation with new id and merge with ws data when it arrives
    return this.chatBackendService.insertConversation(newConversation).pipe(
      switchMap((id: string) => this.loadConversation(id).pipe(map(() => id))),
      switchMap((id) =>
        this.currentConversation.value === null ? this.setCurrentConversation({ ...newConversation, id }) : of(undefined),
      ),
    );
  }

  public addGroupMembers(group: ChatConversation, members: ChatUser[]): Observable<void> {
    const memberIds = members.map((member) => member.id);
    const totalMembers = group.members.concat(memberIds);
    return this.chatBackendService.addGroupMembers(group.id, memberIds).pipe(
      map((success) => {
        if (success) {
          const updatedGroup = {
            ...group,
            members: totalMembers,
            subtitle: totalMembers.map((member) => this.$userData().get(member)?.fullName).join(', '),
          };
          this.updateConversation(updatedGroup);
        }
      }),
    );
  }

  public leaveGroup(conversation: ChatConversation): Observable<boolean> {
    return this.chatBackendService.removeGroupMember(conversation.id, this.userId).pipe(
      tap((success) => {
        if (success) {
          this.setCurrentConversation(null);
          this.updateConversationList(this.unfilteredConversationList.filter((c) => c.id !== conversation.id));
        }
      }),
    );
  }

  private getUserImgSource(id: string): string {
    return this.userData.value.get(id)?.imgSource;
  }

  private differentRelativeCalendar(d1: DateTime, d2: DateTime): boolean {
    return !d1.hasSame(d2, 'day');
  }

  private getMockConversations(): Observable<ChatConversation[]> {
    // Creates a mock conversation for every collaborator
    const res = this.teammatesBackendService.getTeammatesUsers();
    return res.pipe(
      tap((users) => {
        this.userData.next(
          new Map(
            users.map((user) => [
              user.userName,
              {
                id: user.userName,
                fullName: user.fullName,
                imgSource: this.cdnImagePipe.transform(user.image),
                personalLegajoId: user.userTypeId,
                roleName: user.roleName,
                active: user.state === UserState.Active,
              } as ChatUser,
            ]),
          ),
        );
      }),
      map((users) =>
        users
          .filter((user) => user.state !== UserState.Inactive)
          .map(
            (user) =>
              ({
                id: `${this.mockPrefix}${user.userName}`,
                title: user.fullName,
                subtitle: user.roleName,
                createdBy: user.userName,
                members: user.userName !== this.userId ? [user.userName, this.userId] : [this.userId],
                messages: [],
                img: this.cdnImagePipe.transform(user.image),
                loadedMessages: true,
                targetTeammateFileId: user.userTypeId,
                isMock: true,
              }) as ChatConversation,
          ),
      ),
    );
  }

  // Uses mock map to override private or single conversation data (title, subtitle, img)
  private setUpChannelsFromMockData(): Observable<ChatConversation[]> {
    let unreadConversations = 0;

    return from(this.unfilteredConversationList).pipe(
      mergeMap((channel) => {
        if (channel.unreadMsgs) {
          unreadConversations += 1;
        }

        if (!channel.isGroup) {
          // id for fetching data
          let userId: string;

          // Select the userId based on 1 to 1 or single channel
          if (channel.members.length === 2) {
            userId = channel.members.find((memberId) => memberId !== this.userId);
          } else if (channel.members.length === 1) {
            ({ userId } = this);
          }
          const mockChannel = this.mockConversationsMap.get(userId);
          if (mockChannel) {
            this.mockConversationsMap.delete(userId);
            return of({
              ...channel,
              title: mockChannel.title,
              subtitle: mockChannel.subtitle,
              img: mockChannel.img,
              targetTeammateFileId: mockChannel.targetTeammateFileId,
            });
          }
          const userData = this.userData.value.get(userId);
          if (userData) {
            return of<ChatConversation>({
              ...channel,
              title: userData.fullName,
              subtitle: userData.roleName,
              img: userData.imgSource,
              targetTeammateFileId: userData.personalLegajoId,
              ...(!userData.active && { disabled: true, disabledReason: 'UserInactive' }),
            });
          }

          return this.usersServiceBackend.getUsersUsernameUSERNAME(userId).pipe(
            map((user) => ({
              ...channel,
              title: user.fullName,
              subtitle: user.roleName,
              img: this.cdnImagePipe.transform(user.image),
              targetTeammateFileId: user.userTypeId,
            })),
          );
        }

        // Set group subtitle
        return of({ ...channel, subtitle: channel.members.map((member) => this.userData.value.get(member).fullName).join(', ') });
      }),
      toArray(),
      tap(() => {
        this.$_unreadConversations.set(unreadConversations);
      }),
    );
  }

  public loadConversation(chatConversationId: string): Observable<void> {
    const conversation = this.unfilteredConversationList.find((c) => c.id === chatConversationId) ?? null;
    return this.setCurrentConversation(conversation);
  }

  public setCurrentConversation(chatConversation: ChatConversation): Observable<void> {
    if (this.currentConversation.value === chatConversation) {
      return of(undefined);
    }

    this.currentConversation.next(chatConversation);

    const currentConversation = this.currentConversation.value;
    if (currentConversation) {
      if (currentConversation.loadedMessages) {
        if (chatConversation.unreadMsgs) {
          const updatedConversation = { ...currentConversation, unreadMsgs: false };
          this.conversationMessagesRead(updatedConversation);
          this.updateConversation(updatedConversation);
        }
      } else {
        return this.chatBackendService.getMessages(currentConversation).pipe(
          map((res) => {
            const { messages, lastMessageKey } = res;
            const modifiedMessages = messages.map(
              (msg, index): ChatMessage => ({
                ...msg,
                content: msg.type === 'System' ? this.parseSystemMessageContent(msg) : msg.content,
                senderImg: this.getUserImgSource(msg.sender),
                userIcon: index === 0 || msg.sender !== messages[index - 1]?.sender,
                dateSeparator: index === 0 || this.differentRelativeCalendar(msg.creationDate, messages[index - 1].creationDate),
              }),
            );
            const updatedConversation = {
              ...currentConversation,
              messages: modifiedMessages,
              loadedMessages: true,
              lastMessageKey,
              unreadMsgs: false,
            };
            if (chatConversation.unreadMsgs) {
              this.conversationMessagesRead(updatedConversation);
            }

            this.updateConversation(updatedConversation);
            // IF not equal, conversation was changed while loading messages, dont set as current
            if (chatConversation?.id === this.currentConversation.value?.id) {
              this.currentConversation.next(updatedConversation);
            }
          }),
        );
      }
    }

    return of(undefined);
  }

  private updateConversation(updatedConversation: ChatConversation): void {
    this.updateConversationList(
      this.unfilteredConversationList.map((conversation) =>
        conversation.id !== updatedConversation.id ? conversation : updatedConversation,
      ),
    );
  }

  private conversationMessagesRead(updatedConversation: ChatConversation): void {
    this.$_unreadConversations.update((totalUnreadMsgs) => totalUnreadMsgs - 1);
    this.chatBackendService
      .notifyReadMessage(updatedConversation.messages?.at(-1))
      .pipe(catchError(() => EMPTY))
      .subscribe();
  }

  public setConversationFilter(cFilter: string): void {
    this.#filterText$.next(cFilter);
    this.updateConversationList(this.unfilteredConversationList);
  }

  private updateConversationList(newList: ChatConversation[]): void {
    this.unfilteredConversationList = newList;
    this.conversationListUpdateTrigger$.next();
  }

  public loadMessages(): Observable<void> {
    const conversation = this.currentConversation.value;
    if (conversation?.lastMessageKey) {
      return this.chatBackendService.getMessages(conversation).pipe(
        map((res) => {
          const { messages, lastMessageKey } = res;
          const newMessages = messages.map(
            (msg, index): ChatMessage => ({
              ...msg,
              content: msg.type === 'System' ? this.parseSystemMessageContent(msg) : msg.content,
              senderImg: this.getUserImgSource(msg.sender),
              userIcon: index === 0 || msg.sender !== messages[index - 1]?.sender,
              dateSeparator: index === 0 || this.differentRelativeCalendar(msg.creationDate, messages[index - 1].creationDate),
            }),
          );
          // Update first message of old batch, with respect to last message of new batch
          conversation.messages[0].userIcon = conversation.messages[0].sender !== newMessages.at(-1).sender;
          conversation.messages[0].dateSeparator = this.differentRelativeCalendar(
            conversation.messages[0].creationDate,
            newMessages.at(-1).creationDate,
          );
          // Concat new list with old list
          const messagesList = newMessages.concat(conversation.messages);
          const updatedConversation = { ...conversation, messages: messagesList, lastMessageKey };

          this.updateConversation(updatedConversation);
          return updatedConversation;
        }),
        switchMap((updatedConversation) => this.setCurrentConversation(updatedConversation)),
      );
    }
    return EMPTY;
  }

  public sendMessage(content: string): Observable<void> {
    if (content) {
      const currentConversation = this.currentConversation.value;
      const hasMessages = currentConversation.messages?.length > 0;
      const creationDate = DateTime.now();
      let messageSentObs: Observable<void>;

      const newMessage: UserChatMessage = {
        id: crypto.randomUUID(),
        content,
        type: 'User',
        sender: this.userId,
        channelId: currentConversation.id,
        creationDate,
        senderImg: this.getUserImgSource(this.userId),
        userIcon: !hasMessages || currentConversation.messages.at(-1).sender !== this.userId,
        dateSeparator: !hasMessages || this.differentRelativeCalendar(currentConversation.messages.at(-1).creationDate, creationDate),
      };
      const messages = [...currentConversation.messages, newMessage];

      const updatedConversation: ChatConversation = {
        ...currentConversation,
        messages,
        lastMessage: {
          date: newMessage.creationDate,
          sender: this.userId,
          content,
        },
        loadedMessages: true,
      };

      // testear que la interfaz siga utilizable si esto falla. Usar try/catch para subscribe()
      if (updatedConversation.isMock) {
        messageSentObs = this.chatBackendService.insertConversation(updatedConversation).pipe(
          switchMap((conversationId) => {
            // Update the conversation id
            updatedConversation.id = conversationId;
            updatedConversation.isMock = false;
            this.mockConversationsMap.delete(updatedConversation.id);
            // Send message through ws
            return this.chatBackendService.sendMessage(conversationId, content);
          }),
        );
      } else {
        // Send message through ws
        messageSentObs = this.chatBackendService.sendMessage(currentConversation.id, content);
      }
      return messageSentObs.pipe(switchMap(() => this.completeSendMessage(updatedConversation)));
    }
    return EMPTY;
  }

  private completeSendMessage(updatedConversation: ChatConversation): Observable<void> {
    return this.setCurrentConversation(updatedConversation).pipe(
      tap(() => {
        this.updateConversationList([
          this.currentConversation.value,
          ...this.unfilteredConversationList.filter((c) => c.id !== this.currentConversation.value.id),
        ]);
        this.messageSentEvent.next();
      }),
    );
  }

  private onMessageReceived(message: ChatMessage): void {
    const conversation = this.unfilteredConversationList.find((c) => c.id === message.channelId);

    if (!conversation) {
      this.retrieveConversationFromMessage(message).subscribe((retrievedConversation) => {
        if (retrievedConversation) {
          this.handleMessageByType(message, retrievedConversation);
        }
      });
    } else {
      this.handleMessageByType(message, conversation);
    }
  }

  private handleMessageByType(message: ChatMessage, conversation: ChatConversation): void {
    if (message.type === 'System') {
      this.onSystemMessageReceived(message, conversation);
    } else {
      this.pushUserMessageToConversation(message, conversation);
    }
  }

  private retrieveConversationFromMessage(message: ChatMessage): Observable<ChatConversation> {
    return this.chatBackendService.getConversation(message.channelId).pipe(
      switchMap((conversation) => {
        // if the conversation returns as null, a message incoming was from a non existent conversation, throw error
        // this shouldn't happen...
        if (!conversation) {
          console.error('Message received from non existing conversation');
          return of(null);
        }

        if (message.type === 'User') {
          this.$_unreadConversations.update((unreadMsgs) => unreadMsgs + 1);
        }

        if (conversation.isGroup) {
          return of({
            ...conversation,
            subtitle: conversation.members.map((member) => this.$userData().get(member).fullName).join(', '),
          });
        }

        const createdBySelf = message.sender === this.userId;
        const isSelfChat = conversation.members.length === 1;
        const conversationUserId =
          !createdBySelf || isSelfChat ? message.sender : conversation.members.find((memberId) => memberId !== this.userId);

        // Get user data from mock or backend
        const mockData = this.mockConversationsMap.get(conversationUserId);
        if (mockData) {
          const mockId = `${this.mockPrefix}${conversationUserId}`;
          this.mockConversationsMap.delete(conversationUserId);
          this.unfilteredConversationList = this.unfilteredConversationList.filter((channel) => channel.id !== mockId);
          return of({ ...conversation, title: mockData.title, subtitle: mockData.subtitle, img: mockData.img });
        }
        // return data from backend
        return this.usersServiceBackend.getUsersUsernameUSERNAME(conversationUserId).pipe(
          map((user) => ({
            ...conversation,
            title: user.fullName,
            subtitle: user.roleName,
            img: this.cdnImagePipe.transform(user.image),
          })),
        );
      }),
    );
  }
  private onSystemMessageReceived(message: SystemChatMessage, conversation: ChatConversation): void {
    const parsedMessage = { ...message, content: this.parseSystemMessageContent(message) };
    switch (parsedMessage.code) {
      case 'UserReadMessage':
        this.userReadMessage(conversation);
        break;
      case 'GroupCreated': {
        this.pushSystemMessageToConversation(conversation, parsedMessage, true);
        break;
      }
      case 'UserLeftGroup': {
        this.pushSystemMessageToConversation(conversation, parsedMessage);
        break;
      }
      case 'UserAddedToGroup': {
        const updatedConversation = {
          ...conversation,
          members: conversation.members.concat(parsedMessage.metadata.receiver),
          subtitle: conversation.members.map((member) => this.$userData().get(member)?.fullName).join(', '),
        };
        this.pushSystemMessageToConversation(updatedConversation, parsedMessage);
        break;
      }
    }
  }

  private userReadMessage(conversation: ChatConversation): void {
    if (conversation?.unreadMsgs) {
      this.$_unreadConversations.update((value) => value - 1);
      const updatedConversation = { ...conversation, unreadMsgs: false };
      this.updateConversation(updatedConversation);
    }
  }

  private pushUserMessageToConversation(message: ChatMessage, conversation: ChatConversation): void {
    const newMessage: ChatMessage = {
      ...message,
      senderImg: this.getUserImgSource(message.sender),
      userIcon: conversation.messages.length === 0 || conversation.messages.at(-1).sender !== message.sender,
      dateSeparator:
        conversation.messages.length === 0 ||
        this.differentRelativeCalendar(conversation.messages.at(-1).creationDate, message.creationDate),
    };

    const updatedConversation: ChatConversation = {
      ...conversation,
      lastMessage: {
        date: newMessage.creationDate,
        sender: newMessage.sender,
        content: newMessage.content,
      },
      messages: [...conversation.messages, newMessage],
    };

    const isCurrentconversation = this.currentConversation.value?.id === conversation.id;
    const isOwnMessage = message.sender === this.userId;

    // Don't update unreadMsgs if its the current conversation or msg from own user
    if (!isCurrentconversation && !isOwnMessage) {
      if (!updatedConversation.unreadMsgs) {
        this.$_unreadConversations.update((unreadMsgs) => unreadMsgs + 1);
      }
      updatedConversation.unreadMsgs = true;
    }

    if (isCurrentconversation) {
      this.setCurrentConversation(updatedConversation).subscribe();
      if (!isOwnMessage) {
        this.chatBackendService
          .notifyReadMessage(message)
          .pipe(catchError(() => EMPTY))
          .subscribe();
      }
    }

    this.updateConversationList([updatedConversation, ...this.unfilteredConversationList.filter((c) => c.id !== updatedConversation.id)]);
  }

  private pushSystemMessageToConversation(conversation: ChatConversation, message: SystemChatMessage, previewVisible = false): void {
    const ownMessage = message.sender === this.userId;
    const newMessage: ChatMessage = {
      ...message,
      dateSeparator:
        conversation.messages.length === 0 ||
        this.differentRelativeCalendar(conversation.messages.at(-1).creationDate, message.creationDate),
    };

    const updatedConversation: ChatConversation = {
      ...conversation,
      messages: [...conversation.messages, newMessage],
      ...(previewVisible && {
        unreadMsgs: conversation.unreadMsgs || !ownMessage,
        lastMessage: {
          date: message.creationDate,
          sender: message.sender,
          content: message.content,
          isSystem: true,
          systemCode: message.code,
          metadata: message.metadata,
        },
      }),
    };

    if (!conversation.unreadMsgs && updatedConversation.unreadMsgs) {
      this.$_unreadConversations.update((value) => value + 1);
    }

    const isCurrentconversation = this.currentConversation.value?.id === conversation.id;
    if (isCurrentconversation) {
      this.setCurrentConversation(updatedConversation).subscribe(() => {
        this.updateConversationList([
          this.currentConversation.value,
          ...this.unfilteredConversationList.filter((c) => c.id !== this.currentConversation.value.id),
        ]);
      });
    } else {
      this.updateConversationList([updatedConversation, ...this.unfilteredConversationList.filter((c) => c.id !== updatedConversation.id)]);
    }
  }

  public parseSystemMessageContent(message: SystemChatMessage): string {
    const translate = (actorId: string): string =>
      this.translate.instant(SystemMessageMap.get(message.code), {
        name: this.$userData().get(actorId)?.fullName ?? '',
      });

    return ((): string => {
      switch (message.code) {
        case 'UserLeftGroup':
          return translate(message.metadata?.sender);
        case 'UserAddedToGroup':
          return translate(message.metadata?.receiver);
        case 'GroupCreated':
          return translate(message.metadata?.sender);
        default:
          return '';
      }
    })();
  }

  public openTeammateChat = (teammateId: number): void => {
    if (this.headerService.isSmallScreen()) {
      this.loadConversations();
    } else {
      this.openWidgetTrigger.next(true);
    }

    this.conversationListChanged$
      .pipe(
        filter((conversations) => conversations?.length > 0),
        take(1),
        map((conversations) => conversations.find((conversation) => conversation.targetTeammateFileId === teammateId)),
        filter((conversation) => conversation !== undefined),
        switchMap((conversation) =>
          this.headerService.isSmallScreen()
            ? from(this.router.navigate(['/user/chat'], { queryParams: { conversationId: conversation.id } }))
            : this.loadConversation(conversation.id),
        ),
      )
      .subscribe();
  };
}
