<div mat-dialog-content class="text-center mat-modal">
  <div class="icons">
    <div *ngIf="config.type === 'success'" class="swal2-icon swal2-success swal2-animate-success-icon">
      <div class="swal2-success-circular-line-left"></div>
      <span class="swal2-success-line-tip swal2-animate-success-line-tip"></span>
      <span class="swal2-success-line-long swal2-animate-success-line-long"></span>
      <div class="swal2-success-ring"></div>
      <div class="swal2-success-fix"></div>
      <div class="swal2-success-circular-line-right"></div>
    </div>

    <div *ngIf="config.type === 'error'" class="swal2-icon swal2-error swal2-animate-error-icon">
      <span class="swal2-x-mark swal2-animate-x-mark">
        <span class="swal2-x-mark-line-left"></span>
        <span class="swal2-x-mark-line-right"></span>
      </span>
    </div>

    <div *ngIf="config.type === 'warning'"><img [src]="warningImg" /></div>

    <div *ngIf="config.type === 'info'" class="swal2-icon swal2-info">i</div>

    <div *ngIf="config.type === 'question'" class="swal2-icon swal2-question">?</div>
  </div>
  <h4 *ngIf="config.title.visibility">{{ config.title.text }}</h4>
  <p *ngIf="config.message" class="break-line">{{ config.message }}</p>
  <mat-list *ngIf="config.messages?.length">
    <mat-list-item *ngFor="let message of config.messages">{{ message }}</mat-list-item>
  </mat-list>

  <form *ngIf="config.input.visibility" class="w-100">
    <mat-form-field class="w-100">
      <mat-label>{{ config.input.label | translate }}</mat-label>
      <input
        name="inputModal"
        matInput
        id="neoInput"
        #myInput
        [(ngModel)]="config.input.value"
        placeholder="{{ config.input.placeholder }}"
        type="text"
        (keyup.enter)="setFocus()"
        [required]="config.input.required"
      />
    </mat-form-field>
  </form>
</div>
<mat-dialog-actions align="end" #buttons>
  <button
    mat-raised-button
    color="primary"
    id="neoBtnAccept"
    *ngIf="config.button.accept.visibility"
    type="button"
    (click)="informResult('Accept')"
  >
    {{ config.button.accept.text ? config.button.accept.text : ('MODALS.ACCEPT' | translate) }}
  </button>
  <button
    mat-stroked-button
    color="primary"
    id="neoBtnCancel"
    *ngIf="config.button.cancel.visibility"
    type="button"
    (click)="informResult('Cancel')"
  >
    {{ config.button.cancel.text ? config.button.cancel.text : ('MODALS.CANCEL' | translate) }}
  </button>
  <button mat-raised-button color="primary" id="neoBtnYes" *ngIf="config.button.yes.visibility" type="button" (click)="informResult('Yes')">
    {{ 'GENERAL.YES' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    id="neoBtnRetry"
    *ngIf="config.button.retry.visibility"
    type="button"
    (click)="informResult('Retry')"
  >
    {{ 'GENERAL.RETRY' | translate }}
  </button>
  <button mat-raised-button color="primary" id="neoBtnNo" *ngIf="config.button.no.visibility" type="button" (click)="informResult('No')">
    {{ 'GENERAL.NO' | translate }}
  </button>
</mat-dialog-actions>
