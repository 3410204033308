/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { UserBasicDTO } from 'src/app/api/interfaces/user-basic.interface';
import { TeammateRecipientFlatDTO } from 'src/app/api/interfaces/teammate-recipient-flat.interface';

@Injectable({
   providedIn: 'root'
})
export class TeammatesBackendService {

   constructor(protected http: HttpClient) { }


   public getTeammatesIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/teammates/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getTeammatesUsers(): Observable<UserBasicDTO[]> {
      return this.http.get<DataDTO<UserBasicDTO>>(Constants.apiURL + '/teammates/users').pipe(map((res) => convertJsonDates(res.data) as UserBasicDTO[]));
   }

   public getTeammatesRecipients(checkPermissions: boolean): Observable<TeammateRecipientFlatDTO[]> {
      return this.http.get<DataDTO<TeammateRecipientFlatDTO>>(Constants.apiURL + '/teammates/recipients' + '?checkPermissions=' + checkPermissions).pipe(map((res) => convertJsonDates(res.data) as TeammateRecipientFlatDTO[]));
   }
}
