import { toSignal } from '@angular/core/rxjs-interop';
import { ReplaySubject } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { companyFeatureChat, companyFeatureRecruitment } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { IFeatureFlag, IFlagsData } from 'src/app/shared/feature-flags/flag.interface';
import { USER_PERMISSION_FLAGS } from 'src/app/shared/localStorageConstants';

export class FeatureFlagService {
  private initialized = false;

  // use replay so we don't need a default empty set but we allow subscription after initialization
  private flags = new ReplaySubject<IFlagsData>(1);
  private readonly emptyValue = { flags: new Map(), modules: new Map() };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public flags$ = this.flags.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public $flags = toSignal(this.flags.asObservable(), { initialValue: this.emptyValue });

  constructor(
    private initFun: () => Promise<Map<string, IFeatureFlag>>,
    private headerService: HeaderAppService,
  ) {
    this.headerService.loggedOut$.subscribe(() => {
      this.flags.next(this.emptyValue);
    });

    this.headerService.requestLoad$.subscribe(() => {
      this.loadFlags(true);
    });
  }

  public async loadFlags(forceReload = false): Promise<void> {
    if (forceReload || !this.initialized) {
      const modules = this.initModuleRequiredFlags();
      const flags = await this.initFun();
      this.flags.next({ flags, modules });
      const permissions = { flags: JSON.stringify([...flags]), modules: JSON.stringify([...modules]) };
      localStorage.setItem(USER_PERMISSION_FLAGS, JSON.stringify(permissions));
      this.initialized = true;
    }
  }

  public async loadFlagsFromLocalStorage(): Promise<void> {
    const permissionsString = localStorage.getItem(USER_PERMISSION_FLAGS);
    if (!permissionsString) {
      await this.loadFlags(true);
    } else {
      const permissions = JSON.parse(permissionsString);
      const flags = new Map<string, IFeatureFlag>(JSON.parse(permissions.flags));
      const modules = new Map<string, string>(JSON.parse(permissions.modules));

      this.flags.next({ flags, modules });
    }
    this.initialized = true;
  }

  private initModuleRequiredFlags(): Map<string, string> {
    const map = new Map<string, string>();
    map.set('reclutamiento', companyFeatureRecruitment);
    map.set('chat', companyFeatureChat);

    return map;
  }
}
