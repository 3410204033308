/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuthEditUserRequestDTO } from 'src/app/api/interfaces/auth-edit-user-request.interface';
import { AuthNewUserRequestDTO } from 'src/app/api/interfaces/auth-new-user-request.interface';
import { AuthRequestDTO } from 'src/app/api/interfaces/auth-request.interface';
import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { UserBasicDTO } from 'src/app/api/interfaces/user-basic.interface';
import { UserDTO } from 'src/app/api/interfaces/user.interface';
import { UserPasswordInseguroDTO } from 'src/app/api/interfaces/user-password-inseguro.interface';

@Injectable({
   providedIn: 'root'
})
export class UsersBackendService {

   constructor(protected http: HttpClient) { }


   public getUsersIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/users/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getUsers(withoutRole: boolean, withRoleUsers: boolean = false): Observable<UserDTO[]> {
      return this.http.get<DataDTO<UserDTO>>(Constants.apiURL + '/users/' + '?withoutRole=' + withoutRole +
            '&withRoleUsers=' + withRoleUsers).pipe(map((res) => convertJsonDates(res.data) as UserDTO[]));
   }

   public getUsersAutoComplete(contiene: string, pageSize: number): Observable<UserBasicDTO[]> {
      return this.http.get<DataDTO<UserBasicDTO>>(Constants.apiURL + '/users/autoComplete' + '?contiene=' + contiene +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as UserBasicDTO[]));
   }

   public getUsersId(id: number): Observable<UserDTO> {
      return this.http.get<UserDTO>(Constants.apiURL + '/users/' + id).pipe(map((res) => convertJsonDates(res) as UserDTO ));
   }

   public getUsersUsernameUSERNAME(username: string): Observable<UserDTO> {
      return this.http.get<UserDTO>(Constants.apiURL + '/users/username/' + username).pipe(map((res) => convertJsonDates(res) as UserDTO ));
   }

   public getUsersSystemUSERNAME(username: string): Observable<UserDTO> {
      return this.http.get<UserDTO>(Constants.apiURL + '/users/system/' + username).pipe(map((res) => convertJsonDates(res) as UserDTO ));
   }

   public getUsersBasic(onlyAdministratives: boolean = false): Observable<UserBasicDTO[]> {
      return this.http.get<DataDTO<UserBasicDTO>>(Constants.apiURL + '/users/basic' + '?onlyAdministratives=' + onlyAdministratives).pipe(map((res) => convertJsonDates(res.data) as UserBasicDTO[]));
   }

   public getUsersIdPasswordInseguro(id: number): Observable<UserPasswordInseguroDTO> {
      return this.http.get<UserPasswordInseguroDTO>(Constants.apiURL + '/users/' + id + '/passwordInseguro').pipe(map((res) => convertJsonDates(res) as UserPasswordInseguroDTO ));
   }

   public getUsersPasswordInseguro(): Observable<UserPasswordInseguroDTO[]> {
      return this.http.get<DataDTO<UserPasswordInseguroDTO>>(Constants.apiURL + '/users/passwordInseguro').pipe(map((res) => convertJsonDates(res.data) as UserPasswordInseguroDTO[]));
   }

   public insertUsers(authNewUserRequestDTO: AuthNewUserRequestDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/users/', authNewUserRequestDTO);
   }
   public updateUsersId(id: number, authEditUserRequestDTO: AuthEditUserRequestDTO): Observable<UserDTO> {
      return this.http.put<UserDTO>(Constants.apiURL + '/users/' + id, authEditUserRequestDTO).pipe(map((res) => convertJsonDates(res) as UserDTO ));
   }

   public deleteUsersId(id: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/users/' + id);
   }
   public deleteUsersUSERNAMEPassword(username: string): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/users/' + username + '/password');
   }
   public insertUsersValidate(authRequestDTO: AuthRequestDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/users/validate', authRequestDTO);
   }
   public insertUsersIdInvalidateTokenAuth(id: number): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/users/' + id + '/invalidateTokenAuth', null);
   }
   public insertUsersGenerateOwnCodes(): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/users/generateOwnCodes', null);
   }}
