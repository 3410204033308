export enum UserLanguage {
   Default = 0,
   es = 1,
   en = 2,
   es_ar = 3,
   es_es = 4,
   es_uy = 5,
   es_cl = 6,
   es_ve = 7,
   es_co = 8,
   es_mx = 9
 }
