import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';

export class ReclutamientoCandidatoRecomendadoDTO implements IEntityDTO {

   fechaCarga: Date;
   actualizado: Date;
   nombre = '';
   apellido = '';
   image = '';
   email = '';
   linkPublico = '';
   recomendado = '';
   candidato = 0;
   recomendadoPorMi: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fechaCarga != null) { this.fechaCarga = new Date(jsonObj.fechaCarga); }
      if (jsonObj.actualizado != null) { this.actualizado = new Date(jsonObj.actualizado); }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.apellido != null) { this.apellido = jsonObj.apellido; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.email != null) { this.email = jsonObj.email; }
      if (jsonObj.linkPublico != null) { this.linkPublico = jsonObj.linkPublico; }
      if (jsonObj.recomendado != null) { this.recomendado = jsonObj.recomendado; }
      if (jsonObj.candidato != null) { this.candidato = jsonObj.candidato; }
      if (jsonObj.recomendadoPorMi != null) { this.recomendadoPorMi = jsonObj.recomendadoPorMi; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
