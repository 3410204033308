import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { ConfettiAnimationDTO } from '@api/interfaces/confetti-animation.interface';


export class EventoDTO implements IEntityDTO {

   image = '';
   titulo = '';
   descripcion = '';
   fechaInicio: Date;
   fechaFin: Date;
   adjuntos: Array<AttachmentUaalooDTO>;
   comentarios: Array<ComentarioDTO>;
   cantidadComentarios = 0;
   cantidadAdjuntos = 0;
   reactions = '';
   todoElDia: boolean;
   ubicacion = '';
   notificar: boolean;
   personal: PersonalLegajoBasicoDTO;
   animation?: ConfettiAnimationDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.adjuntos = new Array<AttachmentUaalooDTO>();
      this.comentarios = new Array<ComentarioDTO>();
      this.personal = new PersonalLegajoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.titulo != null) { this.titulo = jsonObj.titulo; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.fechaInicio != null) { this.fechaInicio = new Date(jsonObj.fechaInicio); }
      if (jsonObj.fechaFin != null) { this.fechaFin = new Date(jsonObj.fechaFin); }
      if (jsonObj.adjuntos != null) { for (const item of jsonObj.adjuntos) { const itemDTO = new AttachmentUaalooDTO(); itemDTO.PrepareDTO(item); this.adjuntos.push(itemDTO); } }
      if (jsonObj.comentarios != null) { for (const item of jsonObj.comentarios) { const itemDTO = new ComentarioDTO(); itemDTO.PrepareDTO(item); this.comentarios.push(itemDTO); } }
      if (jsonObj.cantidadComentarios != null) { this.cantidadComentarios = jsonObj.cantidadComentarios; }
      if (jsonObj.cantidadAdjuntos != null) { this.cantidadAdjuntos = jsonObj.cantidadAdjuntos; }
      if (jsonObj.reactions != null) { this.reactions = jsonObj.reactions; }
      if (jsonObj.todoElDia != null) { this.todoElDia = jsonObj.todoElDia; }
      if (jsonObj.ubicacion != null) { this.ubicacion = jsonObj.ubicacion; }
      if (jsonObj.notificar != null) { this.notificar = jsonObj.notificar; }
      if (jsonObj.personal != null) { this.personal.PrepareDTO(jsonObj.personal); }
      if (jsonObj.animation != null) { this.animation = jsonObj.animation; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
