import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { PersonalAusenciaDTO } from 'src/app/ModelDTO/DTO/personalAusencia.DTO';
import { EstadoAprobacionSolicitud } from 'src/app/ModelDTO/DTO/estadoAprobacionSolicitud.ENUM';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';
import { AusenciaModelDTO } from 'src/app/ModelDTO/ausencia.ModelDTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { AttachmentUaalooModelDTO } from 'src/app/ModelDTO/attachmentUaaloo.ModelDTO';


export class PersonalAusenciaModelDTO extends EntityModelDTO<PersonalAusenciaDTO> {

   private creadorModel: PersonalLegajoBasicoModelDTO;
   private creadorSubscribe: Subscription;
   private personalLegajoModel: PersonalLegajoBasicoModelDTO;
   private personalLegajoSubscribe: Subscription;
   private ausenciaModel: AusenciaModelDTO;
   private ausenciaSubscribe: Subscription;

   public constructor(protected entityDTO: PersonalAusenciaDTO) {
      super(entityDTO);
   }

   public static getEstadoAprobacionSolicitud(): string[] {
      return EntityModelDTO.getEnumArray(EstadoAprobacionSolicitud);
   }

    protected getStringFromEstadoAprobacionSolicitud(enums: EstadoAprobacionSolicitud): Array<string> {
        if (enums) {
            const arrays = new Array<string>();
            for (let i = 0; i <= 31; i = i++) {
                const pow = Math.pow(2, i);
                if ((enums & pow) !== 0) {
                    arrays.push(EstadoAprobacionSolicitud[pow]);
                }
            }
            return arrays;
        } else {
            return undefined;
        }
    }

    protected getFlagFromEstadoAprobacionSolicitudString(strings: Array<string>): EstadoAprobacionSolicitud {
        let flags: EstadoAprobacionSolicitud;
        strings.forEach(element => {
            const enumVal: EstadoAprobacionSolicitud = EstadoAprobacionSolicitud[element];
            flags |= enumVal;
        });
        return flags;
    }

   public setEntityDTO(entityDTO: PersonalAusenciaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.creadorModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.creador);
      this.creadorSubscribe = this.creadorModel.changed.subscribe((changed) => this.changed.next(changed));
      this.personalLegajoModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.personalLegajo);
      this.personalLegajoSubscribe = this.personalLegajoModel.changed.subscribe((changed) => this.changed.next(changed));
      this.ausenciaModel = new AusenciaModelDTO(this.entityDTO.ausencia);
      this.ausenciaSubscribe = this.ausenciaModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.creadorModel.dispose();
      this.creadorSubscribe.unsubscribe();
      this.personalLegajoModel.dispose();
      this.personalLegajoSubscribe.unsubscribe();
      this.ausenciaModel.dispose();
      this.ausenciaSubscribe.unsubscribe();
   }

   get Estado(): string { return EstadoAprobacionSolicitud[this.entityDTO.estado]; }
   set Estado(value: string) { this.notifyChangeDTO('estado', EstadoAprobacionSolicitud[value]); }

   get EstadoArray(): Array<string> { return this.getStringFromEstadoAprobacionSolicitud(this.entityDTO.estado); }
   set EstadoArray(value: Array<string>) { this.notifyChangeDTO('estado', this.getFlagFromEstadoAprobacionSolicitudString(value)); }

   get CreadorModel(): PersonalLegajoBasicoModelDTO { return this.creadorModel; }
   get Creador(): PersonalLegajoBasicoDTO { return this.creadorModel.getEntityDTO(); }
   set Creador(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.creador = value; this.creadorModel.setEntityDTO(value); }); }

   get PersonalLegajoModel(): PersonalLegajoBasicoModelDTO { return this.personalLegajoModel; }
   get PersonalLegajo(): PersonalLegajoBasicoDTO { return this.personalLegajoModel.getEntityDTO(); }
   set PersonalLegajo(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.personalLegajo = value; this.personalLegajoModel.setEntityDTO(value); }); }

   get FechaDesde(): Date { return this.entityDTO.fechaDesde; }
   set FechaDesde(value: Date) { this.notifyChangeDTO('fechaDesde', value); }

   get FechaHasta(): Date { return this.entityDTO.fechaHasta; }
   set FechaHasta(value: Date) { this.notifyChangeDTO('fechaHasta', value); }

   get DateTimeFrom(): Date { return this.entityDTO.dateTimeFrom; }
   set DateTimeFrom(value: Date) { this.notifyChangeDTO('dateTimeFrom', value); }

   get DateTimeTo(): Date { return this.entityDTO.dateTimeTo; }
   set DateTimeTo(value: Date) { this.notifyChangeDTO('dateTimeTo', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get AusenciaModel(): AusenciaModelDTO { return this.ausenciaModel; }
   get Ausencia(): AusenciaDTO { return this.ausenciaModel.getEntityDTO(); }
   set Ausencia(value: AusenciaDTO) { this.notifyChange(() => { this.entityDTO.ausencia = value; this.ausenciaModel.setEntityDTO(value); }); }

   get ConSolicitud(): boolean { return this.entityDTO.conSolicitud; }
   set ConSolicitud(value: boolean) { this.notifyChangeDTO('conSolicitud', value); }

   get Adjuntos(): Array<AttachmentUaalooDTO> { return this.entityDTO.adjuntos; }
   set Adjuntos(value: Array<AttachmentUaalooDTO>) { this.notifyChangeDTO('adjuntos', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
