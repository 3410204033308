import Quill from 'quill';

const Clipboard = Quill.import('modules/clipboard') as any;
const Delta = Quill.import('delta');

class PlainClipboard extends Clipboard {
  public onPaste(range: { index: number; length: number }, { text }): void {
    const delta = new Delta().retain(range.index).delete(range.length).insert(text);
    this.quill.updateContents(delta, Quill.sources.USER);
    this.quill.setSelection(delta.length() - range.length, Quill.sources.SILENT);
    this.quill.scrollIntoView();
  }
}

export default PlainClipboard;
