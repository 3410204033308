import { Injectable } from '@angular/core';
import { EstadoAprobacionSolicitud } from '@api/enums/estado-aprobacion-solicitud.enum';
import { PersonalAusenciaDTO } from 'src/app/ModelDTO/DTO/personalAusencia.DTO';
import { SolicitudAusenciaDTO } from 'src/app/ModelDTO/DTO/solicitudAusencia.DTO';
import { SolicitudAusenciaModelDTO } from 'src/app/ModelDTO/solicitudAusencia.ModelDTO';
import { PersonalAusenciaServiceBackend } from 'src/app/ServiceBackend/personalAusencia.ServiceBackend';
import { SolicitudAusenciaServiceBackend } from 'src/app/ServiceBackend/solicitudAusencia.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class UserAusenciasService {
  public get aEditar(): SolicitudAusenciaModelDTO {
    return this.solicitudAEditar;
  }
  public set aEditar(solicitud: SolicitudAusenciaModelDTO) {
    this.solicitudAEditar = solicitud;
  }
  private solicitudAEditar: SolicitudAusenciaModelDTO;

  constructor(
    private solicitudAusenciaBackend: SolicitudAusenciaServiceBackend,
    private ausenciasBackend: PersonalAusenciaServiceBackend,
  ) {}

  public async modificarSolicitudDeAusencia(solicitud: SolicitudAusenciaModelDTO, formData: FormData): Promise<SolicitudAusenciaDTO> {
    const res = await this.solicitudAusenciaBackend.updateSolicitudAusenciaId(solicitud.Id, formData);
    return res;
  }
  public async crearSolicitudDeAusencia(formData: FormData): Promise<SolicitudAusenciaDTO> {
    const res = await this.solicitudAusenciaBackend.insertSolicitudAusencia(formData);
    return res;
  }

  public async obtenerAusenciasPropias(
    desde: Date,
    hasta: Date,
    estado?: EstadoAprobacionSolicitud,
    sinRechazos = false,
  ): Promise<PersonalAusenciaDTO[]> {
    const res = await this.ausenciasBackend.getAusenciasPropias(desde, hasta, estado, sinRechazos);
    return res;
  }

  public async obtenerAusenciasFiltradas(
    desde: Date,
    hasta: Date,
    estado: EstadoAprobacionSolicitud,
    idPersonal: number,
    incluirBajas?: boolean,
    sinRechazos = false,
  ): Promise<PersonalAusenciaDTO[]> {
    const res = await this.ausenciasBackend.getAusenciasPersonalFiltradas(
      desde,
      hasta,
      estado,
      idPersonal,
      sinRechazos,
      false,
      incluirBajas,
    );
    return res.sort((x, y) => y.id - x.id);
  }

  public async getAusenciaPorId(ausencia: PersonalAusenciaDTO): Promise<PersonalAusenciaDTO> {
    const res = await this.ausenciasBackend.getAusenciasPersonalAusenciaId(ausencia.id);
    return res;
  }

  public async obtenerSolicitudPorId(id: number): Promise<SolicitudAusenciaModelDTO> {
    const res = await this.solicitudAusenciaBackend.getSolicitudAusenciaPorSolicitudId(id);
    return new SolicitudAusenciaModelDTO(res);
  }

  public async eliminarSolicitudPorId(id: number): Promise<void> {
    await this.solicitudAusenciaBackend.deleteSolicitudAusenciaId(id);
  }

  public async obtenerSolicitudDeAusencia(idAusencia: number): Promise<SolicitudAusenciaModelDTO> {
    const res = await this.solicitudAusenciaBackend.getSolicitudAusenciaIdPersonalAusencia(idAusencia);
    return new SolicitudAusenciaModelDTO(res);
  }

  public async eliminarAusenciaPersonal(personalAusenciaDTO: PersonalAusenciaDTO): Promise<void> {
    await this.ausenciasBackend.deleteAusenciasIdPersonalIDPersonal(personalAusenciaDTO.id, personalAusenciaDTO.personalLegajo.id);
  }
}
