import { Injectable } from '@angular/core';
import { ConvenioColectivoDTO as ConvenioColectivoDTOInterface } from '@api/interfaces/convenio-colectivo.interface';
import { TranslateService } from '@ngx-translate/core';
import { PerfilEmpresaService } from 'src/app/layout/administrador/configuracion/perfil-empresa/perfil-empresa.service';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';
import { ResultadoImportacionDTO } from 'src/app/ModelDTO/DTO/resultadoImportacion.DTO';
import { ConvenioColectivoServiceBackend } from 'src/app/ServiceBackend/convenioColectivo.ServiceBackend';
import { ExportacionConvenioServiceBackend } from 'src/app/ServiceBackend/exportacionConvenio.ServiceBackend';
import { ImportacionConvenioServiceBackend } from 'src/app/ServiceBackend/importacionConvenio.ServiceBackend';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { FileDownloaderService } from 'src/app/shared/services/fileDownloader.service';

enum NacionalityEnum {
  Argentina = 1,
}

@Injectable({
  providedIn: 'root',
})
export class ConvenioColectivoService {
  public get withoutAgreement(): string {
    if (this.perfilEmpresaService.company.pais.id === NacionalityEnum.Argentina) {
      return `${this.translateService.instant('COLLECTIVE_AGREEMENTS.NOT_ASSIGNED')} (${this.translateService.instant('COLLECTIVE_AGREEMENTS.EMPLOYMENT_CONTRACT_LAW')})`;
    }

    return this.translateService.instant('EMPLOYEES.NOT_ASSIGNED');
  }

  private readonly FUERA_DE_CONVENIO = 'Sin asignar (LCT)';

  constructor(
    private conveniosServiceBackend: ConvenioColectivoServiceBackend,
    private importadorConvenioServiceBack: ImportacionConvenioServiceBackend,
    private exportadorConvenioServiceBack: ExportacionConvenioServiceBackend,
    private fileDownloaderService: FileDownloaderService,
    private perfilEmpresaService: PerfilEmpresaService,
    private translateService: TranslateService,
  ) {}

  public async obtenerConvenios(): Promise<Array<ConvenioColectivoDTO>> {
    const res = await this.conveniosServiceBackend.getConvenioColectivo();
    const sinConvenioDTO = new ConvenioColectivoDTO();
    sinConvenioDTO.id = 0;
    sinConvenioDTO.nombreCompleto = this.withoutAgreement;
    res.unshift(sinConvenioDTO);
    return res;
  }

  public async agregarConvenio(conv: ConvenioColectivoDTO): Promise<ConvenioColectivoDTO> {
    const res = await this.conveniosServiceBackend.insertConvenioColectivo(conv);
    return res;
  }

  public async editarConvenio(conv: ConvenioColectivoDTO): Promise<ConvenioColectivoDTO> {
    const res = await this.conveniosServiceBackend.updateConvenioColectivoId(conv.id, conv);
    return res;
  }

  public async eliminarConvenio(conv: ConvenioColectivoDTO): Promise<void> {
    await this.conveniosServiceBackend.deleteConvenioColectivoId(conv.id);
  }

  public async importarConvenio(formData: FormData): Promise<ResultadoImportacionDTO> {
    const res = await this.importadorConvenioServiceBack.insertImportacionesConvenios(formData);
    return res;
  }

  public async exportarConvenio(): Promise<void> {
    const blob = new NamedBlobDTO();
    await this.exportadorConvenioServiceBack.getExportacionesConvenios(blob);
    this.fileDownloaderService.saveAs(blob);
  }

  public nombreConvenio(convenio: ConvenioColectivoDTO | ConvenioColectivoDTOInterface): string {
    return convenio?.id > 0 ? convenio.nombreCompleto : this.withoutAgreement;
  }
}
