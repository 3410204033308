import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { UserLanguage } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/userLanguage.ENUM';


export class PaisDTO implements IEntityDTO {

   nombre = '';
   nacionalidad = '';
   iso = '';
   officialLanguage: UserLanguage;
   maskTaxId? : string;
   validatorPatternTaxId? : string;
   validateTaxId: boolean;
   emploeeTaxIdRequired: boolean;
   maskId? : string;
   validatorPatternId? : string;
   validateId: boolean;
   passportMaskId? : string;
   validatorPatternPassportId? : string;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.nacionalidad != null) { this.nacionalidad = jsonObj.nacionalidad; }
      if (jsonObj.iso != null) { this.iso = jsonObj.iso; }
      if (jsonObj.officialLanguage != null) { this.officialLanguage = jsonObj.officialLanguage as UserLanguage; }
      if (jsonObj.maskTaxId != null) { this.maskTaxId = jsonObj.maskTaxId; }
      if (jsonObj.validatorPatternTaxId != null) { this.validatorPatternTaxId = jsonObj.validatorPatternTaxId; }
      if (jsonObj.validateTaxId != null) { this.validateTaxId = jsonObj.validateTaxId; }
      if (jsonObj.emploeeTaxIdRequired != null) { this.emploeeTaxIdRequired = jsonObj.emploeeTaxIdRequired; }
      if (jsonObj.maskId != null) { this.maskId = jsonObj.maskId; }
      if (jsonObj.validatorPatternId != null) { this.validatorPatternId = jsonObj.validatorPatternId; }
      if (jsonObj.validateId != null) { this.validateId = jsonObj.validateId; }
      if (jsonObj.passportMaskId != null) { this.passportMaskId = jsonObj.passportMaskId; }
      if (jsonObj.validatorPatternPassportId != null) { this.validatorPatternPassportId = jsonObj.validatorPatternPassportId; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
