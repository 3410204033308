import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { DomicilioDTO } from 'src/app/ModelDTO/DTO/domicilio.DTO';


export class OficinaDTO implements IEntityDTO {

   nombre = '';
   descripcion = '';
   identificadorFiscal = '';
   telefonos = '';
   domicilio: DomicilioDTO;
   latitud: number;
   longitud: number;
   rango: number;
   coordenadaExtremoIzquierdoSuperior = '';
   coordenadaExtremoDerechoInferior = '';
   cantidadColaboradores = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.domicilio = new DomicilioDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.identificadorFiscal != null) { this.identificadorFiscal = jsonObj.identificadorFiscal; }
      if (jsonObj.telefonos != null) { this.telefonos = jsonObj.telefonos; }
      if (jsonObj.domicilio != null) { this.domicilio.PrepareDTO(jsonObj.domicilio); }
      if (jsonObj.latitud != null) { this.latitud = jsonObj.latitud; }
      if (jsonObj.longitud != null) { this.longitud = jsonObj.longitud; }
      if (jsonObj.rango != null) { this.rango = jsonObj.rango; }
      if (jsonObj.coordenadaExtremoIzquierdoSuperior != null) { this.coordenadaExtremoIzquierdoSuperior = jsonObj.coordenadaExtremoIzquierdoSuperior; }
      if (jsonObj.coordenadaExtremoDerechoInferior != null) { this.coordenadaExtremoDerechoInferior = jsonObj.coordenadaExtremoDerechoInferior; }
      if (jsonObj.cantidadColaboradores != null) { this.cantidadColaboradores = jsonObj.cantidadColaboradores; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
