import { Injectable } from '@angular/core';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { SancionDTO } from 'src/app/ModelDTO/DTO/sancion.DTO';
import { PersonalSancionDTO } from 'src/app/ModelDTO/DTO/personalSancion.DTO';
import { TipoSancionDTO } from 'src/app/ModelDTO/DTO/tipoSancion.DTO';
import { SancionServiceBackend } from 'src/app/ServiceBackend/sancion.ServiceBackend';
import { PersonalSancionModel } from 'src/app/layout/administrador/sanciones/personalSancion.model';

@Injectable({
  providedIn: 'root',
})
export class SancionesService {
  private indiceSeleccionado: number;

  private personalSancionSeleccionado: PersonalLegajoBasicoDTO;
  private personalAlta: PersonalDTO;

  constructor(private sancionServiceBackend: SancionServiceBackend) {}

  public async obtenerPersonalSancionado(pageNumber: number, pageSize: number): Promise<Array<PersonalSancionModel>> {
    const arrayPersonalModel = new Array<PersonalSancionModel>();
    const res = await this.sancionServiceBackend.getComplianceSancionSancionados(pageNumber, pageSize);
    res.forEach((person) => {
      const personalModel = new PersonalSancionModel(person);
      arrayPersonalModel.push(personalModel);
    });
    return arrayPersonalModel;
  }

  public async obtenerSanciones(idPersonalLegajo: number, todas: boolean, fechaDesde: Date, fechaHasta: Date): Promise<Array<SancionDTO>> {
    const res = await this.sancionServiceBackend.getComplianceSancionId(idPersonalLegajo, fechaDesde, fechaHasta, todas);
    return res;
  }

  public async agregarSancion(idPersonalLegajo: number, formSancion: FormData): Promise<SancionDTO> {
    const res = await this.sancionServiceBackend.insertComplianceSancionId(idPersonalLegajo, formSancion);
    return res;
  }

  public async eliminarSancion(idPersonalLegajo: number, sancion: SancionDTO): Promise<void> {
    await this.sancionServiceBackend.deleteComplianceSancionIdIDSANCION(idPersonalLegajo, sancion.id);
  }

  public async obtenerSancion(idPersonalLegajo: number, idSancion: number): Promise<PersonalSancionDTO> {
    const res = await this.sancionServiceBackend.getComplianceSancionIdIDSANCION(idPersonalLegajo, idSancion);
    return res;
  }

  public async obtenerTiposDeSancion(): Promise<Array<TipoSancionDTO>> {
    const res = await this.sancionServiceBackend.getComplianceSancionTipoSancion();
    return res;
  }

  public async insertarTipoSancion(tipo: TipoSancionDTO): Promise<TipoSancionDTO> {
    const res = await this.sancionServiceBackend.insertComplianceSancionTipoSancion(tipo);
    return res;
  }

  public async editarTipoSancion(tipo: TipoSancionDTO): Promise<TipoSancionDTO> {
    const res = await this.sancionServiceBackend.updateComplianceSancionTipoSancionIDTIPOSANCION(tipo.id, tipo);
    return res;
  }

  public async eliminarTipoSancion(tipo: TipoSancionDTO): Promise<void> {
    await this.sancionServiceBackend.deleteComplianceSancionTipoSancionIDTIPOSANCION(tipo.id);
  }

  get IndiceSeleccionado(): number {
    return this.indiceSeleccionado;
  }
  set IndiceSeleccionado(value: number) {
    this.indiceSeleccionado = value;
  }

  get PersonalSeleccionado(): PersonalLegajoBasicoDTO {
    return this.personalSancionSeleccionado;
  }
  set PersonalSeleccionado(value: PersonalLegajoBasicoDTO) {
    this.personalSancionSeleccionado = value;
  }

  get PersonalAlta(): PersonalDTO {
    return this.personalAlta;
  }
  set PersonalAlta(p: PersonalDTO) {
    this.personalAlta = p;
  }

  public dispose(): void {
    this.indiceSeleccionado = null;
  }
}
