import { CommonModule } from '@angular/common';
import { Component, computed, Inject, Signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { PersonalDTO } from '@api/interfaces/personal.interface';
import { TranslateModule } from '@ngx-translate/core';
import { ChatService } from 'src/app/app-common/chat/chat.service';
import { PersonalDTO as PersonalDTOClass } from 'src/app/ModelDTO/DTO/personal.DTO';
import { CircleImageComponent } from 'src/app/shared/circle-image/circle-image.component';
import {
  companyFeatureChat,
  featureCanAccessTeammateIdentifier,
  featureColaboradorDatosContacto,
} from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { NgxDatePipe } from 'src/app/shared/lib/ngx-neo-pipes/ngx-date.pipe';
import { ProfileAccessService } from 'src/app/shared/services/profile-access.service';
import { classToJson } from 'src/app/shared/shared-functions';

@Component({
  selector: 'app-detalle-persona-contacto-modal',
  templateUrl: './detalle-persona-contacto-modal.component.html',
  standalone: true,
  providers: [ProfileAccessService],
  imports: [CommonModule, MatDialogModule, MatButtonModule, CircleImageComponent, TranslateModule, NgxDatePipe],
})
export class DetallePersonaContactoModalComponent {
  public $verDatosContacto: Signal<boolean>;
  public readonly $canSeeCompleteProfile = this.profileAccessService.$canSeeCompleteProfile;
  public $canAccessIdentifier: Signal<boolean>;
  public $canSendMessage: Signal<boolean>;

  constructor(
    private dialogRef: MatDialogRef<DetallePersonaContactoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public personal: PersonalDTOClass,
    private featureFlagService: FeatureFlagService,
    private profileAccessService: ProfileAccessService,
    private chatService: ChatService,
  ) {
    const { $flags } = this.featureFlagService;
    this.$verDatosContacto = computed(() => FeatureFlagHelper.featureOn(featureColaboradorDatosContacto, $flags().flags));
    this.$canAccessIdentifier = computed(() => FeatureFlagHelper.featureOn(featureCanAccessTeammateIdentifier, $flags().flags));
    this.$canSendMessage = computed(() => FeatureFlagHelper.featureOn(companyFeatureChat, $flags().flags));

    const teammate = classToJson<PersonalDTOClass, PersonalDTO>(this.personal);
    this.profileAccessService.$teammate.set(teammate);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public openProfile(): void {
    this.profileAccessService.openProfile(this.personal.id);
  }

  public openChat(): void {
    this.chatService.openTeammateChat(this.personal.legajo.id);
    this.dialogRef.close();
  }
}
