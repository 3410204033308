import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { ReclutamientoCampo } from 'src/app/ModelDTO/DTO/reclutamientoCampo.ENUM';


export class ReclutamientoOfertaCampoDTO implements IEntityDTO {

   campo: ReclutamientoCampo;
   obligatorio: boolean;
   nombre = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.campo != null) { this.campo = jsonObj.campo as ReclutamientoCampo; }
      if (jsonObj.obligatorio != null) { this.obligatorio = jsonObj.obligatorio; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
