import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { lastValueFrom, Subject } from 'rxjs';
import { CategoriaCantidadDTO } from 'src/app/ModelDTO/DTO/categoriaCantidad.DTO';
import { DistribucionMasivaDTO } from 'src/app/ModelDTO/DTO/distribucionMasiva.DTO';
import { DocumentacionConAnalisisDTO } from 'src/app/ModelDTO/DTO/documentacionConAnalisis.DTO';
import { InformacionDTO } from 'src/app/ModelDTO/DTO/informacion.DTO';
import { InformacionesConCantidadDTO } from 'src/app/ModelDTO/DTO/informacionesConCantidad.DTO';
import { InformacionMasivaDTO } from 'src/app/ModelDTO/DTO/informacionMasiva.DTO';
import { Constants } from '../shared/constants';
import { AuthenticationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/auth/authentication.service';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { HttpClientExtended, StreamingResultDto } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/http/httpClient.extended';
import { CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/cordova.service';

@Injectable({
   providedIn: 'root'
})
export class InformacionServiceBackend {

   constructor(
    protected http: HttpClient,
    protected a: AuthenticationService,
    protected cookieService: CookieService,
    private cordovaService: CordovaService
    ) { }

   public async getBibliotecaIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/biblioteca/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getBiblioteca(pageNumber: number, pageSize: number, fechaDesde: Date, fechaHasta: Date, period: Date, idCategoria: number, idPersonal: number = 0,
      sortActive: number = 0, isAsc: boolean = false, idModalidad: number = 0, searchValue?:string, officeId: number = 0, businessNameId: number = 0): Promise<Array<InformacionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/biblioteca/' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&fechaDesde=' + fechaDesde?.toISOString() +
            '&fechaHasta=' + fechaHasta?.toISOString() +
            '&period=' + period?.toISOString() +
            '&idCategoria=' + idCategoria +
            '&idPersonal=' + idPersonal +
            '&idModalidad=' + idModalidad +
            '&officeId=' + officeId +
            '&businessNameId=' + businessNameId +
            '&searchValue=' + searchValue +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc));
         const resJson = res.data;
         const resDTO = new Array<InformacionDTO>();
         for (const item of resJson) {
            const itemDTO = new InformacionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getBibliotecaId(id: number): Promise<InformacionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/biblioteca/' + id));
         if (!res) { return null; }
         const resDTO = new InformacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getBibliotecaInformacionMasiva(fechaDesde: Date, fechaHasta: Date): Promise<Array<InformacionMasivaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/biblioteca/informacionMasiva' + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString()));
         const resJson = res.data;
         const resDTO = new Array<InformacionMasivaDTO>();
         for (const item of resJson) {
            const itemDTO = new InformacionMasivaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getBibliotecaInformacionMasivaId(id: number): Promise<InformacionMasivaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/biblioteca/informacionMasiva/' + id));
         if (!res) { return null; }
         const resDTO = new InformacionMasivaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getBibliotecaCategoriasCantidad(visualizacion: number, fechaDesde: Date, fechaHasta: Date, idPersonal: number = 0): Promise<Array<CategoriaCantidadDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/biblioteca/categorias/cantidad' + '?visualizacion=' + visualizacion + '&fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&idPersonal=' + idPersonal));
         const resJson = res.data;
         const resDTO = new Array<CategoriaCantidadDTO>();
         for (const item of resJson) {
            const itemDTO = new CategoriaCantidadDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getBibliotecaPublicosUltimosCANTIDAD(cantidad: number): Promise<InformacionesConCantidadDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/biblioteca/publicos/ultimos/' + cantidad));
         if (!res) { return null; }
         const resDTO = new InformacionesConCantidadDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getBibliotecaEmpresa(pageNumber: number, pageSize: number, fechaDesde: Date, fechaHasta: Date, period: Date, idCategoria: number,
    sortActive: number = 0, isAsc: boolean = false, searchValue?:string): Promise<Array<InformacionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/biblioteca/empresa' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize +
          '&fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&period=' + period?.toISOString() +
          '&idCategoria=' + idCategoria + '&sortActive=' + sortActive + '&isAsc=' + isAsc + '&searchValue=' + searchValue ?? ''));
         const resJson = res.data;
         const resDTO = new Array<InformacionDTO>();
         for (const item of resJson) {
            const itemDTO = new InformacionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getBibliotecaProcedimientos(pageNumber: number, pageSize: number, fechaDesde: Date, fechaHasta: Date, period: Date, idCategoria: number,
     sortActive: number = 0, isAsc: boolean = false, searchValue?:string): Promise<Array<InformacionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/biblioteca/procedimientos' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize +
         '&fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&period=' + period?.toISOString() + '&idCategoria=' + idCategoria +
          '&sortActive=' + sortActive + '&isAsc=' + isAsc + '&searchValue=' + searchValue ?? ''));
         const resJson = res.data;
         const resDTO = new Array<InformacionDTO>();
         for (const item of resJson) {
            const itemDTO = new InformacionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertBibliotecaDocumentacion(stream: FormData): Promise<InformacionDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/biblioteca/documentacion', stream));
         const resDTO = new InformacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertBibliotecaProcedimiento(stream: FormData): Promise<InformacionDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/biblioteca/procedimiento', stream));
         const resDTO = new InformacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteBibliotecaId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/biblioteca/' + id));

   }

   public async deleteBibliotecaIdFilesATTACHMENTID(id: number, attachmentId: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/biblioteca/' + id + '/files/' + attachmentId));

   }

   public async updateBibliotecaId(id: number, stream: FormData): Promise<InformacionDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/biblioteca/' + id, stream));
         const resDTO = new InformacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public getBibliotecaDescargar(period: Date, idCategoria: number = 0, idPersonal: number = 0, idModalidad: number = 0, searchValue: string = null, officeId: number = 0, businessNameId: number = 0): void {

      const cookieOptions = {
         expires: 1,
         path: '',
         domain: 'naaloo.com',
         secure: true, // we are not creating https request this time
       };

       // set cookie on window with 'authenticated' value
       this.cookieService.set('Authorization', this.a.authResponseDTO.token, cookieOptions);

       this.cordovaService.openLink(Constants.apiURL + '/biblioteca/descargar' + '?period=' + period?.toISOString() +
       '&idCategoria=' + idCategoria +
       '&idPersonal=' + idPersonal +
       '&idModalidad=' + idModalidad +
       '&searchValue=' + searchValue +
       '&officeId=' + officeId +
       '&businessNameId=' + businessNameId);
   }

   public async insertBibliotecaAnalisisDocumentacion(stream: FormData, selector: number = 0, progress: Subject<number>): Promise<DocumentacionConAnalisisDTO> {
    const res = await (<HttpClientExtended>this.http).GetResponseByStreamingOnPost<StreamingResultDto<DocumentacionConAnalisisDTO>>(Constants.apiURL + '/biblioteca/analisisDocumentacion' + '?selector=' + selector, stream, progress);
    const resDTO = new DocumentacionConAnalisisDTO();
    resDTO.PrepareDTO(res.message);
    return resDTO;
   }

   public async insertBibliotecaDocumentacionConAnalisis(informacionConAnalisisDTO: FormData, progress: Subject<number>): Promise<DistribucionMasivaDTO> {
    const res = await (<HttpClientExtended>this.http).GetResponseByStreamingOnPost<StreamingResultDto<DistribucionMasivaDTO>>(Constants.apiURL + '/biblioteca/documentacionConAnalisis', informacionConAnalisisDTO, progress)
    const resDTO = new DistribucionMasivaDTO();
    resDTO.PrepareDTO(res.message);
    return resDTO;
 }

}
