import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';

export class ConfigurationLevelRangeDTO implements IEntityDTO {

   name = '';
   abbreviation = '';
   colour = '';
   start = 0;
   end = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.abbreviation != null) { this.abbreviation = jsonObj.abbreviation; }
      if (jsonObj.colour != null) { this.colour = jsonObj.colour; }
      if (jsonObj.start != null) { this.start = jsonObj.start; }
      if (jsonObj.end != null) { this.end = jsonObj.end; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
