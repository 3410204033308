import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { ConfigurationSection } from 'src/app/ModelDTO/DTO/configurationSection.ENUM';
import { ConfiguracionSolicitudesCambioDTO } from 'src/app/ModelDTO/DTO/configuracionSolicitudesCambio.DTO';
import { ConfiguracionLlegadasTardeDTO } from 'src/app/ModelDTO/DTO/configuracionLlegadasTarde.DTO';
import { ConfiguracionSalidasTempranasDTO } from 'src/app/ModelDTO/DTO/configuracionSalidasTempranas.DTO';
import { ConfiguracionSancionesDTO } from 'src/app/ModelDTO/DTO/configuracionSanciones.DTO';
import { ConfiguracionExcesoTurnoDTO } from 'src/app/ModelDTO/DTO/configuracionExcesoTurno.DTO';
import { ConfiguracionAusenciasDTO } from 'src/app/ModelDTO/DTO/configuracionAusencias.DTO';
import { ConfiguracionAusenciasEnfermedadDTO } from 'src/app/ModelDTO/DTO/configuracionAusenciasEnfermedad.DTO';
import { ConfiguracionPorcentajeFemeninoDTO } from 'src/app/ModelDTO/DTO/configuracionPorcentajeFemenino.DTO';
import { ConfiguracionPorcentajeAdelantoDTO } from 'src/app/ModelDTO/DTO/configuracionPorcentajeAdelanto.DTO';
import { ConfiguracionMultiplicadorIndemnizacionDTO } from 'src/app/ModelDTO/DTO/configuracionMultiplicadorIndemnizacion.DTO';
import { ConfiguracionDiasPagosAccidenteDTO } from 'src/app/ModelDTO/DTO/configuracionDiasPagosAccidente.DTO';
import { ConfiguracionCuestionariosDTO } from 'src/app/ModelDTO/DTO/configuracionCuestionarios.DTO';
import { ConfiguracionFirmaDigitalDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigital.DTO';
import { ConfiguracionControlHorarioDTO } from 'src/app/ModelDTO/DTO/configuracionControlHorario.DTO';
import { ConfiguracionNotificacionesDTO } from 'src/app/ModelDTO/DTO/configuracionNotificaciones.DTO';
import { ConfiguracionPermisosDTO } from 'src/app/ModelDTO/DTO/configuracionPermisos.DTO';
import { ConfiguracionFeedbackDTO } from 'src/app/ModelDTO/DTO/configuracionFeedback.DTO';
import { ConfigurationLevelRangeDTO } from 'src/app/ModelDTO/DTO/configurationLevelRange.DTO';
import { ConfigurationGoalLevelRangeDTO } from 'src/app/ModelDTO/DTO/configurationGoalLevelRange.DTO';
import { ConfigurationOverallPerformanceDTO } from 'src/app/ModelDTO/DTO/configurationOverallPerformance.DTO';
import { ConfigurationCodenameAliasDTO } from 'src/app/ModelDTO/DTO/configurationCodenameAlias.DTO';


export class ConfiguracionDTO implements IEntityDTO {

   section: ConfigurationSection;
   solicitudes: ConfiguracionSolicitudesCambioDTO;
   llegadasTarde: ConfiguracionLlegadasTardeDTO;
   salidasTempranas: ConfiguracionSalidasTempranasDTO;
   sanciones: ConfiguracionSancionesDTO;
   excesosTurno: ConfiguracionExcesoTurnoDTO;
   ausencias: ConfiguracionAusenciasDTO;
   ausenciasEnfermedad: ConfiguracionAusenciasEnfermedadDTO;
   porcentajeFemenino: ConfiguracionPorcentajeFemeninoDTO;
   porcentajeAdelanto: ConfiguracionPorcentajeAdelantoDTO;
   multiplicadorIndemnizacion: ConfiguracionMultiplicadorIndemnizacionDTO;
   diasPagosAccidente: ConfiguracionDiasPagosAccidenteDTO;
   cuestionarios: ConfiguracionCuestionariosDTO;
   firmaDigital: ConfiguracionFirmaDigitalDTO;
   controlHorario: ConfiguracionControlHorarioDTO;
   notificaciones: ConfiguracionNotificacionesDTO;
   permisos: ConfiguracionPermisosDTO;
   feedback: ConfiguracionFeedbackDTO;
   levelRanges: Array<ConfigurationLevelRangeDTO>;
   goalLevelRanges: Array<ConfigurationGoalLevelRangeDTO>;
   overallPerformance: ConfigurationOverallPerformanceDTO;
   codenamesAlias: Array<ConfigurationCodenameAliasDTO>;
   id = 0;

   constructor() {
      this.solicitudes = new ConfiguracionSolicitudesCambioDTO();
      this.llegadasTarde = new ConfiguracionLlegadasTardeDTO();
      this.salidasTempranas = new ConfiguracionSalidasTempranasDTO();
      this.sanciones = new ConfiguracionSancionesDTO();
      this.excesosTurno = new ConfiguracionExcesoTurnoDTO();
      this.ausencias = new ConfiguracionAusenciasDTO();
      this.ausenciasEnfermedad = new ConfiguracionAusenciasEnfermedadDTO();
      this.porcentajeFemenino = new ConfiguracionPorcentajeFemeninoDTO();
      this.porcentajeAdelanto = new ConfiguracionPorcentajeAdelantoDTO();
      this.multiplicadorIndemnizacion = new ConfiguracionMultiplicadorIndemnizacionDTO();
      this.diasPagosAccidente = new ConfiguracionDiasPagosAccidenteDTO();
      this.cuestionarios = new ConfiguracionCuestionariosDTO();
      this.firmaDigital = new ConfiguracionFirmaDigitalDTO();
      this.controlHorario = new ConfiguracionControlHorarioDTO();
      this.notificaciones = new ConfiguracionNotificacionesDTO();
      this.permisos = new ConfiguracionPermisosDTO();
      this.feedback = new ConfiguracionFeedbackDTO();
      this.levelRanges = new Array<ConfigurationLevelRangeDTO>();
      this.goalLevelRanges = new Array<ConfigurationGoalLevelRangeDTO>();
      this.overallPerformance = new ConfigurationOverallPerformanceDTO();
      this.codenamesAlias = new Array<ConfigurationCodenameAliasDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.section != null) { this.section = jsonObj.section as ConfigurationSection; }
      if (jsonObj.solicitudes != null) { this.solicitudes.PrepareDTO(jsonObj.solicitudes); }
      if (jsonObj.llegadasTarde != null) { this.llegadasTarde.PrepareDTO(jsonObj.llegadasTarde); }
      if (jsonObj.salidasTempranas != null) { this.salidasTempranas.PrepareDTO(jsonObj.salidasTempranas); }
      if (jsonObj.sanciones != null) { this.sanciones.PrepareDTO(jsonObj.sanciones); }
      if (jsonObj.excesosTurno != null) { this.excesosTurno.PrepareDTO(jsonObj.excesosTurno); }
      if (jsonObj.ausencias != null) { this.ausencias.PrepareDTO(jsonObj.ausencias); }
      if (jsonObj.ausenciasEnfermedad != null) { this.ausenciasEnfermedad.PrepareDTO(jsonObj.ausenciasEnfermedad); }
      if (jsonObj.porcentajeFemenino != null) { this.porcentajeFemenino.PrepareDTO(jsonObj.porcentajeFemenino); }
      if (jsonObj.porcentajeAdelanto != null) { this.porcentajeAdelanto.PrepareDTO(jsonObj.porcentajeAdelanto); }
      if (jsonObj.multiplicadorIndemnizacion != null) { this.multiplicadorIndemnizacion.PrepareDTO(jsonObj.multiplicadorIndemnizacion); }
      if (jsonObj.diasPagosAccidente != null) { this.diasPagosAccidente.PrepareDTO(jsonObj.diasPagosAccidente); }
      if (jsonObj.cuestionarios != null) { this.cuestionarios.PrepareDTO(jsonObj.cuestionarios); }
      if (jsonObj.firmaDigital != null) { this.firmaDigital.PrepareDTO(jsonObj.firmaDigital); }
      if (jsonObj.controlHorario != null) { this.controlHorario.PrepareDTO(jsonObj.controlHorario); }
      if (jsonObj.notificaciones != null) { this.notificaciones.PrepareDTO(jsonObj.notificaciones); }
      if (jsonObj.permisos != null) { this.permisos.PrepareDTO(jsonObj.permisos); }
      if (jsonObj.feedback != null) { this.feedback.PrepareDTO(jsonObj.feedback); }
      if (jsonObj.levelRanges != null) { for (const item of jsonObj.levelRanges) { const itemDTO = new ConfigurationLevelRangeDTO(); itemDTO.PrepareDTO(item); this.levelRanges.push(itemDTO); } }
      if (jsonObj.goalLevelRanges != null) { for (const item of jsonObj.goalLevelRanges) { const itemDTO = new ConfigurationGoalLevelRangeDTO(); itemDTO.PrepareDTO(item); this.goalLevelRanges.push(itemDTO); } }
      if (jsonObj.overallPerformance != null) { this.overallPerformance.PrepareDTO(jsonObj.overallPerformance); }
      if (jsonObj.codenamesAlias != null) { for (const item of jsonObj.codenamesAlias) { const itemDTO = new ConfigurationCodenameAliasDTO(); itemDTO.PrepareDTO(item); this.codenamesAlias.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
