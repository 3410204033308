import { Subscription } from 'rxjs';
import { CurrencyModelDTO } from 'src/app/ModelDTO/currency.ModelDTO';
import { AssignmentType } from 'src/app/ModelDTO/DTO/assignmentType.ENUM';
import { CurrencyDTO } from 'src/app/ModelDTO/DTO/currency.DTO';
import { LegajoDTO } from 'src/app/ModelDTO/DTO/legajo.DTO';
import { ModalidadContractualDTO } from 'src/app/ModelDTO/DTO/modalidadContractual.DTO';
import { PersonalBeneficioDTO } from 'src/app/ModelDTO/DTO/personalBeneficio.DTO';
import { PersonalBeneficioAusenciaDTO } from 'src/app/ModelDTO/DTO/personalBeneficioAusencia.DTO';
import { PersonalCambioEstadoConAnteriorDTO } from 'src/app/ModelDTO/DTO/personalCambioEstadoConAnterior.DTO';
import { PersonalCategoriaBasicaDTO } from 'src/app/ModelDTO/DTO/personalCategoriaBasica.DTO';
import { PersonalSubcategoriaDTO } from 'src/app/ModelDTO/DTO/personalSubcategoria.DTO';
import { PuestoDTO } from 'src/app/ModelDTO/DTO/puesto.DTO';
import { TipoContratacion } from 'src/app/ModelDTO/DTO/tipoContratacion.ENUM';
import { WorkDayDTO } from 'src/app/ModelDTO/DTO/workDay.DTO';
import { WorkLocationType } from 'src/app/ModelDTO/DTO/workLocationType.ENUM';
import { ModalidadContractualModelDTO } from 'src/app/ModelDTO/modalidadContractual.ModelDTO';
import { PersonalCambioEstadoConAnteriorModelDTO } from 'src/app/ModelDTO/personalCambioEstadoConAnterior.ModelDTO';
import { PersonalCategoriaBasicaModelDTO } from 'src/app/ModelDTO/personalCategoriaBasica.ModelDTO';
import { PersonalSubcategoriaModelDTO } from 'src/app/ModelDTO/personalSubcategoria.ModelDTO';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { PaymentModalityType } from './DTO/paymentModalityType.ENUM';
import { EmpresaModelDTO } from 'src/app/ModelDTO/empresa.ModelDTO';
import { EmpresaDTO } from 'src/app/ModelDTO/DTO/empresa.DTO';


export class LegajoModelDTO extends EntityModelDTO<LegajoDTO> {

   private categoriaContratacionModel: PersonalCategoriaBasicaModelDTO;
   private categoriaContratacionSubscribe: Subscription;
   private subcategoriaContratacionModel: PersonalSubcategoriaModelDTO;
   private subcategoriaContratacionSubscribe: Subscription;
   private modalidadModel: ModalidadContractualModelDTO;
   private modalidadSubscribe: Subscription;
   private currencyModel: CurrencyModelDTO;
   private currencySubscribe: Subscription;
   private estadoActualModel: PersonalCambioEstadoConAnteriorModelDTO;
   private estadoActualSubscribe: Subscription;
   private empresaModel: EmpresaModelDTO;
   private empresaSubscribe: Subscription;

   public constructor(protected entityDTO: LegajoDTO) {
      super(entityDTO);
   }

   public static getAssignmentType(): string[] {
      return EntityModelDTO.getEnumArray(AssignmentType);
   }

   public static getWorkLocationType(): string[] {
      return EntityModelDTO.getEnumArray(WorkLocationType);
   }

   public static getTipoContratacion(): string[] {
      return EntityModelDTO.getEnumArray(TipoContratacion);
   }

   public static getPaymentModalityType(): string[] {
      return EntityModelDTO.getEnumArray(PaymentModalityType);
   }

   public setEntityDTO(entityDTO: LegajoDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.categoriaContratacionModel = new PersonalCategoriaBasicaModelDTO(this.entityDTO.categoriaContratacion);
      this.categoriaContratacionSubscribe = this.categoriaContratacionModel.changed.subscribe((changed) => this.changed.next(changed));
      this.subcategoriaContratacionModel = new PersonalSubcategoriaModelDTO(this.entityDTO.subcategoriaContratacion);
      this.subcategoriaContratacionSubscribe = this.subcategoriaContratacionModel.changed.subscribe((changed) => this.changed.next(changed));
      this.modalidadModel = new ModalidadContractualModelDTO(this.entityDTO.modalidad);
      this.modalidadSubscribe = this.modalidadModel.changed.subscribe((changed) => this.changed.next(changed));
      this.currencyModel = new CurrencyModelDTO(this.entityDTO.currency);
      this.currencySubscribe = this.currencyModel.changed.subscribe((changed) => this.changed.next(changed));
      this.estadoActualModel = new PersonalCambioEstadoConAnteriorModelDTO(this.entityDTO.estadoActual);
      this.estadoActualSubscribe = this.estadoActualModel.changed.subscribe((changed) => this.changed.next(changed));
      this.empresaModel = new EmpresaModelDTO(this.entityDTO.empresa);
      this.empresaSubscribe = this.empresaModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.categoriaContratacionModel.dispose();
      this.categoriaContratacionSubscribe.unsubscribe();
      this.subcategoriaContratacionModel.dispose();
      this.subcategoriaContratacionSubscribe.unsubscribe();
      this.modalidadModel.dispose();
      this.modalidadSubscribe.unsubscribe();
      this.currencyModel.dispose();
      this.currencySubscribe.unsubscribe();
      this.estadoActualModel.dispose();
      this.estadoActualSubscribe.unsubscribe();
      this.empresaModel.dispose();
      this.empresaSubscribe.unsubscribe();
   }

   get FechaSalida(): Date { return this.entityDTO.fechaSalida; }
   set FechaSalida(value: Date) { this.notifyChangeDTO('fechaSalida', value); }

   get CategoriaContratacionModel(): PersonalCategoriaBasicaModelDTO { return this.categoriaContratacionModel; }
   get CategoriaContratacion(): PersonalCategoriaBasicaDTO { return this.categoriaContratacionModel.getEntityDTO(); }
   set CategoriaContratacion(value: PersonalCategoriaBasicaDTO) { this.notifyChange(() => { this.entityDTO.categoriaContratacion = value; this.categoriaContratacionModel.setEntityDTO(value); }); }

   get SubcategoriaContratacionModel(): PersonalSubcategoriaModelDTO { return this.subcategoriaContratacionModel; }
   get SubcategoriaContratacion(): PersonalSubcategoriaDTO { return this.subcategoriaContratacionModel.getEntityDTO(); }
   set SubcategoriaContratacion(value: PersonalSubcategoriaDTO) { this.notifyChange(() => { this.entityDTO.subcategoriaContratacion = value; this.subcategoriaContratacionModel.setEntityDTO(value); }); }

   get Puestos(): Array<PuestoDTO> { return this.entityDTO.puestos; }
   set Puestos(value: Array<PuestoDTO>) { this.notifyChangeDTO('puestos', value); }

   get WorkDays(): Array<WorkDayDTO> { return this.entityDTO.workDays; }
   set WorkDays(value: Array<WorkDayDTO>) { this.notifyChangeDTO('workDays', value); }

   get ModalidadModel(): ModalidadContractualModelDTO { return this.modalidadModel; }
   get Modalidad(): ModalidadContractualDTO { return this.modalidadModel.getEntityDTO(); }
   set Modalidad(value: ModalidadContractualDTO) { this.notifyChange(() => { this.entityDTO.modalidad = value; this.modalidadModel.setEntityDTO(value); }); }

   get ModoTrabajo(): string { return WorkLocationType[this.entityDTO.modoTrabajo]; }
   set ModoTrabajo(value: string) { this.notifyChangeDTO('modoTrabajo', WorkLocationType[value]); }

   get PaymentModality(): string { return PaymentModalityType[this.entityDTO.paymentModality]; }
   set PaymentModality(value: string) { this.notifyChangeDTO('paymentModality', PaymentModalityType[value]); }

   get HayGrade(): string { return this.entityDTO.hayGrade; }
   set HayGrade(value: string) { this.notifyChangeDTO('hayGrade', value); }

   get HayGradePercentage(): string { return this.entityDTO.hayGradePercentage; }
   set HayGradePercentage(value: string) { this.notifyChangeDTO('hayGradePercentage', value); }

   get RepresentanteGremial(): boolean { return this.entityDTO.representanteGremial; }
   set RepresentanteGremial(value: boolean) { this.notifyChangeDTO('representanteGremial', value); }

   get InicioRepresentacionGremial(): Date { return this.entityDTO.inicioRepresentacionGremial; }
   set InicioRepresentacionGremial(value: Date) { this.notifyChangeDTO('inicioRepresentacionGremial', value); }

   get FinRepresentacionGremial(): Date { return this.entityDTO.finRepresentacionGremial; }
   set FinRepresentacionGremial(value: Date) { this.notifyChangeDTO('finRepresentacionGremial', value); }

   get EmailEmpresa(): string { return this.entityDTO.emailEmpresa; }
   set EmailEmpresa(value: string) { this.notifyChangeDTO('emailEmpresa', value); }

   get Antiguedad(): number { return this.entityDTO.antiguedad; }
   set Antiguedad(value: number) { this.notifyChangeDTO('antiguedad', value); }

   get SueldoBruto(): number { return this.entityDTO.sueldoBruto; }
   set SueldoBruto(value: number) { this.notifyChangeDTO('sueldoBruto', value); }

   get CurrencyModel(): CurrencyModelDTO { return this.currencyModel; }
   get Currency(): CurrencyDTO { return this.currencyModel.getEntityDTO(); }
   set Currency(value: CurrencyDTO) { this.notifyChange(() => { this.entityDTO.currency = value; this.currencyModel.setEntityDTO(value); }); }

   get TieneCertificado(): boolean { return this.entityDTO.tieneCertificado; }
   set TieneCertificado(value: boolean) { this.notifyChangeDTO('tieneCertificado', value); }

   get EsAdmin(): number { return this.entityDTO.esAdmin; }
   set EsAdmin(value: number) { this.notifyChangeDTO('esAdmin', value); }

   get Beneficios(): Array<PersonalBeneficioDTO> { return this.entityDTO.beneficios; }
   set Beneficios(value: Array<PersonalBeneficioDTO>) { this.notifyChangeDTO('beneficios', value); }

   get BeneficiosAusencia(): Array<PersonalBeneficioAusenciaDTO> { return this.entityDTO.beneficiosAusencia; }
   set BeneficiosAusencia(value: Array<PersonalBeneficioAusenciaDTO>) { this.notifyChangeDTO('beneficiosAusencia', value); }

   get EstadoActualModel(): PersonalCambioEstadoConAnteriorModelDTO { return this.estadoActualModel; }
   get EstadoActual(): PersonalCambioEstadoConAnteriorDTO { return this.estadoActualModel.getEntityDTO(); }
   set EstadoActual(value: PersonalCambioEstadoConAnteriorDTO) { this.notifyChange(() => { this.entityDTO.estadoActual = value; this.estadoActualModel.setEntityDTO(value); }); }

   get InternNumber(): number { return this.entityDTO.internNumber; }
   set InternNumber(value: number) { this.notifyChangeDTO('internNumber', value); }

   get Legajo(): string { return this.entityDTO.legajo; }
   set Legajo(value: string) { this.notifyChangeDTO('legajo', value); }

   get EmpresaModel(): EmpresaModelDTO { return this.empresaModel; }
   get Empresa(): EmpresaDTO { return this.empresaModel.getEntityDTO(); }
   set Empresa(value: EmpresaDTO) { this.notifyChange(() => { this.entityDTO.empresa = value; this.empresaModel.setEntityDTO(value); }); }

   get Contratacion(): string { return TipoContratacion[this.entityDTO.contratacion]; }
   set Contratacion(value: string) { this.notifyChangeDTO('contratacion', TipoContratacion[value]); }

   get FechaIngreso(): Date { return this.entityDTO.fechaIngreso; }
   set FechaIngreso(value: Date) { this.notifyChangeDTO('fechaIngreso', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
