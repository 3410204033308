import { CommonModule, DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { EventoDTO } from 'src/app/ModelDTO/DTO/evento.DTO';
import { ConfettiService } from 'src/app/shared/services/confetti.service';
import { EventosService } from 'src/app/app-common/calendario-eventos/eventos.service';
import { ComentariosEventoHandler } from 'src/app/app-common/comentarios/comentarios-evento.handler';
import { ComentariosModule } from 'src/app/app-common/comentarios/comentarios.module';
import { EventDetailComponent } from 'src/app/app-common/modal-evento-detalle/event-detail/event-detail.component';
import { ReactionService } from 'src/app/shared/reactions/reaction.service';

export interface ModalEventoDetalleData {
  evento: EventoDTO;
}

@Component({
  selector: 'app-modal-evento-detalle',
  templateUrl: './modal-evento-detalle.component.html',
  styleUrls: ['./modal-evento-detalle.component.scss'],
  standalone: true,
  imports: [ComentariosModule, EventDetailComponent, CommonModule],
  providers: [DatePipe, ReactionService],
})
export class ModalEventoDetalleComponent implements OnInit {
  public evento: EventoDTO;
  public esAdmin = this.headerService.isAdmin();
  public vistaEmpresa$ = this.headerService.vistaEmpresa$;

  constructor(
    private headerService: HeaderAppService,
    private dialogRef: MatDialogRef<ModalEventoDetalleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalEventoDetalleData,
    private eventoService: EventosService,
    public comentarioHandler: ComentariosEventoHandler,
    private confetti: ConfettiService,
  ) {
    this.evento = this.data.evento;
  }

  public async ngOnInit(): Promise<void> {
    if (this.evento.animation) {
      this.confetti.visualize(this.evento.animation.code);
    }
    this.evento.adjuntos = await this.eventoService.obtenerAdjuntos(this.evento.id);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
