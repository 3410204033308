@switch (config.type) {
  @case ('stroked') {
    <button mat-stroked-button type="button" class="add-reaction-button" [disabled]="disabled" (click)="togglePicker($event)">
      <mat-icon fontSet="material-symbols-outlined">
        {{ config.icon }}
      </mat-icon>
    </button>
  }
  @case ('icon') {
    <button mat-icon-button type="button" [disabled]="disabled" (click)="togglePicker($event)">
      <mat-icon fontSet="material-symbols-outlined">
        {{ config.icon }}
      </mat-icon>
    </button>
  }
}
