import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { DomicilioDTO } from 'src/app/ModelDTO/DTO/domicilio.DTO';
import { LocalidadDTO } from 'src/app/ModelDTO/DTO/localidad.DTO';
import { LocalidadModelDTO } from 'src/app/ModelDTO/localidad.ModelDTO';


export class DomicilioModelDTO extends EntityModelDTO<DomicilioDTO> {

   private localidadModel: LocalidadModelDTO;
   private localidadSubscribe: Subscription;

   public constructor(protected entityDTO: DomicilioDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: DomicilioDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.localidadModel = new LocalidadModelDTO(this.entityDTO.localidad);
      this.localidadSubscribe = this.localidadModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.localidadModel.dispose();
      this.localidadSubscribe.unsubscribe();
   }

   get Direccion(): string { return this.entityDTO.direccion; }
   set Direccion(value: string) { this.notifyChangeDTO('direccion', value); }

   get LocalidadModel(): LocalidadModelDTO { return this.localidadModel; }
   get Localidad(): LocalidadDTO { return this.localidadModel.getEntityDTO(); }
   set Localidad(value: LocalidadDTO) { this.notifyChange(() => { this.entityDTO.localidad = value; this.localidadModel.setEntityDTO(value); }); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
