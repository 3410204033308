import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { PersonalCambioEstadoCompletoDTO } from 'src/app/ModelDTO/DTO/personalCambioEstadoCompleto.DTO';
import { SolicitudAusenciaDTO } from 'src/app/ModelDTO/DTO/solicitudAusencia.DTO';
import { PersonalSancionFlatDTO } from 'src/app/ModelDTO/DTO/personalSancionFlat.DTO';


export class ResumenMensualDTO implements IEntityDTO {

   altas: Array<PersonalCambioEstadoCompletoDTO>;
   bajas: Array<PersonalCambioEstadoCompletoDTO>;
   licencias: Array<SolicitudAusenciaDTO>;
   vacaciones: Array<SolicitudAusenciaDTO>;
   sanciones: Array<PersonalSancionFlatDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.altas = new Array<PersonalCambioEstadoCompletoDTO>();
      this.bajas = new Array<PersonalCambioEstadoCompletoDTO>();
      this.licencias = new Array<SolicitudAusenciaDTO>();
      this.vacaciones = new Array<SolicitudAusenciaDTO>();
      this.sanciones = new Array<PersonalSancionFlatDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.altas != null) { for (const item of jsonObj.altas) { const itemDTO = new PersonalCambioEstadoCompletoDTO(); itemDTO.PrepareDTO(item); this.altas.push(itemDTO); } }
      if (jsonObj.bajas != null) { for (const item of jsonObj.bajas) { const itemDTO = new PersonalCambioEstadoCompletoDTO(); itemDTO.PrepareDTO(item); this.bajas.push(itemDTO); } }
      if (jsonObj.licencias != null) { for (const item of jsonObj.licencias) { const itemDTO = new SolicitudAusenciaDTO(); itemDTO.PrepareDTO(item); this.licencias.push(itemDTO); } }
      if (jsonObj.vacaciones != null) { for (const item of jsonObj.vacaciones) { const itemDTO = new SolicitudAusenciaDTO(); itemDTO.PrepareDTO(item); this.vacaciones.push(itemDTO); } }
      if (jsonObj.sanciones != null) { for (const item of jsonObj.sanciones) { const itemDTO = new PersonalSancionFlatDTO(); itemDTO.PrepareDTO(item); this.sanciones.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
