import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { VAPIKEY } from 'src/app/shared/constants';
import { AuthRequestDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authRequest.DTO';
import { NotificationDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/notification.DTO';
import { TypeSocial } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/typeSocial.ENUM';
import { UserServiceBackend } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/backend/user.ServiceBackend';
import { IWebPushNotification } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/web-push.interface';
import { WEB_DEVICE_ID } from 'src/app/shared/localStorageConstants';
import { NotificationHandlerService } from 'src/app/shared/services/notification-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebPushService {
  private clickSubscription: Subscription;

  constructor(
    private swPush: SwPush,
    private userService: UserServiceBackend,
    private notificationHandlerService: NotificationHandlerService,
    private headerService: HeaderAppService,
  ) {}

  public initWebNotification(): void {
    this.swPush
      .requestSubscription({
        serverPublicKey: VAPIKEY,
      })
      .then(async (sub) => {
        // atob no esta deprecado en web, solo en node.
        const stringSubscriptionInformation = btoa(JSON.stringify(sub));
        if (stringSubscriptionInformation !== localStorage.getItem(WEB_DEVICE_ID)) {
          const user = new AuthRequestDTO();
          user.tokenDeviceId = stringSubscriptionInformation;
          user.typeSocial = TypeSocial.Webpush;
          user.applicationName = environment.packageName;
          await this.userService.insertUserDeviceID(user);

          localStorage.setItem(WEB_DEVICE_ID, stringSubscriptionInformation);
        }
        this.clickSubscription?.unsubscribe();
        this.clickSubscription = this.swPush.notificationClicks.subscribe((click) => this.handleClick(click)); // handle click
      })
      .catch((err) => console.error(VAPIKEY, 'Could not subscribe to notifications', err));
  }

  private handleClick(notification: IWebPushNotification): void {
    if (notification.notification?.data) {
      const notificationDto = new NotificationDTO();
      notificationDto.PrepareDTO(notification.notification.data);

      this.notificationHandlerService.handleNotification(
        notificationDto,
        this.headerService.modoEmpresa(),
        this.headerService.personalLegajoId,
      );
    }
  }
}
