import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';

export class ConvenioColectivoDTO implements IEntityDTO {

   codigo = '';
   nombre = '';
   nombreCompleto = '';
   descripcion = '';
   fechaAniversario: Date;
   horasSemanales = 0;
   domingo: boolean;
   lunes: boolean;
   martes: boolean;
   miercoles: boolean;
   jueves: boolean;
   viernes: boolean;
   sabado: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.codigo != null) { this.codigo = jsonObj.codigo; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.nombreCompleto != null) { this.nombreCompleto = jsonObj.nombreCompleto; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.fechaAniversario != null) { this.fechaAniversario = new Date(jsonObj.fechaAniversario); }
      if (jsonObj.horasSemanales != null) { this.horasSemanales = jsonObj.horasSemanales; }
      if (jsonObj.domingo != null) { this.domingo = jsonObj.domingo; }
      if (jsonObj.lunes != null) { this.lunes = jsonObj.lunes; }
      if (jsonObj.martes != null) { this.martes = jsonObj.martes; }
      if (jsonObj.miercoles != null) { this.miercoles = jsonObj.miercoles; }
      if (jsonObj.jueves != null) { this.jueves = jsonObj.jueves; }
      if (jsonObj.viernes != null) { this.viernes = jsonObj.viernes; }
      if (jsonObj.sabado != null) { this.sabado = jsonObj.sabado; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
