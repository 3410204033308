import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { PersonalCambioEstadoDTO } from 'src/app/ModelDTO/DTO/personalCambioEstado.DTO';

@Injectable({
   providedIn: 'root'
})
export class PersonaCambioEstadolServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getCambioEstadoIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cambioEstado/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertCambioEstadoIdLegajo(idLegajo: number, personalCambioEstadoDTO: PersonalCambioEstadoDTO): Promise<PersonalCambioEstadoDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/cambioEstado/teammate-file/' + idLegajo, personalCambioEstadoDTO));
         const resDTO = new PersonalCambioEstadoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateCambioEstadoId(id: number, personalCambioEstadoDTO: PersonalCambioEstadoDTO): Promise<PersonalCambioEstadoDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/cambioEstado/' + id, personalCambioEstadoDTO));
         const resDTO = new PersonalCambioEstadoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
