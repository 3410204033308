import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectAndCropComponent, SelectAndCropData } from 'src/app/shared/select-and-crop/select-and-crop.component';

@Injectable({
  providedIn: 'root',
})
export class SelectAndCropService {
  constructor(private dialog: MatDialog) {}

  public openImageCropperDialog(
    data: SelectAndCropData,
    panelClass = 'full-size-sm-600-lg-modal',
  ): MatDialogRef<SelectAndCropComponent> | null {
    const file = (data.event.target as HTMLInputElement).files?.[0];

    if (!file) {
      return null;
    }

    return this.dialog.open(SelectAndCropComponent, {
      panelClass,
      disableClose: false,
      data,
    });
  }
}
