import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { TipoSancionDTO } from 'src/app/ModelDTO/DTO/tipoSancion.DTO';
import { PersonalAusenciaDTO } from 'src/app/ModelDTO/DTO/personalAusencia.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { AttachmentConNuevosFirmantesDTO } from 'src/app/ModelDTO/DTO/attachmentConNuevosFirmantes.DTO';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';


export class SancionDTO implements IEntityDTO {

   observacion = '';
   fecha: Date;
   fechaCarga: Date;
   tipo: TipoSancionDTO;
   ausencia: PersonalAusenciaDTO;
   responsable: PersonalLegajoBasicoDTO;
   adjuntos: Array<AttachmentUaalooDTO>;
   attachmentConNuevosFirmantes: AttachmentConNuevosFirmantesDTO;
   comentarios: Array<ComentarioDTO>;
   cantidadComentarios = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.tipo = new TipoSancionDTO();
      this.ausencia = new PersonalAusenciaDTO();
      this.responsable = new PersonalLegajoBasicoDTO();
      this.adjuntos = new Array<AttachmentUaalooDTO>();
      this.attachmentConNuevosFirmantes = new AttachmentConNuevosFirmantesDTO();
      this.comentarios = new Array<ComentarioDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.observacion != null) { this.observacion = jsonObj.observacion; }
      if (jsonObj.fecha != null) { this.fecha = new Date(jsonObj.fecha); }
      if (jsonObj.fechaCarga != null) { this.fechaCarga = new Date(jsonObj.fechaCarga); }
      if (jsonObj.tipo != null) { this.tipo.PrepareDTO(jsonObj.tipo); }
      if (jsonObj.ausencia != null) { this.ausencia.PrepareDTO(jsonObj.ausencia); }
      if (jsonObj.responsable != null) { this.responsable.PrepareDTO(jsonObj.responsable); }
      if (jsonObj.adjuntos != null) { for (const item of jsonObj.adjuntos) { const itemDTO = new AttachmentUaalooDTO(); itemDTO.PrepareDTO(item); this.adjuntos.push(itemDTO); } }
      if (jsonObj.attachmentConNuevosFirmantes != null) { this.attachmentConNuevosFirmantes.PrepareDTO(jsonObj.attachmentConNuevosFirmantes); }
      if (jsonObj.comentarios != null) { for (const item of jsonObj.comentarios) { const itemDTO = new ComentarioDTO(); itemDTO.PrepareDTO(item); this.comentarios.push(itemDTO); } }
      if (jsonObj.cantidadComentarios != null) { this.cantidadComentarios = jsonObj.cantidadComentarios; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
