import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { PersonalBasicoDTO } from 'src/app/ModelDTO/DTO/personalBasico.DTO';
import { PersonalBasicoContratacionDTO } from 'src/app/ModelDTO/DTO/personalBasicoContratacion.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalPuestoFlatDTO } from 'src/app/ModelDTO/DTO/personalPuestoFlat.DTO';
import { Constants } from '../shared/constants';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageEntity.DTO';
import { ImageFileDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageFile.DTO';

@Injectable({
   providedIn: 'root'
})
export class PersonalServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getPersonalIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personal/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personal/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async insertPersonal(personalDTO: PersonalDTO): Promise<PersonalDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/personal/', personalDTO));
         const resDTO = new PersonalDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updatePersonalId(id: number, personalDTO: PersonalDTO): Promise<PersonalDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/personal/' + id, personalDTO));
         const resDTO = new PersonalDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deletePersonalId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/personal/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getPersonalId(id: number): Promise<PersonalDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personal/' + id));
         if (!res) { return null; }
         const resDTO = new PersonalDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getPersonal(workDayIds?: string, areaId: number = 0, sectorId: number = 0, officeId = 0, issuingCountryId?: number ): Promise<Array<PersonalBasicoContratacionDTO>> {
         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personal/' + '?workDayIds=' + workDayIds +
            '&officeId=' + officeId +
            '&areaId=' + areaId +
            '&sectorId=' + sectorId +
            '&issuingCountryId=' + issuingCountryId));
         const resJson = res.data;
         const resDTO = new Array<PersonalBasicoContratacionDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalBasicoContratacionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;
      }

   public async getPersonalLegajoId(id: number): Promise<PersonalDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personal/legajo/' + id + '?basic=false'));
         if (!res) { return null; }
         const resDTO = new PersonalDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

      public async getPersonalLegajoBasicoId(id: number): Promise<PersonalLegajoBasicoDTO> {
            const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personal/legajo/' + id + '?basic=true'));
            if (!res) { return null; }
            const resDTO = new PersonalLegajoBasicoDTO();
            resDTO.PrepareDTO(res);
            return resDTO;
      }

   public async getPersonalBusquedaPorAfipdocumento(documento: string): Promise<PersonalDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personal/BusquedaPorAfip/' + documento));
         if (!res) { return null; }
         const resDTO = new PersonalDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getPersonalAutoComplete(contiene: string, pageSize: number): Promise<Array<PersonalBasicoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personal/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<PersonalBasicoDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalBasicoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertPersonalIdImage(id: number, imageEntityDTO: ImageEntityDTO): Promise<ImageEntityDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/personal/' + id + '/Image', imageEntityDTO));
         const resDTO = new ImageEntityDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertPersonalIdReenviarNuevoMail(id: number): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/personal/' + id + '/reenviarNuevoMail', null));

   }

   public async updatePersonalPerfil(personalDTO: PersonalDTO): Promise<PersonalDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/personal/perfil', personalDTO));
         const resDTO = new PersonalDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertPersonalIdSignatureImage(id: number, imageFileDTO: ImageFileDTO): Promise<ImageEntityDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/personal/' + id + '/signatureImage', imageFileDTO));
         const resDTO = new ImageEntityDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public getPersonalOrganization(area: number = 0, sector: number = 0, office: number = 0, onlyTeam: boolean = false): Observable<PersonalPuestoFlatDTO[]> {
          return this.http.get<DataDTO>(Constants.apiURL + '/personal/organization' + '?area=' + area +
            '&sector=' + sector +
            '&office=' + office +
            '&onlyTeam=' + onlyTeam).pipe(
              map((res) => {
                const resDTO: PersonalPuestoFlatDTO[] = [];

                res.data.forEach((item) => {
                  const itemDTO = new PersonalPuestoFlatDTO();
                  itemDTO.PrepareDTO(item);
                  resDTO.push(itemDTO);
                });

                return resDTO;
            }));
   }
}
