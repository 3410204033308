import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { EmpresaDTO } from 'src/app/ModelDTO/DTO/empresa.DTO';
import { DomicilioDTO } from 'src/app/ModelDTO/DTO/domicilio.DTO';
import { DomicilioModelDTO } from 'src/app/ModelDTO/domicilio.ModelDTO';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { PaisModelDTO } from 'src/app/ModelDTO/pais.ModelDTO';
import { ElectronicSignatureType } from 'src/app/ModelDTO/DTO/electronicSignatureType.ENUM';


export class EmpresaModelDTO extends EntityModelDTO<EmpresaDTO> {

   private domicilioModel: DomicilioModelDTO;
   private domicilioSubscribe: Subscription;
   private paisModel: PaisModelDTO;
   private paisSubscribe: Subscription;

   public constructor(protected entityDTO: EmpresaDTO) {
      super(entityDTO);
   }

   public static getElectronicSignatureType(): string[] {
      return EntityModelDTO.getEnumArray(ElectronicSignatureType);
   }

   public setEntityDTO(entityDTO: EmpresaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.domicilioModel = new DomicilioModelDTO(this.entityDTO.domicilio);
      this.domicilioSubscribe = this.domicilioModel.changed.subscribe((changed) => this.changed.next(changed));
      this.paisModel = new PaisModelDTO(this.entityDTO.pais);
      this.paisSubscribe = this.paisModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.domicilioModel.dispose();
      this.domicilioSubscribe.unsubscribe();
      this.paisModel.dispose();
      this.paisSubscribe.unsubscribe();
   }

   get DomicilioModel(): DomicilioModelDTO { return this.domicilioModel; }
   get Domicilio(): DomicilioDTO { return this.domicilioModel.getEntityDTO(); }
   set Domicilio(value: DomicilioDTO) { this.notifyChange(() => { this.entityDTO.domicilio = value; this.domicilioModel.setEntityDTO(value); }); }

   get PaisModel(): PaisModelDTO { return this.paisModel; }
   get Pais(): PaisDTO { return this.paisModel.getEntityDTO(); }
   set Pais(value: PaisDTO) { this.notifyChange(() => { this.entityDTO.pais = value; this.paisModel.setEntityDTO(value); }); }

   get Name(): string { return this.entityDTO.name; }
   set Name(value: string) { this.notifyChangeDTO('name', value); }

   get Telefono(): string { return this.entityDTO.telefono; }
   set Telefono(value: string) { this.notifyChangeDTO('telefono', value); }

   get ArtPoliza(): string { return this.entityDTO.artPoliza; }
   set ArtPoliza(value: string) { this.notifyChangeDTO('artPoliza', value); }

   get ArtNombre(): string { return this.entityDTO.artNombre; }
   set ArtNombre(value: string) { this.notifyChangeDTO('artNombre', value); }

   get ArtPrivado(): string { return this.entityDTO.artPrivado; }
   set ArtPrivado(value: string) { this.notifyChangeDTO('artPrivado', value); }

   get ArtPublico(): string { return this.entityDTO.artPublico; }
   set ArtPublico(value: string) { this.notifyChangeDTO('artPublico', value); }

   get RazonSocial(): string { return this.entityDTO.razonSocial; }
   set RazonSocial(value: string) { this.notifyChangeDTO('razonSocial', value); }

   get IdentificadorFiscal(): string { return this.entityDTO.identificadorFiscal; }
   set IdentificadorFiscal(value: string) { this.notifyChangeDTO('identificadorFiscal', value); }

   get Sucursal(): number { return this.entityDTO.sucursal; }
   set Sucursal(value: number) { this.notifyChangeDTO('sucursal', value); }

   get UaalooImage(): string { return this.entityDTO.uaalooImage; }
   set UaalooImage(value: string) { this.notifyChangeDTO('uaalooImage', value); }

   get TimeZoneId(): string { return this.entityDTO.timeZoneId; }
   set TimeZoneId(value: string) { this.notifyChangeDTO('timeZoneId', value); }

   get ElectronicSignature(): string { return ElectronicSignatureType[this.entityDTO.electronicSignature]; }
   set ElectronicSignature(value: string) { this.notifyChangeDTO('electronicSignature', ElectronicSignatureType[value]); }

   get TotalStorageSpace(): number { return this.entityDTO.totalStorageSpace; }
   set TotalStorageSpace(value: number) { this.notifyChangeDTO('totalStorageSpace', value); }

   get Image(): string { return this.entityDTO.image; }
   set Image(value: string) { this.notifyChangeDTO('image', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
