import { Pipe, PipeTransform } from '@angular/core';
import { TipoContratacion } from '@api/enums/tipo-contratacion.enum';

@Pipe({
  name: 'ofertaTipoJornada',
})
export class OfertaTipoJornadaPipe implements PipeTransform {
  public transform(value: TipoContratacion): string {
    switch (value) {
      case TipoContratacion.Full_time:
        return 'Tiempo Completo';
      case TipoContratacion.Part_time:
        return 'Tiempo Parcial';
      case TipoContratacion.No_Definido:
        return '';
      default:
        return '';
    }
  }
}
