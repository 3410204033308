import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TipoEstadoPersonalLegajoCuestinario } from '@api/enums/tipo-estado-personal-legajo-cuestinario.enum';
import { CuestionarioPreguntaDTO } from 'src/app/ModelDTO/DTO/cuestionarioPregunta.DTO';
import { CuestionarioRespuestaDTO } from 'src/app/ModelDTO/DTO/cuestionarioRespuesta.DTO';
import { CuestionarioRespuestaOpcionDTO } from 'src/app/ModelDTO/DTO/cuestionarioRespuestaOpcion.DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { PreguntaOpcionDTO } from 'src/app/ModelDTO/DTO/preguntaOpcion.DTO';
import { QuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/question.component';

@Component({
  selector: 'app-single-choice-question',
  templateUrl: './single-choice-question.component.html',
  styleUrls: ['./single-choice-question.component.scss'],
})
export class SingleChoiceQuestionComponent implements OnInit, QuestionComponent {
  @Input() public data: CuestionarioPreguntaDTO;
  @Input() public modoAdministrador: boolean;
  @Input() public editionMode: boolean;
  @Input() public estadoCuestionario: TipoEstadoPersonalLegajoCuestinario;
  @Input() public cuestionarioTerminado: TipoEstadoPersonalLegajoCuestinario;
  @Input() public position: number;
  @Input() public previousResult: LevelRangeDTO;
  @Input() public answerCommentEnabled: boolean;

  @Output() public commentClicked = new EventEmitter<void>();

  public opcionSingle: PreguntaOpcionDTO;
  constructor() {
    this.opcionSingle = new PreguntaOpcionDTO();
  }

  public ngOnInit(): void {
    if (this.estadoCuestionario === this.cuestionarioTerminado) {
      this.data.respuesta.opciones.forEach((respuesta) => {
        // eslint-disable-next-line consistent-return
        this.data.opciones.forEach((opcion) => {
          // eslint-disable-next-line no-return-assign
          if (opcion.id === respuesta.opcion.id) return (this.opcionSingle = opcion);
        });
        return this.opcionSingle;
      });
    }
  }

  public respuestaChange(respuesta: CuestionarioRespuestaDTO, value: PreguntaOpcionDTO): void {
    respuesta.modificada = true;
    respuesta.fecha = new Date();
    respuesta.id = this.data.id;
    respuesta.opciones = [];
    const respuestaDto = new CuestionarioRespuestaOpcionDTO();
    respuestaDto.opcion = value;
    respuesta.opciones.push(respuestaDto);
  }

  public onCommentClicked(): void {
    this.commentClicked.emit();
  }
}
