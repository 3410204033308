<div
  [style.width.px]="width"
  [style.height.px]="width"
  [style.borderRadius]="circle ? '50%' : undefined"
  class="circle-image"
  [ngClass]="{
    contractor: user?.modalidad === 4,
    'bordered-img': user?.modalidad !== 4,
    'invalid-border': invalid
  }"
  [style.border-color]="borderColor"
  [style.border-width]="borderWidth"
  [style.border-style]="borderColor?.length ? 'solid' : 'none'"
  [matTooltip]="getFullName()"
>
  <a [command]="openDetailsCmd" [stopPropagation]="stopPropagation">
    <div
      *ngIf="imageSource || user?.image?.length"
      [style.width.px]="width"
      [style.height.px]="width"
      [style.background-position-y]="positionY"
      [style.background-image]="backgroundImage"
      alt="circle-image"
      loading="lazy"
      class="circle-pic"
    ></div>
    <span [style.fontSize]="fontSize" *ngIf="!imageSource && user && !user?.image?.length">{{ initials }}</span>
    <label *ngIf="!imageSource && !user && showLabel">{{ 'GENERAL.SELECT_PHOTO' | translate }}</label>
  </a>
</div>
<ng-content></ng-content>
