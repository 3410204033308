import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { PersonalLegajoModelDTO } from 'src/app/ModelDTO/personalLegajo.ModelDTO';
import { BusinessNameDTO } from 'src/app/ModelDTO/DTO/businessName.DTO';
import { EstadisticasFirmasDTO } from 'src/app/ModelDTO/DTO/estadisticasFirmas.DTO';
import { InformacionTipoDTO } from 'src/app/ModelDTO/DTO/informacionTipo.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { SignatureType } from '@api/enums/signature-type.enum';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';
import { NotificationDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/notification.DTO';
import { PersonalLegajoDTO } from 'src/app/ModelDTO/DTO/personalLegajo.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { TipoCategoriaDocumento } from '@api/enums/tipo-categoria-documento.enum';
import { PublicLinkDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/publicLink.DTO';
import { AttachmentConNuevosFirmantesDTO } from 'src/app/ModelDTO/DTO/attachmentConNuevosFirmantes.DTO';
import { AttachmentFirmaServiceBackend } from 'src/app/ServiceBackend/attachmentFirma.ServiceBackend';
import { IndicadoresServiceBackend } from 'src/app/ServiceBackend/indicadores.ServiceBackend';
import { PersonalLegajoServiceBackend } from 'src/app/ServiceBackend/personalLegajo.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class AttachmentFirmaService {
  public archivosPendientes: number;
  public fechaDesde = DateTime.now().minus({ months: 2 }).startOf('day').toJSDate();
  public fechaHasta = DateTime.now().endOf('day').toJSDate();

  constructor(
    private attachmentUaalooServiceBackend: AttachmentFirmaServiceBackend,
    private headerService: HeaderAppService,
    private indicadoresServicesBackend: IndicadoresServiceBackend,
    private personalLegajoServiceBackend: PersonalLegajoServiceBackend,
  ) {}

  public async getAttachmentById(id: number): Promise<AttachmentUaalooDTO> {
    return this.attachmentUaalooServiceBackend.getAttachmentsFirmaId(id);
  }

  public async firmarAttachmentUserLogged(
    attachment: AttachmentUaalooDTO,
    owner: PersonalLegajoModelDTO,
    tipoFirma: SignatureType,
    publicDoc: boolean,
    sendMail: boolean,
    acceptPrivacyPolicy: boolean,
  ): Promise<AttachmentUaalooDTO> {
    return this.attachmentUaalooServiceBackend.updateAttachmentsFirmaIdFirmar(
      attachment.id,
      this.headerService.personalLegajoId,
      publicDoc,
      tipoFirma,
      sendMail,
      attachment,
      owner.Id,
      acceptPrivacyPolicy,
    );
  }

  public async agregarFirmantes(
    attachment: AttachmentUaalooDTO,
    firmantes: PersonalLegajoBasicoDTO[],
    notificarPorEmail: boolean,
    posicionFirma: ConfiguracionFirmaDigitalPosicionDTO,
  ): Promise<AttachmentUaalooDTO> {
    const attachmentWrapper = new AttachmentConNuevosFirmantesDTO();
    attachmentWrapper.attachment = attachment;
    attachmentWrapper.nuevosFirmantes = firmantes;
    attachmentWrapper.solicitante = this.headerService.userLogged;
    attachmentWrapper.notificarPorEmail = notificarPorEmail;
    attachmentWrapper.posicionFirma = posicionFirma;

    return this.attachmentUaalooServiceBackend.updateAttachmentsFirmaIdAgregarFirmantes(attachment.id, attachmentWrapper);
  }

  public async getIndicadoresDeFirmas(
    desde: Date,
    hasta: Date,
    pageSize: number,
    pageNumber: number,
    type: SignatureType | null,
    period?: Date,
    documentType?: InformacionTipoDTO,
    teammate?: PersonalLegajoBasicoDTO,
    signaturesDone: boolean = false,
    office?: OficinaDTO,
    businessName?: BusinessNameDTO,
  ): Promise<EstadisticasFirmasDTO> {
    const res = await this.indicadoresServicesBackend.getIndicadoresArchivosConFirmas({
      from: desde,
      to: hasta,
      period,
      pageSize,
      pageNumber,
      documentTypeId: documentType?.id,
      type,
      teammateId: teammate?.id,
      officeId: office?.id,
      signaturesDone,
      businessNameId: businessName?.id,
    });
    return res;
  }

  public async getIndicadoresDeFirmasPropio(
    id: number,
    desde: Date,
    hasta: Date,
    vistaColaborador: boolean,
    sinArchivos: boolean,
    pageSize: number,
    pageNumber: number,
    type: SignatureType | null,
    period?: Date,
    documentType?: InformacionTipoDTO,
    signaturesDone: boolean = false,
  ): Promise<EstadisticasFirmasDTO> {
    return this.personalLegajoServiceBackend.getPersonalLegajosIdArchivosConFirmas(
      id,
      desde,
      hasta,
      period,
      pageSize,
      pageNumber,
      vistaColaborador,
      sinArchivos,
      documentType?.id ?? 0,
      type,
      signaturesDone,
    );
  }

  public async recordarFirmar(idUsuario: number, idAttachment: number): Promise<void> {
    const notificacion = new NotificationDTO();
    await this.personalLegajoServiceBackend.insertPersonalLegajosIdAttachmentsIdAttachmentRecordarFirma(
      idUsuario,
      idAttachment,
      notificacion,
    );
  }

  public async recordarFirmas(): Promise<void> {
    const notificacion = new NotificationDTO();
    await this.personalLegajoServiceBackend.insertPersonalLegajosRecordarFirmas(notificacion);
  }

  public async obtenerArchivosPendientesPropios(
    idUsuario: number,
    pageSize: number,
    pageNumber: number,
    type: SignatureType | null,
    period?: Date,
    vistaColaborador = true,
  ): Promise<number> {
    const indicadores = await this.getIndicadoresDeFirmasPropio(
      idUsuario,
      null,
      null,
      vistaColaborador,
      true,
      pageSize,
      pageNumber,
      type,
      period,
    );
    return indicadores.totalFirmasPendientesColaborador;
  }

  public async generarCertificadoColaborador(idColaborador: number): Promise<PersonalLegajoDTO> {
    return this.personalLegajoServiceBackend.insertPersonalLegajosIdUserCertificate(idColaborador);
  }

  public async obtenerCertificado(namedBlob: NamedBlobDTO): Promise<Blob> {
    return this.attachmentUaalooServiceBackend.getAttachmentsFirmaCertificado(namedBlob);
  }

  public async quitarFirmante(idFirma: number, idColaborador: number): Promise<void> {
    await this.attachmentUaalooServiceBackend.updateAttachmentsFirmaIdQuitarFirmanteIDPERSONALLEGAJO(idFirma, idColaborador);
  }

  public async obtenerAuditoriaFirma(idAttachment: number, owner: PersonalLegajoDTO, namedBlob: NamedBlobDTO): Promise<Blob> {
    return this.attachmentUaalooServiceBackend.getAttachmentsFirmaIdAuditoria(idAttachment, owner.id, namedBlob);
  }

  public async obtenerPosicionFirma(idAttachment: number, idColaborador: number): Promise<ConfiguracionFirmaDigitalPosicionDTO> {
    return this.attachmentUaalooServiceBackend.getAttachmentsFirmaIdPosicionFirmaIDPERSONALLEGAJO(idAttachment, idColaborador);
  }

  public async getPublicLink(
    attachment: AttachmentUaalooDTO,
    personalLegajo: PersonalLegajoModelDTO,
    tipoDocumento: TipoCategoriaDocumento,
    expiredMinutes: number = 15,
  ): Promise<PublicLinkDTO> {
    // Si es publico mando -1 porque todos firman el mismo documento
    const personalId = tipoDocumento === TipoCategoriaDocumento.P1ublico ? -1 : personalLegajo.Id;
    return this.attachmentUaalooServiceBackend.getAttachmentsFirmaPublicLinkId(attachment.id, personalId, expiredMinutes);
  }
}
