import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { PersonalCambioEstadoBasicoDTO } from 'src/app/ModelDTO/DTO/personalCambioEstadoBasico.DTO';
import { TipoCambioEstadoPersonalDTO } from 'src/app/ModelDTO/DTO/tipoCambioEstadoPersonal.DTO';


export class PersonalCambioEstadoConAnteriorDTO implements IEntityDTO {

   estadoAnterior: PersonalCambioEstadoBasicoDTO;
   desde: Date;
   hasta: Date;
   observacion = '';
   litigio: boolean;
   litigioObservacion = '';
   litigioFecha: Date;
   tipo: TipoCambioEstadoPersonalDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.estadoAnterior = new PersonalCambioEstadoBasicoDTO();
      this.tipo = new TipoCambioEstadoPersonalDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.estadoAnterior != null) { this.estadoAnterior.PrepareDTO(jsonObj.estadoAnterior); }
      if (jsonObj.desde != null) { this.desde = new Date(jsonObj.desde); }
      if (jsonObj.hasta != null) { this.hasta = new Date(jsonObj.hasta); }
      if (jsonObj.observacion != null) { this.observacion = jsonObj.observacion; }
      if (jsonObj.litigio != null) { this.litigio = jsonObj.litigio; }
      if (jsonObj.litigioObservacion != null) { this.litigioObservacion = jsonObj.litigioObservacion; }
      if (jsonObj.litigioFecha != null) { this.litigioFecha = new Date(jsonObj.litigioFecha); }
      if (jsonObj.tipo != null) { this.tipo.PrepareDTO(jsonObj.tipo); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
