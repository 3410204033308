import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { AddresseesDTO } from 'src/app/ModelDTO/DTO/addressees.DTO';


export class AddresseesModelDTO extends EntityModelDTO<AddresseesDTO> {


   public constructor(protected entityDTO: AddresseesDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: AddresseesDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get TodaLaNomina(): boolean { return this.entityDTO.todaLaNomina; }
   set TodaLaNomina(value: boolean) { this.notifyChangeDTO('todaLaNomina', value); }

   get PersonalLegajos(): Array<number> { return this.entityDTO.personalLegajos; }
   set PersonalLegajos(value: Array<number>) { this.notifyChangeDTO('personalLegajos', value); }

   get Areas(): Array<number> { return this.entityDTO.areas; }
   set Areas(value: Array<number>) { this.notifyChangeDTO('areas', value); }

   get Sectores(): Array<number> { return this.entityDTO.sectores; }
   set Sectores(value: Array<number>) { this.notifyChangeDTO('sectores', value); }

   get ModalidadadesContractuales(): Array<number> { return this.entityDTO.modalidadadesContractuales; }
   set ModalidadadesContractuales(value: Array<number>) { this.notifyChangeDTO('modalidadadesContractuales', value); }

   get Offices(): Array<number> { return this.entityDTO.offices; }
   set Offices(value: Array<number>) { this.notifyChangeDTO('offices', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
