import { EstadoCivil } from 'src/app/ModelDTO/DTO/estadoCivil.ENUM';
import { RelationshipPositionEnum } from 'src/app/ModelDTO/DTO/relationshipPositionEnum.ENUM';
import { TipoDocumento } from 'src/app/ModelDTO/DTO/tipoDocumento.ENUM';
import { TipoGenero } from 'src/app/ModelDTO/DTO/tipoGenero.ENUM';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';


export class PersonalBasicoContratacionDTO implements IEntityDTO {

   cargo = '';
   area = '';
   sector = '';
   fechaIngreso: Date;
   activo: boolean;
   antiguedadAnterior = 0;
   legajo = '';
   legajoId = 0;
   businessNameId: number;
   antiguedad = 0;
   image = '';
   nombre = '';
   apellido = '';
   nombreCompleto = '';
   dni = '';
   tipoDocumento: TipoDocumento;
   fechaNacimiento: Date;
   genero: TipoGenero;
   cuil = '';
   telefonos = '';
   email = '';
   confirmarEmail: boolean;
   edad: number;
   peso: number;
   altura: number;
   circunferenciaCabeza: number;
   tallePantalon = '';
   talleCalzado = '';
   talleCamisa = '';
   movilidadPropia: boolean;
   disponibilidadTraslado: boolean;
   oficinaNombre = '';
   estadoCivil: EstadoCivil;
   familiaresACargo: number;
   ultimoSueldo: number;
   grupoSanguineo = '';
   nroCuenta = '';
   alias = '';
   relationshipPosition: RelationshipPositionEnum;
   id = 0;
   profileCompleteness = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.cargo != null) { this.cargo = jsonObj.cargo; }
      if (jsonObj.area != null) { this.area = jsonObj.area; }
      if (jsonObj.sector != null) { this.sector = jsonObj.sector; }
      if (jsonObj.fechaIngreso != null) { this.fechaIngreso = new Date(jsonObj.fechaIngreso); }
      if (jsonObj.activo != null) { this.activo = jsonObj.activo; }
      if (jsonObj.antiguedadAnterior != null) { this.antiguedadAnterior = jsonObj.antiguedadAnterior; }
      if (jsonObj.legajo != null) { this.legajo = jsonObj.legajo; }
      if (jsonObj.legajoId != null) { this.legajoId = jsonObj.legajoId; }
      if (jsonObj.businessNameId != null) { this.businessNameId = jsonObj.businessNameId; }
      if (jsonObj.antiguedad != null) { this.antiguedad = jsonObj.antiguedad; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.apellido != null) { this.apellido = jsonObj.apellido; }
      if (jsonObj.nombreCompleto != null) { this.nombreCompleto = jsonObj.nombreCompleto; }
      if (jsonObj.dni != null) { this.dni = jsonObj.dni; }
      if (jsonObj.tipoDocumento != null) { this.tipoDocumento = jsonObj.tipoDocumento as TipoDocumento; }
      if (jsonObj.fechaNacimiento != null) { this.fechaNacimiento = new Date(jsonObj.fechaNacimiento); }
      if (jsonObj.genero != null) { this.genero = jsonObj.genero as TipoGenero; }
      if (jsonObj.cuil != null) { this.cuil = jsonObj.cuil; }
      if (jsonObj.telefonos != null) { this.telefonos = jsonObj.telefonos; }
      if (jsonObj.email != null) { this.email = jsonObj.email; }
      if (jsonObj.confirmarEmail != null) { this.confirmarEmail = jsonObj.confirmarEmail; }
      if (jsonObj.edad != null) { this.edad = jsonObj.edad; }
      if (jsonObj.peso != null) { this.peso = jsonObj.peso; }
      if (jsonObj.altura != null) { this.altura = jsonObj.altura; }
      if (jsonObj.circunferenciaCabeza != null) { this.circunferenciaCabeza = jsonObj.circunferenciaCabeza; }
      if (jsonObj.tallePantalon != null) { this.tallePantalon = jsonObj.tallePantalon; }
      if (jsonObj.talleCalzado != null) { this.talleCalzado = jsonObj.talleCalzado; }
      if (jsonObj.talleCamisa != null) { this.talleCamisa = jsonObj.talleCamisa; }
      if (jsonObj.movilidadPropia != null) { this.movilidadPropia = jsonObj.movilidadPropia; }
      if (jsonObj.disponibilidadTraslado != null) { this.disponibilidadTraslado = jsonObj.disponibilidadTraslado; }
      if (jsonObj.oficinaNombre != null) { this.oficinaNombre = jsonObj.oficinaNombre; }
      if (jsonObj.estadoCivil != null) { this.estadoCivil = jsonObj.estadoCivil as EstadoCivil; }
      if (jsonObj.familiaresACargo != null) { this.familiaresACargo = jsonObj.familiaresACargo; }
      if (jsonObj.ultimoSueldo != null) { this.ultimoSueldo = jsonObj.ultimoSueldo; }
      if (jsonObj.grupoSanguineo != null) { this.grupoSanguineo = jsonObj.grupoSanguineo; }
      if (jsonObj.nroCuenta != null) { this.nroCuenta = jsonObj.nroCuenta; }
      if (jsonObj.alias != null) { this.alias = jsonObj.alias; }
      if (jsonObj.relationshipPosition != null) { this.relationshipPosition = jsonObj.relationshipPosition as RelationshipPositionEnum; }
      if (jsonObj.profileCompleteness != null) { this.profileCompleteness = jsonObj.profileCompleteness; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
