import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { AttachmentSignatureUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentSignatureUaaloo.DTO';
import { AttachmentSignatureUaalooModelDTO } from 'src/app/ModelDTO/attachmentSignatureUaaloo.ModelDTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { ConfiguracionFirmaDigitalPosicionModelDTO } from 'src/app/ModelDTO/configuracionFirmaDigitalPosicion.ModelDTO';
import { FileDBModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/fileDB.ModelDTO';
import { FileDBDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/fileDB.DTO';
import { UserBasicModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/userBasic.ModelDTO';
import { UserBasicDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/userBasic.DTO';
import { AttachmentType } from '@api/enums/attachment-type.enum';
import { AuthRequestModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/authRequest.ModelDTO';
import { AuthRequestDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authRequest.DTO';


export class AttachmentUaalooModelDTO extends EntityModelDTO<AttachmentUaalooDTO> {

   private creatorPersonalLegajoModel: PersonalLegajoBasicoModelDTO;
   private creatorPersonalLegajoSubscribe: Subscription;
   private authRequestModel: AuthRequestModelDTO;
   private authRequestSubscribe: Subscription;
   private posicionFirmaModel: ConfiguracionFirmaDigitalPosicionModelDTO;
   private posicionFirmaSubscribe: Subscription;
   private creatorUserModel: UserBasicModelDTO;
   private creatorUserSubscribe: Subscription;
   private fileModel: FileDBModelDTO;
   private fileSubscribe: Subscription;

   public constructor(protected entityDTO: AttachmentUaalooDTO) {
      super(entityDTO);
   }

   public static getAttachmentType(): string[] {
      return EntityModelDTO.getEnumArray(AttachmentType);
   }

   public setEntityDTO(entityDTO: AttachmentUaalooDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.creatorPersonalLegajoModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.creatorPersonalLegajo);
      this.creatorPersonalLegajoSubscribe = this.creatorPersonalLegajoModel.changed.subscribe((changed) => this.changed.next(changed));
      this.authRequestModel = new AuthRequestModelDTO(this.entityDTO.authRequest);
      this.authRequestSubscribe = this.authRequestModel.changed.subscribe((changed) => this.changed.next(changed));
      this.posicionFirmaModel = new ConfiguracionFirmaDigitalPosicionModelDTO(this.entityDTO.posicionFirma);
      this.posicionFirmaSubscribe = this.posicionFirmaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.creatorUserModel = new UserBasicModelDTO(this.entityDTO.creatorUser);
      this.creatorUserSubscribe = this.creatorUserModel.changed.subscribe((changed) => this.changed.next(changed));
      this.fileModel = new FileDBModelDTO(this.entityDTO.file);
      this.fileSubscribe = this.fileModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.creatorPersonalLegajoModel.dispose();
      this.creatorPersonalLegajoSubscribe.unsubscribe();
      this.authRequestModel.dispose();
      this.authRequestSubscribe.unsubscribe();
      this.posicionFirmaModel.dispose();
      this.posicionFirmaSubscribe.unsubscribe();
      this.creatorUserModel.dispose();
      this.creatorUserSubscribe.unsubscribe();
      this.fileModel.dispose();
      this.fileSubscribe.unsubscribe();
   }

   get Signatures(): Array<AttachmentSignatureUaalooDTO> { return this.entityDTO.signatures; }
   set Signatures(value: Array<AttachmentSignatureUaalooDTO>) { this.notifyChangeDTO('signatures', value); }

   get CreatorPersonalLegajoModel(): PersonalLegajoBasicoModelDTO { return this.creatorPersonalLegajoModel; }
   get CreatorPersonalLegajo(): PersonalLegajoBasicoDTO { return this.creatorPersonalLegajoModel.getEntityDTO(); }
   set CreatorPersonalLegajo(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.creatorPersonalLegajo = value; this.creatorPersonalLegajoModel.setEntityDTO(value); }); }

   get AuthRequestModel(): AuthRequestModelDTO { return this.authRequestModel; }
   get AuthRequest(): AuthRequestDTO { return this.authRequestModel.getEntityDTO(); }
   set AuthRequest(value: AuthRequestDTO) { this.notifyChange(() => { this.entityDTO.authRequest = value; this.authRequestModel.setEntityDTO(value); }); }

   get PosicionFirmaModel(): ConfiguracionFirmaDigitalPosicionModelDTO { return this.posicionFirmaModel; }
   get PosicionFirma(): ConfiguracionFirmaDigitalPosicionDTO { return this.posicionFirmaModel.getEntityDTO(); }
   set PosicionFirma(value: ConfiguracionFirmaDigitalPosicionDTO) { this.notifyChange(() => { this.entityDTO.posicionFirma = value; this.posicionFirmaModel.setEntityDTO(value); }); }

   get Type(): string { return AttachmentType[this.entityDTO.type]; }
   set Type(value: string) { this.notifyChangeDTO('type', AttachmentType[value]); }

   get CreatorUserModel(): UserBasicModelDTO { return this.creatorUserModel; }
   get CreatorUser(): UserBasicDTO { return this.creatorUserModel.getEntityDTO(); }
   set CreatorUser(value: UserBasicDTO) { this.notifyChange(() => { this.entityDTO.creatorUser = value; this.creatorUserModel.setEntityDTO(value); }); }

   get PosicionFija(): boolean { return this.entityDTO.posicionFija; }
   set PosicionFija(value: boolean) { this.notifyChangeDTO('posicionFija', value); }

   get Name(): string { return this.entityDTO.name; }
   set Name(value: string) { this.notifyChangeDTO('name', value); }

   get PublicUrl(): string { return this.entityDTO.publicUrl; }
   set PublicUrl(value: string) { this.notifyChangeDTO('publicUrl', value); }

   get FileModel(): FileDBModelDTO { return this.fileModel; }
   get File(): FileDBDTO { return this.fileModel.getEntityDTO(); }
   set File(value: FileDBDTO) { this.notifyChange(() => { this.entityDTO.file = value; this.fileModel.setEntityDTO(value); }); }

   get AllowsDigitalSignature(): boolean { return this.entityDTO.allowsDigitalSignature; }
   set AllowsDigitalSignature(value: boolean) { this.notifyChangeDTO('allowsDigitalSignature', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
