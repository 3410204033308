import { Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, filter, map, Observable, range, Subject, takeUntil, tap, timer } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { CHAT_WEBSOCKET_API_URL, CHAT_WEBSOCKET_PROTOCOL } from 'src/app/shared/constants';
import { CHAT_AUTH } from 'src/app/shared/localStorageConstants';
import {
  BackendResponse,
  ClockInQRResponse,
  MessageResponse,
  NewClockInResponse,
  NotificationResponse,
  ServiceChangeResponse,
} from 'src/app/app-common/chat/interfaces/chat-backend-response.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DateTime } from 'luxon';
import { AuthenticationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/auth/authentication.service';
import { AuthResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authResponse.DTO';

export interface WebSocketMessage {
  tenantId: string;
  channelId: string;
  userId: string;
  content: string;
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  #connected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public connected$: Observable<boolean> = this.#connected$.asObservable();

  public get shouldExecuteReconnect(): boolean {
    const minutesAfterDisconnect = this.lastDisconnectTime?.diffNow('minutes').minutes ?? Infinity;
    return Math.abs(minutesAfterDisconnect) >= 10;
  }

  // The WebSocket Subject is used internally for mantaining a single configuration
  // and a single point for sending messages.
  private webSocket$: WebSocketSubject<string>;
  // A message subject is used to manage parsed backend messages
  // and to discern between client messages and protocol/error messages
  private messages$: Subject<MessageResponse | NotificationResponse | ServiceChangeResponse | NewClockInResponse | ClockInQRResponse> =
    new Subject<MessageResponse | NotificationResponse | ServiceChangeResponse | NewClockInResponse | ClockInQRResponse>();

  private userId: string;
  private tenantId: string;

  private lastDisconnectTime: DateTime;

  private readonly maxReconnectAttempts = 5;
  private reconnectAbort$ = new Subject<void>();

  constructor(
    private authenticationService: AuthenticationService,
    private headerService: HeaderAppService,
  ) {
    authenticationService.loggedEvent$.pipe(takeUntilDestroyed()).subscribe((logData) => {
      if (logData?.token) {
        this.setupWebSocketConfig(logData);
        // Close preexisting resources if connected
        if (this.#connected$.getValue()) {
          this.webSocket$?.complete();
        }
        this.connect();
      } else {
        this.disconnect();
      }
    });

    this.headerService.loggedOut$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.disconnect();
      this.clearLastDisconnect();
      this.userId = '';
      this.tenantId = '';
    });
  }
  public sendMessage(message: WebSocketMessage): void {
    if (!this.#connected$.value) {
      this.connect();
    }
    const parsedMessage = JSON.stringify({
      action: 'sendMessage',
      ...message,
    });
    this.webSocket$.next(parsedMessage);
  }

  public subscribeToChannel(channel: string): Observable<BackendResponse> {
    return this.messages$.pipe(filter((msg) => msg.action === channel));
  }

  public reconnect(): void {
    if (!this.#connected$.value) {
      this.connect();
    }
  }

  public refreshToken(): void {
    this.requestJWT();
  }

  private clearLastDisconnect(): void {
    this.lastDisconnectTime = null;
  }

  private setupWebSocketConfig(authData: AuthResponseDTO): void {
    this.userId = authData.userName;
    this.tenantId = authData.tenant;

    this.webSocket$ = webSocket<string>({
      url: `${CHAT_WEBSOCKET_API_URL}?userId=${this.userId}&tenantId=${this.tenantId}`,
      protocol: [CHAT_WEBSOCKET_PROTOCOL, this.authenticationService.authResponseDTO?.token],
      openObserver: {
        next: () => {
          if (!this.authenticationService.chatAuth && localStorage.getItem(CHAT_AUTH)) {
            this.authenticationService.setChatAuth(JSON.parse(localStorage.getItem(CHAT_AUTH)));
            this.#connected$.next(true);
          } else {
            this.requestJWT();
          }
          this.reconnectReset();
        },
      },
      closeObserver: {
        next: () => {
          this.lastDisconnectTime = DateTime.now();
          this.scheduleReconnect();
        },
      },
      // override default serializers
      deserializer: (event) => event.data,
      serializer: (msg) => msg,
    });
  }

  private scheduleReconnect(): void {
    range(0, this.maxReconnectAttempts + 1)
      .pipe(
        map((attempt) => 2 ** attempt * 1000),
        concatMap((delay) => timer(delay)),
        tap({
          complete: () => console.error('websocket error: max amount of retries reached.'),
        }),
        takeUntil(this.reconnectAbort$),
      )
      .subscribe({
        next: () => {
          this.connect();
        },
      });
  }

  private reconnectReset(): void {
    this.reconnectAbort$.next();
  }

  private connect(): void {
    if (!this.authenticationService.authResponseDTO?.token) {
      this.#connected$.next(false);
      return;
    }

    // Subscribe to the websocket subject, therefore initiating the connection
    this.webSocket$.subscribe({
      next: (msg) => this.onWebSocketMsgReceived(JSON.parse(msg) as BackendResponse),
      // Error comes when establishing connection through websocket:
      // Missing or incorrect tenant or user data, websocket protocol data, or chat auth token
      error: () => {
        this.#connected$.next(false);
      },
      // Executed when connection is closed without error:
      // - Log out
      // - Idle disconnect
      complete: () => {
        this.#connected$.next(false);
      },
    });
  }

  private disconnect(): void {
    if (this.webSocket$ && !this.webSocket$.closed) {
      this.webSocket$.complete();
      this.#connected$.next(false);
    }
  }

  private requestJWT(): void {
    const messageJSON = JSON.stringify({
      action: 'Token',
      tenantId: this.tenantId,
      userId: this.userId,
    });

    this.webSocket$.next(messageJSON);
  }
  private saveJWT(token: string): void {
    const authData = { token, userId: this.userId, tenantId: this.tenantId };
    this.authenticationService.setChatAuth(authData);
    this.#connected$.next(true);
  }

  private onWebSocketMsgReceived(messageData: BackendResponse): void {
    switch (messageData.action) {
      case 'Message':
      case 'NewNotification':
      case 'ServiceChange':
      case 'ClockInQRToken':
      case 'NewClockIn':
        this.messages$.next(messageData);
        break;
      case 'Token':
        this.saveJWT(messageData.token);
        break;
      case 'Error':
        // Error comes from $default route:
        // - An error occured when soliciting JWT, the connection is closed
        this.disconnect();
        break;
    }
  }
}
