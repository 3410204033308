import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { BehaviorSubject } from 'rxjs';
import { PreguntaOpcionDTO } from 'src/app/ModelDTO/DTO/preguntaOpcion.DTO';

@Component({
  selector: 'app-list-options',
  templateUrl: './list-options.component.html',
  styleUrls: ['./list-options.component.scss'],
})
export class ListOptionsComponent {
  @Input() public options: PreguntaOpcionDTO[];
  @Output() public remove = new EventEmitter();
  @Output() public edit = new EventEmitter();
  public removeCmd: ICommand = new Command((value) => this.remove.emit(value), new BehaviorSubject(true), true);
  public editCmd: ICommand = new Command((value) => this.edit.emit(value), new BehaviorSubject(true), true);

  public displayedColumns: string[] = ['Opciones', 'Peso', 'Accion'];
}
