import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { InformacionDTO } from 'src/app/ModelDTO/DTO/informacion.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { AttachmentUaalooModelDTO } from 'src/app/ModelDTO/attachmentUaaloo.ModelDTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { CategoriaInformacionDTO } from 'src/app/ModelDTO/DTO/categoriaInformacion.DTO';
import { CategoriaInformacionModelDTO } from 'src/app/ModelDTO/categoriaInformacion.ModelDTO';
import { InformacionTipoDTO } from 'src/app/ModelDTO/DTO/informacionTipo.DTO';
import { InformacionTipoModelDTO } from 'src/app/ModelDTO/informacionTipo.ModelDTO';
import { TipoCategoriaDocumento } from 'src/app/ModelDTO/DTO/tipoCategoriaDocumento.ENUM';


export class InformacionModelDTO extends EntityModelDTO<InformacionDTO> {

   private personalModel: PersonalLegajoBasicoModelDTO;
   private personalSubscribe: Subscription;
   private creadorModel: PersonalLegajoBasicoModelDTO;
   private creadorSubscribe: Subscription;
   private editedByModel: PersonalLegajoBasicoModelDTO;
   private editedBySubscribe: Subscription;
   private categoriaModel: CategoriaInformacionModelDTO;
   private categoriaSubscribe: Subscription;
   private tipoModel: InformacionTipoModelDTO;
   private tipoSubscribe: Subscription;

   public constructor(protected entityDTO: InformacionDTO) {
      super(entityDTO);
   }

   public static getTipoCategoriaDocumento(): string[] {
      return EntityModelDTO.getEnumArray(TipoCategoriaDocumento);
   }

   public setEntityDTO(entityDTO: InformacionDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.personalModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.personal);
      this.personalSubscribe = this.personalModel.changed.subscribe((changed) => this.changed.next(changed));
      this.creadorModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.creador);
      this.creadorSubscribe = this.creadorModel.changed.subscribe((changed) => this.changed.next(changed));
      this.editedByModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.editedBy);
      this.editedBySubscribe = this.editedByModel.changed.subscribe((changed) => this.changed.next(changed));
      this.categoriaModel = new CategoriaInformacionModelDTO(this.entityDTO.categoria);
      this.categoriaSubscribe = this.categoriaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.tipoModel = new InformacionTipoModelDTO(this.entityDTO.tipo);
      this.tipoSubscribe = this.tipoModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.personalModel.dispose();
      this.personalSubscribe.unsubscribe();
      this.creadorModel.dispose();
      this.creadorSubscribe.unsubscribe();
      this.editedByModel.dispose();
      this.editedBySubscribe.unsubscribe();
      this.categoriaModel.dispose();
      this.categoriaSubscribe.unsubscribe();
      this.tipoModel.dispose();
      this.tipoSubscribe.unsubscribe();
   }

   get Documentacion(): Array<AttachmentUaalooDTO> { return this.entityDTO.documentacion; }
   set Documentacion(value: Array<AttachmentUaalooDTO>) { this.notifyChangeDTO('documentacion', value); }

   get PersonalModel(): PersonalLegajoBasicoModelDTO { return this.personalModel; }
   get Personal(): PersonalLegajoBasicoDTO { return this.personalModel.getEntityDTO(); }
   set Personal(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.personal = value; this.personalModel.setEntityDTO(value); }); }

   get CreadorModel(): PersonalLegajoBasicoModelDTO { return this.creadorModel; }
   get Creador(): PersonalLegajoBasicoDTO { return this.creadorModel.getEntityDTO(); }
   set Creador(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.creador = value; this.creadorModel.setEntityDTO(value); }); }

   get EditedByModel(): PersonalLegajoBasicoModelDTO { return this.editedByModel; }
   get EditedBy(): PersonalLegajoBasicoDTO { return this.editedByModel.getEntityDTO(); }
   set EditedBy(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.editedBy = value; this.editedByModel.setEntityDTO(value); }); }

   get Titulo(): string { return this.entityDTO.titulo; }
   set Titulo(value: string) { this.notifyChangeDTO('titulo', value); }

   get Cuerpo(): string { return this.entityDTO.cuerpo; }
   set Cuerpo(value: string) { this.notifyChangeDTO('cuerpo', value); }

   get FechaCreacion(): Date { return this.entityDTO.fechaCreacion; }
   set FechaCreacion(value: Date) { this.notifyChangeDTO('fechaCreacion', value); }

   get Period(): Date { return this.entityDTO.period; }
   set Period(value: Date) { this.notifyChangeDTO('period', value); }

   get CategoriaModel(): CategoriaInformacionModelDTO { return this.categoriaModel; }
   get Categoria(): CategoriaInformacionDTO { return this.categoriaModel.getEntityDTO(); }
   set Categoria(value: CategoriaInformacionDTO) { this.notifyChange(() => { this.entityDTO.categoria = value; this.categoriaModel.setEntityDTO(value); }); }

   get TipoModel(): InformacionTipoModelDTO { return this.tipoModel; }
   get Tipo(): InformacionTipoDTO { return this.tipoModel.getEntityDTO(); }
   set Tipo(value: InformacionTipoDTO) { this.notifyChange(() => { this.entityDTO.tipo = value; this.tipoModel.setEntityDTO(value); }); }

   get Visibilidad(): string { return TipoCategoriaDocumento[this.entityDTO.visibilidad]; }
   set Visibilidad(value: string) { this.notifyChangeDTO('visibilidad', TipoCategoriaDocumento[value]); }

   get Generated(): boolean { return this.entityDTO.generated; }
   set Generated(value: boolean) { this.notifyChangeDTO('generated', value); }

   get LastEdition(): Date { return this.entityDTO.lastEdition; }
   set LastEdition(value: Date) { this.notifyChangeDTO('lastEdition', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
