import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { PlanificacionDTO } from 'src/app/ModelDTO/DTO/planificacion.DTO';
import { TipoRecurrencia } from 'src/app/ModelDTO/DTO/tipoRecurrencia.ENUM';
import { AddresseesDTO } from 'src/app/ModelDTO/DTO/addressees.DTO';
import { AddresseesModelDTO } from 'src/app/ModelDTO/addressees.ModelDTO';
import { TipoPlanificacionMensual } from 'src/app/ModelDTO/DTO/tipoPlanificacionMensual.ENUM';
import { DayOfWeek } from 'src/app/ModelDTO/DTO/dayOfWeek.ENUM';


export class PlanificacionModelDTO extends EntityModelDTO<PlanificacionDTO> {

   private destinatariosModel: AddresseesModelDTO;
   private destinatariosSubscribe: Subscription;

   public constructor(protected entityDTO: PlanificacionDTO) {
      super(entityDTO);
   }

   public static getTipoRecurrencia(): string[] {
      return EntityModelDTO.getEnumArray(TipoRecurrencia);
   }

   public static getTipoPlanificacionMensual(): string[] {
      return EntityModelDTO.getEnumArray(TipoPlanificacionMensual);
   }

   public static getDayOfWeek(): string[] {
      return EntityModelDTO.getEnumArray(DayOfWeek);
   }

   public setEntityDTO(entityDTO: PlanificacionDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.destinatariosModel = new AddresseesModelDTO(this.entityDTO.destinatarios);
      this.destinatariosSubscribe = this.destinatariosModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.destinatariosModel.dispose();
      this.destinatariosSubscribe.unsubscribe();
   }

   get Tipo(): string { return TipoRecurrencia[this.entityDTO.tipo]; }
   set Tipo(value: string) { this.notifyChangeDTO('tipo', TipoRecurrencia[value]); }

   get DestinatariosModel(): AddresseesModelDTO { return this.destinatariosModel; }
   get Destinatarios(): AddresseesDTO { return this.destinatariosModel.getEntityDTO(); }
   set Destinatarios(value: AddresseesDTO) { this.notifyChange(() => { this.entityDTO.destinatarios = value; this.destinatariosModel.setEntityDTO(value); }); }

   get DiasVencimiento(): number { return this.entityDTO.diasVencimiento; }
   set DiasVencimiento(value: number) { this.notifyChangeDTO('diasVencimiento', value); }

   get FechaUnica(): Date { return this.entityDTO.fechaUnica; }
   set FechaUnica(value: Date) { this.notifyChangeDTO('fechaUnica', value); }

   get IncluirFeriados(): boolean { return this.entityDTO.incluirFeriados; }
   set IncluirFeriados(value: boolean) { this.notifyChangeDTO('incluirFeriados', value); }

   get FechaInicio(): Date { return this.entityDTO.fechaInicio; }
   set FechaInicio(value: Date) { this.notifyChangeDTO('fechaInicio', value); }

   get FechaFinRecurrencia(): Date { return this.entityDTO.fechaFinRecurrencia; }
   set FechaFinRecurrencia(value: Date) { this.notifyChangeDTO('fechaFinRecurrencia', value); }

   get IncluirSabado(): boolean { return this.entityDTO.incluirSabado; }
   set IncluirSabado(value: boolean) { this.notifyChangeDTO('incluirSabado', value); }

   get IncluirDomingo(): boolean { return this.entityDTO.incluirDomingo; }
   set IncluirDomingo(value: boolean) { this.notifyChangeDTO('incluirDomingo', value); }

   get Domingo(): boolean { return this.entityDTO.domingo; }
   set Domingo(value: boolean) { this.notifyChangeDTO('domingo', value); }

   get Lunes(): boolean { return this.entityDTO.lunes; }
   set Lunes(value: boolean) { this.notifyChangeDTO('lunes', value); }

   get Martes(): boolean { return this.entityDTO.martes; }
   set Martes(value: boolean) { this.notifyChangeDTO('martes', value); }

   get Miercoles(): boolean { return this.entityDTO.miercoles; }
   set Miercoles(value: boolean) { this.notifyChangeDTO('miercoles', value); }

   get Jueves(): boolean { return this.entityDTO.jueves; }
   set Jueves(value: boolean) { this.notifyChangeDTO('jueves', value); }

   get Viernes(): boolean { return this.entityDTO.viernes; }
   set Viernes(value: boolean) { this.notifyChangeDTO('viernes', value); }

   get Sabado(): boolean { return this.entityDTO.sabado; }
   set Sabado(value: boolean) { this.notifyChangeDTO('sabado', value); }

   get TipoPlanificacionMensual(): string { return TipoPlanificacionMensual[this.entityDTO.tipoPlanificacionMensual]; }
   set TipoPlanificacionMensual(value: string) { this.notifyChangeDTO('tipoPlanificacionMensual', TipoPlanificacionMensual[value]); }

   get DiaSemana(): string { return DayOfWeek[this.entityDTO.diaSemana]; }
   set DiaSemana(value: string) { this.notifyChangeDTO('diaSemana', DayOfWeek[value]); }

   get NumeroDiaSemana(): number { return this.entityDTO.numeroDiaSemana; }
   set NumeroDiaSemana(value: number) { this.notifyChangeDTO('numeroDiaSemana', value); }

   get DiaParticular(): number { return this.entityDTO.diaParticular; }
   set DiaParticular(value: number) { this.notifyChangeDTO('diaParticular', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
