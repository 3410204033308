import { PrivacyType } from 'src/app/ModelDTO/DTO/privacyType.ENUM';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';


export class PrivacyPolicyDTO implements IEntityDTO {

   type: PrivacyType;
   name = '';
   description = '';
   versionName = '';
   updateDate: Date;
   content = '';
   id = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.type != null) { this.type = jsonObj.type as PrivacyType; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.description != null) { this.description = jsonObj.description; }
      if (jsonObj.versionName != null) { this.versionName = jsonObj.versionName; }
      if (jsonObj.updateDate != null) { this.updateDate = new Date(jsonObj.updateDate); }
      if (jsonObj.content != null) { this.content = jsonObj.content; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
