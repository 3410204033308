import { HttpEvent } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { Observable, Subscription, throwError, timer } from 'rxjs';
import { b64DecodeUnicode } from 'src/app/shared/lib/ngx-neo-components-mat/shared/functions';

@Injectable()
export class NgxNeoLoaderService {
  //TODO: mejor manejo para que cada loader contemple si debe bloquear la ui
  private $loaders = signal<number[]>([]);
  private timerMap = new Map<number, Subscription>();
  private loaderCounter = 0;

  private $UIblocked = signal<boolean>(false);
  public $isLoading = computed(() => this.$loaders().length > 0);
  public $blockUI = this.$UIblocked.asReadonly();

  public show(delay?: number, blockUI = true): number | null {
    if (!delay) {
      this.$loaders.update((loaders) => [...loaders, ++this.loaderCounter]);
      return null;
    }

    const id = ++this.loaderCounter;
    const sub = timer(delay).subscribe(() => {
      this.$loaders.update((loaders) => [...loaders, id]);
      this.timerMap.delete(id);
      this.$UIblocked.set(blockUI);
    });

    this.timerMap.set(id, sub);
    return id;
  }

  public hide(id?: number): void {
    if (id && this.timerMap.has(id)) {
      this.timerMap.get(id)?.unsubscribe();
      this.timerMap.delete(id);
    } else {
      this.$loaders.update((loaders) => loaders.slice(1));
    }
  }

  public onCatch(error: any, caught: Observable<HttpEvent<any>>, loaderSub?: number): Observable<any> {
    this.hide(loaderSub);
    const neoError = error.headers ? error.headers.get('x-neo-error') : null;
    let modifiedError = { ...error };
    if (neoError && (!error.statusText || error.statusText === '' || error.statusText === 'Bad Request' || error.statusText === 'OK')) {
      modifiedError = { ...modifiedError, statusText: neoError };
    }
    if (modifiedError?.statusText?.startsWith('data:txt;base64,') ?? false) {
      modifiedError = { ...modifiedError, statusText: b64DecodeUnicode(modifiedError.statusText.substring(16)) };
    }

    return throwError(() => modifiedError);
  }
}
