import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { TipoCuestionarioPregunta } from 'src/app/ModelDTO/DTO/tipoCuestionarioPregunta.ENUM';
import { CuestionarioRespuestaDTO } from 'src/app/ModelDTO/DTO/cuestionarioRespuesta.DTO';
import { PreguntaOpcionDTO } from 'src/app/ModelDTO/DTO/preguntaOpcion.DTO';
import { QuestionCompetenceDTO } from 'src/app/ModelDTO/DTO/questionCompetence.DTO';


export class CuestionarioPreguntaDTO implements IEntityDTO {

   tipo: TipoCuestionarioPregunta;
   texto = '';
   respuesta: CuestionarioRespuestaDTO;
   pesoSinTildar = 0;
   pesoTildado = 0;
   multiSelect: boolean;
   opciones: Array<PreguntaOpcionDTO>;
   min: number;
   max: number;
   preguntas: Array<CuestionarioPreguntaDTO>;
   questionCompetence: QuestionCompetenceDTO;
   obligatoria: boolean;
   position = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.respuesta = new CuestionarioRespuestaDTO();
      this.opciones = new Array<PreguntaOpcionDTO>();
      this.preguntas = new Array<CuestionarioPreguntaDTO>();
      this.questionCompetence = new QuestionCompetenceDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.tipo != null) { this.tipo = jsonObj.tipo as TipoCuestionarioPregunta; }
      if (jsonObj.texto != null) { this.texto = jsonObj.texto; }
      if (jsonObj.respuesta != null) { this.respuesta.PrepareDTO(jsonObj.respuesta); }
      if (jsonObj.pesoSinTildar != null) { this.pesoSinTildar = jsonObj.pesoSinTildar; }
      if (jsonObj.pesoTildado != null) { this.pesoTildado = jsonObj.pesoTildado; }
      if (jsonObj.multiSelect != null) { this.multiSelect = jsonObj.multiSelect; }
      if (jsonObj.opciones != null) { for (const item of jsonObj.opciones) { const itemDTO = new PreguntaOpcionDTO(); itemDTO.PrepareDTO(item); this.opciones.push(itemDTO); } }
      if (jsonObj.min != null) { this.min = jsonObj.min; }
      if (jsonObj.max != null) { this.max = jsonObj.max; }
      if (jsonObj.preguntas != null) { for (const item of jsonObj.preguntas) { const itemDTO = new CuestionarioPreguntaDTO(); itemDTO.PrepareDTO(item); this.preguntas.push(itemDTO); } }
      if (jsonObj.questionCompetence != null) { this.questionCompetence.PrepareDTO(jsonObj.questionCompetence); }
      if (jsonObj.obligatoria != null) { this.obligatoria = jsonObj.obligatoria; }
      if (jsonObj.position != null) { this.position = jsonObj.position; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
