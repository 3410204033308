import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { AttachmentSignatureUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentSignatureUaaloo.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { AuthRequestDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authRequest.DTO';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { FileDBDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/fileDB.DTO';
import { UserBasicDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/userBasic.DTO';
import { AttachmentType } from '@api/enums/attachment-type.enum';


export class AttachmentUaalooDTO implements IEntityDTO {

   signatures: Array<AttachmentSignatureUaalooDTO>;
   creatorPersonalLegajo: PersonalLegajoBasicoDTO;
   authRequest: AuthRequestDTO;
   posicionFirma: ConfiguracionFirmaDigitalPosicionDTO;
   type: AttachmentType;
   creatorUser: UserBasicDTO;
   posicionFija: boolean;
   name = '';
   publicUrl = '';
   file: FileDBDTO;
   allowsDigitalSignature: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.signatures = new Array<AttachmentSignatureUaalooDTO>();
      this.creatorPersonalLegajo = new PersonalLegajoBasicoDTO();
      this.authRequest = new AuthRequestDTO();
      this.posicionFirma = new ConfiguracionFirmaDigitalPosicionDTO();
      this.creatorUser = new UserBasicDTO();
      this.file = new FileDBDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.signatures != null) { for (const item of jsonObj.signatures) { const itemDTO = new AttachmentSignatureUaalooDTO(); itemDTO.PrepareDTO(item); this.signatures.push(itemDTO); } }
      if (jsonObj.creatorPersonalLegajo != null) { this.creatorPersonalLegajo.PrepareDTO(jsonObj.creatorPersonalLegajo); }
      if (jsonObj.authRequest != null) { this.authRequest.PrepareDTO(jsonObj.authRequest); }
      if (jsonObj.posicionFirma != null) { this.posicionFirma.PrepareDTO(jsonObj.posicionFirma); }
      if (jsonObj.type != null) { this.type = jsonObj.type as AttachmentType; }
      if (jsonObj.creatorUser != null) { this.creatorUser.PrepareDTO(jsonObj.creatorUser); }
      if (jsonObj.posicionFija != null) { this.posicionFija = jsonObj.posicionFija; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.publicUrl != null) { this.publicUrl = jsonObj.publicUrl; }
      if (jsonObj.file != null) { this.file.PrepareDTO(jsonObj.file); }
      if (jsonObj.allowsDigitalSignature != null) { this.allowsDigitalSignature = jsonObj.allowsDigitalSignature; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
