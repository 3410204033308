import { UserLanguage } from '@api/enums/user-language.enum';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';


export class PaisModelDTO extends EntityModelDTO<PaisDTO> {


   public constructor(protected entityDTO: PaisDTO) {
      super(entityDTO);
   }

   public static getUserLanguage(): string[] {
      return EntityModelDTO.getEnumArray(UserLanguage);
   }

   public setEntityDTO(entityDTO: PaisDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Nacionalidad(): string { return this.entityDTO.nacionalidad; }
   set Nacionalidad(value: string) { this.notifyChangeDTO('nacionalidad', value); }

   get Iso(): string { return this.entityDTO.iso; }
   set Iso(value: string) { this.notifyChangeDTO('iso', value); }

   get OfficialLanguage(): string { return UserLanguage[this.entityDTO.officialLanguage]; }
   set OfficialLanguage(value: string) { this.notifyChangeDTO('officialLanguage', UserLanguage[value]); }

   get MaskTaxId(): string { return this.entityDTO.maskTaxId; }
   set MaskTaxId(value: string) { this.notifyChangeDTO('maskTaxId', value); }

   get ValidatorPatternTaxId(): string { return this.entityDTO.validatorPatternTaxId; }
   set ValidatorPatternTaxId(value: string) { this.notifyChangeDTO('validatorPatternTaxId', value); }

   get ValidateTaxId(): boolean { return this.entityDTO.validateTaxId; }
   set ValidateTaxId(value: boolean) { this.notifyChangeDTO('validateTaxId', value); }

   get EmploeeTaxIdRequired(): boolean { return this.entityDTO.emploeeTaxIdRequired; }
   set EmploeeTaxIdRequired(value: boolean) { this.notifyChangeDTO('emploeeTaxIdRequired', value); }

   get MaskId(): string { return this.entityDTO.maskId; }
   set MaskId(value: string) { this.notifyChangeDTO('maskId', value); }

   get ValidatorPatternId(): string { return this.entityDTO.validatorPatternId; }
   set ValidatorPatternId(value: string) { this.notifyChangeDTO('validatorPatternId', value); }

   get ValidateId(): boolean { return this.entityDTO.validateId; }
   set ValidateId(value: boolean) { this.notifyChangeDTO('validateId', value); }

   get PassportMaskId(): string { return this.entityDTO.passportMaskId; }
   set PassportMaskId(value: string) { this.notifyChangeDTO('passportMaskId', value); }

   get ValidatorPatternPassportId(): string { return this.entityDTO.validatorPatternPassportId; }
   set ValidatorPatternPassportId(value: string) { this.notifyChangeDTO('validatorPatternPassportId', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
