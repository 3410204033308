import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { PersonalSubcategoriaDTO } from 'src/app/ModelDTO/DTO/personalSubcategoria.DTO';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';


export class PersonalCategoriaDTO implements IEntityDTO {

   subcategorias: Array<PersonalSubcategoriaDTO>;
   nombre = '';
   descripcion = '';
   convenio: ConvenioColectivoDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.subcategorias = new Array<PersonalSubcategoriaDTO>();
      this.convenio = new ConvenioColectivoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.subcategorias != null) { for (const item of jsonObj.subcategorias) { const itemDTO = new PersonalSubcategoriaDTO(); itemDTO.PrepareDTO(item); this.subcategorias.push(itemDTO); } }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.convenio != null) { this.convenio.PrepareDTO(jsonObj.convenio); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
