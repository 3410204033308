import { Subscription } from 'rxjs';
import { CuestionarioModelDTO } from 'src/app/ModelDTO/cuestionario.ModelDTO';
import { CuestionarioDTO } from 'src/app/ModelDTO/DTO/cuestionario.DTO';
import { CuestionarioRespuestaDTO } from 'src/app/ModelDTO/DTO/cuestionarioRespuesta.DTO';
import { EncuestaDTO } from 'src/app/ModelDTO/DTO/encuesta.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoCuestionarioDTO } from 'src/app/ModelDTO/DTO/personalLegajoCuestionario.DTO';
import { TipoEstadoPersonalLegajoCuestinario } from 'src/app/ModelDTO/DTO/tipoEstadoPersonalLegajoCuestinario.ENUM';
import { EncuestaModelDTO } from 'src/app/ModelDTO/encuesta.ModelDTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { CompetenceEvaluationModelDTO } from 'src/app/ModelDTO/competenceEvaluation.ModelDTO';
import { LevelRangeModelDTO } from 'src/app/ModelDTO/levelRange.ModelDTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { CompetenceEvaluationDTO } from 'src/app/ModelDTO/DTO/competenceEvaluation.DTO';


export class PersonalLegajoCuestionarioModelDTO extends EntityModelDTO<PersonalLegajoCuestionarioDTO> {

   private cuestionarioModel: CuestionarioModelDTO;
   private cuestionarioSubscribe: Subscription;
   private encuestaModel: EncuestaModelDTO;
   private encuestaSubscribe: Subscription;
   private evaluatedModel: PersonalLegajoBasicoModelDTO;
   private evaluatedSubscribe: Subscription;
   private resultadoModel: LevelRangeModelDTO;
   private resultadoSubscribe: Subscription;
   private lastSelfEvaluationModel: CompetenceEvaluationModelDTO;
   private lastSelfEvaluationSubscribe: Subscription;
   private personalLegajoModel: PersonalLegajoBasicoModelDTO;
   private personalLegajoSubscribe: Subscription;
   private creadorModel: PersonalLegajoBasicoModelDTO;
   private creadorSubscribe: Subscription;

   public constructor(protected entityDTO: PersonalLegajoCuestionarioDTO) {
      super(entityDTO);
   }

   public static getTipoEstadoPersonalLegajoCuestinario(): string[] {
      return EntityModelDTO.getEnumArray(TipoEstadoPersonalLegajoCuestinario);
   }

    protected getStringFromTipoEstadoPersonalLegajoCuestinario(enums: TipoEstadoPersonalLegajoCuestinario): Array<string> {
        if (enums) {
            const arrays = new Array<string>();
            for (let i = 0; i <= 31; i = i++) {
                const pow = Math.pow(2, i);
                if ((enums & pow) !== 0) {
                    arrays.push(TipoEstadoPersonalLegajoCuestinario[pow]);
                }
            }
            return arrays;
        } else {
            return undefined;
        }
    }

    protected getFlagFromTipoEstadoPersonalLegajoCuestinarioString(strings: Array<string>): TipoEstadoPersonalLegajoCuestinario {
        let flags: TipoEstadoPersonalLegajoCuestinario;
        strings.forEach(element => {
            const enumVal: TipoEstadoPersonalLegajoCuestinario = TipoEstadoPersonalLegajoCuestinario[element];
            flags |= enumVal;
        });
        return flags;
    }

   public setEntityDTO(entityDTO: PersonalLegajoCuestionarioDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.cuestionarioModel = new CuestionarioModelDTO(this.entityDTO.cuestionario);
      this.cuestionarioSubscribe = this.cuestionarioModel.changed.subscribe((changed) => this.changed.next(changed));
      this.encuestaModel = new EncuestaModelDTO(this.entityDTO.encuesta);
      this.encuestaSubscribe = this.encuestaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.evaluatedModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.evaluated);
      this.evaluatedSubscribe = this.evaluatedModel.changed.subscribe((changed) => this.changed.next(changed));
      this.resultadoModel = new LevelRangeModelDTO(this.entityDTO.resultado);
      this.resultadoSubscribe = this.resultadoModel.changed.subscribe((changed) => this.changed.next(changed));
      this.lastSelfEvaluationModel = new CompetenceEvaluationModelDTO(this.entityDTO.lastSelfEvaluation);
      this.lastSelfEvaluationSubscribe = this.lastSelfEvaluationModel.changed.subscribe((changed) => this.changed.next(changed));
      this.personalLegajoModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.personalLegajo);
      this.personalLegajoSubscribe = this.personalLegajoModel.changed.subscribe((changed) => this.changed.next(changed));
      this.creadorModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.creador);
      this.creadorSubscribe = this.creadorModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.cuestionarioModel.dispose();
      this.cuestionarioSubscribe.unsubscribe();
      this.encuestaModel.dispose();
      this.encuestaSubscribe.unsubscribe();
      this.evaluatedModel.dispose();
      this.evaluatedSubscribe.unsubscribe();
      this.resultadoModel.dispose();
      this.resultadoSubscribe.unsubscribe();
      this.lastSelfEvaluationModel.dispose();
      this.lastSelfEvaluationSubscribe.unsubscribe();
      this.personalLegajoModel.dispose();
      this.personalLegajoSubscribe.unsubscribe();
      this.creadorModel.dispose();
      this.creadorSubscribe.unsubscribe();
   }

   get CuestionarioModel(): CuestionarioModelDTO { return this.cuestionarioModel; }
   get Cuestionario(): CuestionarioDTO { return this.cuestionarioModel.getEntityDTO(); }
   set Cuestionario(value: CuestionarioDTO) { this.notifyChange(() => { this.entityDTO.cuestionario = value; this.cuestionarioModel.setEntityDTO(value); }); }

   get EncuestaModel(): EncuestaModelDTO { return this.encuestaModel; }
   get Encuesta(): EncuestaDTO { return this.encuestaModel.getEntityDTO(); }
   set Encuesta(value: EncuestaDTO) { this.notifyChange(() => { this.entityDTO.encuesta = value; this.encuestaModel.setEntityDTO(value); }); }

   get EvaluatedModel(): PersonalLegajoBasicoModelDTO { return this.evaluatedModel; }
   get Evaluated(): PersonalLegajoBasicoDTO { return this.evaluatedModel.getEntityDTO(); }
   set Evaluated(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.evaluated = value; this.evaluatedModel.setEntityDTO(value); }); }

   get Respuestas(): Array<CuestionarioRespuestaDTO> { return this.entityDTO.respuestas; }
   set Respuestas(value: Array<CuestionarioRespuestaDTO>) { this.notifyChangeDTO('respuestas', value); }

   get DiasVencimiento(): number { return this.entityDTO.diasVencimiento; }
   set DiasVencimiento(value: number) { this.notifyChangeDTO('diasVencimiento', value); }

   get Total(): number { return this.entityDTO.total; }
   set Total(value: number) { this.notifyChangeDTO('total', value); }

   get ResultadoModel(): LevelRangeModelDTO { return this.resultadoModel; }
   get Resultado(): LevelRangeDTO { return this.resultadoModel.getEntityDTO(); }
   set Resultado(value: LevelRangeDTO) { this.notifyChange(() => { this.entityDTO.resultado = value; this.resultadoModel.setEntityDTO(value); }); }

   get LastSelfEvaluationModel(): CompetenceEvaluationModelDTO { return this.lastSelfEvaluationModel; }
   get LastSelfEvaluation(): CompetenceEvaluationDTO { return this.lastSelfEvaluationModel.getEntityDTO(); }
   set LastSelfEvaluation(value: CompetenceEvaluationDTO) { this.notifyChange(() => { this.entityDTO.lastSelfEvaluation = value; this.lastSelfEvaluationModel.setEntityDTO(value); }); }


   get PersonalLegajoModel(): PersonalLegajoBasicoModelDTO { return this.personalLegajoModel; }
   get PersonalLegajo(): PersonalLegajoBasicoDTO { return this.personalLegajoModel.getEntityDTO(); }
   set PersonalLegajo(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.personalLegajo = value; this.personalLegajoModel.setEntityDTO(value); }); }

   get Estado(): string { return TipoEstadoPersonalLegajoCuestinario[this.entityDTO.estado]; }
   set Estado(value: string) { this.notifyChangeDTO('estado', TipoEstadoPersonalLegajoCuestinario[value]); }

   get EstadoArray(): Array<string> { return this.getStringFromTipoEstadoPersonalLegajoCuestinario(this.entityDTO.estado); }
   set EstadoArray(value: Array<string>) { this.notifyChangeDTO('estado', this.getFlagFromTipoEstadoPersonalLegajoCuestinarioString(value)); }

   get FechaCreacion(): Date { return this.entityDTO.fechaCreacion; }
   set FechaCreacion(value: Date) { this.notifyChangeDTO('fechaCreacion', value); }

   get FechaRespuesta(): Date { return this.entityDTO.fechaRespuesta; }
   set FechaRespuesta(value: Date) { this.notifyChangeDTO('fechaRespuesta', value); }

   get CreadorModel(): PersonalLegajoBasicoModelDTO { return this.creadorModel; }
   get Creador(): PersonalLegajoBasicoDTO { return this.creadorModel.getEntityDTO(); }
   set Creador(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.creador = value; this.creadorModel.setEntityDTO(value); }); }

   get Firma(): string { return this.entityDTO.firma; }
   set Firma(value: string) { this.notifyChangeDTO('firma', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
