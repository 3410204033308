<div class="d-flex flex-row justify-content-between m-0">
  <p>
    Estado actual: <strong>{{ estadoAnterior | splitBeauty: '_' }}</strong>
  </p>
</div>
<div class="d-flex flex-row justify-content-between mt-2 align-items-center gap-1">
  <mat-form-field class="flex-fill">
    <mat-label>Nuevo estado</mat-label>
    <mat-select [(ngModel)]="entregaModel.Estado">
      <mat-option *ngFor="let estado of estadosEntrega" [value]="estado"> {{ estado | splitBeauty: '_' }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
