import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { CapacitacionAgrupadaDTO } from 'src/app/ModelDTO/DTO/capacitacionAgrupada.DTO';
import { PersonalCapacitacionDTO } from 'src/app/ModelDTO/DTO/personalCapacitacion.DTO';

@Injectable({
   providedIn: 'root'
})
export class PersonalCapacitacionServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getCapacitacionIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/capacitacion/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getCapacitacionAgrupadas(tipoAgrupamiento: number, estado: number = 2): Promise<Array<CapacitacionAgrupadaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/capacitacion/agrupadas' + '?tipoAgrupamiento=' + tipoAgrupamiento + '&estado=' + estado));
         const resJson = res.data;
         const resDTO = new Array<CapacitacionAgrupadaDTO>();
         for (const item of resJson) {
            const itemDTO = new CapacitacionAgrupadaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async updateCapacitacionIdCambioEstado(id: number, personalCapacitacionDTO: PersonalCapacitacionDTO): Promise<void> {

         await lastValueFrom(this.http.put(Constants.apiURL + '/capacitacion/' + id + '/cambioEstado', personalCapacitacionDTO));

   }

   public async insertCapacitacionMasivoIdCategoriaIdSubategoria(idCategoria: number, idSubategoria: number, personalCapacitacionDTO: PersonalCapacitacionDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/capacitacion/masivo/' + idCategoria + '/' + idSubategoria, personalCapacitacionDTO));

   }

   public async insertCapacitacionAreaMasivoIdAreaIdSector(idArea: number, idSector: number, personalCapacitacionDTO: PersonalCapacitacionDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/capacitacion/area/masivo/' + idArea + '/' + idSector, personalCapacitacionDTO));

   }

   public async insertCapacitacionCargoMasivoIdCargo(idCargo: number, personalCapacitacionDTO: PersonalCapacitacionDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/capacitacion/cargo/masivo/' + idCargo, personalCapacitacionDTO));

   }

   public async insertCapacitacionPersonalIDPersonal(iDPersonal: number, personalCapacitacionDTO: FormData): Promise<PersonalCapacitacionDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/capacitacion/personal/' + iDPersonal, personalCapacitacionDTO));
         const resDTO = new PersonalCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getCapacitacionPersonal(fechaDesde: Date, fechaHasta: Date, estado: number = 0, idPersonal: number = 0, idTipoCapacitacion: number = 0, sinRechazos: boolean = false): Promise<Array<PersonalCapacitacionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/capacitacion/personal' + '?fechaDesde=' + fechaDesde.toISOString() + '&fechaHasta=' + fechaHasta.toISOString() + '&estado=' + estado + '&idPersonal=' + idPersonal + '&idTipoCapacitacion=' + idTipoCapacitacion + '&sinRechazos=' + sinRechazos));
         const resJson = res.data;
         const resDTO = new Array<PersonalCapacitacionDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalCapacitacionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getCapacitacionPersonalXLSX(fechaDesde: Date, fechaHasta: Date, estado: number = 0, idPersonal: number = 0, idTipoCapacitacion: number = 0, sinRechazos: boolean = false, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/capacitacion/personal' + '?fechaDesde=' + fechaDesde.toISOString() + '&fechaHasta=' + fechaHasta.toISOString() + '&estado=' + estado + '&idPersonal=' + idPersonal + '&idTipoCapacitacion=' + idTipoCapacitacion + '&sinRechazos=' + sinRechazos + '&BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/text' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async deleteCapacitacionIdPersonalIDPersonal(id: number, iDPersonal: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/capacitacion/' + id + '/personal/' + iDPersonal));

   }

}
