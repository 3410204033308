import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { LocalidadDTO } from 'src/app/ModelDTO/DTO/localidad.DTO';


export class DomicilioDTO implements IEntityDTO {

   direccion = '';
   localidad: LocalidadDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.localidad = new LocalidadDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.direccion != null) { this.direccion = jsonObj.direccion; }
      if (jsonObj.localidad != null) { this.localidad.PrepareDTO(jsonObj.localidad); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
