import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { ProvinciaDTO } from 'src/app/ModelDTO/DTO/provincia.DTO';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { PaisModelDTO } from 'src/app/ModelDTO/pais.ModelDTO';


export class ProvinciaModelDTO extends EntityModelDTO<ProvinciaDTO> {

   private paisModel: PaisModelDTO;
   private paisSubscribe: Subscription;

   public constructor(protected entityDTO: ProvinciaDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: ProvinciaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.paisModel = new PaisModelDTO(this.entityDTO.pais);
      this.paisSubscribe = this.paisModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.paisModel.dispose();
      this.paisSubscribe.unsubscribe();
   }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get PaisModel(): PaisModelDTO { return this.paisModel; }
   get Pais(): PaisDTO { return this.paisModel.getEntityDTO(); }
   set Pais(value: PaisDTO) { this.notifyChange(() => { this.entityDTO.pais = value; this.paisModel.setEntityDTO(value); }); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
