import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';
import { DomicilioDTO } from 'src/app/ModelDTO/DTO/domicilio.DTO';
import { DomicilioModelDTO } from 'src/app/ModelDTO/domicilio.ModelDTO';


export class OficinaModelDTO extends EntityModelDTO<OficinaDTO> {

   private domicilioModel: DomicilioModelDTO;
   private domicilioSubscribe: Subscription;

   public constructor(protected entityDTO: OficinaDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: OficinaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.domicilioModel = new DomicilioModelDTO(this.entityDTO.domicilio);
      this.domicilioSubscribe = this.domicilioModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.domicilioModel.dispose();
      this.domicilioSubscribe.unsubscribe();
   }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get IdentificadorFiscal(): string { return this.entityDTO.identificadorFiscal; }
   set IdentificadorFiscal(value: string) { this.notifyChangeDTO('identificadorFiscal', value); }

   get Telefonos(): string { return this.entityDTO.telefonos; }
   set Telefonos(value: string) { this.notifyChangeDTO('telefonos', value); }

   get DomicilioModel(): DomicilioModelDTO { return this.domicilioModel; }
   get Domicilio(): DomicilioDTO { return this.domicilioModel.getEntityDTO(); }
   set Domicilio(value: DomicilioDTO) { this.notifyChange(() => { this.entityDTO.domicilio = value; this.domicilioModel.setEntityDTO(value); }); }

   get Latitud(): number { return this.entityDTO.latitud; }
   set Latitud(value: number) { this.notifyChangeDTO('latitud', value); }

   get Longitud(): number { return this.entityDTO.longitud; }
   set Longitud(value: number) { this.notifyChangeDTO('longitud', value); }

   get Rango(): number { return this.entityDTO.rango; }
   set Rango(value: number) { this.notifyChangeDTO('rango', value); }

   get CoordenadaExtremoIzquierdoSuperior(): string { return this.entityDTO.coordenadaExtremoIzquierdoSuperior; }
   set CoordenadaExtremoIzquierdoSuperior(value: string) { this.notifyChangeDTO('coordenadaExtremoIzquierdoSuperior', value); }

   get CoordenadaExtremoDerechoInferior(): string { return this.entityDTO.coordenadaExtremoDerechoInferior; }
   set CoordenadaExtremoDerechoInferior(value: string) { this.notifyChangeDTO('coordenadaExtremoDerechoInferior', value); }

   get CantidadColaboradores(): number { return this.entityDTO.cantidadColaboradores; }
   set CantidadColaboradores(value: number) { this.notifyChangeDTO('cantidadColaboradores', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
