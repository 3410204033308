import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';


export class ConvenioColectivoModelDTO extends EntityModelDTO<ConvenioColectivoDTO> {


   public constructor(protected entityDTO: ConvenioColectivoDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: ConvenioColectivoDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get Codigo(): string { return this.entityDTO.codigo; }
   set Codigo(value: string) { this.notifyChangeDTO('codigo', value); }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get NombreCompleto(): string { return this.entityDTO.nombreCompleto; }
   set NombreCompleto(value: string) { this.notifyChangeDTO('nombreCompleto', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get FechaAniversario(): Date { return this.entityDTO.fechaAniversario; }
   set FechaAniversario(value: Date) { this.notifyChangeDTO('fechaAniversario', value); }

   get HorasSemanales(): number { return this.entityDTO.horasSemanales; }
   set HorasSemanales(value: number) { this.notifyChangeDTO('horasSemanales', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
