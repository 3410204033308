import { BehaviorSubject, Subject } from 'rxjs';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { Inject } from '@angular/core';
import { FrontEndConfigService, FrontEndConfig } from '../../FrontendConfig';
import { CompleterItem } from 'src/app/shared/lib/ngx-neo-completer-mat/components/completer-item';
import { CompleterData } from 'src/app/shared/lib/ngx-neo-completer-mat/services/completer-data';

export abstract class BaseEntitiesSearch extends Subject<CompleterItem[]> implements CompleterData {
  public searchCmd: ICommand = new Command(
    (value) => this.getEntitiesSearch(value),
    new BehaviorSubject(true),
    true,
    this.Constants.delaySearchMilliseconds,
  );

  constructor(@Inject(FrontEndConfigService) protected Constants: FrontEndConfig) {
    super();
  }
  public search(term: string): void {
    this.searchCmd.execute(term);
  }

  public abstract getEntitiesSearch(startWith: string): Promise<void>;

  public cancel(): void {
    return;
  }
}
