import { Injectable } from '@angular/core';
import { EstadoAprobacionSolicitud } from '@api/enums/estado-aprobacion-solicitud.enum';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { ListadoDeEntregasService } from 'src/app/layout/administrador/administrador-entregas/listado-de-entregas.service';
import { PersonalEntregaDTO } from 'src/app/ModelDTO/DTO/personalEntrega.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { TipoEntregaDTO } from 'src/app/ModelDTO/DTO/tipoEntrega.DTO';

@Injectable({
  providedIn: 'root',
})
export class UserEntregasViewModel {
  public desde: Date;
  public hasta: Date;
  public userSelected: PersonalLegajoBasicoDTO;
  public usuarioString: string;
  public entregasFiltradas: PersonalEntregaDTO[];
  public estadoSolicitud: string;
  public estadoEntrega: string;
  public pageNumber: number;
  public pageSize = 50;

  public tiposDeEntrega: Array<TipoEntregaDTO>;
  public tipoDeEntrega: TipoEntregaDTO;

  constructor(
    private headerService: HeaderAppService,
    private listadoDeEntregasService: ListadoDeEntregasService,
  ) {
    this.initViewModel();

    this.headerService.loggedOut$.subscribe((data) => {
      if (data) {
        this.initViewModel();
      }
    });
  }

  public initViewModel(): void {
    this.pageNumber = 0;
    this.estadoSolicitud = EstadoAprobacionSolicitud[EstadoAprobacionSolicitud.No_definido];
    this.userSelected = new PersonalLegajoBasicoDTO();
    this.desde = new Date();
    this.hasta = new Date();
    this.desde.setMonth(this.desde.getMonth() - 3);
    this.hasta.setMonth(this.hasta.getMonth() + 3);
    this.tipoDeEntrega = new TipoEntregaDTO();
    this.userSelected.id = this.headerService.personalLegajoId;
  }

  public async filtrar(): Promise<void> {
    if (this.desde && this.hasta) {
      this.entregasFiltradas = await this.listadoDeEntregasService.obtenerEntregasFiltradas(
        this.desde,
        this.hasta,
        EstadoAprobacionSolicitud[this.estadoSolicitud],
        this.tipoDeEntrega,
        this.userSelected.id,
      );
    }
  }
}
