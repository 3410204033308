import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';

export class SaldoCuentaCorrienteDTO implements IEntityDTO {

   porPolitica = 0;
   disponible = 0;
   usadoEnCiclo = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.porPolitica != null) { this.porPolitica = jsonObj.porPolitica; }
      if (jsonObj.disponible != null) { this.disponible = jsonObj.disponible; }
      if (jsonObj.usadoEnCiclo != null) { this.usadoEnCiclo = jsonObj.usadoEnCiclo; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
