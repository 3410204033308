import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageEntity.DTO';
import { SelectAndCropService } from 'src/app/shared/select-and-crop/select-and-crop.service';
import { SelectAndCropData } from 'src/app/shared/select-and-crop/select-and-crop.component';

export interface CandidateImageData {
  imagen: string;
  changed: boolean;
}

@Component({
  selector: 'app-seleccionar-profile-img',
  templateUrl: './seleccionar-profile-img.component.html',
  styleUrls: ['./seleccionar-profile-img.component.scss'],
})
export class SeleccionarProfileImgComponent {
  @ViewChild('fileImage') public fileImage: ElementRef;
  @Input() public candidateImage: CandidateImageData;
  @Input() public imageControl: AbstractControl;
  @Input() public width: number;
  @Input() public invalid = false;

  constructor(private selectAndCropService: SelectAndCropService) {}

  public fileChangeEvent(event: unknown): void {
    const data: SelectAndCropData = {
      event,
    };
    const dialogRef = this.selectAndCropService.openImageCropperDialog(data);

    dialogRef?.afterClosed().subscribe((result: string) => {
      if (result) {
        const image = new ImageEntityDTO();
        image.image = result;
        this.candidateImage.imagen = image.image;
        this.candidateImage.changed = true;
        this.imageControl.setValue(image.image);
      }
    });
  }

  public dropImagen(files: FileList): void {
    this.fileChangeEvent({
      target: {
        files,
      },
    });
  }

  public clickImageInput(): void {
    this.fileImage.nativeElement.click();
  }
}
