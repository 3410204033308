import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CandidatoPostuladoOfertaDTO } from 'src/app/ModelDTO/DTO/candidatoPostuladoOferta.DTO';
import { CandidatoPostuladoUserValidationDTO } from 'src/app/ModelDTO/DTO/candidatoPostuladoUserValidation.DTO';
import { ReclutamientoCandidatoDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidato.DTO';
import { ReclutamientoCandidatoParaPostulacionDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoParaPostulacion.DTO';
import { ReclutamientoOfertaPublicaDTO } from 'src/app/ModelDTO/DTO/reclutamientoOfertaPublica.DTO';
import { Constants } from '../shared/constants';
import { lastValueFrom } from 'rxjs';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageEntity.DTO';

@Injectable({
   providedIn: 'root'
})
export class ReclutamientoPublicoServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async insertReclutamientoPublico(userToken: string, candidatoPostuladoOfertaDTO: FormData): Promise<CandidatoPostuladoOfertaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/reclutamientoPublico/' + '?userToken=' + userToken, candidatoPostuladoOfertaDTO));
         const resDTO = new CandidatoPostuladoOfertaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateReclutamientoPublicoId(id: number, userToken: string, candidatoPostuladoOfertaDTO: FormData): Promise<CandidatoPostuladoOfertaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/reclutamientoPublico/' + id + '?userToken=' + userToken, candidatoPostuladoOfertaDTO));
         const resDTO = new CandidatoPostuladoOfertaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertReclutamientoPublicoIdProfilePicture(id: number, userToken: string, recaptchaToken: string, imageEntityDTO: ImageEntityDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/reclutamientoPublico/' + id + '/profilePicture' + '?userToken=' + userToken + '&recaptchaToken=' + recaptchaToken, imageEntityDTO));

   }

   public async getReclutamientoPublicoAuthenticatedUsersUSERTOKEN(userToken: string): Promise<CandidatoPostuladoOfertaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/reclutamientoPublico/authenticated-users/' + userToken));
         if (!res) { return null; }
         const resDTO = new CandidatoPostuladoOfertaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertReclutamientoPublicoValidateEmail(candidatoPostuladoUserValidationDTO: CandidatoPostuladoUserValidationDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/reclutamientoPublico/validateEmail', candidatoPostuladoUserValidationDTO));

   }

   public async getReclutamientoPublicoReclutamientoOfertaLINK(link: string): Promise<ReclutamientoOfertaPublicaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/reclutamientoPublico/reclutamientoOferta/' + link));
         if (!res) { return null; }
         const resDTO = new ReclutamientoOfertaPublicaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getReclutamientoPublicoReclutamientoOfertaLINKCandidato(link: string, email: string): Promise<ReclutamientoCandidatoParaPostulacionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/reclutamientoPublico/reclutamientoOferta/' + link + '/candidato' + '?email=' + email));
         if (!res) { return null; }
         const resDTO = new ReclutamientoCandidatoParaPostulacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertReclutamientoPublicoReclutamientoOfertaLINK(link: string, reclutamientoOfertaPostulacionInputDTO: FormData): Promise<ReclutamientoCandidatoDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/reclutamientoPublico/reclutamientoOferta/' + link, reclutamientoOfertaPostulacionInputDTO));
         const resDTO = new ReclutamientoCandidatoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
