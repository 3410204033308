import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { LocalidadDTO } from 'src/app/ModelDTO/DTO/localidad.DTO';
import { ProvinciaDTO } from 'src/app/ModelDTO/DTO/provincia.DTO';
import { ProvinciaModelDTO } from 'src/app/ModelDTO/provincia.ModelDTO';


export class LocalidadModelDTO extends EntityModelDTO<LocalidadDTO> {

   private provinciaModel: ProvinciaModelDTO;
   private provinciaSubscribe: Subscription;

   public constructor(protected entityDTO: LocalidadDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: LocalidadDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.provinciaModel = new ProvinciaModelDTO(this.entityDTO.provincia);
      this.provinciaSubscribe = this.provinciaModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.provinciaModel.dispose();
      this.provinciaSubscribe.unsubscribe();
   }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get CodigoPostal(): string { return this.entityDTO.codigoPostal; }
   set CodigoPostal(value: string) { this.notifyChangeDTO('codigoPostal', value); }

   get ProvinciaModel(): ProvinciaModelDTO { return this.provinciaModel; }
   get Provincia(): ProvinciaDTO { return this.provinciaModel.getEntityDTO(); }
   set Provincia(value: ProvinciaDTO) { this.notifyChange(() => { this.entityDTO.provincia = value; this.provinciaModel.setEntityDTO(value); }); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
