import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';
import { BancoDTO } from 'src/app/ModelDTO/DTO/banco.DTO';
import { BankAccountTypeDTO } from 'src/app/ModelDTO/DTO/bankAccountType.DTO';
import { ContactoEmergenciaDTO } from 'src/app/ModelDTO/DTO/contactoEmergencia.DTO';
import { DomicilioDTO } from 'src/app/ModelDTO/DTO/domicilio.DTO';
import { EstadoCivil } from 'src/app/ModelDTO/DTO/estadoCivil.ENUM';
import { FamiliarDTO } from 'src/app/ModelDTO/DTO/familiar.DTO';
import { LegajoDTO } from 'src/app/ModelDTO/DTO/legajo.DTO';
import { ModalidadContractualDTO } from 'src/app/ModelDTO/DTO/modalidadContractual.DTO';
import { NivelEstudioDTO } from 'src/app/ModelDTO/DTO/nivelEstudio.DTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { RelationshipPositionEnum } from 'src/app/ModelDTO/DTO/relationshipPositionEnum.ENUM';
import { TipoDocumento } from 'src/app/ModelDTO/DTO/tipoDocumento.ENUM';
import { TipoGenero } from 'src/app/ModelDTO/DTO/tipoGenero.ENUM';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';


export class PersonalDTO implements IEntityDTO {

   modalidad: ModalidadContractualDTO;
   nivelEstudio: NivelEstudioDTO;
   nacionalidad: PaisDTO;
   issuingCountry: PaisDTO;
   domicilio: DomicilioDTO;
   domicilioResidencia: DomicilioDTO;
   oficina: OficinaDTO;
   contactoEmergencia: ContactoEmergenciaDTO;
   banco: BancoDTO;
   bankAccountType: BankAccountTypeDTO;
   legajo: LegajoDTO;
   familiares: Array<FamiliarDTO>;
   posiblesAusencias: Array<AusenciaDTO>;
   firmaOlografaImage = '';
   usaSuFirmaOlografa: boolean;
   agregarDatosAnagraficos: boolean;
   image = '';
   nombre = '';
   apellido = '';
   nombreCompleto = '';
   dni = '';
   passport = '';
   tipoDocumento: TipoDocumento;
   fechaNacimiento: Date;
   genero: TipoGenero;
   cuil = '';
   telefonos = '';
   email = '';
   confirmarEmail: boolean;
   edad: number;
   peso: number;
   altura: number;
   circunferenciaCabeza: number;
   tallePantalon = '';
   talleCalzado = '';
   talleCamisa = '';
   movilidadPropia: boolean;
   disponibilidadTraslado: boolean;
   oficinaNombre = '';
   estadoCivil: EstadoCivil;
   familiaresACargo: number;
   ultimoSueldo: number;
   grupoSanguineo = '';
   nroCuenta = '';
   alias = '';
   relationshipPosition: RelationshipPositionEnum;
   profileCompleteness = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.modalidad = new ModalidadContractualDTO();
      this.nivelEstudio = new NivelEstudioDTO();
      this.nacionalidad = new PaisDTO();
      this.domicilio = new DomicilioDTO();
      this.domicilioResidencia = new DomicilioDTO();
      this.oficina = new OficinaDTO();
      this.contactoEmergencia = new ContactoEmergenciaDTO();
      this.banco = new BancoDTO();
      this.bankAccountType = new BankAccountTypeDTO();
      this.legajo = new LegajoDTO();
      this.familiares = new Array<FamiliarDTO>();
      this.posiblesAusencias = new Array<AusenciaDTO>();
      this.issuingCountry = new PaisDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.modalidad != null) { this.modalidad.PrepareDTO(jsonObj.modalidad); }
      if (jsonObj.nivelEstudio != null) { this.nivelEstudio.PrepareDTO(jsonObj.nivelEstudio); }
      if (jsonObj.nacionalidad != null) { this.nacionalidad.PrepareDTO(jsonObj.nacionalidad); }
      if (jsonObj.issuingCountry != null) { this.issuingCountry.PrepareDTO(jsonObj.issuingCountry); }
      if (jsonObj.domicilio != null) { this.domicilio.PrepareDTO(jsonObj.domicilio); }
      if (jsonObj.domicilioResidencia != null) { this.domicilioResidencia.PrepareDTO(jsonObj.domicilioResidencia); }
      if (jsonObj.oficina != null) { this.oficina.PrepareDTO(jsonObj.oficina); }
      if (jsonObj.contactoEmergencia != null) { this.contactoEmergencia.PrepareDTO(jsonObj.contactoEmergencia); }
      if (jsonObj.banco != null) { this.banco.PrepareDTO(jsonObj.banco); }
      if (jsonObj.bankAccountType != null) { this.bankAccountType.PrepareDTO(jsonObj.bankAccountType); }
      if (jsonObj.legajo != null) { this.legajo.PrepareDTO(jsonObj.legajo); }
      if (jsonObj.familiares != null) { for (const item of jsonObj.familiares) { const itemDTO = new FamiliarDTO(); itemDTO.PrepareDTO(item); this.familiares.push(itemDTO); } }
      if (jsonObj.posiblesAusencias != null) { for (const item of jsonObj.posiblesAusencias) { const itemDTO = new AusenciaDTO(); itemDTO.PrepareDTO(item); this.posiblesAusencias.push(itemDTO); } }
      if (jsonObj.firmaOlografaImage != null) { this.firmaOlografaImage = jsonObj.firmaOlografaImage; }
      if (jsonObj.usaSuFirmaOlografa != null) { this.usaSuFirmaOlografa = jsonObj.usaSuFirmaOlografa; }
      if (jsonObj.agregarDatosAnagraficos != null) { this.agregarDatosAnagraficos = jsonObj.agregarDatosAnagraficos; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.apellido != null) { this.apellido = jsonObj.apellido; }
      if (jsonObj.nombreCompleto != null) { this.nombreCompleto = jsonObj.nombreCompleto; }
      if (jsonObj.dni != null) { this.dni = jsonObj.dni; }
      if (jsonObj.passport != null) { this.passport = jsonObj.passport; }
      if (jsonObj.tipoDocumento != null) { this.tipoDocumento = jsonObj.tipoDocumento as TipoDocumento; }
      if (jsonObj.fechaNacimiento != null) { this.fechaNacimiento = new Date(jsonObj.fechaNacimiento); }
      if (jsonObj.genero != null) { this.genero = jsonObj.genero as TipoGenero; }
      if (jsonObj.cuil != null) { this.cuil = jsonObj.cuil; }
      if (jsonObj.telefonos != null) { this.telefonos = jsonObj.telefonos; }
      if (jsonObj.email != null) { this.email = jsonObj.email; }
      if (jsonObj.confirmarEmail != null) { this.confirmarEmail = jsonObj.confirmarEmail; }
      if (jsonObj.edad != null) { this.edad = jsonObj.edad; }
      if (jsonObj.peso != null) { this.peso = jsonObj.peso; }
      if (jsonObj.altura != null) { this.altura = jsonObj.altura; }
      if (jsonObj.circunferenciaCabeza != null) { this.circunferenciaCabeza = jsonObj.circunferenciaCabeza; }
      if (jsonObj.tallePantalon != null) { this.tallePantalon = jsonObj.tallePantalon; }
      if (jsonObj.talleCalzado != null) { this.talleCalzado = jsonObj.talleCalzado; }
      if (jsonObj.talleCamisa != null) { this.talleCamisa = jsonObj.talleCamisa; }
      if (jsonObj.movilidadPropia != null) { this.movilidadPropia = jsonObj.movilidadPropia; }
      if (jsonObj.disponibilidadTraslado != null) { this.disponibilidadTraslado = jsonObj.disponibilidadTraslado; }
      if (jsonObj.oficinaNombre != null) { this.oficinaNombre = jsonObj.oficinaNombre; }
      if (jsonObj.estadoCivil != null) { this.estadoCivil = jsonObj.estadoCivil as EstadoCivil; }
      if (jsonObj.familiaresACargo != null) { this.familiaresACargo = jsonObj.familiaresACargo; }
      if (jsonObj.ultimoSueldo != null) { this.ultimoSueldo = jsonObj.ultimoSueldo; }
      if (jsonObj.grupoSanguineo != null) { this.grupoSanguineo = jsonObj.grupoSanguineo; }
      if (jsonObj.nroCuenta != null) { this.nroCuenta = jsonObj.nroCuenta; }
      if (jsonObj.alias != null) { this.alias = jsonObj.alias; }
      if (jsonObj.relationshipPosition != null) { this.relationshipPosition = jsonObj.relationshipPosition as RelationshipPositionEnum; }
      if (jsonObj.profileCompleteness != null) { this.profileCompleteness = jsonObj.profileCompleteness; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
