import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { TurnoConSupervisorDTO } from 'src/app/ModelDTO/DTO/turnoConSupervisor.DTO';


export class TurnosDiaAgenteDTO implements IEntityDTO {

   fechaDia: Date;
   turnos: Array<TurnoConSupervisorDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.turnos = new Array<TurnoConSupervisorDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fechaDia != null) { this.fechaDia = new Date(jsonObj.fechaDia); }
      if (jsonObj.turnos != null) { for (const item of jsonObj.turnos) { const itemDTO = new TurnoConSupervisorDTO(); itemDTO.PrepareDTO(item); this.turnos.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
