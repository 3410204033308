import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';

export class EventSummaryDTO implements IEntityDTO {

   image = '';
   titulo = '';
   descripcion = '';
   fechaInicio: Date;
   fechaFin: Date;
   cantidadComentarios = 0;
   cantidadAdjuntos = 0;
   todoElDia: boolean;
   ubicacion = '';
   notificar: boolean;
   personalId = 0;
   personalImage = '';
   personalNombreCompleto = '';
   id = 0;
   cacheStamp = 0;
   reactions: string | undefined;
   currentReaction: string | undefined;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.titulo != null) { this.titulo = jsonObj.titulo; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.fechaInicio != null) { this.fechaInicio = new Date(jsonObj.fechaInicio); }
      if (jsonObj.fechaFin != null) { this.fechaFin = new Date(jsonObj.fechaFin); }
      if (jsonObj.cantidadComentarios != null) { this.cantidadComentarios = jsonObj.cantidadComentarios; }
      if (jsonObj.cantidadAdjuntos != null) { this.cantidadAdjuntos = jsonObj.cantidadAdjuntos; }
      if (jsonObj.todoElDia != null) { this.todoElDia = jsonObj.todoElDia; }
      if (jsonObj.ubicacion != null) { this.ubicacion = jsonObj.ubicacion; }
      if (jsonObj.notificar != null) { this.notificar = jsonObj.notificar; }
      if (jsonObj.personalId != null) { this.personalId = jsonObj.personalId; }
      if (jsonObj.personalImage != null) { this.personalImage = jsonObj.personalImage; }
      if (jsonObj.personalNombreCompleto != null) { this.personalNombreCompleto = jsonObj.personalNombreCompleto; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
      if (jsonObj.reactions != null) {
        this.reactions = jsonObj.reactions;
      }
      if (jsonObj.currentReaction != null) {
        this.currentReaction = jsonObj.currentReaction;
      }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
