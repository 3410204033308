import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { FileDBDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/fileDB.DTO';


export class ReclutamientoCandidatoParaPostulacionDTO implements IEntityDTO {

   nombre = '';
   apellido = '';
   image = '';
   cartaDePresentacion = '';
   email = '';
   telefono = '';
   cV: FileDBDTO;
   linkedin = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.cV = new FileDBDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.apellido != null) { this.apellido = jsonObj.apellido; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.cartaDePresentacion != null) { this.cartaDePresentacion = jsonObj.cartaDePresentacion; }
      if (jsonObj.email != null) { this.email = jsonObj.email; }
      if (jsonObj.telefono != null) { this.telefono = jsonObj.telefono; }
      if (jsonObj.cV != null) { this.cV.PrepareDTO(jsonObj.cV); }
      if (jsonObj.linkedin != null) { this.linkedin = jsonObj.linkedin; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
