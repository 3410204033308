import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { PersonalAusenciaDTO } from 'src/app/ModelDTO/DTO/personalAusencia.DTO';

@Injectable({
   providedIn: 'root'
})
export class PersonalAusenciaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getAusenciasIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/ausencias/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertAusenciasPersonalIDPersonal(iDPersonal: number, personalAusenciaDTO: FormData): Promise<PersonalAusenciaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/ausencias/personal/' + iDPersonal, personalAusenciaDTO));
         const resDTO = new PersonalAusenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateAusenciasPersonalAusenciaId(id: number, personalAusenciaDTO: FormData): Promise<PersonalAusenciaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/ausencias/personalAusencia/' + id, personalAusenciaDTO));
         const resDTO = new PersonalAusenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getAusenciasPersonalAusenciaId(id: number): Promise<PersonalAusenciaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/ausencias/personalAusencia/' + id));
         if (!res) { return null; }
         const resDTO = new PersonalAusenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getAusenciasPersonalFiltradas(fechaDesde: Date, fechaHasta: Date, estado: number = 0, idPersonal: number = 0, sinRechazos: boolean = false, filterByTeam: boolean = true, incluirBajas: boolean = false): Promise<Array<PersonalAusenciaDTO>> {
         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/ausencias/personal/filtradas' + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&estado=' + estado + '&idPersonal=' + idPersonal + '&sinRechazos=' + sinRechazos + '&filterByTeam=' + filterByTeam + '&incluirBajas=' + incluirBajas));
         const resJson = res.data;
         const resDTO = new Array<PersonalAusenciaDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalAusenciaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getAusenciasSiguientes(estado: number = 0, pageSize: number = 0, days: number = 0): Promise<Array<PersonalAusenciaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/ausencias/siguientes' + '?estado=' + estado + '&pageSize=' + pageSize + '&days=' + days));
         const resJson = res.data;
         const resDTO = new Array<PersonalAusenciaDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalAusenciaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getAusenciasPropias(fechaDesde: Date, fechaHasta: Date, estado: number = 0, sinRechazos: boolean = false): Promise<Array<PersonalAusenciaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/ausencias/propias' + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&estado=' + estado + '&sinRechazos=' + sinRechazos));
         const resJson = res.data;
         const resDTO = new Array<PersonalAusenciaDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalAusenciaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async deleteAusenciasIdPersonalIDPersonal(id: number, iDPersonal: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/ausencias/' + id + '/personal/' + iDPersonal));

   }

   public async getAusenciasIdComentarios(id: number): Promise<Array<ComentarioDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/ausencias/' + id + '/comentarios'));
         const resJson = res.data;
         const resDTO = new Array<ComentarioDTO>();
         for (const item of resJson) {
            const itemDTO = new ComentarioDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertAusenciasIdComentarios(id: number, stream: FormData): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/ausencias/' + id + '/comentarios', stream));

   }

   public async updateAusenciasIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Promise<ComentarioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/ausencias/' + id + '/comentarios/' + iDComentario, comentarioDTO));
         const resDTO = new ComentarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteAusenciasIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/ausencias/' + id + '/comentarios/' + iDComentario));

   }

}
