import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { FrontEndConfigService, FrontEndConfig } from '../../FrontendConfig';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';

import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { PublicLinkDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/publicLink.DTO';
import { QuoteFilesDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/quoteFiles.DTO';

@Injectable({
  providedIn: 'root',
})
export class FilesServiceBackend {
  constructor(
    @Inject(FrontEndConfigService) protected Constants: FrontEndConfig,
    protected http: HttpClient,
  ) {}

  public async getFilesIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {
    const res = await this.http.get<DataDTO>(this.Constants.apiURL + '/files/' + id + '/Auditory').toPromise();
    const resJson = res.data;
    const resDTO = new Array<AuditLogEntryDTO>();
    for (const item of resJson) {
      const itemDTO = new AuditLogEntryDTO();
      itemDTO.PrepareDTO(item);
      resDTO.push(itemDTO);
    }
    return resDTO;
  }

  public async getFilesBlobId(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {
    const res = await this.http
      .get(this.Constants.apiURL + '/files/blob/' + id + '?BLOB=true', { observe: 'response', responseType: 'blob' })
      .toPromise();
    const resDTO = new Blob([res.body], { type: 'application/pdf' });
    if (namedBlob) {
      namedBlob.setBlobNameFromHttpResponse(res);
      namedBlob.blob = res.body;
    }
    return resDTO;
  }

  public async getFilesPublicLinkId(id: number, expiredMinutes: number = 15): Promise<PublicLinkDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/files/publicLink/' + id + '?expiredMinutes=' + expiredMinutes).toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new PublicLinkDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getFilesSignedUploadUrl(): Promise<PublicLinkDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/files/signed-upload-url').toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new PublicLinkDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getFilesTotalQuote(): Promise<QuoteFilesDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/files/totalQuote').toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new QuoteFilesDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }
}
