import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { EntregaAgrupadaDTO } from 'src/app/ModelDTO/DTO/entregaAgrupada.DTO';
import { PersonalEntregaDTO } from 'src/app/ModelDTO/DTO/personalEntrega.DTO';

@Injectable({
   providedIn: 'root'
})
export class PersonalEntregaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getEntregaIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/entrega/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getEntregaAgrupadas(tipoAgrupamiento: number, estado: number = 2): Promise<Array<EntregaAgrupadaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/entrega/agrupadas' + '?tipoAgrupamiento=' + tipoAgrupamiento + '&estado=' + estado));
         const resJson = res.data;
         const resDTO = new Array<EntregaAgrupadaDTO>();
         for (const item of resJson) {
            const itemDTO = new EntregaAgrupadaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async updateEntregaIdCambioEstado(id: number, personalEntregaDTO: PersonalEntregaDTO): Promise<void> {

         await lastValueFrom(this.http.put(Constants.apiURL + '/entrega/' + id + '/cambioEstado', personalEntregaDTO));

   }

   public async insertEntregaMasivoIdCategoriaIdSubategoria(idCategoria: number, idSubategoria: number, personalEntregaDTO: PersonalEntregaDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/entrega/masivo/' + idCategoria + '/' + idSubategoria, personalEntregaDTO));

   }

   public async insertEntregaAreaMasivoIdAreaIdSector(idArea: number, idSector: number, personalEntregaDTO: PersonalEntregaDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/entrega/area/masivo/' + idArea + '/' + idSector, personalEntregaDTO));

   }

   public async insertEntregaCargoMasivoIdCargo(idCargo: number, personalEntregaDTO: PersonalEntregaDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/entrega/cargo/masivo/' + idCargo, personalEntregaDTO));

   }

   public async insertEntregaPersonalIDPersonal(iDPersonal: number, personalEntregaDTO: FormData): Promise<PersonalEntregaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/entrega/personal/' + iDPersonal, personalEntregaDTO));
         const resDTO = new PersonalEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getEntregaPersonal(fechaDesde: Date, fechaHasta: Date, estado: number = 0, idPersonal: number = 0, idTipoEntrega: number = 0, sinRechazos: boolean = false, duedReturn: boolean = false): Promise<Array<PersonalEntregaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/entrega/personal' + '?fechaDesde=' + fechaDesde.toISOString() + '&fechaHasta=' + fechaHasta.toISOString() + '&estado=' + estado + '&idPersonal=' + idPersonal + '&idTipoEntrega=' + idTipoEntrega + '&sinRechazos=' + sinRechazos + '&duedReturn=' + duedReturn));
         const resJson = res.data;
         const resDTO = new Array<PersonalEntregaDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalEntregaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getEntregaPersonalXLSX(fechaDesde: Date, fechaHasta: Date, estado: number = 0, idPersonal: number = 0, idTipoEntrega: number = 0, sinRechazos: boolean = false, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/entrega/personal' + '?fechaDesde=' + fechaDesde.toISOString() + '&fechaHasta=' + fechaHasta.toISOString() + '&estado=' + estado + '&idPersonal=' + idPersonal + '&idTipoEntrega=' + idTipoEntrega + '&sinRechazos=' + sinRechazos + '&BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/text' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async deleteEntregaIdPersonalIDPersonal(id: number, iDPersonal: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/entrega/' + id + '/personal/' + iDPersonal));

   }

}
