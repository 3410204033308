import { Component, DestroyRef, inject, Input, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, from, mergeMap, Observable, Subscription, switchMap } from 'rxjs';
import { CommentExtended } from 'src/app/app-common/comentarios/commentExtended';
import { IQuillMention } from 'src/app/app-common/comentarios/quill-mention.interface';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { OrganigramaService } from 'src/app/layout/administrador/colaboradores/organigrama.service';
import { PersonalEntityService } from 'src/app/shared/services/entities/personal-entity.service';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { quillModuloMinimal } from 'src/app/shared/shared-functions';
import { ComentariosService } from 'src/app/app-common/comentarios/comentarios.service';
import { IComentarios } from 'src/app/app-common/comentarios/icomentarios';
import { ComentarioDTO } from '@api/interfaces/comentario.interface';
import { throttledObservableGenerator, ThrottledObservableGeneratorData } from 'src/app/shared/utils/throttled-obsevable-generator';
import { AlertButton } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import 'quill-mention/autoregister';

@Component({
  selector: 'app-comentarios-lista',
  templateUrl: './comentarios-lista.component.html',
  styleUrls: ['./comentarios-lista.component.scss'],
})
export class ComentariosListaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public comentarioHandler: IComentarios;
  @Input() public set id(value: number) {
    this.idPersonal = value;
  }
  public comentarios: CommentExtended[] = [];
  public textoSinData = '';
  public quillModule: unknown;
  public get id(): number {
    return this.idPersonal;
  }

  private saveEditedThrottle: ThrottledObservableGeneratorData<ComentarioDTO>;
  private deletedThrottle: ThrottledObservableGeneratorData<ComentarioDTO>;

  private idPersonal: number;
  private sub = new Subscription();

  constructor(
    private comentarioService: ComentariosService,
    private neoModalService: NgxNeoModalMatService,
    private headerService: HeaderAppService,
    private organigramaService: OrganigramaService,
    private personalService: PersonalEntityService,
    private snackBar: SnackBarService,
    private zone: NgZone,
  ) {
    const destroyRef = inject(DestroyRef);
    this.saveEditedThrottle = throttledObservableGenerator<ComentarioDTO>((comment: ComentarioDTO) => this.saveEdited(comment), destroyRef);
    this.deletedThrottle = throttledObservableGenerator<ComentarioDTO>((comment: ComentarioDTO) => this.delete(comment), destroyRef);
  }

  public ngOnInit(): void {
    this.textoSinData = this.comentarioHandler.getNoDataString();
    this.quillModule = {
      ...quillModuloMinimal,
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        minChars: 3,
        isolateCharacter: true,
        dataAttributes: ['id', 'value', 'denotationChar', 'role'],
        onSelect: (item: any, insertItem: (arg0: any) => void): void => {
          insertItem(item);
          this.zone.run(() => {
            this.mentionAlert();
          });
        },
        source: async (searchTerm: string, renderList: (arg0: IQuillMention[]) => void): Promise<void> => {
          const teammates = await this.searchTeammate(searchTerm);
          renderList(teammates);
        },
      },
    };
  }

  public ngOnChanges(): void {
    if (this.comentarioHandler && this.id > 0) {
      this.getComentarios();
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public edit(comentario: CommentExtended): void {
    // eslint-disable-next-line no-param-reassign
    comentario.inEdition = true;
  }
  public saveEditThrottle(comment: ComentarioDTO): void {
    this.saveEditedThrottle.executionSubject$.next(comment);
  }

  public deleteThrottle(comment: ComentarioDTO): void {
    this.deletedThrottle.executionSubject$.next(comment);
  }

  public saveEdited = (comentario: ComentarioDTO): Observable<ComentarioDTO> => {
    if (comentario?.mensaje) {
      return this.comentarioHandler.editMessage(this.id, comentario);
    }
    return EMPTY;
  };

  public async clickedComment($event: any): Promise<void> {
    if ($event.target?.getAttribute) {
      const id = $event.target.getAttribute('data-id');
      await this.organigramaService.openPersonalDetailsById(id, false);
    }
  }

  public delete = (comentario: ComentarioDTO): Observable<void> => {
    if (comentario?.mensaje) {
      return from(this.neoModalService.decision('COMMENTS.REMOVAL_REQUEST')).pipe(
        mergeMap((res) => {
          if (res.ButtonResponse === AlertButton.Accept) {
            return this.comentarioHandler.deleteMessage(this.id, comentario);
          }
          return EMPTY;
        }),
      );
    }
    return EMPTY;
  };

  private async searchTeammate(term: string): Promise<IQuillMention[]> {
    const filteredTeamamtes = await this.personalService.getEntities(term, true);
    return filteredTeamamtes?.map((x) => ({
      id: x.id.toString(),
      value: x.nombreCompleto,
      role: x.esAdmin.toString(),
    }));
  }

  private mentionAlert(): void {
    this.snackBar.showInfo('COMMENTS.NO_NOTIFICATION_EDITION');
  }

  private getComentarios(): void {
    this.comentarioHandler
      .init(this.id, this.headerService.personalLegajoId, this.headerService.isAdmin())
      .subscribe((comments: CommentExtended[]) => {
        this.comentarios = comments;
      });

    this.sub.add(
      this.comentarioService.comentariosCambio
        .pipe(switchMap(() => this.comentarioHandler.init(this.id, this.headerService.personalLegajoId, this.headerService.isAdmin())))
        .subscribe((comments: CommentExtended[]) => {
          this.comentarios = comments;
        }),
    );
  }
}
