import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { CompetenceSkillDTO } from 'src/app/ModelDTO/DTO/competenceSkill.DTO';
import { CompetenceResultDTO } from 'src/app/ModelDTO/DTO/competenceResult.DTO';


export class CompetenceTypeDTO implements IEntityDTO {

   name = '';
   skills: Array<CompetenceSkillDTO>;
   result: CompetenceResultDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.skills = new Array<CompetenceSkillDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.skills != null) { for (const item of jsonObj.skills) { const itemDTO = new CompetenceSkillDTO(); itemDTO.PrepareDTO(item); this.skills.push(itemDTO); } }
      if (jsonObj.result != null) {
        this.result = new CompetenceResultDTO();
        this.result.PrepareDTO(jsonObj.result);
      }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
