import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';

export class SkillsSurveyExecutionDTO implements IEntityDTO {

   teammateId = 0;
   teammateImage = '';
   teammateName = '';
   fecha: Date;
   fechaVencimiento: Date;
   enviados = 0;
   completados = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.teammateId != null) { this.teammateId = jsonObj.teammateId; }
      if (jsonObj.teammateImage != null) { this.teammateImage = jsonObj.teammateImage; }
      if (jsonObj.teammateName != null) { this.teammateName = jsonObj.teammateName; }
      if (jsonObj.fecha != null) { this.fecha = new Date(jsonObj.fecha); }
      if (jsonObj.fechaVencimiento != null) { this.fechaVencimiento = new Date(jsonObj.fechaVencimiento); }
      if (jsonObj.enviados != null) { this.enviados = jsonObj.enviados; }
      if (jsonObj.completados != null) { this.completados = jsonObj.completados; }
   }
}
