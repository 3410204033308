import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AreasService } from 'src/app/layout/administrador/configuracion/areas/areas.service';
import { PersonalAreaDTO } from 'src/app/ModelDTO/DTO/personalArea.DTO';
import { PersonalSectorDTO } from 'src/app/ModelDTO/DTO/personalSector.DTO';
import { compareDtoId } from 'src/app/shared/shared-functions';

@Component({
  selector: 'app-selector-area',
  templateUrl: './selector-area.component.html',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, FormsModule, TranslateModule, MatTooltipModule],
})
export class SelectorAreaComponent implements OnInit {
  @Input() public area: PersonalAreaDTO;
  @Input() public sector: PersonalSectorDTO;
  @Input() public opcionTodosArea = false;
  @Input() public disabled = false;
  @Input() public opcionTodosSector = false;

  @Output() public sectorChange = new EventEmitter<PersonalSectorDTO>();
  @Output() public areaChange = new EventEmitter<PersonalAreaDTO>();
  @Output() public filtersChanged = new EventEmitter();

  public readonly compareId = compareDtoId;

  public areas: PersonalAreaDTO[] = [];

  constructor(
    private areaService: AreasService,
    private translateService: TranslateService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.areas = await this.areaService.obtenerAreas();
    if (this.opcionTodosArea) {
      this.ponerTodos();
    }
    if (this.opcionTodosSector) {
      this.agregarTodosSector();
    }
    if (this.area) {
      this.area = this.areas.find((x) => x.id === this.area.id);
      if (this.area && this.sector) {
        this.sector = this.area.sectores.find((x) => x.id === this.sector.id);
      }
    } else {
      // Area y sector son undefined pero opcion todos es true -> selecciono esa opcion
      // eslint-disable-next-line no-lonely-if
      if (this.opcionTodosArea) {
        this.area = this.areas[0];
        this.sector = this.area.sectores[0];
      }
    }
  }

  public onAreaChange(): void {
    if (this.area && this.area.sectores.length > 0) {
      this.area = this.areas.find((x) => x.id === this.area.id);
      this.sector = this.area.sectores[0];
    } else {
      this.sector = new PersonalSectorDTO();
    }
    this.sectorChange.emit(this.sector);
    this.areaChange.emit(this.area);
    this.filtersChanged.emit();
  }

  public onSectorChange(): void {
    this.sectorChange.emit(this.sector);
    this.areaChange.emit(this.area);
    this.filtersChanged.emit();
  }

  private ponerTodos(): void {
    const todas = new PersonalAreaDTO();
    todas.nombre = 'Todas';
    const todosSect = new PersonalSectorDTO();
    todosSect.nombre = 'Todos';
    todas.sectores.unshift(todosSect);
    this.areas.unshift(todas);
  }

  // Opcion todos los sectores en todas las areas
  private agregarTodosSector(): void {
    const todosSect = new PersonalSectorDTO();
    todosSect.nombre = 'Todos';
    // eslint-disable-next-line no-restricted-syntax
    for (const ar of this.areas) {
      ar.sectores.unshift(todosSect);
    }
  }
}
