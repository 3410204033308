import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule, Sort } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { NgxNeoDirectivesModule } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-neo-directives.module';
import { MatTableModule } from '@angular/material/table';
import { compare, fileIconClass } from 'src/app/shared/shared-functions';

enum ColumnNames {
  Nombre = 'nombre',
  Acciones = 'acciones',
}

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    NgxNeoDirectivesModule,
    TranslateModule,
    MatButtonModule,
  ],
})
export class FileSelectorComponent implements OnChanges {
  @Input() public files: File[] = [];
  @Input() public onlyOneFile = false;
  @Input() public accept = 'image/jpeg, image/png, image/jpg, application/pdf,.doc,.docx,.xls,.xlsx';
  @Output() public filesChange = new EventEmitter<Array<File>>();

  @ViewChild('fileInput') public fileInput: ElementRef;

  public readonly fileIconClass = fileIconClass;
  public readonly ColumnNames = ColumnNames;

  public arrayFilesASubir: Array<File>;
  public displayedColumns: string[] = [ColumnNames.Nombre, ColumnNames.Acciones];
  public fileSelectedString = '';

  public eliminarArchCmd: ICommand = new Command((value) => this.removerArchivo(value), new BehaviorSubject(true), false);

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.files) {
      this.updateDescription();
      this.arrayFilesASubir = [...this.files];
    }
  }

  public sortData(sort: Sort): void {
    const data = this.arrayFilesASubir.slice();
    if (!sort.active || !sort.direction?.length) {
      this.arrayFilesASubir = data;
      return;
    }

    this.arrayFilesASubir = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case ColumnNames.Nombre:
          return compare(a.name, b.name, isAsc);
        default:
          return 0;
      }
    });
  }

  public onDocumentacionChange(event: Event): void {
    const { files } = event.target as HTMLInputElement;

    if (files) {
      if (this.onlyOneFile) {
        this.arrayFilesASubir = [files[0]];
      } else {
        Array.from(files).forEach((file) => {
          this.arrayFilesASubir.unshift(file);
        });

        this.arrayFilesASubir = [...this.arrayFilesASubir];
      }

      this.updateDescription();
      this.filesChange.emit(this.arrayFilesASubir);
      this.fileInput.nativeElement.value = '';
    }
  }

  public async removerArchivo(file: File): Promise<void> {
    const index = this.arrayFilesASubir.indexOf(file);
    this.arrayFilesASubir.splice(index, 1);
    this.arrayFilesASubir = [...this.arrayFilesASubir];
    this.filesChange.emit(this.arrayFilesASubir);
  }

  private updateDescription(): void {
    let fileString = '';

    this.files?.forEach((file) => {
      fileString = `${file.name},${fileString}`;
    });

    this.fileSelectedString = fileString;
  }
}
