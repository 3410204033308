<h1 mat-dialog-title class="d-flex flex-row justify-content-between">
  <span> {{ entregaModel.Entrega.nombre }}</span>
  <small>{{ !entregaModel.Entrega.convenio.nombre?.length ? '' : ' - ' + entregaModel.Entrega.convenio.nombre }}</small>
  <span class="dialog-close-icon material-symbols-outlined pt-1" [mat-dialog-close]="true"> close </span>
</h1>
<div mat-dialog-content>
  <div class="mb-2 p-2 color-white color-warn-medium-background" *ngIf="error">Selecciona el campo para cambiar de estado</div>
  <app-detalle-entrega
    [entregaModel]="entregaModel"
    [solicitudModel]="solicitudEntregaModel"
    [status]="status"
    [color]="chipColor"
  ></app-detalle-entrega>
  <mat-divider class="mt-2 mb-2"></mat-divider>
  <app-cambio-estado-entrega
    *ngIf="
      (vistaEmpresa$ | async) &&
      entregaModel.Estado !== estadoEntregaEnum[estadoEntregaEnum.Rechazado] &&
      entregaModel.Estado !== estadoEntregaEnum[estadoEntregaEnum.Cancelado]
    "
    [entregaModel]="entregaModelAux"
  >
  </app-cambio-estado-entrega>
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
  <div *ngIf="vistaEmpresa$ | async">
    <button mat-stroked-button color="danger" [command]="eliminarCmd">Eliminar</button>
    <button
      mat-flat-button
      color="primary"
      [command]="cambiarEstadoCmd"
      *ngIf="
        entregaModel.Estado !== estadoEntregaEnum[estadoEntregaEnum.Rechazado] &&
        entregaModel.Estado !== estadoEntregaEnum[estadoEntregaEnum.Cancelado]
      "
    >
      Cambiar estado
    </button>
  </div>
  <button
    *ngIf="
      (vistaEmpresa$ | async) === false && entregaModel.ConSolicitud && entregaModel.getEntityDTO().estado === estadoEntregaEnum.Pendiente
    "
    mat-stroked-button
    color="danger"
    [command]="eliminarCmd"
  >
    Dar de baja
  </button>
</div>
