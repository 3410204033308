import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { QuestionCompetenceDTO } from 'src/app/ModelDTO/DTO/questionCompetence.DTO';


export class CompetenceResultDTO implements IEntityDTO {

   id = 0;
   cacheStamp = 0;
   resultRange: LevelRangeDTO;
   expectedLevel: LevelRangeDTO;
   evaluationCreationDate: Date;
   questionCompetence: QuestionCompetenceDTO;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.evaluationCreationDate != null) { this.evaluationCreationDate = new Date(jsonObj.evaluationCreationDate); }
      if (jsonObj.resultRange != null) {
        this.resultRange = new LevelRangeDTO();
        this.resultRange.PrepareDTO(jsonObj.resultRange);
      }
      if (jsonObj.expectedLevel != null) {
        this.expectedLevel = new LevelRangeDTO();
        this.expectedLevel.PrepareDTO(jsonObj.expectedLevel);
      }
      if (jsonObj.questionCompetence != null) {
         this.questionCompetence = new QuestionCompetenceDTO();
         this.questionCompetence.PrepareDTO(jsonObj.questionCompetence); }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
