import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { FeedbackDTO } from 'src/app/ModelDTO/DTO/feedback.DTO';
import { PersonalFeedbackDTO } from 'src/app/ModelDTO/DTO/personalFeedback.DTO';
import { Constants } from '../shared/constants';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NameDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/nameDetail.DTO';

@Injectable({
   providedIn: 'root'
})
export class FeedbackServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getFeedbackIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/feedback/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getFeedback(pageNumber: number, pageSize: number): Promise<Array<PersonalFeedbackDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/feedback/' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<PersonalFeedbackDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalFeedbackDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getFeedbackConFeedback(pageNumber: number, pageSize: number): Promise<Array<PersonalFeedbackDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/feedback/conFeedback' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<PersonalFeedbackDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalFeedbackDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getFeedbackPersonalLegajoId(id: number, fechaDesde: Date, fechaHasta: Date, todas: boolean = true): Promise<Array<FeedbackDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/feedback/personalLegajo/' + id + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&todas=' + todas));
         const resJson = res.data;
         const resDTO = new Array<FeedbackDTO>();
         for (const item of resJson) {
            const itemDTO = new FeedbackDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getFeedbackId(id: number): Promise<PersonalFeedbackDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/feedback/' + id));
         if (!res) { return null; }
         const resDTO = new PersonalFeedbackDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertFeedbackPersonalLegajoId(id: number, feedbackDTO: FormData): Promise<FeedbackDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/feedback/personalLegajo/' + id, feedbackDTO));
         const resDTO = new FeedbackDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteFeedbackId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/feedback/' + id));

   }

   public async getFeedbackIdComentarios(id: number): Promise<Array<ComentarioDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/feedback/' + id + '/comentarios'));
         const resJson = res.data;
         const resDTO = new Array<ComentarioDTO>();
         for (const item of resJson) {
            const itemDTO = new ComentarioDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertFeedbackIdComentarios(id: number, stream: FormData): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/feedback/' + id + '/comentarios', stream));

   }

   public async updateFeedbackIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Promise<ComentarioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/feedback/' + id + '/comentarios/' + iDComentario, comentarioDTO));
         const resDTO = new ComentarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteFeedbackIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/feedback/' + id + '/comentarios/' + iDComentario));

   }
}
