import { Injectable } from '@angular/core';
import { EstadoAprobacionSolicitud } from '@api/enums/estado-aprobacion-solicitud.enum';
import { EstadoCapacitacion } from '@api/enums/estado-capacitacion.enum';
import { TipoUnidadDevolucion } from '@api/enums/tipo-unidad-devolucion.enum';
import { CapacitacionAgrupadaDTO } from 'src/app/ModelDTO/DTO/capacitacionAgrupada.DTO';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { PersonalCapacitacionDTO } from 'src/app/ModelDTO/DTO/personalCapacitacion.DTO';
import { SolicitudCapacitacionDTO } from 'src/app/ModelDTO/DTO/solicitudCapacitacion.DTO';
import { SolicitudCapacitacionFlatDTO } from 'src/app/ModelDTO/DTO/solicitudCapacitacionFlat.DTO';
import { TipoCapacitacionFlatDTO } from 'src/app/ModelDTO/DTO/tipoCapacitacionFlat.DTO';
import { PersonalCapacitacionServiceBackend } from 'src/app/ServiceBackend/personalCapacitacion.ServiceBackend';
import { SolicitudCapacitacionServiceBackend } from 'src/app/ServiceBackend/solicitudCapacitacion.ServiceBackend';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { FileDownloaderService } from 'src/app/shared/services/fileDownloader.service';

@Injectable({
  providedIn: 'root',
})
export class ListadoDeCapacitacionesService {
  public get PersonalAlta(): PersonalDTO {
    return this.personalAlta;
  }
  public set PersonalAlta(p: PersonalDTO) {
    this.personalAlta = p;
  }

  private personalAlta: PersonalDTO;

  constructor(
    private fileDownloaderService: FileDownloaderService,
    private personalCapacitacion: PersonalCapacitacionServiceBackend,
    private solicitudCapacitacionServiceBackend: SolicitudCapacitacionServiceBackend,
  ) {}

  public async responderSolicitud(solicitud: SolicitudCapacitacionDTO): Promise<void> {
    await this.solicitudCapacitacionServiceBackend.updateSolicitudCapacitacionIdEstado(solicitud.id, solicitud);
  }

  public async geRequestById(requestId: number): Promise<SolicitudCapacitacionDTO> {
    return this.solicitudCapacitacionServiceBackend.getSolicitudCapacitacionPorSolicitudId(requestId);
  }

  public async obtenerSolicitudesFiltradas(
    pageNumber: number,
    pageSize: number,
    desde: Date,
    hasta: Date,
    estado: EstadoAprobacionSolicitud,
    idUser: number,
  ): Promise<Array<SolicitudCapacitacionFlatDTO>> {
    const res = await this.solicitudCapacitacionServiceBackend.getSolicitudCapacitacionFiltradas(
      desde,
      hasta,
      pageNumber,
      pageSize,
      estado,
      idUser,
    );
    return res;
  }

  public async obtenerCapacitacionesFiltradas(
    desde: Date,
    hasta: Date,
    estado: EstadoAprobacionSolicitud,
    tipoDeCapacitacion: TipoCapacitacionFlatDTO,
    idUser: number,
    sinRechazos?: boolean,
  ): Promise<Array<PersonalCapacitacionDTO>> {
    const res = await this.personalCapacitacion.getCapacitacionPersonal(
      desde,
      hasta,
      estado,
      idUser,
      tipoDeCapacitacion?.id ?? 0,
      sinRechazos,
    );
    return res;
  }

  public async exportarCapacitacionesFiltradasEnExcel(
    desde: Date,
    hasta: Date,
    estado: EstadoAprobacionSolicitud,
    tipoDeCapacitacion: TipoCapacitacionFlatDTO,
    idUser: number,
    sinRechazos?: boolean,
  ): Promise<void> {
    const blob = new NamedBlobDTO();
    await this.personalCapacitacion.getCapacitacionPersonalXLSX(
      desde,
      hasta,
      estado,
      idUser,
      tipoDeCapacitacion?.id ?? 0,
      sinRechazos,
      blob,
    );
    this.fileDownloaderService.saveAs(blob);
  }

  public async agregarCapacitacion(idPersonal: number, formCapacitacion: FormData): Promise<PersonalCapacitacionDTO> {
    const res = await this.personalCapacitacion.insertCapacitacionPersonalIDPersonal(idPersonal, formCapacitacion);
    return res;
  }

  public async agregarCapacitacionMasivaCategoria(
    idCategoria: number,
    idSubcategoria: number,
    capacitacion: PersonalCapacitacionDTO,
  ): Promise<void> {
    await this.personalCapacitacion.insertCapacitacionMasivoIdCategoriaIdSubategoria(idCategoria, idSubcategoria, capacitacion);
  }

  public async agregarCapacitacionMasivaCargo(idCargo: number, capacitacion: PersonalCapacitacionDTO): Promise<void> {
    await this.personalCapacitacion.insertCapacitacionCargoMasivoIdCargo(idCargo, capacitacion);
  }

  public async eliminarCapacitacion(capacitacion: PersonalCapacitacionDTO): Promise<void> {
    await this.personalCapacitacion.deleteCapacitacionIdPersonalIDPersonal(capacitacion.id, capacitacion.personalLegajo.id);
  }

  public async eliminarSolicitudCapacitacion(capacitacion: SolicitudCapacitacionDTO): Promise<void> {
    await this.solicitudCapacitacionServiceBackend.deleteSolicitudCapacitacionIdSolicitudCancelar(capacitacion.id);
  }

  public async bajaDeCapacitacion(capacitacion: PersonalCapacitacionDTO): Promise<void> {
    capacitacion.estado = EstadoCapacitacion.Cancelado;
    await this.personalCapacitacion.updateCapacitacionIdCambioEstado(capacitacion.id, capacitacion);
  }

  public async obtenerCapacitacionesAgrupadas(tipoDev: TipoUnidadDevolucion): Promise<Array<CapacitacionAgrupadaDTO>> {
    const res = await this.personalCapacitacion.getCapacitacionAgrupadas(tipoDev);
    return res;
  }

  public async cambiarEstadoDeCapacitacion(personalCapacitacionDTO: PersonalCapacitacionDTO): Promise<void> {
    await this.personalCapacitacion.updateCapacitacionIdCambioEstado(personalCapacitacionDTO.id, personalCapacitacionDTO);
  }

  public clonCapacitacionReducido(personalCapacitacionDTO: PersonalCapacitacionDTO): PersonalCapacitacionDTO {
    // Ni object assign ni prepareDTO sirven para clonar en profundidad.
    // Esto es un clon reducido. Solo necesito mostrar estos datos y modificar sólo el estado.
    const newPE = new PersonalCapacitacionDTO();
    newPE.id = personalCapacitacionDTO.id;
    newPE.descripcion = personalCapacitacionDTO.descripcion;
    newPE.capacitacion = personalCapacitacionDTO.capacitacion;
    newPE.estado = personalCapacitacionDTO.estado;
    newPE.conSolicitud = personalCapacitacionDTO.conSolicitud;
    newPE.capacitacionEfectiva = personalCapacitacionDTO.capacitacionEfectiva;
    newPE.vencimientoAproximado = personalCapacitacionDTO.vencimientoAproximado;
    newPE.personalLegajo = personalCapacitacionDTO.personalLegajo;
    newPE.fechaPactada = personalCapacitacionDTO.fechaPactada;
    return newPE;
  }

  public dispose() {
    this.personalAlta = null;
  }
}
