import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { ConfigurationCodenamesType } from 'src/app/ModelDTO/DTO/configurationCodenamesType.ENUM';


export class ConfigurationCodenameAliasDTO implements IEntityDTO {

   nameType: ConfigurationCodenamesType;
   codename = '';
   alias = '';
   id = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nameType != null) { this.nameType = jsonObj.nameType as ConfigurationCodenamesType; }
      if (jsonObj.codename != null) { this.codename = jsonObj.codename; }
      if (jsonObj.alias != null) { this.alias = jsonObj.alias; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
