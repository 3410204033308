import { Injectable, Type } from '@angular/core';
import { PersonalLegajoCuestionarioModelDTO } from 'src/app/ModelDTO/personalLegajoCuestionario.ModelDTO';
import { CuestionarioBasicoDTO } from 'src/app/ModelDTO/DTO/cuestionarioBasico.DTO';
import { EncuestaResumenDTO } from 'src/app/ModelDTO/DTO/encuestaResumen.DTO';
import { EncuestaStatsDTO } from 'src/app/ModelDTO/DTO/encuestaStats.DTO';
import { OverallEvaluationDTO } from 'src/app/ModelDTO/DTO/overallEvaluation.DTO';
import { SortSurveySummary } from 'src/app/ModelDTO/DTO/sortSurveySummary.ENUM';
import { TipoEvaluacionCuestionario } from 'src/app/ModelDTO/DTO/tipoEvaluacionCuestionario.ENUM';
import { CuestionarioServiceBackend } from 'src/app/ServiceBackend/cuestionario.ServiceBackend';
import { noFormImage } from 'src/app/shared/constants';
import { AccentMarkQuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/accent-mark-question/accent-mark-question.component';
import { MultipleChoiceQuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/multiple-choice-question/multiple-choice-question.component';
import { NumericQuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/numeric-question/numeric-question.component';
import { OnlyTextQuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/only-text-question/only-text-question.component';
import { QuestionGroupComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/question-group/question-group.component';
import { QuestionSubgroupComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/question-subgroup/question-subgroup.component';
import { QuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/question.component';
import { SingleChoiceQuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/single-choice-question/single-choice-question.component';
import { SubtitleQuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/subtitle-question/subtitle-question.component';
import { QuestionNoOptionComponent } from 'src/app/shared/forms/cuestionario-tipo-pregunta/question-no-option/question-no-option.component';
import { QuestionNumericComponent } from 'src/app/shared/forms/cuestionario-tipo-pregunta/question-numeric/question-numeric.component';
import { QuestionOptionComponent } from 'src/app/shared/forms/cuestionario-tipo-pregunta/question-option/question-option.component';
import { TypeQuestionComponent } from 'src/app/shared/forms/cuestionario-tipo-pregunta/typeQuestion.component';
import { HeaderImageData } from 'src/app/shared/header-image/header-image/header-image.component';
import { FileDownloaderService } from 'src/app/shared/services/fileDownloader.service';
import { EncuestaDTO } from 'src/app/ModelDTO/DTO/encuesta.DTO';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageEntity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { CuestionarioPreguntaDTO } from 'src/app/ModelDTO/DTO/cuestionarioPregunta.DTO';
import { TipoCuestionarioPregunta } from '@api/enums/tipo-cuestionario-pregunta.enum';
import { TipoRecurrencia } from '@api/enums/tipo-recurrencia.enum';
import { TipoEstadoPersonalLegajoCuestinario } from '@api/enums/tipo-estado-personal-legajo-cuestinario.enum';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoCuestionarioDTO } from 'src/app/ModelDTO/DTO/personalLegajoCuestionario.DTO';
import { TipoPlanificacionMensual } from '@api/enums/tipo-planificacion-mensual.enum';
import { PreguntaOpcionDTO } from 'src/app/ModelDTO/DTO/preguntaOpcion.DTO';
import { CuestionarioDTO } from 'src/app/ModelDTO/DTO/cuestionario.DTO';
import { EstadoEncuesta } from '@api/enums/estado-encuesta.enum';
import { TipoCuestionario } from '@api/enums/tipo-cuestionario.enum';
import { EncuestaEjecucionDTO } from 'src/app/ModelDTO/DTO/encuestaEjecucion.DTO';
import { WeeklyComponent } from './administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/weekly/weekly.component';
import { TypeRecurrenceComponent } from './administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/typeRecurrence.component';
import { TypeMonthlyPlanningComponent } from './administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/monthly/tipo-planificacion-mensual/typeMonthlyPlanning.component';
import { ParticularDayComponent } from './administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/monthly/tipo-planificacion-mensual/particular-day/particular-day.component';
import { FirstBusinessDayComponent } from './administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/monthly/tipo-planificacion-mensual/first-business-day/first-business-day.component';
import { DayWeekComponent } from './administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/monthly/tipo-planificacion-mensual/day-week/day-week.component';
import { MonthlyComponent } from './administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/monthly/monthly.component';
import { ForOnlyTimeComponent } from './administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/for-only-time/for-only-time.component';
import { DailyComponent } from './administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/daily/daily.component';
import { ListQuestions } from './administrador-cuestionario-alta/cuestionario-main/cuestionario-pregunta-listado/cuestionario-pregunta-listado.component';

export const flattenQuestions = (questions: CuestionarioPreguntaDTO[]): CuestionarioPreguntaDTO[] =>
  questions.flatMap((question) => {
    const flattened = [question];
    if (question.preguntas?.length > 0) {
      flattened.push(...flattenQuestions(question.preguntas));
    }
    return flattened;
  });

export const orderQuestions = (questions: CuestionarioPreguntaDTO[], level: number = 0): CuestionarioPreguntaDTO[] => {
  const orderedQuestions =
    [...(questions ?? [])].sort((a: CuestionarioPreguntaDTO, b: CuestionarioPreguntaDTO) => {
      if (a.position === b.position) {
        return a.id - b.id;
      }
      return a.position - b.position;
    }) ?? [];

  orderedQuestions.forEach((question) => {
    if (question.preguntas.length > 0) {
      // eslint-disable-next-line no-param-reassign
      question.tipo = question.tipo === TipoCuestionarioPregunta.Grupo && level > 0 ? TipoCuestionarioPregunta.Subgrupo : question.tipo;
      orderQuestions(question.preguntas, level + 1);
    }
  });

  return orderedQuestions;
};

export const isBlockQuestion = (question: CuestionarioPreguntaDTO): boolean =>
  [TipoCuestionarioPregunta.Subtitle, TipoCuestionarioPregunta.Grupo, TipoCuestionarioPregunta.Subgrupo].includes(question.tipo);

export const assignPosition = (questions: CuestionarioPreguntaDTO[]): ListQuestions[] => {
  const listQuestions: ListQuestions[] = [];
  let order = 1;
  questions?.forEach((value, index) => {
    // eslint-disable-next-line no-param-reassign
    value.position = index + 1;
    listQuestions.push({
      // eslint-disable-next-line no-plusplus
      order: isBlockQuestion(value) ? 0 : order++,
      question: value,
    });
  });
  return listQuestions;
};

@Injectable({
  providedIn: 'root',
})
export class CuestionariosService {
  public answersComponent: Map<TipoCuestionarioPregunta, Type<QuestionComponent>> = new Map();
  public optionsComponent: Map<TipoCuestionarioPregunta, Type<TypeQuestionComponent>> = new Map();
  public typeRecurrenceComponent: Map<TipoRecurrencia, Type<TypeRecurrenceComponent>> = new Map();
  public typeMonthlyPlanningComponent: Map<TipoPlanificacionMensual, Type<TypeMonthlyPlanningComponent>> = new Map();
  public optionEdit: PreguntaOpcionDTO;
  public questionEdit: CuestionarioPreguntaDTO;
  public arrayPersonalLegajoCuestionario: PersonalLegajoCuestionarioDTO[];
  public personalLegajoFiltroSeleccionado: PersonalLegajoBasicoDTO;
  public estadoPLegajoCuestionario: TipoEstadoPersonalLegajoCuestinario;
  public tipoCorreccionArrayS: string[];
  public tipoEstadoPLegajoCuestinario = TipoEstadoPersonalLegajoCuestinario;
  public tipoEstadoPLegajoCuestinarioArrayS: string[];
  public tipoCuestionarioPregunta = TipoCuestionarioPregunta;
  public tipoRecurrencia = TipoRecurrencia;
  public tipoPlanificacionMensual = TipoPlanificacionMensual;
  public cuestionarios: CuestionarioDTO[];
  public cuestionario: CuestionarioDTO;
  public filtradoXCuestionario: PersonalLegajoCuestionarioDTO[];
  public header: HeaderImageData;

  public notificationServiceId = 0;

  constructor(
    private cuestionarioServiceBackend: CuestionarioServiceBackend,
    private fileDownloaderService: FileDownloaderService,
  ) {
    this.tipoEstadoPLegajoCuestinarioArrayS = PersonalLegajoCuestionarioModelDTO.getTipoEstadoPersonalLegajoCuestinario();
    this.tipoEstadoPLegajoCuestinarioArrayS = this.tipoEstadoPLegajoCuestinarioArrayS.filter((x) => x !== 'Respondiendo');
    this.answersComponent.set(TipoCuestionarioPregunta.Opciones_SingleSelect, SingleChoiceQuestionComponent);
    this.answersComponent.set(TipoCuestionarioPregunta.Texto, OnlyTextQuestionComponent);
    this.answersComponent.set(TipoCuestionarioPregunta.Opciones_MultiSelect, MultipleChoiceQuestionComponent);
    this.answersComponent.set(TipoCuestionarioPregunta.Tilde, AccentMarkQuestionComponent);
    this.answersComponent.set(TipoCuestionarioPregunta.Numeric, NumericQuestionComponent);
    this.answersComponent.set(TipoCuestionarioPregunta.Subtitle, SubtitleQuestionComponent);
    this.answersComponent.set(TipoCuestionarioPregunta.Grupo, QuestionGroupComponent);
    this.answersComponent.set(TipoCuestionarioPregunta.Subgrupo, QuestionSubgroupComponent);

    this.optionsComponent.set(TipoCuestionarioPregunta.Opciones_SingleSelect, QuestionOptionComponent);
    this.optionsComponent.set(TipoCuestionarioPregunta.Opciones_MultiSelect, QuestionOptionComponent);
    this.optionsComponent.set(TipoCuestionarioPregunta.Tilde, QuestionNoOptionComponent);
    this.optionsComponent.set(TipoCuestionarioPregunta.Texto, QuestionNoOptionComponent);
    this.optionsComponent.set(TipoCuestionarioPregunta.Numeric, QuestionNumericComponent);

    this.typeRecurrenceComponent.set(TipoRecurrencia.PorUnicaVez, ForOnlyTimeComponent);
    this.typeRecurrenceComponent.set(TipoRecurrencia.Diaria, DailyComponent);
    this.typeRecurrenceComponent.set(TipoRecurrencia.Semanal, WeeklyComponent);
    this.typeRecurrenceComponent.set(TipoRecurrencia.Mensual, MonthlyComponent);

    this.typeMonthlyPlanningComponent.set(TipoPlanificacionMensual.DiaParticular, ParticularDayComponent);
    this.typeMonthlyPlanningComponent.set(TipoPlanificacionMensual.DiaSemana, DayWeekComponent);
    this.typeMonthlyPlanningComponent.set(TipoPlanificacionMensual.PrimerDiaHabil, FirstBusinessDayComponent);
    this.header = { imagen: noFormImage, cartelEdicionPortada: true, changed: false };

    this.init();
  }

  public async init(): Promise<void> {
    this.estadoPLegajoCuestionario = this.tipoEstadoPLegajoCuestinario.Respondido;
    this.personalLegajoFiltroSeleccionado = new PersonalLegajoBasicoDTO();
    this.arrayPersonalLegajoCuestionario = [];
    this.cuestionario = new CuestionarioDTO();
    this.optionEdit = new PreguntaOpcionDTO();
    this.questionEdit = new CuestionarioPreguntaDTO();
  }

  public async enviarRespuestasCuestioinario(
    personalLegajoCuestionario: PersonalLegajoCuestionarioDTO,
  ): Promise<PersonalLegajoCuestionarioDTO> {
    return this.cuestionarioServiceBackend.updateCuestionariosId(personalLegajoCuestionario.id, personalLegajoCuestionario);
  }

  public async getPersonalLegajoCuestionarioPorId(id: number): Promise<PersonalLegajoCuestionarioDTO> {
    const res = await this.cuestionarioServiceBackend.getCuestionariosId(id);
    return res;
  }

  public async getPersonalLegajoCuestionarioPorIdYUsuarioActual(id: number): Promise<PersonalLegajoCuestionarioDTO> {
    const res = await this.cuestionarioServiceBackend.getCuestionariosUsuarioActualId(id);
    return res;
  }

  public async exportarCuestionarios(desde: Date, hasta: Date, plantillaId: number): Promise<void> {
    const blob = new NamedBlobDTO();
    await this.cuestionarioServiceBackend.getCuestionariosXLSX(desde, hasta, plantillaId, blob);
    this.fileDownloaderService.saveAs(blob);
  }

  public async getFormTemplates(quizType: number = 0, latest = false): Promise<CuestionarioBasicoDTO[]> {
    const res = await this.cuestionarioServiceBackend.getCuestionariosCuestionario(quizType, latest);
    return res;
  }

  public async getTemplate(id: number): Promise<CuestionarioDTO> {
    const res = await this.cuestionarioServiceBackend.getCuestionariosCuestionarioId(id);
    return res;
  }

  public async insertarCuestionario(cuestionario: CuestionarioDTO, imageEntityDTO: ImageEntityDTO): Promise<CuestionarioDTO> {
    const res = await this.cuestionarioServiceBackend.insertCuestionariosCuestionario(cuestionario);
    if (res && imageEntityDTO.image) {
      await this.cuestionarioServiceBackend.insertCuestionariosCuestionarioIDCuestionarioImage(res.id, imageEntityDTO);
    }
    return res;
  }

  public async actualizarCuestionario(id: number, cuestionario: CuestionarioDTO, imageEntityDTO: ImageEntityDTO): Promise<CuestionarioDTO> {
    const res = await this.cuestionarioServiceBackend.updateCuestionariosCuestionarioIDCuestionario(id, cuestionario);
    if (res && imageEntityDTO.image) {
      await this.cuestionarioServiceBackend.insertCuestionariosCuestionarioIDCuestionarioImage(res.id, imageEntityDTO);
    }
    return res;
  }

  public async eliminarCuestionario(id: number): Promise<void> {
    await this.cuestionarioServiceBackend.deleteCuestionariosCuestionarioIDCuestionario(id);
  }

  public async insertarPlanificacionCuestionario(encuesta: EncuestaDTO): Promise<EncuestaDTO> {
    const res = await this.cuestionarioServiceBackend.insertCuestionariosPlanificacion(encuesta);
    return res;
  }

  public async obtenerFormularios(
    quizType: TipoCuestionario,
    startDate: Date,
    endDate: Date,
    pageNumber: number,
    pageSize: number,
    templateId: number = 0,
    sortActive: SortSurveySummary = 0,
    isAsc: boolean = false,
    state: EstadoEncuesta = undefined,
    evaluationType: TipoEvaluacionCuestionario = undefined,
    overallEvaluation: OverallEvaluationDTO = undefined,
  ): Promise<EncuestaResumenDTO[]> {
    const res = await this.cuestionarioServiceBackend.getCuestionariosEncuestas(
      quizType,
      startDate,
      endDate,
      pageNumber,
      pageSize,
      templateId,
      sortActive,
      isAsc,
      state,
      evaluationType,
      overallEvaluation?.id,
    );
    return res;
  }

  public async eliminarFormulario(idEncuesta: number): Promise<void> {
    await this.cuestionarioServiceBackend.deleteCuestionariosPlanificacionIdEncuesta(idEncuesta);
  }

  public async actualizarCambioEstadoFormulario(idEncuesta: number, accion: number): Promise<EncuestaDTO> {
    const res = await this.cuestionarioServiceBackend.updateCuestionariosPlanificacionIdEncuestaCambioEstado(idEncuesta, accion);
    return res;
  }

  public async getFormById(idEncuesta: number): Promise<EncuestaDTO> {
    const res = await this.cuestionarioServiceBackend.getCuestionariosEncuestasIdEncuesta(idEncuesta);
    return res;
  }

  public async updateForm(
    idEncuesta: number,
    encuesta: EncuestaDTO,
    mostrar: boolean = false,
    reanudar: boolean = false,
  ): Promise<EncuestaDTO> {
    const res = await this.cuestionarioServiceBackend.updateCuestionariosPlanificacionIdEncuesta(idEncuesta, encuesta, mostrar, reanudar);
    return res;
  }

  public async getExecutionsForm(idEncuesta: number): Promise<EncuestaEjecucionDTO[]> {
    const res = await this.cuestionarioServiceBackend.getCuestionariosEncuestasIdEncuestaEjecuciones(idEncuesta);
    return res;
  }

  public async exportForm(idForm: number): Promise<void> {
    const blob = new NamedBlobDTO();
    await this.cuestionarioServiceBackend.getCuestionariosEncuestasIdEncuestaEjecucionesXLSX(idForm, blob);
    this.fileDownloaderService.saveAs(blob);
  }

  public async getFormByIdSurvey(idSurvey: number): Promise<PersonalLegajoCuestionarioDTO> {
    const res = await this.cuestionarioServiceBackend.getCuestionariosCurrentUserSurveyIdSurvey(idSurvey);
    return res;
  }

  public async getCuestionariosEncuestasIdStats(form: PersonalLegajoCuestionarioDTO): Promise<EncuestaStatsDTO> {
    return this.cuestionarioServiceBackend.getCuestionariosEncuestasIdStats(form.id);
  }
}
