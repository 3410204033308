import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConfiguracionDTO } from 'src/app/ModelDTO/DTO/configuracion.DTO';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';

@Injectable({
   providedIn: 'root'
})
export class ConfiguracionServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getConfiguracionIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/configuracion/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getConfiguracionIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/configuracion/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getConfiguracion(): Promise<Array<ConfiguracionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/configuracion/'));
         const resJson = res.data;
         const resDTO = new Array<ConfiguracionDTO>();
         for (const item of resJson) {
            const itemDTO = new ConfiguracionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertConfiguracion(configuracionDTO: ConfiguracionDTO): Promise<ConfiguracionDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/configuracion/', configuracionDTO));
         const resDTO = new ConfiguracionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateConfiguracionId(id: number, configuracionDTO: ConfiguracionDTO): Promise<ConfiguracionDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/configuracion/' + id, configuracionDTO));
         const resDTO = new ConfiguracionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteConfiguracionId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/configuracion/' + id));

   }

   public async getConfiguracionId(id: number): Promise<ConfiguracionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/configuracion/' + id));
         if (!res) { return null; }
         const resDTO = new ConfiguracionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateConfiguracionIdPermisos(id: number, configuracionDTO: ConfiguracionDTO): Promise<ConfiguracionDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/configuracion/' + id + '/permisos', configuracionDTO));
         const resDTO = new ConfiguracionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async patchConfiguracionId(id: number, configuracionDTO: ConfiguracionDTO): Promise<ConfiguracionDTO> {

         const res = await lastValueFrom(this.http.patch(Constants.apiURL + '/configuracion/' + id, configuracionDTO));
         const resDTO = new ConfiguracionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
