import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';

export class CommentReactionDTO implements IEntityDTO {

   emoji = '';
   createDateTime: Date;
   teammateImage = '';
   teammateName = '';
   teammateId = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.emoji != null) { this.emoji = jsonObj.emoji; }
      if (jsonObj.createDateTime != null) { this.createDateTime = new Date(jsonObj.createDateTime); }
      if (jsonObj.teammateImage != null) { this.teammateImage = jsonObj.teammateImage; }
      if (jsonObj.teammateName != null) { this.teammateName = jsonObj.teammateName; }
      if (jsonObj.teammateId != null) { this.teammateId = jsonObj.teammateId; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
