import { Pipe, PipeTransform } from '@angular/core';
import { PersonalLegajoSector } from 'src/app/app-common/organigramas/detalle-personas-por-areas-modal/personal-legajo-sector';
import { INodeDTO } from 'src/app/ModelDTO/DTO/iNode.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { Constants, noProfileImage } from 'src/app/shared/constants';

@Pipe({
  name: 'profileImageThumbnailUrlPipe',
  standalone: true,
})
export class ProfileImageThumbnailUrlPipe implements PipeTransform {
  public transform(personal: PersonalLegajoBasicoDTO | PersonalLegajoSector | INodeDTO | string, ...args: unknown[]): string {
    const pixelsSize = args.length > 0 ? (args[0] as number) : 96;
    const queryParams = pixelsSize < 100 ? '&pixelsSize=96' : '';
    // Las fotos estan en el CDN con un tamaño fijo de 96px, si es mas grande la pedimos de tamaño original
    if (typeof personal === 'string' && personal.length) {
      return personal.startsWith('http') ? `${personal}${queryParams}` : `${Constants.apiURL}/ownimage${personal}&pixelsSize=${pixelsSize}`;
    }

    if (typeof personal !== 'string' && personal?.image) {
      return personal.image.startsWith('http')
        ? `${personal.image}${queryParams}`
        : `${Constants.apiURL}/ownimage${personal.image}&pixelsSize=${pixelsSize}`;
    }
    return noProfileImage;
  }
}
