import { AttachmentSignatureFlatDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/attachmentSignatureFlat.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';


export class EstadisticasFirmasDTO implements IEntityDTO {

   totalFirmasSolicitadas = 0;
   totalFirmasRealizadas = 0;
   totalFirmasPendientes = 0;
   totalFirmasPendientesColaborador = 0;
   signatures: Array<AttachmentSignatureFlatDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.signatures = new Array<AttachmentSignatureFlatDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.totalFirmasSolicitadas != null) { this.totalFirmasSolicitadas = jsonObj.totalFirmasSolicitadas; }
      if (jsonObj.totalFirmasRealizadas != null) { this.totalFirmasRealizadas = jsonObj.totalFirmasRealizadas; }
      if (jsonObj.totalFirmasPendientes != null) { this.totalFirmasPendientes = jsonObj.totalFirmasPendientes; }
      if (jsonObj.totalFirmasPendientesColaborador != null) { this.totalFirmasPendientesColaborador = jsonObj.totalFirmasPendientesColaborador; }
      if (jsonObj.signatures != null) { for (const item of jsonObj.signatures) { const itemDTO = new AttachmentSignatureFlatDTO(); itemDTO.PrepareDTO(item); this.signatures.push(itemDTO); } }      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
