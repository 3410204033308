import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AlertButton } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { TypeQuestionComponent } from 'src/app/shared/forms/cuestionario-tipo-pregunta/typeQuestion.component';
import { PreguntaOpcionDTO } from 'src/app/ModelDTO/DTO/preguntaOpcion.DTO';
import { CuestionarioPreguntaDTO } from 'src/app/ModelDTO/DTO/cuestionarioPregunta.DTO';

@Component({
  selector: 'app-question-option',
  templateUrl: './question-option.component.html',
  styleUrls: ['./question-option.component.scss'],
})
export class QuestionOptionComponent implements OnInit, TypeQuestionComponent {
  @Input() public quizQuestionForm: UntypedFormGroup;
  @Input() public optionEdit: PreguntaOpcionDTO;
  @Input() public questionEdit: CuestionarioPreguntaDTO;
  @Input() public set withEvaluation(value: boolean) {
    this.getWithEvaluation = value;
    if (!value) {
      this.optionQuestionForm.controls.selectedWeight.disable();
    } else {
      this.optionQuestionForm.controls.selectedWeight.enable();
    }
  }

  public options: PreguntaOpcionDTO[] = [];
  public optionQuestionForm = new UntypedFormGroup({
    text: new UntypedFormControl('', Validators.required),
    selectedWeight: new UntypedFormControl(0, Validators.required),
  });

  public get withEvaluation(): boolean {
    return this.getWithEvaluation;
  }
  private getWithEvaluation: boolean;

  constructor(private neoModalService: NgxNeoModalMatService) {}

  public ngOnInit(): void {
    if (this.questionEdit.texto.length) {
      this.options = this.questionEdit.opciones;
    }
  }

  public async removeOption(preguntaOpcionDTO: PreguntaOpcionDTO): Promise<void> {
    const res = await this.neoModalService.decision('¿Estás seguro que deseas eliminar esta opción?');
    if (res.ButtonResponse === AlertButton.Accept) {
      const indexOption = this.options.indexOf(preguntaOpcionDTO);
      this.options.splice(indexOption, 1);
      this.options = [...this.options];
      if (this.optionEdit?.texto?.length) {
        this.optionEdit = new PreguntaOpcionDTO();
        this.optionQuestionForm.patchValue({
          text: '',
          selectedWeight: 0,
        });
      }
    }
    this.quizQuestionForm.patchValue({
      options: this.options,
    });
  }

  public editOption(preguntaOpcionDTO: PreguntaOpcionDTO): void {
    this.optionEdit = preguntaOpcionDTO;
    this.optionQuestionForm.patchValue({
      text: this.optionEdit.texto,
      selectedWeight: this.optionEdit.pesoSeleccionado,
    });
  }

  public addOption(): void {
    if (this.optionQuestionForm.valid) {
      if (this.optionEdit?.texto?.length) {
        const i = this.options.indexOf(this.optionEdit);
        this.options[i].texto = this.optionQuestionForm.value.text;
        this.options[i].pesoSeleccionado = !this.withEvaluation ? 0 : this.optionQuestionForm.value.selectedWeight;
      } else {
        const option = new PreguntaOpcionDTO();
        option.texto = this.optionQuestionForm.value.text;
        option.pesoSeleccionado = !this.withEvaluation ? 0 : this.optionQuestionForm.value.selectedWeight;
        this.options.push(option);
      }

      this.options = [...this.options];
      this.quizQuestionForm.patchValue({
        options: this.options,
      });
      this.optionQuestionForm.reset();
      this.optionEdit = new PreguntaOpcionDTO();
    } else {
      this.optionQuestionForm.markAllAsTouched();
    }
  }
}
