import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { PersonalLegajoBasicoDTO as PersonalLegajoBasicoDTOInterface } from '@api/interfaces/personal-legajo-basico.interface';
import { ReclutamientoCandidatoDTO as ReclutamientoCandidatoDTOInterface } from '@api/interfaces/reclutamiento-candidato.interface';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { OrganigramaService } from 'src/app/layout/administrador/colaboradores/organigrama.service';
import { PersonalExtendModel } from 'src/app/layout/administrador/colaboradores/PersonalExtend.model';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { ReclutamientoCandidatoDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidato.DTO';
import { NgxNeoDirectivesModule } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-neo-directives.module';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { ProfileImageThumbnailUrlPipe } from 'src/app/shared/pipes/profile-image-thumbnail-url.pipe';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoDTO } from 'src/app/ModelDTO/DTO/personalLegajo.DTO';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { PersonalServiceBackend } from 'src/app/ServiceBackend/personal.ServiceBackend';
import { UserTeammateFile } from './user-legajo.interface';

export type ActionType = 'openModalProfile' | 'socialProfile';

@Component({
  selector: 'app-circle-image',
  templateUrl: './circle-image.component.html',
  styleUrls: ['./circle-image.component.scss'],
  standalone: true,
  imports: [CommonModule, NgxNeoDirectivesModule, MatTooltipModule, TranslateModule],
  providers: [ProfileImageThumbnailUrlPipe],
})
export class CircleImageComponent {
  @Input() public width = 100;
  @Input() public fontSize = 'medium';
  @Input() public circle = true;
  @Input() public positionY = 'center';
  @Input() public invalid = false;
  @Input() public forceActionType?: ActionType;
  @Input() public stopPropagation = true;
  @Input() public openProfile = true;
  @Input() public borderColor = 'whitesmoke';
  @Input() public borderWidth = '3px';
  @Input() public showLabel = true;

  @Input() public set imageSource(value: string) {
    this.image = value;
    this.updateImageSource();
  }

  @Input() public set personalLegajo(
    personalLegajo:
      | PersonalLegajoBasicoDTO
      | PersonalLegajoDTO
      | PersonalExtendModel
      | PersonalDTO
      | ReclutamientoCandidatoDTO
      | ReclutamientoCandidatoDTOInterface
      | PersonalLegajoBasicoDTOInterface
      | UserTeammateFile,
  ) {
    this.user = personalLegajo;
    if (this.user) {
      if (this.user.nombreCompleto) {
        const split = (this.user.nombreCompleto as string).split(' ');
        this.initials = split[0].substring(0, 1) + (split.length > 1 ? split[1].substring(0, 1) : '');
      } else {
        this.initials = (this.user.apellido?.substring(0, 1) ?? '') + (this.user.nombre?.substring(0, 1) ?? '');
      }
    }
    this.updateImageSource();
  }

  public initials: string;
  public user;
  public backgroundImage: string;

  public openDetailsCmd: ICommand = new Command(() => this.openDetails(), new BehaviorSubject(true), true);

  public get imageSource(): string {
    return this.image;
  }

  private image: string;

  constructor(
    private dialog: MatDialog,
    private perfilEmpleadoSharedService: PerfilEmpleadoSharedService,
    private organigramaService: OrganigramaService,
    private profileImagePipe: ProfileImageThumbnailUrlPipe,
    private personalServiceBackend: PersonalServiceBackend,
    private headerService: HeaderAppService,
    private router: Router,
  ) {}

  public async openDetails(): Promise<void> {
    if (!this.user?.id || !this.openProfile) return;
    this.dialog.closeAll();
    let teammate: PersonalModelDTO;

    const actionType = this.forceActionType ?? (this.headerService.modoEmpresa() ? 'openModalProfile' : 'socialProfile');
    if (this.user instanceof PersonalExtendModel || this.user instanceof PersonalDTO) {
      teammate = await this.perfilEmpleadoSharedService.obtenerPersonal(this.user.id);

      if (this.isSocialProfileAction(actionType)) {
        this.navigateToSocialProfile(teammate.getEntityDTO().legajo.id);
      } else {
        this.organigramaService.openPersonalDetails(teammate.getEntityDTO());
      }
      return;
    }

    if (this.isSocialProfileAction(actionType)) {
      this.navigateToSocialProfile(this.user.teammateFileId || this.user.id);
      return;
    }

    teammate = await this.getTeammate(this.user.teammateFileId || this.user.id);

    this.organigramaService.openPersonalDetails(teammate.getEntityDTO());
  }

  public getFullName(): string {
    if (this.user?.nombreCompleto) {
      return this.user.nombreCompleto;
    }

    if (this.user?.nombre && this.user?.apellido) {
      return `${this.user?.nombre} ${this.user?.apellido}`;
    }

    return '';
  }

  public updateImageSource(): void {
    this.backgroundImage = `url(${this.imageSource ? this.imageSource : this.profileImagePipe.transform(this.user, this.width)})`;
  }

  private isSocialProfileAction(actionType: string): boolean {
    return actionType === 'socialProfile';
  }

  private navigateToSocialProfile(id: number): void {
    this.router.navigate(['/user/social-profile', id]);
  }

  private async getTeammate(id: number): Promise<PersonalModelDTO> {
    const teammate = await this.personalServiceBackend.getPersonalLegajoId(id);
    return new PersonalModelDTO(teammate);
  }
}
