import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { ResultadoImportacionDTO } from 'src/app/ModelDTO/DTO/resultadoImportacion.DTO';

@Injectable({
   providedIn: 'root'
})
export class ImportacionConvenioServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getImportacionesConveniosIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/importacionesConvenios/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertImportacionesConvenios(stream: FormData): Promise<ResultadoImportacionDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/importacionesConvenios/', stream));
         const resDTO = new ResultadoImportacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
