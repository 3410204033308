import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { BusinessNameDTO } from 'src/app/ModelDTO/DTO/businessName.DTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';
import { PersonalAreaDTO } from 'src/app/ModelDTO/DTO/personalArea.DTO';
import { PersonalSectorDTO } from 'src/app/ModelDTO/DTO/personalSector.DTO';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { PersonalExtendModel } from './PersonalExtend.model';

@Injectable({
  providedIn: 'root',
})
export class TeammatesListService {
  public userIndex: number;
  public userFilter: string;
  public oficinaFilter: OficinaDTO;
  public businessNameFilter: BusinessNameDTO;
  public areaFilter: PersonalAreaDTO;
  public sectorFilter: PersonalSectorDTO;
  public sinResultados: boolean;
  public showActiveOnly: boolean;

  // Colaboradores
  public arrayPersonal: PersonalExtendModel[] = [];
  public arrayPersonalFiltrado: PersonalExtendModel[] = [];

  constructor(protected headerService: HeaderAppService) {
    this.clearViewModel();

    this.headerService.loggedOut$.pipe(takeUntilDestroyed()).subscribe(() => this.clearViewModel());
  }

  public actualizarColaborador(personal: PersonalDTO): void {
    const index = this.arrayPersonal.findIndex((x) => x.id === personal.id);
    if (index !== -1) {
      const updatedTeammate = new PersonalExtendModel(this.arrayPersonal[index]);

      updatedTeammate.PrepareDTO({
        ...this.arrayPersonal[index],
        fechaIngreso: personal.legajo.fechaIngreso,
        nombreCompleto: personal.nombreCompleto,
        area: personal.legajo.puestos[0]?.area.nombre,
        cargo: personal.legajo.puestos[0]?.cargo.nombre,
        image: personal.image,
        activo: personal.legajo.estadoActual.tipo.esAlta,
        profileCompleteness: personal.profileCompleteness,
      });

      this.arrayPersonal = this.arrayPersonal.map((teammate) => (teammate.id === personal.id ? updatedTeammate : teammate));
      this.onFilterChanged();
    }
  }

  public onFilterChanged(): void {
    this.arrayPersonalFiltrado = this.showActiveOnly ? this.arrayPersonal.filter((arr) => arr.activo) : this.arrayPersonal;

    if (this.userFilter) {
      this.arrayPersonalFiltrado = this.arrayPersonalFiltrado.filter((colaborador) => {
        const searchValue = `${colaborador.nombreCompleto} ${colaborador.dni} ${colaborador.cuil} ${colaborador.legajo}`.toLowerCase();

        return searchValue.includes(this.userFilter?.toLowerCase());
      });
    }

    if (this.oficinaFilter) {
      this.arrayPersonalFiltrado = this.arrayPersonalFiltrado.filter(
        (colaborador) => colaborador.oficinaNombre === this.oficinaFilter.nombre,
      );
    }

    if (this.businessNameFilter) {
      this.arrayPersonalFiltrado = this.arrayPersonalFiltrado.filter(
        (colaborador) => colaborador.businessNameId === this.businessNameFilter.id,
      );
    }

    if (this.areaFilter && this.areaFilter.nombre !== 'Todas') {
      this.arrayPersonalFiltrado = this.arrayPersonalFiltrado.filter((colaborador) => colaborador.area === this.areaFilter.nombre);
      if (this.sectorFilter && this.sectorFilter.nombre !== 'Todos') {
        this.arrayPersonalFiltrado = this.arrayPersonalFiltrado.filter((colaborador) => colaborador.sector === this.sectorFilter.nombre);
      }
    }

    this.sinResultados = this.arrayPersonalFiltrado.length <= 0;
  }

  private clearViewModel(): void {
    this.userIndex = 0;
    this.userFilter = '';
    this.oficinaFilter = undefined;
    this.businessNameFilter = undefined;
    this.sinResultados = false;
    this.areaFilter = undefined;
    this.sectorFilter = undefined;
    this.showActiveOnly = true;
  }
}
