import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { TipoDeMovimiento } from 'src/app/ModelDTO/DTO/tipoDeMovimiento.ENUM';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';


export class CuentaCorrienteDTO implements IEntityDTO {

   fecha: Date;
   tipoDeMovimiento: TipoDeMovimiento;
   colaborador: PersonalLegajoBasicoDTO;
   ausencia: AusenciaDTO;
   debe = 0;
   haber = 0;
   idRenovacionAcumulado: number;
   saldo = 0;
   id = 0;
   cacheStamp = 0;
   observations: string;

   constructor() {
      this.colaborador = new PersonalLegajoBasicoDTO();
      this.ausencia = new AusenciaDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fecha != null) { this.fecha = new Date(jsonObj.fecha); }
      if (jsonObj.tipoDeMovimiento != null) { this.tipoDeMovimiento = jsonObj.tipoDeMovimiento as TipoDeMovimiento; }
      if (jsonObj.colaborador != null) { this.colaborador.PrepareDTO(jsonObj.colaborador); }
      if (jsonObj.ausencia != null) { this.ausencia.PrepareDTO(jsonObj.ausencia); }
      if (jsonObj.debe != null) { this.debe = jsonObj.debe; }
      if (jsonObj.haber != null) { this.haber = jsonObj.haber; }
      if (jsonObj.idRenovacionAcumulado != null) { this.idRenovacionAcumulado = jsonObj.idRenovacionAcumulado; }
      if (jsonObj.saldo != null) { this.saldo = jsonObj.saldo; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
      if (jsonObj.observations != null) {this.observations = jsonObj.observations; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
