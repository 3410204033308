import { Component, Input } from '@angular/core';
import { TipoEstadoPersonalLegajoCuestinario } from '@api/enums/tipo-estado-personal-legajo-cuestinario.enum';
import { CuestionarioPreguntaDTO } from 'src/app/ModelDTO/DTO/cuestionarioPregunta.DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';

@Component({
  selector: 'app-question-group',
  templateUrl: './question-group.component.html',
  styleUrls: ['./question-group.component.scss'],
})
export class QuestionGroupComponent {
  @Input() public data: CuestionarioPreguntaDTO;
  @Input() public modoAdministrador: boolean;
  @Input() public editionMode: boolean;
  @Input() public estadoCuestionario: TipoEstadoPersonalLegajoCuestinario;
  @Input() public cuestionarioTerminado: TipoEstadoPersonalLegajoCuestinario;
  @Input() public position: number;
  @Input() public previousResult: LevelRangeDTO;
}
