import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';

@Injectable({
   providedIn: 'root'
})
export class OficinasServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getOficinasIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/oficinas/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getOficinasIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/oficinas/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async insertOficinas(oficinaDTO: OficinaDTO): Promise<OficinaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/oficinas/', oficinaDTO));
         const resDTO = new OficinaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateOficinasId(id: number, oficinaDTO: OficinaDTO): Promise<OficinaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/oficinas/' + id, oficinaDTO));
         const resDTO = new OficinaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteOficinasId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/oficinas/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getOficinasId(id: number): Promise<OficinaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/oficinas/' + id));
         if (!res) { return null; }
         const resDTO = new OficinaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getOficinas(): Promise<Array<OficinaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/oficinas/'));
         const resJson = res.data;
         const resDTO = new Array<OficinaDTO>();
         for (const item of resJson) {
            const itemDTO = new OficinaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getOficinasAutoComplete(contiene: string, pageSize: number): Promise<Array<OficinaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/oficinas/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<OficinaDTO>();
         for (const item of resJson) {
            const itemDTO = new OficinaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

}
