import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { SolicitudEntregaDTO } from 'src/app/ModelDTO/DTO/solicitudEntrega.DTO';
import { PersonalEntregaDTO } from 'src/app/ModelDTO/DTO/personalEntrega.DTO';
import { PersonalEntregaModelDTO } from 'src/app/ModelDTO/personalEntrega.ModelDTO';
import { EstadoAprobacionSolicitud } from 'src/app/ModelDTO/DTO/estadoAprobacionSolicitud.ENUM';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { AttachmentUaalooModelDTO } from 'src/app/ModelDTO/attachmentUaaloo.ModelDTO';


export class SolicitudEntregaModelDTO extends EntityModelDTO<SolicitudEntregaDTO> {

   private entregaModel: PersonalEntregaModelDTO;
   private entregaSubscribe: Subscription;
   private solicitanteModel: PersonalLegajoBasicoModelDTO;
   private solicitanteSubscribe: Subscription;
   private responsableModel: PersonalLegajoBasicoModelDTO;
   private responsableSubscribe: Subscription;

   public constructor(protected entityDTO: SolicitudEntregaDTO) {
      super(entityDTO);
   }

   public static getEstadoAprobacionSolicitud(): string[] {
      return EntityModelDTO.getEnumArray(EstadoAprobacionSolicitud);
   }

    protected getStringFromEstadoAprobacionSolicitud(enums: EstadoAprobacionSolicitud): Array<string> {
        if (enums) {
            const arrays = new Array<string>();
            for (let i = 0; i <= 31; i = i++) {
                const pow = Math.pow(2, i);
                if ((enums & pow) !== 0) {
                    arrays.push(EstadoAprobacionSolicitud[pow]);
                }
            }
            return arrays;
        } else {
            return undefined;
        }
    }

    protected getFlagFromEstadoAprobacionSolicitudString(strings: Array<string>): EstadoAprobacionSolicitud {
        let flags: EstadoAprobacionSolicitud;
        strings.forEach(element => {
            const enumVal: EstadoAprobacionSolicitud = EstadoAprobacionSolicitud[element];
            flags |= enumVal;
        });
        return flags;
    }

   public setEntityDTO(entityDTO: SolicitudEntregaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.entregaModel = new PersonalEntregaModelDTO(this.entityDTO.entrega);
      this.entregaSubscribe = this.entregaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.solicitanteModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.solicitante);
      this.solicitanteSubscribe = this.solicitanteModel.changed.subscribe((changed) => this.changed.next(changed));
      this.responsableModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.responsable);
      this.responsableSubscribe = this.responsableModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.entregaModel.dispose();
      this.entregaSubscribe.unsubscribe();
      this.solicitanteModel.dispose();
      this.solicitanteSubscribe.unsubscribe();
      this.responsableModel.dispose();
      this.responsableSubscribe.unsubscribe();
   }

   get EntregaModel(): PersonalEntregaModelDTO { return this.entregaModel; }
   get Entrega(): PersonalEntregaDTO { return this.entregaModel.getEntityDTO(); }
   set Entrega(value: PersonalEntregaDTO) { this.notifyChange(() => { this.entityDTO.entrega = value; this.entregaModel.setEntityDTO(value); }); }

   get Estado(): string { return EstadoAprobacionSolicitud[this.entityDTO.estado]; }
   set Estado(value: string) { this.notifyChangeDTO('estado', EstadoAprobacionSolicitud[value]); }

   get EstadoArray(): Array<string> { return this.getStringFromEstadoAprobacionSolicitud(this.entityDTO.estado); }
   set EstadoArray(value: Array<string>) { this.notifyChangeDTO('estado', this.getFlagFromEstadoAprobacionSolicitudString(value)); }

   get Inicio(): Date { return this.entityDTO.inicio; }
   set Inicio(value: Date) { this.notifyChangeDTO('inicio', value); }

   get CambioEstado(): Date { return this.entityDTO.cambioEstado; }
   set CambioEstado(value: Date) { this.notifyChangeDTO('cambioEstado', value); }

   get SolicitanteModel(): PersonalLegajoBasicoModelDTO { return this.solicitanteModel; }
   get Solicitante(): PersonalLegajoBasicoDTO { return this.solicitanteModel.getEntityDTO(); }
   set Solicitante(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.solicitante = value; this.solicitanteModel.setEntityDTO(value); }); }

   get Motivo(): string { return this.entityDTO.motivo; }
   set Motivo(value: string) { this.notifyChangeDTO('motivo', value); }

   get Adjuntos(): Array<AttachmentUaalooDTO> { return this.entityDTO.adjuntos; }
   set Adjuntos(value: Array<AttachmentUaalooDTO>) { this.notifyChangeDTO('adjuntos', value); }

   get ResponsableModel(): PersonalLegajoBasicoModelDTO { return this.responsableModel; }
   get Responsable(): PersonalLegajoBasicoDTO { return this.responsableModel.getEntityDTO(); }
   set Responsable(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.responsable = value; this.responsableModel.setEntityDTO(value); }); }

   get ObservacionSupervisor(): string { return this.entityDTO.observacionSupervisor; }
   set ObservacionSupervisor(value: string) { this.notifyChangeDTO('observacionSupervisor', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
