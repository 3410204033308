export const colors = {
  color_blue_300: '#9bcffb',
  color_blue_400: '#69b7fa',
  color_primary: '#379ff8', // color_blue_500
  color_blue_800: '#004c8f',

  color_blue_sky_300: '#b4bbf3',
  color_blue_sky_500: '#6977e7',

  color_green_500: '#34b16c',

  color_green_apple_300: '#b1ee98',
  color_green_apple_400: '#a1ea85',
  color_green_apple_500: '#8ae566',

  color_gray_100: '#f9fafb',
  color_gray_200: '#f0f1f2',
  color_gray_300: '#e5e7eb',
  color_gray_400: '#d1d5db',
  color_gray_500: '#9ca3af',
  color_gray_600: '#6b7280',
  color_gray_700: '#4b5563',

  color_orange_300: '#f8c37f',
  color_orange_400: '#f7b766',
  color_orange_500: '#f5a540',

  color_brown_500: '#8c6d5e',

  color_violet_300: '#ba94d4',
  color_violet_400: '#ad7fcc',
  color_violet_500: '#985fbf',
  color_violet_700: '#6c4388',

  color_pink_400: '#eb89ae',
  color_pink_500: '#e66b9a',

  color_yellow_300: '#fce988',
  color_yellow_400: '#fce571',
  color_yellow_500: '#fbde4d',

  color_red_400: '#eb616b',
  color_red_500: '#e63946',

  color_black: '#030303',
  color_white: '#ffffff',
};
