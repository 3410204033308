import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { CompetenceCategoryBasicDTO } from 'src/app/ModelDTO/DTO/competenceCategoryBasic.DTO';
import { CompetenceTypeDTO } from 'src/app/ModelDTO/DTO/competenceType.DTO';
import { CompetenceSkillDTO } from 'src/app/ModelDTO/DTO/competenceSkill.DTO';
import { QuestionCompetenceClass } from 'src/app/ModelDTO/DTO/questionCompetenceClass.ENUM';


export class QuestionCompetenceDTO implements IEntityDTO {

   class: QuestionCompetenceClass;
   questionId = 0;
   category: CompetenceCategoryBasicDTO;
   type: CompetenceTypeDTO;
   skill: CompetenceSkillDTO;
   id = 0;

   constructor() {


   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.class != null) { this.class = jsonObj.class as QuestionCompetenceClass; }
      if (jsonObj.questionId != null) { this.questionId = jsonObj.questionId; }
      if (jsonObj.category != null) {
        this.category = new CompetenceCategoryBasicDTO();
        this.category.PrepareDTO(jsonObj.category);
    }
      if (jsonObj.type != null) {
        this.type = new CompetenceTypeDTO();
        this.type.PrepareDTO(jsonObj.type);
    }
      if (jsonObj.skill != null) {
        this.skill = new CompetenceSkillDTO();
        this.skill.PrepareDTO(jsonObj.skill);
    }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
