import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';

export class FeatureDTO implements IEntityDTO {

   name = '';
   due: Date;
   notifyBefore = 0;
   limit: number;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.due != null) { this.due = new Date(jsonObj.due); }
      if (jsonObj.notifyBefore != null) { this.notifyBefore = jsonObj.notifyBefore; }
      if (jsonObj.limit != null) { this.limit = jsonObj.limit; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
