import { Injectable } from '@angular/core';
import { TipoFeedbackServiceBackend } from 'src/app/ServiceBackend/tipoFeedback.ServiceBackend';
import { TipoFeedbackDTO } from 'src/app/ModelDTO/DTO/tipoFeedback.DTO';
import { FeedbackDTO } from 'src/app/ModelDTO/DTO/feedback.DTO';
import { PersonalFeedbackDTO } from 'src/app/ModelDTO/DTO/personalFeedback.DTO';
import { PersonalLegajoServiceBackend } from 'src/app/ServiceBackend/personalLegajo.ServiceBackend';
import { FeedbackServiceBackend } from 'src/app/ServiceBackend/feedback.ServiceBackend';
import { PersonalFeedbackResumenDTO } from 'src/app/ModelDTO/DTO/personalFeedbackResumen.DTO';
import { PersonalFeedbackModel } from 'src/app/layout/administrador/feedback/personalFeedback.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  public get indiceSeleccionado(): number {
    return this.pIndiceSeleccionado;
  }

  public set indiceSeleccionado(value: number) {
    this.pIndiceSeleccionado = value;
  }

  private pIndiceSeleccionado: number;

  constructor(
    private personalFeedback: PersonalLegajoServiceBackend,
    private feedbackServiceBackend: FeedbackServiceBackend,
    private tipoFeedbackServiceBackend: TipoFeedbackServiceBackend,
  ) {}

  public async obtenerTipoFeedbackPersonal(id: number): Promise<PersonalFeedbackResumenDTO[]> {
    const res = await this.personalFeedback.getPersonalLegajosIdFeedbackResumen(id);
    return res;
  }

  public async obtenerPersonalConFeedback(pageNumber: number, pageSize: number): Promise<PersonalFeedbackModel[]> {
    const arrayPersonalModel: PersonalFeedbackModel[] = [];
    const res = await this.feedbackServiceBackend.getFeedbackConFeedback(pageNumber, pageSize);
    res.forEach((x) => {
      const personalModel = new PersonalFeedbackModel(x);
      arrayPersonalModel.push(personalModel);
    });
    return arrayPersonalModel;
  }

  public async obtenerTiposDeFeedback(): Promise<Array<TipoFeedbackDTO>> {
    const res = await this.tipoFeedbackServiceBackend.getTipoFeedback();
    return res;
  }

  public async obtenerFeedbacks(idPersonalLegajo: number, todas: boolean, fechaDesde: Date, fechaHasta: Date): Promise<Array<FeedbackDTO>> {
    const res = await this.feedbackServiceBackend.getFeedbackPersonalLegajoId(idPersonalLegajo, fechaDesde, fechaHasta, todas);
    return res;
  }

  public async agregarFeedback(idPersonalLegajo: number, formFeedback: FormData): Promise<FeedbackDTO> {
    const res = await this.feedbackServiceBackend.insertFeedbackPersonalLegajoId(idPersonalLegajo, formFeedback);
    return res;
  }

  public async eliminarFeedback(feedback: FeedbackDTO): Promise<void> {
    await this.feedbackServiceBackend.deleteFeedbackId(feedback.id);
  }

  public async obtenerFeedback(idFeedback: number): Promise<PersonalFeedbackDTO> {
    const res = await this.feedbackServiceBackend.getFeedbackId(idFeedback);
    return res;
  }

  public async getAllFeedbacks(pageNumber: number, pageSize: number): Promise<PersonalFeedbackModel[]> {
    const arrayPersonalModel: PersonalFeedbackModel[] = [];
    const res = await this.feedbackServiceBackend.getFeedback(pageNumber, pageSize);
    res.forEach((x) => {
      const personalModel = new PersonalFeedbackModel(x);
      arrayPersonalModel.push(personalModel);
    });
    return arrayPersonalModel;
  }

  public dispose(): void {
    this.pIndiceSeleccionado = null;
  }
}
