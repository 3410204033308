import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { CargoBasicoDTO } from 'src/app/ModelDTO/DTO/cargoBasico.DTO';


export class TipoCapacitacionFlatDTO implements IEntityDTO {

   nombre = '';
   convenioId: number;
   convenioNombreCompleto = '';
   categoriaId: number;
   categoriaNombre = '';
   cargos: Array<CargoBasicoDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.cargos = new Array<CargoBasicoDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.convenioId != null) { this.convenioId = jsonObj.convenioId; }
      if (jsonObj.convenioNombreCompleto != null) { this.convenioNombreCompleto = jsonObj.convenioNombreCompleto; }
      if (jsonObj.categoriaId != null) { this.categoriaId = jsonObj.categoriaId; }
      if (jsonObj.categoriaNombre != null) { this.categoriaNombre = jsonObj.categoriaNombre; }
      if (jsonObj.cargos != null) { for (const item of jsonObj.cargos) { const itemDTO = new CargoBasicoDTO(); itemDTO.PrepareDTO(item); this.cargos.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
