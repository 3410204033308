import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { CambiosHistoricosDTO } from 'src/app/ModelDTO/DTO/cambiosHistoricos.DTO';
import { CambioDTO } from 'src/app/ModelDTO/DTO/cambio.DTO';
import { CambioModelDTO } from 'src/app/ModelDTO/cambio.ModelDTO';


export class CambiosHistoricosModelDTO extends EntityModelDTO<CambiosHistoricosDTO> {


   public constructor(protected entityDTO: CambiosHistoricosDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: CambiosHistoricosDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get Cargos(): Array<CambioDTO> { return this.entityDTO.cargos; }
   set Cargos(value: Array<CambioDTO>) { this.notifyChangeDTO('cargos', value); }

   get Sueldos(): Array<CambioDTO> { return this.entityDTO.sueldos; }
   set Sueldos(value: Array<CambioDTO>) { this.notifyChangeDTO('sueldos', value); }

   get Categoria(): Array<CambioDTO> { return this.entityDTO.categoria; }
   set Categoria(value: Array<CambioDTO>) { this.notifyChangeDTO('categoria', value); }

   get Subcategoria(): Array<CambioDTO> { return this.entityDTO.subcategoria; }
   set Subcategoria(value: Array<CambioDTO>) { this.notifyChangeDTO('subcategoria', value); }

   get Area(): Array<CambioDTO> { return this.entityDTO.area; }
   set Area(value: Array<CambioDTO>) { this.notifyChangeDTO('area', value); }

   get Sector(): Array<CambioDTO> { return this.entityDTO.sector; }
   set Sector(value: Array<CambioDTO>) { this.notifyChangeDTO('sector', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
