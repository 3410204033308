import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, catchError, map, of } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { CHAT_REST_API_URL } from 'src/app/shared/constants';
import { AuthenticationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/auth/authentication.service';
import { DateTime } from 'luxon';
import { WebSocketService } from 'src/app/shared/services/web-socket.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ChatConversationDTO, ChatConversation } from './interfaces/chat-conversation.interface';
import { ChatMessageDTO, ChatMessage, SystemChatMessage } from './interfaces/chat-message.interface';
import { MessageResponse } from './interfaces/chat-backend-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ChatBackendService {
  private messageReceived: Subject<ChatMessage> = new Subject<ChatMessage>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public onMessageReceived$: Observable<ChatMessage> = this.messageReceived.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public chatConnected$: Observable<boolean> = this.webSocketService.connected$;

  private userId: string;
  private tenantId: string;

  constructor(
    private http: HttpClient,
    private headerService: HeaderAppService,
    private webSocketService: WebSocketService,
    authenticationService: AuthenticationService,
  ) {
    this.connectToWebSocket();

    authenticationService.loggedEvent$.pipe(takeUntilDestroyed()).subscribe((logData) => {
      if (logData?.token) {
        this.userId = this.headerService.userLogged.userName;
        this.tenantId = this.headerService.tenant;
      }
    });
  }

  /*
  --------------------------- API REST -------------------------------
  */
  public getConversations(): Observable<ChatConversation[]> {
    return this.http
      .get<ChatConversationDTO[]>(`${CHAT_REST_API_URL}/users/${this.userId}/channels`)
      .pipe(map((conversations) => conversations.map((conversation) => this.parseConversationDTO(conversation))));
  }

  public getConversation(id: string): Observable<ChatConversation> {
    return this.http
      .get<{ channel: ChatConversationDTO }>(`${CHAT_REST_API_URL}/channels/${id}`)
      .pipe(map(({ channel }) => this.parseConversationDTO(channel)));
  }

  public getMessages(conversation: ChatConversation): Observable<{ messages: ChatMessage[]; lastMessageKey: string }> {
    const { id, lastMessageKey } = conversation;
    const lastMessageKeyParam = lastMessageKey ? `?lastMessageKey=${encodeURI(JSON.stringify(lastMessageKey))}` : '';
    return this.http
      .get<{ messages: ChatMessageDTO[]; lastMessageKey: string }>(`${CHAT_REST_API_URL}/channels/${id}/messages${lastMessageKeyParam}`)
      .pipe(
        map((res) => ({
          messages: res.messages.reverse().map((message) => this.parseMessageDTO(message)),
          lastMessageKey: res.lastMessageKey,
        })),
      );
  }

  public notifyReadMessage(message: ChatMessage): Observable<void> {
    if (message) {
      const body = {
        lastMessageRead: message.id,
      };
      return this.http.patch<void>(`${CHAT_REST_API_URL}/users/${this.userId}/channels/${message.channelId}`, body);
    }
    return EMPTY;
  }

  public getUnreadConversations(): Observable<number> {
    return this.http
      .get<{ unreadMessages: number }>(`${CHAT_REST_API_URL}/users/${this.userId}/unread-messages`)
      .pipe(map((result) => result.unreadMessages));
  }

  public insertConversation(conversation: ChatConversation): Observable<string> {
    const newConversation = {
      members: conversation.members,
      createdBy: this.userId,
      creationDate: DateTime.now(),
      ...(conversation.isGroup && {
        title: conversation.title,
        isGroup: true,
        admin: this.userId,
      }),
    };

    // returns the id of the created object
    return this.http.post(`${CHAT_REST_API_URL}/channels`, newConversation).pipe(map((data: any) => data.channelId));
  }

  public addGroupMembers(conversationId: string, memberIds: string[]): Observable<boolean> {
    const body = {
      members: memberIds,
    };
    return this.http.post(`${CHAT_REST_API_URL}/channels/${conversationId}/members`, body).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  public removeGroupMember(conversationId: string, memberId: string): Observable<boolean> {
    return this.http.delete(`${CHAT_REST_API_URL}/channels/${conversationId}/members/${memberId}`).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  /*
  --------------------------- WebSocket -------------------------------
  */

  public sendMessage(receiver: string, message: string): Observable<void> {
    const webSocketMessage = {
      tenantId: this.tenantId,
      channelId: receiver,
      userId: this.userId,
      content: message,
    };
    this.webSocketService.sendMessage(webSocketMessage);
    return of(undefined);
  }

  private connectToWebSocket(): void {
    this.webSocketService.subscribeToChannel('Message').subscribe((messageResponse: MessageResponse) => {
      this.messageReceived.next(this.parseMessageDTO(messageResponse.message));
    });
  }

  private parseConversationDTO(conversation: ChatConversationDTO): ChatConversation {
    let lastMessageDateParsed: DateTime;
    if (conversation.lastMessageData?.date) {
      lastMessageDateParsed = DateTime.fromISO(conversation.lastMessageData.date);
    } else if (conversation.lastMessageDate) {
      lastMessageDateParsed = DateTime.fromISO(conversation.lastMessageDate);
    } else {
      lastMessageDateParsed = DateTime.now();
    }

    return {
      ...conversation,
      title: conversation.title || '',
      creationDate: conversation.creationDate ? DateTime.fromISO(conversation.creationDate) : DateTime.now(),
      messages: [],
      unreadMsgs: conversation.lastMessageUser !== this.userId && conversation.lastMessageId !== conversation.lastMessageRead,
      loadedMessages: false,
      lastMessage: {
        ...conversation.lastMessageData,
        date: lastMessageDateParsed,
        sender: conversation.lastMessageData?.sender || conversation.lastMessageUser,
        content: conversation.lastMessageData?.content || conversation.lastMessage,
      },
    };
  }

  private parseMessageDTO(message: ChatMessageDTO): ChatMessage {
    if (message.type === 'System') {
      return {
        ...message,
        id: message.timestamp,
        creationDate: DateTime.fromISO(message.creationDate),
      } as SystemChatMessage;
    }
    return {
      ...message,
      id: message.timestamp,
      type: message.type,
      creationDate: DateTime.fromISO(message.creationDate),
    };
  }
}
