import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { TipoFeedbackDTO } from 'src/app/ModelDTO/DTO/tipoFeedback.DTO';


export class PersonalFeedbackResumenDTO implements IEntityDTO {

   tipo: TipoFeedbackDTO;
   totalFeedback = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.tipo = new TipoFeedbackDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.tipo != null) { this.tipo.PrepareDTO(jsonObj.tipo); }
      if (jsonObj.totalFeedback != null) { this.totalFeedback = jsonObj.totalFeedback; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
