import { Inject, Injectable } from '@angular/core';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { CompleterItem } from 'src/app/shared/lib/ngx-neo-completer-mat/components/completer-item';
import { FrontEndConfig, FrontEndConfigService } from 'src/app/shared/lib/ngx-neo-frontend-mat/FrontendConfig';
import { BaseEntitiesSearch } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/search/base-entitites.search';
import { ProfileImageThumbnailUrlPipe } from 'src/app/shared/pipes/profile-image-thumbnail-url.pipe';
import { PersonalEntityService } from 'src/app/shared/services/entities/personal-entity.service';

@Injectable({
  providedIn: 'root',
})
export class PersonalSearchService extends BaseEntitiesSearch {
  public todos: boolean;

  constructor(
    @Inject(FrontEndConfigService) frontEndConfig: FrontEndConfig,
    private personalEntityService: PersonalEntityService,
    private profileImagePipe: ProfileImageThumbnailUrlPipe,
  ) {
    super(frontEndConfig);
  }

  public async getEntitiesSearch(startWith: string): Promise<void> {
    if (startWith === null || startWith === undefined) {
      return;
    }
    let res = null;

    res = await this.personalEntityService.getEntities(startWith, this.todos);

    const matches: CompleterItem[] = new Array<CompleterItem>(res.length);
    res.forEach((item, index) => {
      matches[index] = this.convertToItem(item);
    });
    this.next(matches);
  }

  public convertToItem?(item: PersonalLegajoBasicoDTO): CompleterItem | null {
    return {
      title: item.nombreCompleto,
      originalObject: item,
      image: this.profileImagePipe.transform(item),
    } as CompleterItem;
  }

  public convertToItemDniCuil?(item: PersonalLegajoBasicoDTO): CompleterItem | null {
    return {
      title: `${item.nombreCompleto} - ${item.cuil}`,
      originalObject: item,
    } as CompleterItem;
  }
}
