import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Emoji } from 'src/app/lib/quill-emoji/emoji';

@Component({
  selector: 'app-emoji-picker-content',
  standalone: true,
  template: '<div #container></div>',
})
export class EmojiPickerContentComponent implements AfterViewInit {
  @ViewChild('container', { static: true }) public container: ElementRef;
  @Output() public emojiSelect = new EventEmitter<Emoji>();
  @Output() public mouseEnter = new EventEmitter<void>();
  @Output() public mouseLeave = new EventEmitter<void>();
  @Input({ required: true }) public picker: HTMLElement;

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.mouseEnter.emit();
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.mouseLeave.emit();
  }

  public ngAfterViewInit(): void {
    this.container.nativeElement.appendChild(this.picker);
  }
}
