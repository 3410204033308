import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { TipoUnidadDevolucion } from 'src/app/ModelDTO/DTO/tipoUnidadDevolucion.ENUM';
import { CategoriaCapacitacionBasicaDTO } from 'src/app/ModelDTO/DTO/categoriaCapacitacionBasica.DTO';
import { SubcategoriaCapacitacionDTO } from 'src/app/ModelDTO/DTO/subcategoriaCapacitacion.DTO';
import { TipoDevolucionCapacitacion } from 'src/app/ModelDTO/DTO/tipoDevolucionCapacitacion.ENUM';
import { TipoModalidad } from 'src/app/ModelDTO/DTO/tipoModalidad.ENUM';
import { TipoCaracter } from 'src/app/ModelDTO/DTO/tipoCaracter.ENUM';
import { CargoDTO } from 'src/app/ModelDTO/DTO/cargo.DTO';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';


export class TipoCapacitacionDTO implements IEntityDTO {

   nombre = '';
   descripcion = '';
   tiempoDevolucion = 0;
   unidadDevolucion: TipoUnidadDevolucion;
   categoria: CategoriaCapacitacionBasicaDTO;
   subcategoria: SubcategoriaCapacitacionDTO;
   tipoDevolucion: TipoDevolucionCapacitacion;
   modalidad: TipoModalidad;
   caracter: TipoCaracter;
   diasPrevioAviso = 0;
   cargos: Array<CargoDTO>;
   convenio: ConvenioColectivoDTO;
   asignarColaboradoresExistentes: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.categoria = new CategoriaCapacitacionBasicaDTO();
      this.subcategoria = new SubcategoriaCapacitacionDTO();
      this.cargos = new Array<CargoDTO>();
      this.convenio = new ConvenioColectivoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.tiempoDevolucion != null) { this.tiempoDevolucion = jsonObj.tiempoDevolucion; }
      if (jsonObj.unidadDevolucion != null) { this.unidadDevolucion = jsonObj.unidadDevolucion as TipoUnidadDevolucion; }
      if (jsonObj.categoria != null) { this.categoria.PrepareDTO(jsonObj.categoria); }
      if (jsonObj.subcategoria != null) { this.subcategoria.PrepareDTO(jsonObj.subcategoria); }
      if (jsonObj.tipoDevolucion != null) { this.tipoDevolucion = jsonObj.tipoDevolucion as TipoDevolucionCapacitacion; }
      if (jsonObj.modalidad != null) { this.modalidad = jsonObj.modalidad as TipoModalidad; }
      if (jsonObj.caracter != null) { this.caracter = jsonObj.caracter as TipoCaracter; }
      if (jsonObj.diasPrevioAviso != null) { this.diasPrevioAviso = jsonObj.diasPrevioAviso; }
      if (jsonObj.cargos != null) { for (const item of jsonObj.cargos) { const itemDTO = new CargoDTO(); itemDTO.PrepareDTO(item); this.cargos.push(itemDTO); } }
      if (jsonObj.convenio != null) { this.convenio.PrepareDTO(jsonObj.convenio); }
      if (jsonObj.asignarColaboradoresExistentes != null) { this.asignarColaboradoresExistentes = jsonObj.asignarColaboradoresExistentes; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
