import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { CargoBasicoDTO } from 'src/app/ModelDTO/DTO/cargoBasico.DTO';


export class CargoDTO implements IEntityDTO {

   superior: CargoBasicoDTO;
   nombre = '';
   descripcion = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.superior = new CargoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.superior != null) { this.superior.PrepareDTO(jsonObj.superior); }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
