import { Injectable, Inject } from '@angular/core';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageEntity.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { EventoDTO } from 'src/app/ModelDTO/DTO/evento.DTO';
import { EventSummaryDTO } from 'src/app/ModelDTO/DTO/eventSummary.DTO';
import { TipoEventoDTO } from 'src/app/ModelDTO/DTO/tipoEvento.DTO';
import { Observable, lastValueFrom, map } from 'rxjs';
import { CommentReactionDTO } from '../ModelDTO/DTO/commentReaction.DTO';
import { NewCommentReactionDTO } from '../ModelDTO/DTO/newCommentReaction.DTO';
import { CurrentReactionDTO } from '@api/interfaces/current-reaction.interface';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { NameDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/nameDetail.DTO';

@Injectable({
   providedIn: 'root'
})
export class EventoServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getEventoIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/evento/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getEventoIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/evento/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getEvento(): Promise<Array<EventoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/evento/'));
         const resJson = res.data;
         const resDTO = new Array<EventoDTO>();
         for (const item of resJson) {
            const itemDTO = new EventoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async deleteEventoId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/evento/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getEventoId(id: number): Promise<EventoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/evento/' + id));
         if (!res) { return null; }
         const resDTO = new EventoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertEvento(stream: FormData): Promise<EventoDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/evento/', stream));
         const resDTO = new EventoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateEventoId(id: number, stream: FormData): Promise<EventoDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/evento/' + id, stream));
         const resDTO = new EventoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getEventoProximos(): Promise<Array<EventSummaryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/evento/proximos'));
         const resJson = res.data;
         const resDTO = new Array<EventSummaryDTO>();
         for (const item of resJson) {
            const itemDTO = new EventSummaryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getEventoTipos(): Promise<TipoEventoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/evento/tipos'));
         if (!res) { return null; }
         const resDTO = new TipoEventoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public getEventoFiltrado(fechaDesde: Date, fechaHasta: Date, pageNumber: number, pageSize: number, isDesc = false): Observable<EventSummaryDTO[]> {
         return this.http.get<DataDTO>(Constants.apiURL + '/evento/filtrado' + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&isDesc=' + isDesc)
         .pipe(map(res=> {
          const resJson = res.data;
          const resDTO = new Array<EventSummaryDTO>();
          for (const item of resJson) {
             const itemDTO = new EventSummaryDTO();
             itemDTO.PrepareDTO(item);
             resDTO.push(itemDTO);
          }
          return resDTO;
         } ));
   }

   public async insertEventoIdImage(id: number, imageEntityDTO: ImageEntityDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/evento/' + id + '/Image', imageEntityDTO));

   }

   public async getEventoIdComentarios(id: number): Promise<Array<ComentarioDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/evento/' + id + '/comentarios'));
         const resJson = res.data;
         const resDTO = new Array<ComentarioDTO>();
         for (const item of resJson) {
            const itemDTO = new ComentarioDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertEventoIdComentarios(id: number, stream: FormData): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/evento/' + id + '/comentarios', stream));

   }

   public async updateEventoIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Promise<ComentarioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/evento/' + id + '/comentarios/' + iDComentario, comentarioDTO));
         const resDTO = new ComentarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteEventoIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/evento/' + id + '/comentarios/' + iDComentario));

   }

   public async getEventoIdAdjuntos(id: number): Promise<Array<AttachmentUaalooDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/evento/' + id + '/adjuntos'));
         const resJson = res.data;
         const resDTO = new Array<AttachmentUaalooDTO>();
         for (const item of resJson) {
            const itemDTO = new AttachmentUaalooDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertEventoChatGptDescription(nameDetailDTO: NameDetailDTO): Promise<NameDetailDTO> {
      const res = await lastValueFrom(this.http.post(Constants.apiURL + '/evento/chat-gpt/description', nameDetailDTO));
      const resDTO = new NameDetailDTO();
      resDTO.PrepareDTO(res);
      return resDTO;
   }

   public getEventoIdReactions(id: number): Observable<CommentReactionDTO[]> {
    return this.http.get<DataDTO>(Constants.apiURL + '/evento/' + id + '/reactions').pipe(
      map((res) =>
        res.data.map((element) => {
          const resDTO = new CommentReactionDTO();
          resDTO.PrepareDTO(element);
          return resDTO;
        })
      )
    );
   }

   public updateEventoIdReactions(id: number, newCommentReactionDTO: NewCommentReactionDTO): Observable<CurrentReactionDTO> {
      return this.http.put<CurrentReactionDTO>(Constants.apiURL + '/evento/' + id + '/reactions', newCommentReactionDTO);
   }

   public deleteEventoIdReactionsEMOJIID(id: number, emojiId: string): Observable<CurrentReactionDTO> {
      return this.http.delete<CurrentReactionDTO>(Constants.apiURL + '/evento/' + id + '/reactions/' + emojiId);
   }

}
