<div class="d-flex flex-row mt-2 file-input">
  <input type="text" class="flex-text-truncate upload-file-input pointer file-input-displayed" readonly (click)="fileInput.click()" />
  <div class="file-input-content">
    <p [innerHTML]="fileSelectedString || 'DOCUMENTS.ATTACH_A_FILE' | translate"></p>
    <button type="button" color="primary" mat-stroked-button (click)="fileInput.click()">
      {{ 'GENERAL.ATTACH' | translate }}
    </button>
  </div>
  <input
    class="file-input-hidden"
    [accept]="accept"
    (change)="onDocumentacionChange($event)"
    #fileInput
    type="file"
    id="file"
    [multiple]="!onlyOneFile"
  />
</div>
<table mat-table [dataSource]="arrayFilesASubir" *ngIf="arrayFilesASubir?.length > 0" matSort (matSortChange)="sortData($event)">
  <ng-container [matColumnDef]="ColumnNames.Nombre">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DOCUMENTS.NAME' | translate }}</th>
    <td mat-cell *matCellDef="let file">
      <div class="d-flex flex-row">
        <div class="d-flex flex-fill align-items-center gap-1">
          <span>
            <mat-icon fontSet="material-symbols-outlined" [svgIcon]="fileIconClass(file.name)"></mat-icon>
          </span>
          <span class="flex-text-truncate">{{ file.name }}</span>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container [matColumnDef]="ColumnNames.Acciones">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let file" class="text-right">
      <div class="form-sm text-right">
        <button type="button" mat-stroked-button color="danger" [command]="eliminarArchCmd" [commandValue]="file">
          {{ 'DOCUMENTS.REMOVE' | translate }}
        </button>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
