import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { FileDBDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/fileDB.DTO';
import { ReclutamientoCandidatoEstado } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoEstado.ENUM';
import { ReclutamientoCandidatoPuntajeDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoPuntaje.DTO';
import { ReclutamientoOfertaFaseDTO } from 'src/app/ModelDTO/DTO/reclutamientoOfertaFase.DTO';
import { CandidatoPostuladoOfertaDTO } from 'src/app/ModelDTO/DTO/candidatoPostuladoOferta.DTO';


export class ReclutamientoCandidatoParaFaseDTO implements IEntityDTO {

   fechaCarga: Date;
   actualizado: Date;
   nombre = '';
   apellido = '';
   email = '';
   telefono = '';
   image = '';
   cV: FileDBDTO;
   cartaDePresentacion = '';
   linkedin = '';
   linkPublico = '';
   estado: ReclutamientoCandidatoEstado;
   puntaje: ReclutamientoCandidatoPuntajeDTO;
   fase: ReclutamientoOfertaFaseDTO;
   postulate: CandidatoPostuladoOfertaDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.cV = new FileDBDTO();
      this.puntaje = new ReclutamientoCandidatoPuntajeDTO();
      this.fase = new ReclutamientoOfertaFaseDTO();
      this.postulate = new CandidatoPostuladoOfertaDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fechaCarga != null) { this.fechaCarga = new Date(jsonObj.fechaCarga); }
      if (jsonObj.actualizado != null) { this.actualizado = new Date(jsonObj.actualizado); }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.apellido != null) { this.apellido = jsonObj.apellido; }
      if (jsonObj.email != null) { this.email = jsonObj.email; }
      if (jsonObj.telefono != null) { this.telefono = jsonObj.telefono; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.cV != null) { this.cV.PrepareDTO(jsonObj.cV); }
      if (jsonObj.cartaDePresentacion != null) { this.cartaDePresentacion = jsonObj.cartaDePresentacion; }
      if (jsonObj.linkedin != null) { this.linkedin = jsonObj.linkedin; }
      if (jsonObj.linkPublico != null) { this.linkPublico = jsonObj.linkPublico; }
      if (jsonObj.estado != null) { this.estado = jsonObj.estado as ReclutamientoCandidatoEstado; }
      if (jsonObj.puntaje != null) { this.puntaje.PrepareDTO(jsonObj.puntaje); }
      if (jsonObj.fase != null) { this.fase.PrepareDTO(jsonObj.fase); }
      if (jsonObj.postulate != null) { this.postulate.PrepareDTO(jsonObj.postulate); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
