import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';

export class EncuestaEjecucionDTO implements IEntityDTO {

   fecha: Date;
   fechaVencimiento: Date;
   enviados = 0;
   completados = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fecha != null) { this.fecha = new Date(jsonObj.fecha); }
      if (jsonObj.fechaVencimiento != null) { this.fechaVencimiento = new Date(jsonObj.fechaVencimiento); }
      if (jsonObj.enviados != null) { this.enviados = jsonObj.enviados; }
      if (jsonObj.completados != null) { this.completados = jsonObj.completados; }
   }
}
