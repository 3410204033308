import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CuestionarioPreguntaDTO } from 'src/app/ModelDTO/DTO/cuestionarioPregunta.DTO';
import { PreguntaOpcionDTO } from 'src/app/ModelDTO/DTO/preguntaOpcion.DTO';
import { TypeQuestionComponent } from 'src/app/shared/forms/cuestionario-tipo-pregunta/typeQuestion.component';

@Component({
  selector: 'app-question-numeric',
  templateUrl: './question-numeric.component.html',
  styleUrls: ['./question-numeric.component.scss'],
})
export class QuestionNumericComponent implements TypeQuestionComponent {
  @Input() public quizQuestionForm: UntypedFormGroup;
  @Input() public optionEdit: PreguntaOpcionDTO;
  @Input() public questionEdit: CuestionarioPreguntaDTO;
  @Input() public withEvaluation: boolean;
}
