import { CompetenceResultDTO } from 'src/app/ModelDTO/DTO/competenceResult.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';

export class CompetenceSkillDTO implements IEntityDTO {

   name = '';
   id = 0;
   result: CompetenceResultDTO;

   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.result != null) {
        this.result = new CompetenceResultDTO();
        this.result.PrepareDTO(jsonObj.result);
      }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
