import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EstadisticasAgrupadaTipoAusenciaDTO } from 'src/app/ModelDTO/DTO/estadisticasAgrupadaTipoAusencia.DTO';
import { EstadisticasAgrupadaTipoBajaDTO } from 'src/app/ModelDTO/DTO/estadisticasAgrupadaTipoBaja.DTO';
import { EstadisticasArtDTO } from 'src/app/ModelDTO/DTO/estadisticasArt.DTO';
import { EstadisticasAusenciasDTO } from 'src/app/ModelDTO/DTO/estadisticasAusencias.DTO';
import { EstadisticasCambioEstadoDTO } from 'src/app/ModelDTO/DTO/estadisticasCambioEstado.DTO';
import { EstadisticasFirmasDTO } from 'src/app/ModelDTO/DTO/estadisticasFirmas.DTO';
import { EstadisticasPoblacionDTO } from 'src/app/ModelDTO/DTO/estadisticasPoblacion.DTO';
import { EstadisticasRotacionDTO } from 'src/app/ModelDTO/DTO/estadisticasRotacion.DTO';
import { NewsFormatDTO } from 'src/app/ModelDTO/DTO/newsFormat.DTO';
import { ResumenMensualDTO } from 'src/app/ModelDTO/DTO/resumenMensual.DTO';
import { Constants } from '../shared/constants';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { NameNumberDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/nameNumberDetail.DTO';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { SignatureType } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/signatureType.ENUM';

@Injectable({
   providedIn: 'root'
})
export class IndicadoresServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getIndicadoresResumen(from: Date, to: Date): Promise<ResumenMensualDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/indicadores/resumen' + '?from=' + from?.toISOString() + '&to=' + to?.toISOString()));
         if (!res) { return null; }
         const resDTO = new ResumenMensualDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getIndicadoresResumenXLSX(from: Date, to: Date, format: NewsFormatDTO, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/indicadores/resumen' + '?from=' + from?.toISOString() + '&to=' + to?.toISOString() + '&formatId=' + format.id  + '&BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/text' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getIndicadoresPoblacion(): Promise<EstadisticasPoblacionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/indicadores/poblacion'));
         if (!res) { return null; }
         const resDTO = new EstadisticasPoblacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getIndicadoresEdades(): Promise<Array<NameNumberDetailDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/indicadores/edades'));
         const resJson = res.data;
         const resDTO = new Array<NameNumberDetailDTO>();
         for (const item of resJson) {
            const itemDTO = new NameNumberDetailDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getIndicadoresAntiguedad(): Promise<Array<NameNumberDetailDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/indicadores/antiguedad'));
         const resJson = res.data;
         const resDTO = new Array<NameNumberDetailDTO>();
         for (const item of resJson) {
            const itemDTO = new NameNumberDetailDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getIndicadoresArt(): Promise<EstadisticasArtDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/indicadores/art'));
         if (!res) { return null; }
         const resDTO = new EstadisticasArtDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getIndicadoresAusencias(): Promise<EstadisticasAusenciasDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/indicadores/ausencias'));
         if (!res) { return null; }
         const resDTO = new EstadisticasAusenciasDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getIndicadoresAusenciasTotalesPorTipo(fecha: Date): Promise<EstadisticasAusenciasDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/indicadores/ausencias/totalesPorTipo' + '?fecha=' + fecha?.toISOString()));
         if (!res) { return null; }
         const resDTO = new EstadisticasAusenciasDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getIndicadoresEstados(): Promise<EstadisticasCambioEstadoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/indicadores/estados'));
         if (!res) { return null; }
         const resDTO = new EstadisticasCambioEstadoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getIndicadoresRotacion(): Promise<EstadisticasRotacionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/indicadores/rotacion'));
         if (!res) { return null; }
         const resDTO = new EstadisticasRotacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getIndicadoresBajastipo(): Promise<Array<EstadisticasAgrupadaTipoBajaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/indicadores/bajastipo'));
         const resJson = res.data;
         const resDTO = new Array<EstadisticasAgrupadaTipoBajaDTO>();
         for (const item of resJson) {
            const itemDTO = new EstadisticasAgrupadaTipoBajaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getIndicadoresAusenciasPorMes(): Promise<Array<EstadisticasAgrupadaTipoAusenciaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/indicadores/ausenciasPorMes'));
         const resJson = res.data;
         const resDTO = new Array<EstadisticasAgrupadaTipoAusenciaDTO>();
         for (const item of resJson) {
            const itemDTO = new EstadisticasAgrupadaTipoAusenciaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getIndicadoresArchivosConFirmas(params: {from:Date, to:Date, period:Date, pageSize:number, pageNumber:number, documentTypeId?:number, type?:SignatureType, teammateId?:number, officeId?: number, signaturesDone?:boolean, businessNameId?:number}): Promise<EstadisticasFirmasDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/indicadores/archivosConFirmas' + '?fechaDesde=' + params.from?.toISOString() +
            '&fechaHasta=' + params.to?.toISOString() +
            '&period=' + params.period?.toISOString() +
            '&pageSize=' + params.pageSize +
            '&pageNumber=' + params.pageNumber +
            '&documentTypeId=' + (params.documentTypeId ?? 0) +
            '&type=' + params.type +
            '&teammateId=' + (params.teammateId ?? 0) +
            '&officeId=' + (params.officeId ?? 0) +
            '&signaturesDone=' + (params.signaturesDone ?? false) +
            '&businessNameId=' + (params.businessNameId ?? 0)  ));
         if (!res) { return null; }
         const resDTO = new EstadisticasFirmasDTO();
         resDTO.PrepareDTO(res);
         return resDTO;
     }

}
