import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { PersonalAreaDTO } from 'src/app/ModelDTO/DTO/personalArea.DTO';
import { PersonalSectorDTO } from 'src/app/ModelDTO/DTO/personalSector.DTO';
import { CargoDTO } from 'src/app/ModelDTO/DTO/cargo.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';


export class PuestoDTO implements IEntityDTO {

   area: PersonalAreaDTO;
   sector: PersonalSectorDTO;
   cargo: CargoDTO;
   superior: PersonalLegajoBasicoDTO;
   oculto:boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.area = new PersonalAreaDTO();
      this.sector = new PersonalSectorDTO();
      this.cargo = new CargoDTO();
      this.superior = new PersonalLegajoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.area != null) { this.area.PrepareDTO(jsonObj.area); }
      if (jsonObj.sector != null) { this.sector.PrepareDTO(jsonObj.sector); }
      if (jsonObj.cargo != null) { this.cargo.PrepareDTO(jsonObj.cargo); }
      if (jsonObj.superior != null) { this.superior.PrepareDTO(jsonObj.superior); }
      if (jsonObj.oculto != null) { this.oculto = jsonObj.oculto; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
