<div class="d-flex flex-column gap-1 p-relative" [ngClass]="conPadding ? 'pl-6 pr-6 pb-6' : ''">
  <label class="label-quill" *ngIf="titulo?.length > 0">{{ titulo }}</label>
  <quill-editor
    #quilleditor
    [modules]="quillModule"
    class="form-control mb-0"
    [placeholder]="placeholder"
    [(ngModel)]="comentario.mensaje"
    bounds="quill-editor"
  >
  </quill-editor>
  <app-select-puntaje *ngIf="comentarioHandler.allowStars()" (rating)="selectRating($event)" #selectPuntaje></app-select-puntaje>
  <app-file-selector *ngIf="showFileSelector" [(files)]="filesToAdd"></app-file-selector>
  <div class="d-flex flex-row mb-2 align-items-center justify-content-between">
    <div class="d-flex justify-content-start align-items-center">
      <div *ngIf="comentarioHandler.showPrivate()">
        <mat-slide-toggle
          class="mr-2"
          [matTooltip]="'GENERAL.ADMINS_MENTIONED' | translate"
          color="primary"
          [checked]="privadoDefault"
          (change)="toggleVisibilidad()"
        >
          {{ 'GENERAL.PRIVATE' | translate }}
        </mat-slide-toggle>
      </div>
      <button *ngIf="!showFileSelector" mat-stroked-button color="primary" (click)="showFileSelector = !showFileSelector">
        <span class="d-none d-lg-inline-flex"> {{ 'GENERAL.ATTACH_FILE' | translate }}</span>
        <mat-icon fontSet="material-symbols-outlined" iconPositionEnd>attach_file</mat-icon>
      </button>
    </div>
    <div class="d-flex justify-content-end mt-2">
      <button
        mat-raised-button
        color="primary"
        [disabled]="!comentario?.mensaje?.length"
        [throttledExecution]="enviarMensaje"
        [throttledExecutionParams]="comentario"
      >
        {{ 'COMMENTS.SAVE_ACTION' | translate }}
      </button>
    </div>
  </div>
  <div class="d-flex flex-column gap-1">
    <app-comentarios-lista [id]="id" [comentarioHandler]="comentarioHandler"></app-comentarios-lista>
  </div>
</div>
